import "../styles/home.scss";
import "../styles/single-shipment.scss";
import "../styles/components.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useState, useRef, useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import {
  calculateDHLURL,
  createInternationalShipmentURL,
  getDHLPickupLocationsURL,
  getPackagingURL,
  getShipmentDetailsURL,
  getShipmentPackagesURL,
  searchForCustomersURL,
} from "../library/URLs";
import styled from "styled-components";
import ReactFlagsSelect from "react-flags-select";
import { generateTransactionID } from "../library/utilities";
import { UserProfileData, countryCodes } from "../library/constants";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BsDot } from "react-icons/bs";
import * as BiIcons from "react-icons/bi";
import phoneList from "../files/phone.json";
import { SessionManagement } from "../library/SessionManagement";
import "../styles/home.scss";
import "../styles/single-shipment.scss";
import "../styles/components.scss";
import SubscriptionInactive from "../components/SubscriptionInactive";
import moment from "moment/moment";

const LoadingContainer = styled.div`
  height: 155px;
  text-align: center;
  display: flex;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 5px;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #fcd2d2;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #c2eddd;
  margin-bottom: 20px;
  text-align: center;
`;

const ContentFieldsActionButton = styled.div`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  color: #ffffff;
  display: flex;
  font-size: 30px;
  margin-right: 13px;
  align-items: center;
  justify-content: center;
  background-color: #008561;
  cursor: pointer;
  padding-left: 2px;
`;

const SteppedNumber = styled.div`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #008561;
`;

const SteppedTitle = styled.span`
  font-size: 20px;
`;

const SubmitButton = styled.button`
  width: 250px;
`;

const DuplicateShipment = () => {
  const navigator = useNavigate();

  const form = useRef(null);

  const [contentFields, setContentFields] = useState(1);

  const [contentDescription1, setContentDescription1] = useState("");

  const [shipmentPackage, setShipmentPackage] = useState({
    content_description_1: "",
    content_hs_code_1: "",
    content_quantity_1: "",
    content_weight_1: "",
    content_value_1: "",
  });

  const [showSenderSuggestions, setShowSenderSuggestions] = useState(false);
  const [senderSuggestions, setSenderSuggestions] = useState([]);

  const [senderCountryCode, setSenderCountryCode] = useState("NG");
  const onSelect = (code) => {
    setSenderCountryCode(code);

    setIsShipmentCalculated(false);
    setShowPhase2Shipment(false);
  };

  const [receiverName, setReceiverName] = useState("");
  const [receiverAddress1, setReceiverAddress1] = useState("");
  const [receiverAddress2, setReceiverAddress2] = useState("");
  const [receiverCity, setReceiverCity] = useState("");
  const [receiverState, setReceiverState] = useState("");
  const [receiverPostcode, setReceiverPostcode] = useState("");
  const [receiverCountryCode, setReceiverCountryCode] = useState("US");
  const [receiverPhoneNumber, setReceiverPhoneNumber] = useState("");
  const [receiverEmailAddress, setReceiverEmailAddress] = useState("");

  const onSelectReceiverCountry = (code) => {
    setReceiverCountryCode(code);

    setIsShipmentCalculated(false);
    setShowPhase2Shipment(false);

    const countryCode =
      isNaN(code.substring(0, 1)) === false
        ? phoneList[code]
        : "+" + phoneList[code];
    setReceiverPhoneNumber(countryCode);
  };

  const [startDate, setStartDate] = useState(new Date());

  const [isCalculatingShipment, setIsCalculatingShipment] = useState(false);
  const [isCreatingShipment, setIsCreatingShipment] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [pickupLocations, setPickupLocations] = useState([]);
  const [shipmentPackages, setShipmentPackages] = useState([]);
  const [containerTypes, setContainerTypes] = useState([]);

  const [dhlPrice, setDHLPrice] = useState("0");
  const [operatorPrice, setOperatorPrice] = useState("0");

  const [isShipmentCalculated, setIsShipmentCalculated] = useState(false);
  const [showPhase2Shipment, setShowPhase2Shipment] = useState(false);

  const [shipmentNumber, setShipmentNumber] = useState("");

  const [weight, setWeight] = useState("");
  const [length, setLength] = useState("");
  const [breadth, setBreadth] = useState("");
  const [height, setHeight] = useState("");

  const [packageType, setPackageType] = useState("");
  const [insurance, setInsurance] = useState("");

  const [senderName, setSenderName] = useState("");
  const [senderEmailAddress, setSenderEmailAddress] = useState("");
  const [senderPhoneNumber, setSenderPhoneNumber] = useState("");
  const [senderAddress1, setSenderAddress1] = useState("");
  const [senderAddress2, setSenderAddress2] = useState("");
  const [senderCity, setSenderCity] = useState("");
  const [senderState, setSenderState] = useState("");
  const [senderPostcode, setSenderPostcode] = useState("");
  const [description, setDescription] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");


  const [carriers, setCarriers] = useState([]);

  const [selectedCarrierName, setSelectedCarrierName] = useState("");
  const [selectedCarrierCostPrice, setSelectedCarrierCostPrice] = useState("");
  const [selectedCarrierSellingPrice, setSelectedCarrierSellingPrice] =
    useState("");
  const [selectedCarrierInsurance, setSelectedCarrierInsurance] =
    useState("No");

  const selectSender = (customerName, customerNumber, emailAddress) => {
    setSenderName(customerName);
    setSenderEmailAddress(emailAddress);
    setSenderPhoneNumber(customerNumber);

    setShowSenderSuggestions(false);
  };

  const onChangeSenderDetails = (event, type) => {
    if (type === "packageType") {
      setPackageType(event.target.value);
    } else if (type === "weight") {
      setWeight(event.target.value);
    } else if (type === "length") {
      setLength(event.target.value);
    } else if (type === "breadth") {
      setBreadth(event.target.value);
    } else if (type === "height") {
      setHeight(event.target.value);
    } else if (type === "senderEmailAddress") {
      setSenderEmailAddress(event.target.value);
    } else if (type === "senderPhoneNumber") {
      setSenderPhoneNumber(event.target.value);
    } else if (type === "senderName") {
      setSenderName(event.target.value);
      searchForCustomers(event);
    } else if (type === "senderCity") {
      setSenderCity(event.target.value);
    } else if (type === "senderState") {
      setSenderState(event.target.value);
    } else if (type === "postcode") {
      setSenderPostcode(event.target.value);
    } else if (type === "address_1") {
      setSenderAddress1(event.target.value);
    } else if (type === "address_2") {
      setSenderAddress2(event.target.value);
    } else if (type === "description") {
      setDescription(event.target.value);
    } else if (type === "payment_method") {
      setPaymentMethod(event.target.value);
    }

    setIsShipmentCalculated(false);
    setShowPhase2Shipment(false);
  };

  const onChangeReceiverDetails = (event, type) => {
    setIsShipmentCalculated(false);
    setShowPhase2Shipment(false);

    if (type === "Name") {
      setReceiverName(event.target.value);
    } else if (type === "ReceiverPhone") {
      setReceiverPhoneNumber(event.target.value);
    } else if (type === "Email") {
      setReceiverEmailAddress(event.target.value);
    } else if (type === "City") {
      setReceiverCity(event.target.value);
    } else if (type === "State") {
      setReceiverState(event.target.value);
    } else if (type === "PostalCode") {
      setReceiverPostcode(event.target.value);
    } else if (type === "address_1") {
      setReceiverAddress1(event.target.value);
    } else if (type === "address_2") {
      setReceiverAddress2(event.target.value);
    }
  };

  const params = useParams();
  const shipmentID = params["shipmentID"];
  const data = {
    shipment_id: shipmentID,
  };

  const newData = { ...data, ...UserProfileData() };

  useEffect(() => {
    axios
      .post(getShipmentDetailsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response["data"]["success"] === false) return;

        const shipmentDetails = response["data"];

        console.log(shipmentDetails);

        const sender = shipmentDetails["data"]["user_id"];
        const senderType = sender.toString().split("-")[0];
        const senderID = sender.toString().split("-")[1];

        const shipmentNumber = shipmentDetails["data"]["shipment_no"];

        const senderName = shipmentDetails["data"]["sender_name"];
        const senderPhoneNumber = shipmentDetails["data"]["sender_phone_no"];
        const senderEmailAddress = shipmentDetails["data"]["sender_email"];
        const senderAddress1 = shipmentDetails["data"]["sender_address"];
        const senderAddress2 = shipmentDetails["data"]["sender_address_2"];
        const senderCity = shipmentDetails["data"]["sender_city"];
        const senderState = shipmentDetails["data"]["sender_state"];
        const senderPostcode = shipmentDetails["data"]["sender_postcode"];
        const senderCountryCode = shipmentDetails["data"]["sender_country"];

        const receiverName = shipmentDetails["data"]["recipient_name"];
        const receiverPhoneNumber =
          shipmentDetails["data"]["recipient_phone_no"];
        const receiverEmailAddress = shipmentDetails["data"]["recipient_email"];
        const receiverAddress1 = shipmentDetails["data"]["receiver_address"];
        const receiverAddress2 = shipmentDetails["data"]["receiver_address_2"];
        const receiverCity = shipmentDetails["data"]["receiver_city"];
        const receiverState = shipmentDetails["data"]["receiver_state"];
        const receiverPostcode = shipmentDetails["data"]["receiver_postcode"];
        const receiverCountryCode = shipmentDetails["data"]["receiver_country"];
        const itemValue = shipmentDetails["data"]["item_value"];
        const itemValueCurrency =
          shipmentDetails["data"]["item_value_currency"];

        const packageType = shipmentDetails["data"]["package_type"];
        const insurance = shipmentDetails["data"]["insurance"];

        const weight = shipmentDetails["data"]["weight"];

        const length = shipmentDetails["data"]["length"];
        const breadth = shipmentDetails["data"]["breadth"];
        const height = shipmentDetails["data"]["height"];
        const description = shipmentDetails["data"]["item_name"];
        const payment_method = shipmentDetails["data"]["payment_method"];

        const timestamp = shipmentDetails["data"]["date_time"];
        const shipmentTime = moment(timestamp).format("ddd MMM Do, h:mm A");

        setShipmentNumber(shipmentNumber);
        setWeight(weight);
        setLength(length);
        setBreadth(breadth);
        setHeight(height);

        setInsurance(insurance);
        setPackageType(packageType);
        setDescription(description);
        setPaymentMethod(payment_method);

        setSenderName(senderName);
        setSenderPhoneNumber(senderPhoneNumber);
        setSenderEmailAddress(senderEmailAddress);
        setSenderAddress1(senderAddress1);
        setSenderAddress2(senderAddress2);
        setSenderCity(senderCity);
        setSenderState(senderState);
        setSenderPostcode(senderPostcode);
        setSenderCountryCode(senderCountryCode);

        // setAmount(amount);
        setReceiverName(receiverName);
        setReceiverPhoneNumber(receiverPhoneNumber);
        setReceiverEmailAddress(receiverEmailAddress);
        setReceiverAddress1(receiverAddress1);
        setReceiverAddress2(receiverAddress2);
        setReceiverCity(receiverCity);
        setReceiverState(receiverState);
        setReceiverPostcode(receiverPostcode);
        setReceiverCountryCode(receiverCountryCode);
      });
  }, []);

  useEffect(() => {
    axios
      .post(getDHLPickupLocationsURL, UserProfileData())
      .then(function (response) {
        if (response["data"]["success"] === true) {
          const pickupLocations = response["data"]["data"];

          setPickupLocations(pickupLocations);
        }
      })
      .catch(function (error) {
        return error;
      });
  }, []);

  useEffect(() => {
    const data = {
      shipment_number: shipmentNumber,
    };

    const newData = { ...data, ...UserProfileData() };

    axios
      .post(getShipmentPackagesURL, newData)
      .then(function (response) {
        if (response["data"]) {
          const packages = response["data"];
          let packageObj = {};
          if (packages && packages?.length > 0) {
            packages?.forEach((packageItem, id) => {
              packageObj[`content_description_${id + 1}`] =
                packageItem?.description;
              packageObj[`content_hs_code_${id + 1}`] = packageItem?.hs_code;
              packageObj[`content_quantity_${id + 1}`] = packageItem?.quantity;
              packageObj[`content_weight_${id + 1}`] = packageItem?.weight;
              packageObj[`content_value_${id + 1}`] = parseFloat(packageItem?.value);
            });
            setShipmentPackage(packageObj);
            setContentFields(packages?.length)
          }

          setContentDescription1("My description");
          // setPickupLocations(packages);
        }
      })
      .catch(function (error) {
        return error;
      });
  }, [shipmentNumber]);

  useEffect(() => {
    axios
      .post(getPackagingURL, UserProfileData())
      .then(function (response) {
        if (response["data"]["success"] === true) {
          const packaging = response["data"]["data"];

          setContainerTypes(packaging);
        }
      })
      .catch(function (error) {
        return error;
      });
  }, []);

  const clearErrorMessage = () => {
    setErrorVisibility(false);
    setErrorMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    clearErrorMessage();

    const data = new FormData(form.current);

    const formValues = {};

    for (const [key, value] of data.entries()) {
      formValues[key] = value;
    }

    formValues["transaction_id"] = generateTransactionID(15);
    formValues["sender_country_code"] = senderCountryCode;
    formValues["receiver_country_code"] = receiverCountryCode;

    formValues["amount"] = selectedCarrierSellingPrice;
    formValues["insurance"] = selectedCarrierInsurance;
    formValues["carrier"] = selectedCarrierName;

    const newData = { ...formValues, ...shipmentPackage, ...UserProfileData() };

    console.log(newData);

    setIsCreatingShipment(true);

    axios
      .post(createInternationalShipmentURL, newData)
      .then(function (response) {
        console.log(response);
        setIsCreatingShipment(false);

        if (response["data"]["success"] === false) {
          window.scrollTo(0, 0);
          setErrorVisibility(true);
          setErrorMessage(response["data"]["message"]);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        } else if (response["data"]["success"] === true) {
          window.scrollTo(0, 0);

          const shipmentID = response["data"]["data"]["shipment_id"];

          setSuccessVisibility(true);
          setSuccessMessage(
            "Shipment has been successfully created. Redirecting..."
          );

          setTimeout(() => {
            navigator("/shipment/" + shipmentID);
          }, 3000);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const calculateDHLPrice = () => {
    const data = new FormData(form.current);

    const formValues = {};

    for (const [key, value] of data.entries()) {
      formValues[key] = value;
    }

    formValues["transaction_id"] = generateTransactionID(15);
    formValues["sender_country_code"] = senderCountryCode;
    formValues["receiver_country_code"] = receiverCountryCode;

    const newData = { ...formValues, ...shipmentPackage, ...UserProfileData() };

    setIsCalculatingShipment(true);

    axios
      .post(calculateDHLURL, newData)
      .then(function (response) {
        console.log(response);
        setIsCalculatingShipment(false);
        if (response["data"]["success"] === true) {
          const dhlPrice = response["data"]["data"]["dhl_price"];
          const operatorPrice = response["data"]["data"]["operator_price"];

          setDHLPrice(dhlPrice);
          setOperatorPrice(operatorPrice);

          const carriers = response["data"]["data"]["carriers"];
          setShowPhase2Shipment(true);

          console.log(carriers);

          carriers.forEach((carrier) => {
            carrier["selected"] = false;
          });
          setCarriers(carriers);
        } else {
          window.scrollTo(0, 0);
          setErrorVisibility(true);
          setErrorMessage(response["data"]["message"]);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);

          console.log("Not getting here");
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const handleContinueShipment = () => {
    setShowPhase2Shipment(true);
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handlePackageInput = (e) => {
    const { name, value } = e.target;

    let packageCopy = { ...shipmentPackage };
    packageCopy[name] = value;
    setShipmentPackage(packageCopy);
  };

  const createContentFields = () => {
    const td = [];
    for (let index = 1; index <= contentFields; index++) {
      td.push(
        <Row className="form-row mb-3">
          <Col className="form-group col-xl-5 col-md-3">
            <label htmlFor="Length">Description</label>
            <input
              onChange={(e) => handlePackageInput(e)}
              value={shipmentPackage[`content_description_${index}`]}
              name={`content_description_${index}`}
              className="form-control"
              type="text"
              placeholder=""
            />
          </Col>

          <Col className="form-group col-xl-3 col-md-2">
            <label htmlFor="Length">HS Code</label>
            <input
              name={`content_hs_code_${index}`}
              onChange={(e) => handlePackageInput(e)}
              value={shipmentPackage[`content_hs_code_${index}`]}
              className="form-control"
              type="text"
              placeholder=""
            />
          </Col>

          <Col className="form-group col-xl-1 col-md-6">
            <label htmlFor="Quantity">Quantity</label>
            <input
              name={`content_quantity_${index}`}
              className="form-control"
              onChange={(e) => handlePackageInput(e)}
              value={shipmentPackage[`content_quantity_${index}`]}
              type="text"
              placeholder=""
            />
          </Col>

          <Col className="form-group col-xl-1 col-md-6">
            <label htmlFor="Weight">Weight</label>
            <input
              name={`content_weight_${index}`}
              className="form-control"
              onChange={(e) => handlePackageInput(e)}
              value={shipmentPackage[`content_weight_${index}`]}
              type="text"
              placeholder=""
            />
          </Col>

          <Col className="form-group col-xl-2 col-md-6">
            <label htmlFor="Value">Value (USD)</label>
            <input
              name={`content_value_${index}`}
              className="form-control"
              onChange={(e) => handlePackageInput(e)}
              value={shipmentPackage[`content_value_${index}`]}
              type="number"
            />
          </Col>
        </Row>
      );
    }
    return td;
  };

  const handleContentFieldsIncrease = () => {
    if (contentFields < 5) {
      let packageCopy = { ...shipmentPackage };
      packageCopy[`content_description_${contentFields + 1}`] = "";
      packageCopy[`content_hs_code_${contentFields + 1}`] = "";
      packageCopy[`content_quantity_${contentFields + 1}`] = "";
      packageCopy[`content_weight_${contentFields + 1}`] = "";
      packageCopy[`content_value_${contentFields + 1}`] = "";
      setShipmentPackage(packageCopy);
      setContentFields(contentFields + 1);
    }
  };

  const updateContents = (packageNumber) => {
    setContentFields(packageNumber);
  };

  const updateRowContents = (event, type, index) => {
    if (index === 1) {
      if (type === "description") {
        setContentDescription1(event.target.value);
      }
    }
  };

  const handleContentFieldsDecrease = () => {
    if (contentFields > 1) {
      let newObj = Object.fromEntries(
        Object.entries(shipmentPackage).filter(
          ([key]) =>
            key !== `content_description_${contentFields}` ||
            key !== `content_hs_code_${contentFields}` ||
            key !== `content_quantity_${contentFields}` ||
            key !== `content_weight_${contentFields}` ||
            key !== `content_value_${contentFields}`
        )
      );

      setShipmentPackage(newObj);

      setContentFields(contentFields - 1);
    }
  };

  const searchForCustomers = (e) => {
    const query = e.target.value;

    if (query.toString().trim() === "") {
      return null;
    }

    const formValues = {};
    formValues["query"] = query;
    formValues["limit"] = "5";

    const newData = { ...formValues, ...UserProfileData() };

    console.log(newData);

    axios.post(searchForCustomersURL, newData).then(function (response) {
      console.log(response);

      if (response["data"]["success"] === false) return;

      const customers = response["data"]["data"];

      setShowSenderSuggestions(true);
      setSenderSuggestions(customers);
    });
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />

          <div className="container-fluid">
            <div className="card bg-light border-0 shadow px-4 py-3">
              <div className="">
                {isError === false ? null : (
                  <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
                )}

                {isSuccess === false ? null : (
                  <SuccessMessageContainer>
                    {successMessage}
                  </SuccessMessageContainer>
                )}

                <form ref={form} onSubmit={handleSubmit}>
                  <section>
                    <div className="d-flex align-items-center mb-2">
                      <SteppedNumber>1</SteppedNumber>
                      <SteppedTitle className="mx-2">
                        Package Details
                      </SteppedTitle>
                    </div>
                    <Row className="form-row mt-3">
                      <Col className="form-group col-xl-4 col-md-6">
                        <label htmlFor="Weight">Weight</label>
                        <input
                          onChange={(e) => onChangeSenderDetails(e, "weight")}
                          value={weight}
                          name="weight"
                          className="form-control"
                          type="text"
                        />
                      </Col>

                      <Col className="form-group col-xl-4 col-md-6">
                        <label htmlFor="Value">Parcel Type</label>
                        <select
                          onChange={(e) =>
                            onChangeSenderDetails(e, "packageType")
                          }
                          value={packageType}
                          name="parcel_type"
                          className="form-select"
                        >
                          <option value="Parcel">Parcel</option>
                          <option value="Document">Document</option>
                        </select>
                      </Col>

                      <Col className="form-group col-xl-4 col-md-6">
                        <label htmlFor="Value">Insurance</label>
                        <select name="insurance" className="form-select">
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </Col>
                    </Row>

                    <Row className="form-row mt-3">
                      <Col className="form-group col-xl-4 col-md-6">
                        <label htmlFor="Length">Length</label>
                        <input
                          onChange={(e) => onChangeSenderDetails(e, "length")}
                          value={length}
                          name="length"
                          className="form-control"
                          type="text"
                        />
                      </Col>

                      <Col className="form-group col-xl-4 col-md-6">
                        <label htmlFor="Breadth">Breadth</label>
                        <input
                          onChange={(e) => onChangeSenderDetails(e, "breadth")}
                          value={breadth}
                          name="breadth"
                          className="form-control"
                          type="text"
                        />
                      </Col>

                      <Col className="form-group col-xl-4 col-md-6">
                        <label htmlFor="Height">Height</label>
                        <input
                          onChange={(e) => onChangeSenderDetails(e, "height")}
                          value={height}
                          name="height"
                          className="form-control"
                          type="text"
                        />
                      </Col>
                    </Row>
                  </section>

                  <section className="mt-5">
                    <div className="d-flex align-items-center mb-2">
                      <SteppedNumber>2</SteppedNumber>
                      <SteppedTitle className="mx-2">
                        Sender Details
                      </SteppedTitle>
                    </div>
                    <Row className="form-row">
                      <Col className="form-group col-xl-4 col-md-6">
                        <label htmlFor="Full Name">Full Name</label>
                        <input
                          onChange={(e) =>
                            onChangeSenderDetails(e, "senderName")
                          }
                          name="sender_name"
                          value={senderName}
                          className="form-control"
                          type="text"
                        />
                        <div
                          style={{ width: "31%" }}
                          className={`suggestions-field ${
                            showSenderSuggestions
                              ? "suggestions-field-visible"
                              : null
                          }`}
                        >
                          {senderSuggestions.map((suggestions) => {
                            const fullName = suggestions.fullname;
                            const phoneNumber = suggestions.phone;
                            const emailAddress = suggestions.email;
                            return (
                              <div
                                onClick={(e) =>
                                  selectSender(
                                    fullName,
                                    phoneNumber,
                                    emailAddress
                                  )
                                }
                                key={suggestions.id}
                                className="single-suggestion"
                              >
                                <span>{fullName}</span>
                                <div className="d-flex flex-row align-items-center">
                                  <span style={{ fontSize: 13, marginTop: -5 }}>
                                    {phoneNumber}
                                  </span>
                                  <BsDot />
                                  <span style={{ fontSize: 13, marginTop: -5 }}>
                                    {emailAddress}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Col>

                      <Col className="form-group col-xl-4 col-md-6">
                        <label htmlFor="Email">Email</label>
                        <input
                          onChange={(e) =>
                            onChangeSenderDetails(e, "senderEmailAddress")
                          }
                          value={senderEmailAddress}
                          name="sender_email"
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                      </Col>

                      <Col className="form-group col-xl-4 col-md-6">
                        <label htmlFor="Phone Number">Phone Number</label>
                        <input
                          onChange={(e) =>
                            onChangeSenderDetails(e, "senderPhoneNumber")
                          }
                          value={senderPhoneNumber}
                          name="sender_phone"
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                      </Col>
                    </Row>

                    <Row className="form-row mt-3">
                      <Col className="form-group col-xl-6 col-md-6">
                        <label htmlFor="Address">Address</label>
                        <input
                          onChange={(e) =>
                            onChangeSenderDetails(e, "address_1")
                          }
                          value={senderAddress1}
                          name="sender_address"
                          className="form-control"
                          type="text"
                          maxLength={45}
                        />
                      </Col>

                      <Col className="form-group col-xl-6 col-md-6">
                        <label htmlFor="Address">Address 2 (Optional)</label>
                        <input
                          onChange={(e) =>
                            onChangeSenderDetails(e, "address_2")
                          }
                          value={senderAddress2}
                          name="sender_address_2"
                          className="form-control"
                          type="text"
                          placeholder=""
                          maxLength={45}
                        />
                      </Col>
                    </Row>

                    <Row className="form-row mt-3">
                      <Col className="form-group col-xl-3 col-md-6">
                        <label htmlFor="Sender Country">Country</label>
                        <ReactFlagsSelect
                          className="countries-text-field"
                          selected={senderCountryCode}
                          onSelect={onSelect}
                          countries={["fi", "NG"]}
                        />
                      </Col>

                      <Col className="form-group col-xl-3 col-md-6">
                        <label htmlFor="Sender City">City</label>
                        <input
                          onChange={(e) =>
                            onChangeSenderDetails(e, "senderCity")
                          }
                          value={senderCity}
                          name="sender_city"
                          className="form-control"
                          type="text"
                        />
                      </Col>

                      <Col className="form-group col-xl-3 col-md-6">
                        <label htmlFor="Sender State">State</label>
                        <input
                          onChange={(e) =>
                            onChangeSenderDetails(e, "senderState")
                          }
                          value={senderState}
                          name="sender_state"
                          className="form-control"
                          type="text"
                        />
                      </Col>

                      <Col className="form-group col-xl-3 col-md-6">
                        <label htmlFor="Sender Postal">Postal Code</label>
                        <input
                          onChange={(e) => onChangeSenderDetails(e, "postcode")}
                          value={senderPostcode}
                          name="sender_postal_code"
                          className="form-control"
                          type="text"
                        />
                      </Col>
                    </Row>
                  </section>

                  <section className="mt-5">
                    <div className="d-flex align-items-center mb-2">
                      <SteppedNumber>3</SteppedNumber>
                      <SteppedTitle className="mx-2">
                        Receiver Details
                      </SteppedTitle>
                    </div>
                    <Row className="form-row">
                      <Col className="form-group col-xl-3 col-md-6">
                        <label htmlFor="Sender Country">Country</label>
                        <ReactFlagsSelect
                          className="countries-text-field"
                          selected={receiverCountryCode}
                          onSelect={onSelectReceiverCountry}
                          searchable={true}
                          countries={countryCodes}
                        />
                      </Col>

                      <Col className="form-group col-xl-3 col-md-6">
                        <label htmlFor="Receiver City">City</label>
                        <input
                          onChange={(e) => onChangeReceiverDetails(e, "city")}
                          value={receiverCity}
                          name="receiver_city"
                          className="form-control"
                          type="text"
                        />
                      </Col>

                      <Col className="form-group col-xl-3 col-md-6">
                        <label htmlFor="Receiver State">State</label>
                        <input
                          onChange={(e) => onChangeReceiverDetails(e, "state")}
                          value={receiverState}
                          name="receiver_county"
                          className="form-control"
                          type="text"
                        />
                      </Col>

                      <Col className="form-group col-xl-3 col-md-6">
                        <label htmlFor="Receiver Postal">Postal Code</label>
                        <input
                          onChange={(e) =>
                            onChangeReceiverDetails(e, "postalCode")
                          }
                          value={receiverPostcode}
                          name="receiver_postal_code"
                          className="form-control"
                          type="text"
                        />
                      </Col>
                    </Row>

                    <Row className="form-row mt-3">
                      <Col className="form-group col-xl-6 col-md-6">
                        <label htmlFor="Address">Address</label>
                        <input
                          onChange={(e) =>
                            onChangeReceiverDetails(e, "address_1")
                          }
                          value={receiverAddress1}
                          name="receiver_address"
                          className="form-control"
                          type="text"
                          maxLength={45}
                        />
                      </Col>

                      <Col className="form-group col-xl-6 col-md-6">
                        <label htmlFor="Address">Address 2 (Optional)</label>
                        <input
                          onChange={(e) =>
                            onChangeReceiverDetails(e, "address_2")
                          }
                          value={receiverAddress2}
                          name="receiver_address_2"
                          className="form-control"
                          type="text"
                          placeholder=""
                          maxLength={45}
                        />
                      </Col>
                    </Row>

                    <Row className="form-row mt-3">
                      <Col className="form-group col-xl-4 col-md-6">
                        <label htmlFor="Full Name">Full Name</label>
                        <input
                          name="receiver_name"
                          onChange={(e) => onChangeReceiverDetails(e, "Name")}
                          className="form-control"
                          type="text"
                          value={receiverName}
                        />
                      </Col>

                      <Col className="form-group col-xl-4 col-md-6">
                        <label htmlFor="Email">Email</label>
                        <input
                          name="receiver_email"
                          onChange={(e) => onChangeReceiverDetails(e, "Email")}
                          className="form-control"
                          type="text"
                          placeholder=""
                          value={receiverEmailAddress}
                        />
                      </Col>

                      <Col className="form-group col-xl-4 col-md-6">
                        <label htmlFor="Phone Number">Phone Number</label>
                        <input
                          name="receiver_phone"
                          onChange={(e) =>
                            onChangeReceiverDetails(e, "ReceiverPhone")
                          }
                          value={receiverPhoneNumber}
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                      </Col>
                    </Row>
                  </section>

                  <section className="mt-5">
                    <div className="d-flex align-items-center mb-2">
                      <SteppedNumber>4</SteppedNumber>
                      <SteppedTitle className="mx-2">
                        Content Details
                      </SteppedTitle>
                    </div>
                    {createContentFields()}
                    <div className="d-flex justify-content-center">
                      <ContentFieldsActionButton
                        onClick={handleContentFieldsDecrease}
                      >
                        -
                      </ContentFieldsActionButton>
                      <ContentFieldsActionButton
                        onClick={handleContentFieldsIncrease}
                      >
                        +
                      </ContentFieldsActionButton>
                    </div>

                    {isShipmentCalculated === false &&
                    isCalculatingShipment === false ? (
                      <div className="d-flex justify-content-end mt-5">
                        <SubmitButton
                          onClick={calculateDHLPrice}
                          type="button"
                          className="confirm-button"
                        >
                          <span className="button-text">CALCULATE COST</span>
                        </SubmitButton>
                      </div>
                    ) : isShipmentCalculated === true ? null : (
                      <div className="d-flex justify-content-end mt-5">
                        <SubmitButton
                          onClick={calculateDHLPrice}
                          type="button"
                          className="confirm-button"
                        >
                          <span className="spinner-border spinner-grow-sm" />
                        </SubmitButton>
                      </div>
                    )}

                    {isShipmentCalculated === true &&
                    showPhase2Shipment === false ? (
                      <div className="d-flex justify-content-end mt-5">
                        <SubmitButton type="button" className="confirm-button">
                          <span
                            onClick={handleContinueShipment}
                            className="button-text"
                          >
                            CONTINUE
                          </span>
                        </SubmitButton>
                      </div>
                    ) : null}
                  </section>

                  {showPhase2Shipment === false ? null : (
                    <div>
                      <section className="mt-5">
                        <div className="d-flex align-items-center mb-2">
                          <SteppedNumber>4</SteppedNumber>
                          <SteppedTitle className="mx-2">
                            Carriers{" "}
                            <span style={{ fontSize: 15, fontStyle: "italic" }}>
                              (Select a carrier)
                            </span>
                          </SteppedTitle>
                        </div>
                        <table style={{ width: "100%" }}>
                          <tbody style={{ width: "100%" }}>
                            {carriers.map((carrier) => {
                              const selectCarrier = (selectedCarrier) => {
                                let newCarriers = [...carriers];
                                newCarriers.forEach((transporter) => {
                                  transporter["selected"] = false;
                                });

                                const carrierIndex = carriers.findIndex(
                                  (carrier) =>
                                    carrier.carrier_id ===
                                    selectedCarrier.carrier_id
                                );

                                newCarriers[carrierIndex] = {
                                  ...newCarriers[carrierIndex],
                                  selected: !newCarriers[carrierIndex].selected,
                                };

                                setCarriers(newCarriers);

                                setSelectedCarrierCostPrice(carrier.cost_price);
                                setSelectedCarrierSellingPrice(
                                  carrier.selling_price
                                );
                                setSelectedCarrierName(
                                  selectedCarrier.carrier_name
                                );

                                if (
                                  selectedCarrier.carrier_name ===
                                  "DHL + Insurance"
                                ) {
                                  setSelectedCarrierInsurance("Yes");
                                  console.log("Yes");
                                } else {
                                  setSelectedCarrierInsurance("No");
                                  console.log("No");
                                }
                              };

                              return (
                                <tr
                                  onClick={() => selectCarrier(carrier)}
                                  style={{ cursor: "pointer" }}
                                  key={carrier.carrier_id}
                                >
                                  <td
                                    className="description"
                                    style={{ width: 100 }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <img
                                        className="icon"
                                        src={carrier.carrier_logo}
                                        alt=""
                                      />
                                    </div>
                                  </td>
                                  <td className="amount">
                                    <div className="d-flex flex-column">
                                      <span style={{ fontSize: 18 }}>
                                        {carrier.carrier_name}
                                      </span>
                                      <span>{carrier.delivery_time}</span>
                                    </div>
                                  </td>
                                  {SessionManagement.getCompanyID() !== "44" ? (
                                    <>
                                      <td className="amount">
                                        <div className="d-flex flex-column">
                                          <span style={{ fontSize: 18 }}>
                                            ₦{Math.ceil(carrier.cost_price)}
                                          </span>
                                          <span>Cost Price</span>
                                        </div>
                                      </td>
                                    </>
                                  ) : null}

                                  <td className="amount">
                                    <div className="d-flex flex-column">
                                      <span style={{ fontSize: 18 }}>
                                        ₦{Math.ceil(carrier.selling_price)}
                                      </span>
                                      <span>Selling Price</span>
                                    </div>
                                  </td>
                                  <td className="status">
                                    {carrier.selected === false ? null : (
                                      <div
                                        style={{
                                          borderRadius: "50%",
                                          float: "right",
                                          height: 40,
                                          width: 40,
                                          backgroundColor: "#008561",
                                          textAlign: "center",
                                        }}
                                      >
                                        <BiIcons.BiCheck
                                          style={{
                                            fontSize: 25,
                                            color: "white",
                                            marginTop: 7,
                                          }}
                                        />
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </section>

                      <section className="mt-5">
                        <div className="d-flex align-items-center mb-2">
                          <SteppedNumber>5</SteppedNumber>
                          <SteppedTitle className="mx-2">
                            Entire Package Details
                          </SteppedTitle>
                        </div>
                        <Row className="form-row mt-3">
                          <Col className="form-group col-xl-8 col-md-6">
                            <label htmlFor="Description">Description</label>
                            <input
                              name="description"
                              onChange={(e) =>
                                onChangeSenderDetails(e, "description")
                              }
                              value={description}
                              className="form-control"
                              type="text"
                              maxLength={80}
                            />
                          </Col>

                          <Col className="form-group col-xl-4 col-md-6">
                            <label htmlFor="Sender Postal">
                              Payment Method
                            </label>
                            <select
                              name="payment_method"
                              className="form-select"
                              onChange={(e) =>
                                onChangeSenderDetails(e, "payment_method")
                              }
                              value={paymentMethod}
                            >
                              <option value="">Select Payment Method</option>
                              <option value="Cash">Cash</option>
                              <option value="Card Payment">Card Payment</option>
                              <option value="Direct Transfer">
                                Direct Transfer
                              </option>
                              <option value="Wallet">Wallet</option>
                            </select>
                          </Col>
                        </Row>

                        <Row className="form-row mt-3">
                          <Col className="form-group col-xl-4 col-md-6">
                            <label htmlFor="Weight">
                              Choose Container Type
                            </label>
                            <select
                              name="container_type_id"
                              className="form-select"
                            >
                              <option value="">Choose container type</option>
                              {containerTypes.map((container) => {
                                return (
                                  <option
                                    key={container.id}
                                    value={container.id}
                                  >
                                    {container.name}
                                  </option>
                                );
                              })}
                            </select>
                          </Col>

                          <Col className="form-group col-xl-4 col-md-6">
                            <label htmlFor="Sender Country">
                              Pickup Station
                            </label>
                            <select
                              name="pickup_detail_id"
                              className="form-select"
                            >
                              <option value="">Choose pickup station</option>
                              {pickupLocations.map((locations) => {
                                return (
                                  <option
                                    key={locations.id}
                                    value={locations.id}
                                  >
                                    {locations.address}
                                  </option>
                                );
                              })}
                            </select>
                          </Col>

                          <Col className="form-group col-xl-4 col-md-6">
                            <label htmlFor="Sender City">Pickup Date</label>
                            <ReactDatePicker
                              name="shipping_date"
                              className="form-control"
                              selected={startDate}
                              dateFormat="P"
                              onChange={(date) => setStartDate(date)}
                            />
                            {/* <input className="form-control" type="text" /> */}
                          </Col>
                        </Row>
                      </section>

                      <div className="d-flex flex-row my-5 justify-content-end">
                        <SubmitButton type="submit" className="confirm-button">
                          {isCreatingShipment === false ? (
                            <span className="button-text">
                              PROCESS SHIPMENT
                            </span>
                          ) : (
                            <span className="spinner-border spinner-grow-sm" />
                          )}
                        </SubmitButton>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DuplicateShipment;
