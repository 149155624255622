import "../styles/home.scss";
import "../styles/single-shipment.scss";
import "../styles/components.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useState } from "react";
import {SessionManagement} from "../library/SessionManagement";
import SubscriptionInactive from "../components/SubscriptionInactive";
import CreateStationForm from "./CreateStationForm";

const EditStation = () => {
  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />
          <div className="d-flex flex-row justify-content-between mb-2 mx-3">
            <h5 className="header">Edit Station</h5>
            <span>Home</span>
          </div>
          <div className="container-fluid">
            <div className="card bg-light border-0 shadow px-3 px-md-4 py-5">
                <CreateStationForm action="edit" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditStation;
