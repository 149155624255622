import "../styles/home.scss";
import "../styles/create-shipment.scss";
import "../styles/components.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useState } from "react";
import SubscriptionInactive from "../components/SubscriptionInactive";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import CreateShipIcon from "../assets/create-shipment/monitor_weight_gain.svg";
import LocalIcon from "../assets/create-shipment/local.svg";
import LocalActiveIcon from "../assets/create-shipment/local-active.svg";
import InterstateIcon from "../assets/create-shipment/interstate.svg";
import InterstateActiveIcon from "../assets/create-shipment/interstate-active.svg";
import InternationalIcon from "../assets/create-shipment/international.svg";
import InternationalActiveIcon from "../assets/create-shipment/international-active.svg";
import LocalShipment from "./CreateShipmentForms/Local";
import InterstateShipment from "./CreateShipmentForms/Interstate";
import InternationalShipment from "./CreateShipmentForms/International";

const CreateShipment = () => {
  const [shipmentType, setShipmentType] = useState("local");

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />
          <div className="container-fluid create-shipment-container">
            <div className="navigation-contain">
              <button className="back-btn">
                <BsChevronLeft />
              </button>
              <ul className="nav-options">
                <li>Shipments</li>
                <li className="nav-options-arrow">
                  <BsChevronRight />{" "}
                </li>
                <li>Create Shipments</li>
                <li className="nav-options-arrow">
                  <BsChevronRight />{" "}
                </li>
                <li className="nav-options-active">
                  {shipmentType === "local"
                    ? "Intracity Shipment"
                    : shipmentType === "interstate"
                    ? "Interstate Shipment"
                    : shipmentType === "international"
                    ? "International Shipment"
                    : ""}
                </li>
              </ul>
            </div>

            <div className="create-shipment-wrapper">
              <div className="create-shipment-title" style={{fontWeight:500}}>
                <img src={CreateShipIcon} alt="icon" className="mt-2" />
                Create Shipment
              </div>
              <ul className="create-shipment-tab">
                <li
                  onClick={() => setShipmentType("local")}
                  className={`create-shipment-tab-option ${
                    shipmentType === "local" &&
                    "create-shipment-tab-option-active"
                  }`}
                >
                  {
                    shipmentType === "local" ? (
                      <img src={LocalActiveIcon} alt="local" />
                    ) : (
                      <img src={LocalIcon} alt="local" />
                    )
                  }
                  Intracity Shipment
                </li>
                <li
                  onClick={() => setShipmentType("interstate")}
                  className={`create-shipment-tab-option ${
                    shipmentType === "interstate" &&
                    "create-shipment-tab-option-active"
                  }`}
                >
                  {
                    shipmentType === "interstate" ? (
                      <img src={InterstateActiveIcon} alt="interstate" />
                    ) : (
                      <img src={InterstateIcon} alt="interstate" />
                    )
                  }
                  Interstate Shipment
                </li>
                <li
                  onClick={() => setShipmentType("international")}
                  className={`create-shipment-tab-option ${
                    shipmentType === "international" &&
                    "create-shipment-tab-option-active"
                  }`}
                >
                  {
                    shipmentType === "international" ? (
                      <img src={InternationalActiveIcon} alt="international" />
                    ) : (
                      <img src={InternationalIcon} alt="international" />
                    )
                  }
                  International Shipment
                </li>
              </ul>
              <div className="shipment-form-contain">
                {
                  shipmentType === "local" ? (
                    <LocalShipment />
                  ) : shipmentType === "interstate" ? (
                    <InterstateShipment />
                  ) : shipmentType === "international" ? (
                    <InternationalShipment />
                  ) : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateShipment;
