import * as React from "react";
import PaymentMethods from "./Shipments/PaymentMethods";
import Insurance from "./Shipments/Insurance";
import TransporterSettings from "./Shipments/Transporters";
import ShipmentTime from "./Shipments/ShipmentTime";

const PriceSettings = () => {
  return <>
    <PaymentMethods />
    <Insurance />
    <TransporterSettings />
    <ShipmentTime />
  </>;
};

export default PriceSettings;
