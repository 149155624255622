import "../../styles/home.scss";
import "../../styles/shipments.scss";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserProfileData } from "../../library/constants";
import {getTransactionsURL, getWhatsappTemplatesURL} from "../../library/URLs";
import axios from "axios";
import styled from "styled-components";
import * as React from "react";
import moment from "moment";
import * as BiIcons from "react-icons/bi";
import {GoPrimitiveDot} from "react-icons/go";
import CustomDateRangeDialog from "../../modals/CustomDateRangeDialog";
import SubscriptionInactive from "../../components/SubscriptionInactive";
import DeleteTemplateDialog from "../../modals/DeleteWhatsappDialog";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const WhatsappTemplates = () => {
    const navigator = useNavigate();
    const params = useParams();

    const [chosenPaymentStatus, setChosenPaymentStatus] = useState("");
    const [chosenDate, setChosenDate] = useState("");

    const [isLoading, setIsLoading] = useState(true);
    const [currentOffset, setCurrentOffset] = useState(0);
    const [isRetrievingMore, setIsRetrievingMore] = useState(false);
    const [isCustomDateRangeDialogVisible, setIsCustomDateRangeDialogVisible] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);


    const [isOptionSelected, setIsOptionSelected] = useState("");

    const [templates, setTemplates] = useState([]);

    const data = {
        limit: 20,
        offset: currentOffset,
        payment_status : chosenPaymentStatus,
        date : chosenDate
    };

    const newData = { ...data, ...UserProfileData() };

    const FetchTemplates = () => {
        axios
            .post(getWhatsappTemplatesURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                if (isLoading === false && isRetrievingMore === false){
                    console.log("Should not retrieve");
                    return;
                }
                console.log(response["data"]);
                setIsRetrievingMore(false)
                setIsLoading(false);

                const templates = response["data"]["data"];
                setTemplates(templates);
            });
    };
    useEffect(() => {
        FetchTemplates();
    }, [isRetrievingMore, isOptionSelected]);

    const handleClick = (transactionID) => {
        navigator("/transaction/" + transactionID);
    };

    const createTemplateClick = () => {
        navigator("/whatsapp/create-template");
    }

    const setScroll = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && isRetrievingMore === false){
            setIsRetrievingMore(true);
        }
    };

    React.useEffect(() => {
        window.addEventListener("scroll", setScroll);
        return () => {
            window.removeEventListener("scroll", setScroll);
        };
    }, []);

    const exportToCSV = () => {
        if (templates.length === 0)
            return;

        let csvContent = "data:text/csv;charset=utf-8,";

        csvContent += "Shipment ID,Sender Name,Sender Phone Number,Pickup Address,Receiver Name,Receiver Phone Number,Dropoff Address,Amount,Weight"  + "\r\n";

        templates.forEach(function(shipment) {
            let id = shipment.id;

            let senderDetails = shipment.sender_name + "," + shipment.sender_phone_no
                + "," + shipment.pick_up_location.replaceAll(",", "");
            let receiverDetails = shipment.recipient_name + "," + shipment.recipient_phone_no
                + "," + shipment.drop_off_location.replaceAll(",", "");
            let packageDetails = shipment.standard_fee.replaceAll(",", "") + ","
                + shipment.weight;

            const row = id + "," +senderDetails + "," + receiverDetails + "," + packageDetails;

            csvContent += row + "\r\n";
        });

        const encodedURI = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedURI)
        link.setAttribute("download", "shipments1.csv")
        document.body.appendChild(link)

        link.click();
    }

    const onChangePaymentStatus = event => {
        setChosenPaymentStatus(event.target.value)
    }

    const toggleCustomDateRangeDialog= () => {
        setIsCustomDateRangeDialogVisible(!isCustomDateRangeDialogVisible);
    }

    const onChangeShipmentDate = event => {
        if (event.target.value === "custom"){
            toggleCustomDateRangeDialog();
        } else {
            setChosenDate(event.target.value);
        }
    }

    const getReports = () => {
        if (chosenPaymentStatus !== "" && chosenDate !== ""){
            setIsLoading(true);
            setCurrentOffset(0);
            setIsOptionSelected(chosenPaymentStatus + chosenDate);
        }
    }

    return (
        <div className="home">
            <Sidebar />
            <div className="main">
                <Navbar />
                <div className="page pb-5">
                    <SubscriptionInactive />
                    <div className="d-flex flex-row justify-content-between mb-2 mx-3">
                        <h5>Whatsapp Templates</h5>
                        <span>Home</span>
                    </div>
                    <div className="container-fluid shipments-container">
                        <CustomDateRangeDialog
                            isModalVisible={isCustomDateRangeDialogVisible}
                            handleCloseDialog={(e) => {
                                if (typeof e === "string"){
                                    console.log("The chosen date is " + e.toString());
                                    setChosenDate(e);
                                }
                                toggleCustomDateRangeDialog("close");
                            }}
                        />

                        <div className="card bg-light border-0 shadow p-4 min-vh-100">

                            <div className="d-md-flex gap-2 d-grid justify-content-between">
                                <div className="d-flex">
                                    <select onChange={onChangePaymentStatus} name="pickup_detail_id" className="form-select shipment-top-input" >
                                        <option value="">Filter By</option>
                                        <option value="Credit">Credit Payments</option>
                                        <option value="Debit">Debit Payments</option>
                                    </select>

                                    <select onChange={onChangeShipmentDate} name="pickup_detail_id" className="form-select shipment-top-input" >
                                        <option value="">Choose date</option>
                                        <option value="today">Today</option>
                                        <option value="yesterday">Yesterday</option>
                                        <option value="this week">This Week</option>
                                        <option value="this month">This Month</option>
                                        <option value="custom">Custom Date</option>
                                    </select>

                                    <button onClick={getReports} className="confirm-button">
                                        <BiIcons.BiSearch style={{fontSize:20}}/>
                                    </button>
                                </div>

                                <input className="confirm-button" type="button" onClick={createTemplateClick} value="CREATE NEW TEMPLATE" />
                            </div>

                            <div className="table-contain w-100">
                                <table className="shipments-table">
                                    <thead>
                                    <tr>
                                        <th scope="col">Message</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Edit</th>
                                        <th scope="col">Delete</th>
                                    </tr>
                                    </thead>
                                    {isLoading === true ? null : (
                                        <tbody>
                                        {templates.map((transaction) => {
                                            const messageType = transaction.components[0].type;
                                            let message = "";
                                            if (messageType !== "BODY"){
                                                message =transaction.components[1].text;
                                            } else {
                                                message =transaction.components[0].text;
                                            }
                                            return (
                                                <tr key={transaction.name}>
                                                    <td style={{width:"40%"}}>{message}</td>
                                                    <td>{transaction.name}</td>
                                                    <td>{transaction.status}</td>
                                                    <td>Edit</td>
                                                    <td onClick={() => setSelectedTemplate({id: transaction.id, name: transaction.name})} >Delete</td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                            {isRetrievingMore === false ? null :
                                <div style={{textAlign:"center", marginTop:40}}>
                                    <LoadingSpinner className="spinner-border spinner-grow-sm"/> Retrieving More..
                                </div>
                            }

                            {isLoading === false ? null : (
                                <LoadingContainer>
                                    <LoadingSpinner className="spinner-border spinner-grow-sm"/>
                                </LoadingContainer>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <DeleteTemplateDialog 
                isModalVisible={selectedTemplate !== null}
                handleCloseDialog={() => setSelectedTemplate(null)}
                updateTemplate={() => {
                    FetchTemplates();
                    setIsRetrievingMore(true);
                }}
                templateID={selectedTemplate?.id}
                templateName={selectedTemplate?.name}
            />
        </div>
    );
};

export default WhatsappTemplates;
