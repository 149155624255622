import React, { useEffect, useState } from "react";
import PriceIcon from "../../../../assets/settings/price_settings/credit_card_gear.svg";
import { Switch } from "@mui/material";
import { styled as mui_styled } from "@mui/material/styles";
import {
  getShipmentSettings2Url,
  editShipmentSettings2Url,
} from "../../../../library/URLs";
import axios from "axios";
import { UserProfileData } from "../../../../library/constants";
import { toast } from "react-toastify";
import styled from "styled-components";
import { TextBox } from "../../../../components/InputFields";

const ParcelflowSwitch = mui_styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 34,
  height: 16,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#15AB68",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 12,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#98A2B3",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const SubmitButton = styled.button`
  width: 250px;
`;

const ShipmentTime = () => {
  const [shipmentSettings, setShipmentSettings] = useState({
    accept_shipment: 0,
    daily_number_limit: "",
    daily_time_limit: "",
  });

  const [statusPending, setStatusPending] = useState(false);

  const fetchShipmentSettings = async () => {
    await axios
      .post(getShipmentSettings2Url, UserProfileData())
      .then((res) => {
        if (res?.data?.success) {
          if (res?.data?.data) {
            let shipment_status = res?.data?.data;
            setShipmentSettings(shipment_status);
          }
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchShipmentSettings();
    // eslint-disable-next-line
  }, []);

  const updateShipmentTime = async () => {
    const formData = {
      ...UserProfileData(),
      ...shipmentSettings,
    };

    setStatusPending(true);

    await axios
      .post(editShipmentSettings2Url, formData)
      .then((res) => {
        setStatusPending(false);
        if (res?.data?.success) {
          toast.success("Shipment Time updated successfully.");
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {});
  };

  const toggleAcceptShipment = () => {
    setShipmentSettings({
      ...shipmentSettings,
      accept_shipment: shipmentSettings?.accept_shipment === 1 ? 0 : 1,
    });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    setShipmentSettings({
      ...shipmentSettings,
      [name]: value,
    });
  };

  return (
    <div>
      <div className="settings_section d-flex flex-xl-row flex-column align-items-start">
        <div className="d-flex align-items-center justify-content-between price-settings-left">
          <div className="d-flex gap-3 align-items-start">
            <img src={PriceIcon} alt="price" className="mt-2" />
            <div>
              <p style={{ fontSize: 22, marginBottom: 8, fontWeight: "bold" }}>
                Customer <br />
                Shipment Time
              </p>
              <span>Customize settings that applies with shipment time.</span>
            </div>
          </div>
        </div>
        <div className=" price-settings-right">
          <div className=" col-12">
            <div className="d-flex flex-row justify-content-between align-items-center py-3 px-4">
              <div className="d-flex flex-column">
                <span style={{ fontSize: 18, color: "#2C3E50" }}>
                  Accept Shipment
                </span>
                <span style={{ fontSize: 14 }}>
                  {/* Automatically assign routes to rider */}
                </span>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                <ParcelflowSwitch
                  name="accept_shipment"
                  onChange={toggleAcceptShipment}
                  checked={shipmentSettings?.accept_shipment === 1}
                  className="react-switch"
                />
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center py-3 px-4">
              <div className="d-flex flex-column">
                <span style={{ fontSize: 18, color: "#2C3E50" }}>
                  Daily Number Limit
                </span>
                {/* <span style={{ fontSize: 14 }}>Positional Marking Option</span> */}
              </div>

              <div className="d-flex justify-content-center align-items-center">
                <TextBox
                  type="number"
                  placeholder="Enter Daily Number Limit"
                  name="daily_number_limit"
                  value={shipmentSettings.daily_number_limit}
                  onChange={handleInput}
                />
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center py-3 px-4">
              <div className="d-flex flex-column">
                <span style={{ fontSize: 18, color: "#2C3E50" }}>
                  Daily Time Limit
                </span>
                {/* <span style={{ fontSize: 14 }}>
                  Make tracking of rider's location compulsory
                </span> */}
              </div>

              <div className="d-flex justify-content-center align-items-center">
                <div>
                  <TextBox
                    type="time"
                    placeholder="Enter Daily Time Limit"
                    name="daily_time_limit"
                    value={shipmentSettings.daily_time_limit}
                    onChange={handleInput}
                  />
                </div>
              </div>
            </div>

            <div className=" pt-4 d-flex justify-content-end">
              <SubmitButton
                type="submit"
                onClick={updateShipmentTime}
                className="confirm-button btn-primary"
              >
                {statusPending === false ? (
                  <span className="button-text">UPDATE</span>
                ) : (
                  <span className="spinner-border spinner-grow-sm" />
                )}
              </SubmitButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipmentTime;
