import React, { useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import styled from "styled-components";
import { registerWhatsappPhoneURL } from "../../library/URLs";
import axios from "axios";
import { UserProfileData } from "../../library/constants";
import { BiX } from "react-icons/bi";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #fcd2d2;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #c2eddd;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
`;

const ActivatePhone = ({ phoneData, handleCloseDialog, isModalVisible, updateList }) => {
    const [formData, setFormData] = useState({
        new_pin: "",
        confirm_pin: "",
    });

    // toggle password and confirm password visibility
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    // reset when closed
    const handleClose = () => {
        handleCloseDialog();
        setFormData({
            new_pin: "",
            confirm_pin: "",
        });
    }

    const handleInput = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const submit = () => {
        setTimeout(() => {
            handleClose();
        }, 3000);
    };

    const registerNumber = (e) => {
        e.preventDefault();

        if (formData.new_pin === "") {
            setErrorVisibility(true);
            setErrorMessage("Provide your new pin");
            return;
        }

        if (formData.confirm_pin !== formData.new_pin) {
            setErrorVisibility(true);
            setErrorMessage("Make sure confirm pin matches your new pin");
            return;
        }

        setErrorVisibility(false);

        const newData = {
            params: {
                phone_number: phoneData?.phone_number,
                pin: formData.new_pin,
            },
            ...UserProfileData(),
            level: "4",
        };

        console.log(newData);

        setIsLoading(true);

        axios
            .post(registerWhatsappPhoneURL, newData)
            .then(function (response) {
                console.log(response);
                setIsLoading(false);

                if (response["data"]["success"] === false) {
                    // window.scrollTo(0, 0);
                    // submit();
                    setErrorVisibility(true);
                    setErrorMessage(response["data"]["message"]);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                } else if (response["data"]["success"] === true) {
                    // window.scrollTo(0, 0);
                    updateList();
                    submit();

                    setSuccessVisibility(true);
                    setSuccessMessage("Business number registered successfully.");

                    setTimeout(() => {
                        setSuccessVisibility(false);
                    }, 3000);
                }
            })
            .catch(function (error) {
                return error;
            });
    };
    return (
        <Modal show={isModalVisible} onHide={handleClose} size="lg">
            <Modal.Header>
                <h5 className="header">ACTIVATE WHATSAPP NUMBER</h5>
                <BiX
                    style={{ fontSize: 25, cursor: "pointer" }}
                    onClick={handleClose}
                />
            </Modal.Header>
            {isError === false ? null : (
                <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
            )}

            {isSuccess === false ? null : (
                <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
            )}
            <Modal.Body>
                <div className="Add-phone-forms">
                    <p className="Add-phone-form-info">Please enter new pin below</p>
                    <form>
                        <Row className="mb-3">
                            <Col className="form-group col-xl-8 col-md-12 position-relative">
                                <label htmlFor="New Pin">New Pin</label>
                                <input
                                    name="new_pin"
                                    className="form-control pe-5"
                                    type={showPassword ? "text" : "password"}
                                    onChange={handleInput}
                                    value={formData.new_pin}
                                />
                                {showPassword ? (
                                    <BsEyeSlash
                                        className=" position-absolute top-50"
                                        style={{ right: "2rem", cursor: "pointer" }}
                                        onClick={() => setShowPassword(false)}
                                    />
                                ) : (
                                    <BsEye
                                        className=" position-absolute top-50"
                                        style={{ right: "2rem", cursor: "pointer" }}
                                        onClick={() => setShowPassword(true)}
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col className="form-group col-xl-8 col-md-12 position-relative">
                                <label htmlFor="Confirm Pin">Confirm Pin</label>
                                <input
                                    name="confirm_pin"
                                    className="form-control"
                                    type={showConfirmPassword?"text":"password"}
                                    onChange={handleInput}
                                    value={formData.confirm_pin}
                                />
                                {showConfirmPassword ? (
                                    <BsEyeSlash
                                        className=" position-absolute top-50"
                                        style={{ right: "2rem", cursor: "pointer" }}
                                        onClick={() => setShowConfirmPassword(false)}
                                    />
                                ) : (
                                    <BsEye
                                        className=" position-absolute top-50"
                                        style={{ right: "2rem", cursor: "pointer" }}
                                        onClick={() => setShowConfirmPassword(true)}
                                    />
                                )}
                            </Col>
                        </Row>
                        <div className="d-flex flex-row my-5 justify-content-end">
                            <SubmitButton
                                type="button"
                                className={`confirm-button ${formData.new_pin === "" ||
                                        formData.new_pin !== formData.confirm_pin
                                        ? "opacity-50"
                                        : ""
                                    }`}
                                onClick={registerNumber}
                                disabled={
                                    formData.new_pin === "" ||
                                    formData.new_pin !== formData.confirm_pin
                                }
                            >
                                {isLoading === false ? (
                                    <span className="button-text">COMPLETE REGISTRATION</span>
                                ) : (
                                    <span className="spinner-border spinner-grow-sm" />
                                )}
                            </SubmitButton>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ActivatePhone;
