import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import packageIcon from "../../images/package.jpg";
import userIcon from "../../images/user.png";
import transporterIcon from "../../images/transporter.png";
import * as React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ReportIcon from "../../assets/reports/ReportIcon.svg";
import CardIllustrate01 from "../../assets/reports/CardIllustrate01.png";
import CardIllustrate02 from "../../assets/reports/CardIllustrate02.png";
import CardIllustrate03 from "../../assets/reports/CardIllustrate03.png";
import ShipmentIcon from "../../assets/reports/deployed_code.svg";
import CustomerIcon from "../../assets/reports/user_attributes.svg";
import TransporterIcon from "../../assets/reports/two_wheeler.svg";
import ShipmentsCard from "../../assets/reports/ShipmentsCard.png";

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 65px;
  height: 65px;
`;

const ReportsHome = () => {
  const navigator = useNavigate();

  const viewReport = (type) => {
    if (type === "Shipments") {
      navigator("/reports/shipments/options");
    } else if (type === "Customers") {
      navigator("/reports/customers");
    } else if (type === "Transporters") {
      navigator("/reports/transporters");
    }
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <div className="container-fluid">
            <div
              className="card border-0 p-4 min-vh-100"
              style={{ backgroundColor: "#FFF" }}
            >
              <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  <img src={ReportIcon} alt="group" />
                  <h5 className="m-0">Reports</h5>
                </div>
                <div className="d-flex flex-column flex-md-row gap-3"></div>
              </div>
              <div className="row mt-4 reports-home">
                <div className="col-xl-4 col-md-6 col-12 pe-2 mb-xl-0 ">
                  <div
                    onClick={() => viewReport("Shipments")}
                    className="report-card report-shipment-card position-relative"
                  >
                    <div
                      style={{ cursor: "pointer", background: "transparent" }}
                      className="card border-0"
                    >
                      <div className="card-icon">
                        <img src={ShipmentIcon} alt="shipment" />
                      </div>
                      <div className="">
                        <h6 className="report-card-title">SHIPMENT REPORTS</h6>
                        <p className="report-card-desc">
                          For all reports associated
                          <br /> with shipment
                        </p>
                      </div>
                    </div>
                    <img
                      src={CardIllustrate01}
                      alt="card-illustrate"
                      className=" position-absolute card-illustrate"
                      style={{ right: 0, top: 0, zIndex: 1 }}
                    />
                  </div>
                </div>

                <div className="col-xl-4 pe-2 col-md-6 col-12 mb-xl-0 ">
                  <div
                    onClick={() => viewReport("Customers")}
                    className="report-card report-customer-card position-relative"
                  >
                    <div
                      style={{ cursor: "pointer", background: "transparent" }}
                      className="card border-0"
                    >
                      <div className="card-icon">
                        <img src={CustomerIcon} alt="shipment" />
                      </div>
                      <div className="">
                        <h6 className="report-card-title">CUSTOMER REPORTS</h6>
                        <p className="report-card-desc">
                          For all reports associated
                          <br /> with customers{" "}
                        </p>
                      </div>
                    </div>
                    <img
                      src={CardIllustrate02}
                      alt="card-illustrate"
                      className=" position-absolute card-illustrate"
                      style={{ right: 0, top: 0, zIndex: 1 }}
                    />
                  </div>
                </div>

                <div className="col-xl-4 col-md-6 col-12 mb-4 mb-xl-0 ">
                  <div
                    onClick={() => viewReport("Transporters")}
                    className="report-card report-transporter-card position-relative"
                  >
                    <div
                      style={{ cursor: "pointer", background: "transparent" }}
                      className="card border-0"
                    >
                      <div className="card-icon">
                        <img src={TransporterIcon} alt="shipment" />
                      </div>
                      <div className="">
                        <h6 className="report-card-title">
                          TRANSPORTER REPORTS
                        </h6>
                        <p className="report-card-desc">
                          For all reports associated
                          <br /> with transporter{" "}
                        </p>
                      </div>
                    </div>
                    <img
                      src={CardIllustrate03}
                      alt="card-illustrate"
                      className=" position-absolute card-illustrate"
                      style={{ right: 0, top: 0, zIndex: 1 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportsHome;
