import {Col, Modal, Row} from "react-bootstrap";
import {createRef, useRef, useState} from "react";
import styled from "styled-components";
import axios from "axios";
import {loginURL} from "../library/URLs";
import {UserProfileData} from "../library/constants";
import {BiX} from "react-icons/bi";
import * as React from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { SessionManagement } from "../library/SessionManagement";
import { generateTransactionID } from "../library/utilities";
import { TextBox } from "../components/InputFields";

const SubmitButton = styled.button`
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    border-color: #fcd2d2;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    border-color: #c2eddd;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const LoadingContainer = styled.div`
  height: 365px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

const PaystackPasswordModal = ({ isModalVisible, handleCloseDialog, setIsEditable, title="PAYSTACK"}) => {
    const form = useRef(null);
    const urlPromoRef = createRef();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    function getDevice() {
        let operatingSystem = 'Not known';
        if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
        if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
        if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
        if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }
    
        let currentBrowser = 'Not known';
        if (window.navigator.userAgent.indexOf('Chrome') !== -1) { currentBrowser = 'Chrome'; }
        else if (window.navigator.userAgent.indexOf('Firefox') !== -1) { currentBrowser = 'Mozilla Firefox'; }
        else if (window.navigator.userAgent.indexOf('MSIE') !== -1) { currentBrowser = 'Internet Exployer'; }
        else if (window.navigator.userAgent.indexOf('Edge') !== -1) { currentBrowser = 'Edge'; }
        else if (window.navigator.userAgent.indexOf('Safari') !== -1) { currentBrowser = 'Safari'; }
        else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'Opera'; }
        else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'YaBrowser'; }
    
        return currentBrowser + " on " + operatingSystem;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        
        const data = new FormData(form.current);
    
        const formValues = {};
    
        for (let [key, value] of data.entries()) {
          formValues[key] = value;
        }
        formValues["device_id"] = "value";
        formValues["device_name"] = getDevice();
        formValues["token"] = "value";
        formValues["email"] = SessionManagement.getEmail();
        formValues["transaction_id"] = generateTransactionID(15);
    
        setIsSubmitting(true);
    
        axios
          .post(loginURL, formValues)
          .then(function (response) {
            console.log(response);
            setIsSubmitting(false);
    
            if (response["data"]["success"] === false) {
              setErrorMessage("Invalid password");
              setErrorVisibility(true);
    
              setTimeout(() => {
                setErrorVisibility(false);
              }, 5000);
            } else if (response["data"]["success"] === true) {
                setIsEditable(true);
                handleCloseDialog();
            }
          })
          .catch(function (error) {
            return error;
          });
    };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 style={{marginTop:10}} className="header">EDIT {title} KEYS</h5>
          <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
      </Modal.Header>
      <Modal.Body>
          {
              isError === false ? null :
                  (<ErrorMessageContainer>
                      {errorMessage}
                  </ErrorMessageContainer>)
          }

          {
              isSuccess=== false ? null :
                  (<SuccessMessageContainer>
                      {successMessage}
                  </SuccessMessageContainer>)
          }

          <form ref={form} onSubmit={handleSubmit}>
              <div>
                  <div>
                      <label htmlFor="Subject">Admin Password</label>
                      <TextBox 
                        name="password" 
                        className="form-control" 
                        type="password"
                      />
                  </div>
              </div>
          </form>

      </Modal.Body>
      <Modal.Footer>
        <button className="btn-secondary mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>
          <SubmitButton onClick={handleSubmit} type="submit" className="btn-primary">
              {isSubmitting === false ? (
                  <span className="button-text">Confirm</span>
              ) : (
                  <span className="spinner-border spinner-grow-sm"/>
              )}
          </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default PaystackPasswordModal;
