import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import {
  getAPICarriersUrl,
  uploadPackageImgUrl,
  addCarrierTypeUrl,
  uploadRateDocumentUrl,
  getApiCarrierAccountsUrl,
} from "../library/URLs";
import { useEffect, useRef, useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import {
  MultiSelectBox,
  SelectBox,
  TextBox,
  TextFieldBox,
} from "../components/InputFields";
import { BsX } from "react-icons/bs";
import countries from "../files/countries.json";
import currencies from "../files/currencies.json";

const SubmitButton = styled.button``;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const ContentFieldsActionButton = styled.div`
  border-radius: 50%;
  height: 40px;
  min-width: 40px;
  color: #ffffff;
  display: flex;
  font-size: 30px;
  margin-right: 13px;
  align-items: center;
  justify-content: center;
  background-color: #008561;
  cursor: pointer;
  padding-left: 2px;

  svg {
    font-size: 18px;
  }
`;

const AddCarrierTypeDialog = ({
  isModalVisible,
  handleCloseDialog,
  shipment_type,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiCarriers, setApiCarriers] = useState([]);
  const [carrierAccounts, setCarrierAccounts] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [carrierForm, setCarrierForm] = useState({
    shipment_type: "",
    carrier: "",
    connection_type: "",
    calculation_connection_type: "",
    creation_connection_type: "",
    transit_time: "",
    display_name: "",
    pricing_type: "",
    additional_info: "",
    rate_document_url: "",
    image_url: "",
    per_kg_countries: [
      {
        countries: [],
        countries_select: [],
        minimum_weight: "",
        maximum_weight: "",
        price: "",
        currency: "NGN",
      },
    ],
  });
  const [logoFile, setLogoFile] = useState("");
  const [rateDocFile, setRateDocFile] = useState("");

  const logoInputRef = useRef();
  const rateDocInputRef = useRef();

  const getCarrierAccounts = async (carrier_id) => {
    const formValues = {
      ...UserProfileData(),
      carrier: carrier_id,
    };
    setFetching(true);

    await axios
      .post(getApiCarrierAccountsUrl, formValues)
      .then((res) => {
        setFetching(false);
        if (res?.data?.success) {
          let carrier_accounts = res?.data?.data?.map((carrier) => {
            return {
              label: carrier?.name,
              value: carrier?.id,
            };
          });

          setCarrierAccounts(carrier_accounts);
        }
      })
      .catch((err) => {setFetching(false)});
  };

  const fetchApiCarriers = async () => {
    await axios
      .post(getAPICarriersUrl, UserProfileData())
      .then((res) => {
        if (res?.data?.success) {
          if (shipment_type) {
            let api_carriers = res?.data?.data
              ?.filter(
                (carrier) => carrier[shipment_type?.toLowerCase()] === "Yes"
              )
              ?.map((carrier) => {
                return {
                  label: carrier?.name,
                  value: carrier?.id,
                  img_url: carrier?.image_url,
                };
              });

            setApiCarriers(api_carriers);
          }
        }
      })
      .catch((err) => {});
  };

  const [uploadingLogo, setUploadingLogo] = useState(false);

  const uploadLogoImage = (file) => {
    const imgFormData = new FormData();
    imgFormData.append("image", file);

    setUploadingLogo(true);

    axios
      .post(uploadPackageImgUrl, imgFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        setUploadingLogo(false);
        setCarrierForm({
          ...carrierForm,
          image_url: response?.data,
        });
      })
      .catch(function (error) {
        return error;
      });
  };

  const [uploadingRateDoc, setUploadingRateDoc] = useState(false);

  const uploadRateDocument = (file) => {
    const docFormData = new FormData();
    docFormData.append("document", file);

    setUploadingRateDoc(true);

    axios
      .post(uploadRateDocumentUrl, docFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        setUploadingRateDoc(false);
        setCarrierForm({
          ...carrierForm,
          rate_document_url: response?.data,
        });
      })
      .catch(function (error) {
        return error;
      });
  };

  useEffect(() => {
    if (shipment_type) {
      setCarrierForm({
        ...carrierForm,
        shipment_type,
      });
      fetchApiCarriers();
    }
    // eslint-disable-next-line
  }, [shipment_type]);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleInput = (e) => {
    const { name, value } = e.target;

    if (name === "api_type") {
      setCarrierForm({
        ...carrierForm,
        [name]: value,
        image_url: apiCarriers?.find((api) => `${api?.value}` === value)
          ?.img_url,
      });
      setLogoFile(apiCarriers?.find((api) => `${api?.value}` === value)?.label);
      getCarrierAccounts(value);
    } else {
      setCarrierForm({
        ...carrierForm,
        [name]: value,
      });
    }
  };

  const triggerLogoFileInput = () => {
    logoInputRef.current.click();
  };

  const triggerRateDocInput = () => {
    rateDocInputRef.current.click();
  };

  const handleLogoInput = (e) => {
    const { files } = e.target;
    setLogoFile(files[0]?.name);
    uploadLogoImage(files[0]);
  };

  const handleRateDocInput = (e) => {
    const { files } = e.target;
    setRateDocFile(files[0]?.name);
    uploadRateDocument(files[0]);
  };

  const calculate_connection_type_options = [
    { label: "Custom", value: "Custom" },
    { label: "API", value: "API" },
    { label: "Manual", value: "Manual" },
  ];

  const create_connection_type_options = [
    { label: "Custom", value: "Custom" },
    { label: "API", value: "API" },
  ];

  const country_options = countries.map((country) => {
    return {
      label: country.name,
      value: country.name,
    };
  });

  const currency_options = currencies
    .filter(
      (currency) =>
        currency.cc === "USD" ||
        currency.cc === "NGN" ||
        currency.cc === "GBP" ||
        currency.cc === "EUR"
    )
    ?.map((currency) => {
      return {
        label: `${currency.name} - ${currency.cc}`,
        value: currency.cc,
      };
    });

  const addWeightRate = () => {
    const per_kg_copy = [...carrierForm.per_kg_countries];

    per_kg_copy.push({
      countries: "",
      minimum_weight: "",
      maximum_weight: "",
      price: "",
      currency: "NGN",
    });

    setCarrierForm({
      ...carrierForm,
      per_kg_countries: per_kg_copy,
    });
  };

  const removeWeightRate = () => {
    if (carrierForm.per_kg_countries.length < 2) {
      return;
    }

    const per_kg_copy = [...carrierForm.per_kg_countries];
    per_kg_copy.pop();
    setCarrierForm({
      ...carrierForm,
      per_kg_countries: per_kg_copy,
    });
  };

  const handleWeightRateInput = (e, id) => {
    const { name, value } = e.target;
    const per_kg_copy = [...carrierForm.per_kg_countries];

    per_kg_copy[id] = {
      ...per_kg_copy[id],
      [name]: value,
    };

    setCarrierForm({
      ...carrierForm,
      per_kg_countries: per_kg_copy,
    });
  };

  const handleSelectCountryInput = (id, value) => {
    const per_kg_copy = [...carrierForm.per_kg_countries];

    let countries_map = value?.map((country) => country?.value);
    let countries = countries_map?.join(",");

    per_kg_copy[id] = {
      ...per_kg_copy[id],
      countries: countries,
      countries_select: value,
    };

    setCarrierForm({
      ...carrierForm,
      per_kg_countries: per_kg_copy,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const formdata = {
      ...UserProfileData(),
      ...carrierForm,
      carrier: carrierForm?.api_type,
      pricing_type:
        carrierForm?.connection_type === "Custom" ? "Per KG" : "Default",
    };
    setIsSubmitting(true);

    axios
      .post(addCarrierTypeUrl, formdata)
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.success) {
          window.scrollTo(0, 0);

          setSuccessVisibility(true);
          setSuccessMessage("Carrier added successfully!");

          setTimeout(() => {
            setSuccessVisibility(false);
          }, 3000);
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        } else {
          window.scrollTo(0, 0);
          setErrorVisibility(true);
          setErrorMessage(res?.data?.message);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  console.log("dfa ", carrierForm);

  return (
    <Modal size="lg" show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">ADD NEW CARRIER ({shipment_type})</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        <p>Fill the details below to add a carrier type</p>
        <div className="query-result-container">
          {/* <div className="mb-2" >
            <label htmlFor="Description" className="mb-2">
              Carrier
            </label>
            <TextBox
              type="text"
              name="carrier"
              value={carrierForm.carrier}
              onChange={handleInput}
              placeholder="Enter Carrier Name"
            />
          </div> */}
          <div className="mb-2">
            <label htmlFor="Description" className="mb-2">
              Display Name
            </label>
            <TextBox
              type="text"
              name="display_name"
              value={carrierForm.display_name}
              onChange={handleInput}
              placeholder="Enter Carrier Display Name"
            />
          </div>
          <Row className="mb-2">
            <Col md={6}>
              <div>
                <label htmlFor="Description" className="mb-2">
                  Calculate Connection Type
                </label>
                <SelectBox
                  name="calculation_connection_type"
                  value={carrierForm?.calculation_connection_type}
                  options={calculate_connection_type_options}
                  onChange={handleInput}
                  placeholder="Enter Connection Type"
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label htmlFor="Description" className="mb-2">
                  Create Connection Type
                </label>
                <SelectBox
                  name="creation_connection_type"
                  value={carrierForm?.creation_connection_type}
                  options={create_connection_type_options}
                  onChange={handleInput}
                  placeholder="Enter Connection Type"
                />
              </div>
            </Col>
          </Row>
          {(carrierForm?.creation_connection_type === "API" ||
            carrierForm?.calculation_connection_type === "API") && (
            <Row className="mb-2">
              <Col md={6}>
                <div>
                  <label htmlFor="Description" className="mb-2">
                    API Type
                  </label>
                  <SelectBox
                    name="api_type"
                    value={carrierForm?.api_type}
                    options={apiCarriers}
                    onChange={handleInput}
                    placeholder="Enter Connection Type"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <label htmlFor="Description" className="mb-2">
                    Carrier Account
                  </label>
                  <div className="d-flex gap-2 align-items-center" >
                    {fetching && <span className="spinner-border spinner-grow-sm" />}
                    <SelectBox
                      name="api_account_id"
                      value={carrierForm?.api_account_id}
                      options={carrierAccounts}
                      onChange={handleInput}
                      placeholder="Select carrier account"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          )}
          <div className="mb-2">
            <label className="mb-2" htmlFor="Description">
              Transit Time
            </label>
            <TextBox
              name="transit_time"
              type="text"
              value={carrierForm.transit_time}
              onChange={handleInput}
              placeholder="E.g: 2 to 3 working days"
            />
          </div>
          {carrierForm.calculation_connection_type === "Manual" && (
            <div className="mb-2">
              <label htmlFor="package img">Upload Carrier Rates Document</label>
              <div className="d-flex gap-2 align-items-center">
                {uploadingRateDoc && (
                  <span className="spinner-border spinner-grow-sm" />
                )}
                <div className="w-100">
                  <input
                    style={{
                      visibility: "hidden",
                      position: "fixed",
                      bottom: 0,
                      zIndex: -1,
                    }}
                    type="file"
                    onChange={handleRateDocInput}
                    ref={rateDocInputRef}
                    accept=".xlsx, .xls"
                    name=""
                    id=""
                  />
                  <div
                    style={{ marginTop: 20 }}
                    onClick={triggerRateDocInput}
                    className="change-password-button"
                  >
                    Choose Spreadsheet
                  </div>
                </div>
              </div>
              {carrierForm?.rate_document_url !== "" && (
                <small>{rateDocFile}</small>
              )}
            </div>
          )}
          <div className="mb-2">
            <label htmlFor="package img">Upload Carrier Logo</label>
            <div className="d-flex gap-2 align-items-center">
              {uploadingLogo && (
                <span className="spinner-border spinner-grow-sm" />
              )}
              <div className="w-100">
                <input
                  style={{
                    visibility: "hidden",
                    position: "fixed",
                    bottom: 0,
                    zIndex: -1,
                  }}
                  type="file"
                  onChange={handleLogoInput}
                  ref={logoInputRef}
                  accept="image/*"
                  name=""
                  id=""
                />
                <div
                  style={{ marginTop: 20 }}
                  onClick={triggerLogoFileInput}
                  className="change-password-button"
                >
                  Choose Image
                </div>
              </div>
            </div>
            {carrierForm?.image_url !== "" && <small>{logoFile}</small>}
          </div>
          <div className="mb-2">
            <label htmlFor="Description" className="mb-2">
              Additional Information
            </label>
            <TextFieldBox
              name="additional_info"
              value={carrierForm.additional_info}
              onChange={handleInput}
              placeholder="Enter Carrier Display Name"
            />
          </div>
          {carrierForm?.calculation_connection_type === "Custom" && (
            <ol className="mb-3">
              {carrierForm.per_kg_countries?.map((kg_item, id) => (
                <li key={id}>
                  <div className="mb-2">
                    <label className="mb-2" htmlFor="Description">
                      Country
                    </label>
                    {/* <SelectTextBox2
                      name="countries"
                      value={kg_item?.countries}
                      options={country_options}
                      onChange={handleSelectWeightRateInput}
                      id={id}
                      placeholder="Enter Connection Type"
                    /> */}
                    <MultiSelectBox
                      name="countries"
                      value={kg_item?.countries_select}
                      options={country_options}
                      onChange={(value) => handleSelectCountryInput(id, value)}
                      id={id}
                      placeholder="Select Countries"
                    />
                  </div>
                  <Row className="mb-2">
                    <Col sm={12} md={6}>
                      <div className="mb-2">
                        <label className="mb-2" htmlFor="Description">
                          Minimum Weight
                        </label>
                        <TextBox
                          name="minimum_weight"
                          type="text"
                          value={kg_item?.minimum_weight}
                          onChange={(e) => handleWeightRateInput(e, id)}
                          placeholder="KG"
                          prefix="KG"
                        />
                      </div>
                    </Col>
                    <Col sm={12} md={6}>
                      <div className="mb-2">
                        <label className="mb-2" htmlFor="Description">
                          Maximum Weight
                        </label>
                        <TextBox
                          name="maximum_weight"
                          type="text"
                          value={kg_item?.maximum_weight}
                          onChange={(e) => handleWeightRateInput(e, id)}
                          placeholder="KG"
                          prefix="KG"
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="mb-2">
                    <label className="mb-2" htmlFor="Description">
                      Currency
                    </label>
                    <SelectBox
                      name="currency"
                      value={kg_item?.currency}
                      onChange={(e) => handleWeightRateInput(e, id)}
                      placeholder="Select Currency"
                      options={currency_options}
                    />
                  </div>
                  <div className="mb-2">
                    <label className="mb-2" htmlFor="Description">
                      Price
                    </label>
                    <TextBox
                      name="price"
                      type="text"
                      value={kg_item?.price}
                      onChange={(e) => handleWeightRateInput(e, id)}
                      prefix={kg_item?.currency ?? ""}
                      placeholder="Enter Fee"
                    />
                  </div>
                </li>
              ))}

              <div className="d-flex gap-3 align-items-center mb-2">
                <div className="">
                  <ContentFieldsActionButton onClick={addWeightRate}>
                    +
                  </ContentFieldsActionButton>
                </div>
                <div className="">
                  <ContentFieldsActionButton onClick={removeWeightRate}>
                    -
                  </ContentFieldsActionButton>
                </div>
              </div>
            </ol>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-secondary mx-3" onClick={handleCloseDialog}>
          <BsX />
          Cancel
        </button>
        <SubmitButton onClick={onSubmit} type="submit" className="btn-primary">
          {isSubmitting === false ? (
            <span className="button-text d-flex align-items-center gap-2">
              Proceed
            </span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCarrierTypeDialog;
