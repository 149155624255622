import "../styles/home.scss";
import "../styles/single-shipment.scss";
import "../styles/components.scss";
import "../styles/create-admin.scss"
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import {useCallback, useEffect, useRef, useState} from "react";
import {Col, Row} from "react-bootstrap";
import * as React from "react";
import styled from "styled-components";
import {Switch} from "@mui/material";
import axios from "axios";
import {createAdminURL, getStationsURL, uploadImageURL} from "../library/URLs";
import {UserProfileData} from "../library/constants";
import {useNavigate} from "react-router-dom";
import SubscriptionInactive from "../components/SubscriptionInactive";

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #fcd2d2;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #c2eddd;
    margin-bottom:20px;
    text-align:center;
`;

const SteppedNumber = styled.div`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #008561;
`;

const SteppedTitle = styled.span`
  font-size: 20px;
`;

const SubmitButton = styled.button`
  width: 250px;
`;

const CreateAdmin = () => {
    const navigator = useNavigate();

  const [isCreatingAdmin, setIsCreatingAdmin] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

    const [stations, setStations] = useState([]);

  const [createShipment, setCreateShipment] = useState(false);
  const [approveShipment, setApproveShipment] = useState(false);
  const [routeShipment, setRouteShipment] = useState(false);
  const [rerouteShipment, setRerouteShipment] = useState(false);
  const [cancelShipment, setCancelShipment] = useState(false);
  const [rejectShipment, setRejectShipment] = useState(false);
  const [transferShipment, setTransferShipment] = useState(false);

  const [approveWalletTopup, setApproveWalletTopup] = useState(false);
  const [markPaymentAsPaid, setMarkPaymentAsPaid] = useState(false);
  const [markPaymentAsUnpaid, setMarkPaymentAsUnpaid] = useState(false);
  const [fundUserWallet, setFundUserWallet] = useState(false);
  const [deductUserWallet, setDeductUserWallet] = useState(false);

  const [sendPushNotifications, setSendPushNotifications] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [sendSMS, setSendSMS] = useState(false);
  const [makeContractUser, setMakeContractUser] = useState(false);
  const [makeRegularUser, setMakeRegularUser] = useState(false);
  const [replyMessages, setReplyMessages] = useState(false);
  const [activateCustomer, setActivateCustomer] = useState(false);
  const [suspendCustomer, setSuspendCustomer] = useState(false);
  const [deactivateCustomer, setDeactivateCustomer] = useState(false);
  const [unsuspendCustomer, setUnsuspendCustomer] = useState(false);

  const [createTransporter, setCreateTransporter] = useState(false);
  const [changeTransporterDetails, setChangeTransporterDetails] = useState(false);
  const [deactivateTransporter, setDeactivateTransporter] = useState(false);
  const [reactivateTransporter, setReactivateTransporter] = useState(false);
  const [deleteTransporter, setDeleteTransporter] = useState(false);

  const [transferFunds, setTransferFunds] = useState(false);
  const [buyAirtime, setBuyAirtime] = useState(false);
  const [buyElectricity, setBuyElectricity] = useState(false);
  const [buyInternet, setBuyInternet] = useState(false);
  const [buyToll, setBuyToll] = useState(false);

  const [createAdmin, setCreateAdmin] = useState(false);
  const [editAdmin, setEditAdmin] = useState(false);
  const [suspendAdmin, setSuspendAdmin] = useState(false);
  const [unsuspendAdmin, setUnsuspendAdmin] = useState(false);
  const [deleteAdmin, setDeleteAdmin] = useState(false);

  const [showDashboard, setShowDashboard] = useState(false);
  const [showShipments, setShowShipments] = useState(false);
  const [showCustomers, setShowCustomers] = useState(false);
  const [showTransactions, setShowTransactions] = useState(false);
  const [showAdmins, setShowAdmins] = useState(false);
  const [showTransporters, setShowTransporters] = useState(false);
  const [showReports, setShowReports] = useState(false);

  const form = useRef(null);

    const data = {
        limit: 20,
        status: "All",
    };

    const newData = { ...data, ...UserProfileData() };

    useEffect(() => {
        axios
            .post(getStationsURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                if (response["data"]["success"] === false) return;

                const stations = response["data"]["data"]["data"];

                setStations(stations);
            });
    }, []);

  const createNewAdmin = (profileImageURL) => {
      const formValues = {};
      formValues["profile_image_url"] = profileImageURL;
      formValues["privileges"] = {};

      const data = new FormData(form.current);

      const nonPrivileges = ["name", "email", "phone", "password", "confirm_password", "station"];
      for (const [key, value] of data.entries()) {
          if (nonPrivileges.includes(key) ){
              formValues[key] = value;
          } else {
              formValues["privileges"][key] = "1";
          }
      }

      const newData = { ...formValues, ...UserProfileData() };

      console.log(newData);
      axios
          .post(createAdminURL, newData)
          .then((response) => {
              console.log(response);
              setIsCreatingAdmin(false);
              window.scrollTo(0, 0);

              if (response["data"]["success"] === true){
                  setSuccessVisibility(true);
                  setSuccessMessage("Successfully created admin. Upload profile picture");

                  setTimeout(() => {
                      navigator("/admins");
                  }, 3000);
              } else {
                  const message = response['data']['message'];

                  setErrorVisibility(true);
                  setErrorMessage(message);
                  setTimeout(() => {
                      // setErrorVisibility(false);
                  }, 3000);
              }
          })
          .catch(error => console.log(error));
  }

  function showError(message){
      window.scrollTo(0, 0);
      setErrorVisibility(true);
      setErrorMessage(message);

      setTimeout(() => {
          setErrorVisibility(false);
      }, 3000);
  }

    const uploadProfileImage = () => {
        const name = document.querySelector('#name') ;
        const emailAddress = document.querySelector('#email') ;
        const phoneNumber = document.querySelector('#phone') ;
        const fileInput = document.querySelector('#profile-image') ;

        if (name.value === ""){
            showError("Please enter valid name");
            return false;
        }

        if (emailAddress.value === ""){
            showError("Please enter valid email address");
            return false;
        }

        if (phoneNumber.value === ""){
            showError("Please enter valid phone number");
            return false;
        }

        if (fileInput.files[0] === undefined){
            showError("Please upload a picture");
            return false;
        }

        const formValues = {};
        formValues["document"] = fileInput.files[0];

        setIsCreatingAdmin(true);

        axios
            .post(uploadImageURL, formValues, {
                headers:{
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                const profileImageURL = response["data"];

                createNewAdmin(profileImageURL);
            })
            .catch(error => console.log(error));
    }

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5" style={{maxWidth:"100vw", overflow: "hidden"}}>
           <SubscriptionInactive />

          <div className="d-flex flex-row justify-content-between mb-2 mx-3">
            <h5 className="header">Create Admin</h5>
            <span>Home</span>
          </div>
          <div className="container-fluid">
            <div className="card bg-light border-0 shadow p-4">
              <div className="">
                {
                  isError === false ? null :
                      (<ErrorMessageContainer>
                        {errorMessage}
                      </ErrorMessageContainer>)
                }

                {
                  isSuccess=== false ? null :
                      (<SuccessMessageContainer>
                        {successMessage}
                      </SuccessMessageContainer>)
                }


                <form ref={form}>
                    <section>
                        <div className="d-flex align-items-center mb-2">
                            <SteppedNumber>1</SteppedNumber>
                            <SteppedTitle className="mx-2">Basic Details</SteppedTitle>
                        </div>
                        <Row className="form-row">
                            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                                <label htmlFor="Full Name">Full Name</label>
                                <input id="name" name="name" className="form-control" type="text" />
                            </Col>

                            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                                <label htmlFor="Email">Email</label>
                                <input id="email" name="email" className="form-control" type="text" placeholder=""
                                />
                            </Col>

                            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                                <label htmlFor="Phone Number">Phone Number</label>
                                <input id="phone" name="phone" className="form-control" type="text" placeholder=""
                                />
                            </Col>
                        </Row>
                        <Row className="form-row mt-3">
                            <Col className="form-group col-xl-6 col-md-6 col-12 mb-3 mb-xl-0">
                                <label htmlFor="Full Name">Password</label>
                                <input id="password" name="password" className="form-control" type="text" />
                            </Col>

                            <Col className="form-group col-xl-6 col-md-6 col-12 mb-3 mb-xl-0">
                                <label htmlFor="Phone Number">Confirm Password</label>
                                <input id="confirm_password" name="confirm_password" className="form-control" type="text" placeholder=""
                                />
                            </Col>
                        </Row>

                        <Row className="form-row mt-3">
                            <Col className="form-group col-xl-12 col-md-12">
                                <label htmlFor="Width">Station</label>
                                <select name="station" className="form-select">
                                    <option value="">Choose Station</option>
                                    {stations.map((station) => {
                                        return (<option key={station.id}
                                                        value={station.id}>{station.address + " " + station.address_2 + " " + station.city_name + " " + station.state}
                                        </option>);
                                    })}
                                </select>
                            </Col>
                        </Row>
                    </section>

                    <section className="mt-5">
                        <div className="d-flex align-items-center mb-2">
                            <SteppedNumber>2</SteppedNumber>
                            <SteppedTitle className="mx-2">Roles & Privileges</SteppedTitle>
                        </div>
                        <Row className="form-row gap-3 gap-md-0">
                            <Col className="form-group col-xl-4 col-md-6">
                                <span style={{fontWeight:"bold"}}>Shipment Privileges</span>
                                <div style={{borderRadius:6, border:"1px solid #D5DBDB",  paddingLeft:10, backgroundColor:"white"}}>
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Create Shipment</label>
                                        <Switch defaultValue="0" name="create_shipment" onChange={() => setCreateShipment(!createShipment)} checked={createShipment} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Approve Shipment</label>
                                        <Switch name="approve_shipment" onChange={() => setApproveShipment(!approveShipment)} checked={approveShipment} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Route Shipment</label>
                                        <Switch name="route_shipment" onChange={() => setRouteShipment(!routeShipment)} checked={routeShipment} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Reroute Shipment</label>
                                        <Switch name="reroute_shipment" onChange={() => setRerouteShipment(!rerouteShipment)} checked={rerouteShipment} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Cancel Shipment</label>
                                        <Switch name="cancel_shipment" onChange={() => setCancelShipment(!cancelShipment)} checked={cancelShipment} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Reject Shipment</label>
                                        <Switch name="reject_shipment" onChange={() => setRejectShipment(!rejectShipment)} checked={rejectShipment} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Transfer Shipment</label>
                                        <Switch name="transfer_shipment" onChange={() => setTransferShipment(!transferShipment)} checked={transferShipment} className="react-switch" />
                                    </div>
                                </div>
                            </Col>

                            <Col className="form-group col-xl-4 col-md-6">
                                <span style={{fontWeight:"bold"}}>Payment Privileges</span>
                                <div style={{borderRadius:6, border:"1px solid #D5DBDB",  paddingLeft:10, backgroundColor:"white"}}>
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Approve Wallet Topup</label>
                                        <Switch name="approve_wallet_topup" onChange={() => setApproveWalletTopup(!approveWalletTopup)} checked={approveWalletTopup} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Mark Shipment As Paid</label>
                                        <Switch name="mark_payment_as_paid" onChange={() => setMarkPaymentAsPaid(!markPaymentAsPaid)} checked={markPaymentAsPaid} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Mark Shipment As Unpaid</label>
                                        <Switch name="mark_payment_as_unpaid" onChange={() => setMarkPaymentAsUnpaid(!markPaymentAsUnpaid)} checked={markPaymentAsUnpaid} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Fund Customer Wallet</label>
                                        <Switch name="fund_user_wallet" onChange={() => setFundUserWallet(!fundUserWallet)} checked={fundUserWallet} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Deduct Customer Wallet</label>
                                        <Switch name="deduct_user_wallet" onChange={() => setDeductUserWallet(!deductUserWallet)} checked={deductUserWallet} className="react-switch" />
                                    </div>
                                </div>
                            </Col>

                            <Col className="form-group col-xl-4 col-md-6">
                                <span style={{fontWeight:"bold"}}>Customer Privileges</span>
                                <div style={{borderRadius:6, border:"1px solid #D5DBDB",  paddingLeft:10, backgroundColor:"white"}}>
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Send Push Notifications</label>
                                        <Switch name="send_push_notifications" onChange={() => setSendPushNotifications(!sendPushNotifications)} checked={sendPushNotifications} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Send Email</label>
                                        <Switch name="send_email" onChange={() => setSendEmail(!sendEmail)} checked={sendEmail} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Send SMS</label>
                                        <Switch name="send_sms" onChange={() => setSendSMS(!sendSMS)} checked={sendSMS} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Make Contract Customer</label>
                                        <Switch name="make_contract_user" onChange={() => setMakeContractUser(!makeContractUser)} checked={makeContractUser} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Make Regular Customer</label>
                                        <Switch name="make_regular_user" onChange={() => setMakeRegularUser(!makeRegularUser)} checked={makeRegularUser} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Reply Messages</label>
                                        <Switch name="reply_messages" onChange={() => setReplyMessages(!replyMessages)} checked={replyMessages} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Suspend User</label>
                                        <Switch name="suspend_user" onChange={() => setSuspendCustomer(!suspendCustomer)} checked={suspendCustomer} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">UnSuspend User</label>
                                        <Switch name="unsuspend_user" onChange={() => setUnsuspendCustomer(!unsuspendCustomer)} checked={unsuspendCustomer} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Activate User</label>
                                        <Switch name="activate_user" onChange={() => setActivateCustomer(!activateCustomer)} checked={activateCustomer} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Deactivate User</label>
                                        <Switch name="deactivate_user" onChange={() => setDeactivateCustomer(!deactivateCustomer)} checked={deactivateCustomer} className="react-switch" />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="form-row mt-4 gap-3 gap-md-0">
                            <Col className="form-group col-xl-4 col-md-6">
                                <span style={{fontWeight:"bold"}}>Transporter Privileges</span>
                                <div style={{borderRadius:6, border:"1px solid #D5DBDB",  paddingLeft:10, backgroundColor:"white"}}>
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Create Transporter</label>
                                        <Switch name="create_rider" onChange={() => setCreateTransporter(!createTransporter)} checked={createTransporter} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Change Transporter Details</label>
                                        <Switch name="change_rider_details" onChange={() => setChangeTransporterDetails(!changeTransporterDetails)} checked={changeTransporterDetails} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Deactivate Transporter</label>
                                        <Switch name="deactivate_rider" onChange={() => setDeactivateTransporter(!deactivateTransporter)} checked={deactivateTransporter} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Reactivate Transporter</label>
                                        <Switch name="reactivate_rider" onChange={() => setReactivateTransporter(!reactivateTransporter)} checked={reactivateTransporter} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Delete Transporter</label>
                                        <Switch name="delete_rider" onChange={() => setDeleteTransporter(!deleteTransporter)} checked={deleteTransporter} className="react-switch" />
                                    </div>
                                </div>
                            </Col>

                            <Col className="form-group col-xl-4 col-md-6">
                                <span style={{fontWeight:"bold"}}>Fund Management</span>
                                <div style={{borderRadius:6, border:"1px solid #D5DBDB",  paddingLeft:10, backgroundColor:"white"}}>
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Transfer Funds</label>
                                        <Switch name="transfer_funds" onChange={() => setTransferFunds(!transferFunds)} checked={transferFunds} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Buy Airtime</label>
                                        <Switch name="buy_airtime" onChange={() => setBuyAirtime(!buyAirtime)} checked={buyAirtime} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Buy Electricity</label>
                                        <Switch onChange={() => setBuyElectricity(!buyElectricity)} checked={buyElectricity} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Buy Internet</label>
                                        <Switch name="buy_internet" onChange={() => setBuyInternet(!buyInternet)} checked={buyInternet} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Buy Toll</label>
                                        <Switch name="buy_toll" onChange={() => setBuyToll(!buyToll)} checked={buyToll} className="react-switch" />
                                    </div>
                                </div>
                            </Col>

                            <Col className="form-group col-xl-4 col-md-6">
                                <span style={{fontWeight:"bold"}}>Admin Privileges</span>
                                <div style={{borderRadius:6, border:"1px solid #D5DBDB",  paddingLeft:10, backgroundColor:"white"}}>
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Create New Admin</label>
                                        <Switch name="create_employee" onChange={() => setCreateAdmin(!createAdmin)} checked={createAdmin} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Edit Admin Details</label>
                                        <Switch name="edit_employee" onChange={() => setEditAdmin(!editAdmin)} checked={editAdmin} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Suspend Admin</label>
                                        <Switch name="suspend_employee" onChange={() => setSuspendAdmin(!suspendAdmin)} checked={suspendAdmin} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Unsuspend Admin</label>
                                        <Switch name="unsuspend_employee" onChange={() => setUnsuspendAdmin(!unsuspendAdmin)} checked={unsuspendAdmin} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Delete Admin</label>
                                        <Switch name="delete_employee" onChange={() => setDeleteAdmin(!deleteAdmin)} checked={deleteAdmin} className="react-switch" />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="form-row mt-4 gap-3 gap-md-0">
                            <Col className="form-group col-xl-4 col-md-6">
                                <span style={{fontWeight:"bold"}}>Transporter Privileges</span>
                                <div style={{borderRadius:6, border:"1px solid #D5DBDB",  paddingLeft:10, backgroundColor:"white"}}>
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Create Transporter</label>
                                        <Switch name="create_rider" onChange={() => setCreateTransporter(!createTransporter)} checked={createTransporter} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Change Transporter Details</label>
                                        <Switch name="change_rider_details" onChange={() => setChangeTransporterDetails(!changeTransporterDetails)} checked={changeTransporterDetails} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Deactivate Transporter</label>
                                        <Switch name="deactivate_rider" onChange={() => setDeactivateTransporter(!deactivateTransporter)} checked={deactivateTransporter} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Reactivate Transporter</label>
                                        <Switch name="reactivate_rider" onChange={() => setReactivateTransporter(!reactivateTransporter)} checked={reactivateTransporter} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Delete Transporter</label>
                                        <Switch name="delete_rider" onChange={() => setDeleteTransporter(!deleteTransporter)} checked={deleteTransporter} className="react-switch" />
                                    </div>
                                </div>
                            </Col>

                            <Col className="form-group col-xl-4 col-md-6">
                                <span style={{fontWeight:"bold"}}>Pages Privileges</span>
                                <div style={{borderRadius:6, border:"1px solid #D5DBDB",  paddingLeft:10, backgroundColor:"white"}}>
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Show Dashboard</label>
                                        <Switch name="show_dashboard" onChange={() => setShowDashboard(!showDashboard)} checked={showDashboard} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Show Shipments</label>
                                        <Switch name="show_shipments" onChange={() => setShowShipments(!showShipments)} checked={showShipments} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Show Customers</label>
                                        <Switch name="show_customers" onChange={() => setShowCustomers(!showCustomers)} checked={showCustomers} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Show Transactions</label>
                                        <Switch name="show_transactions" onChange={() => setShowTransactions(!showTransactions)} checked={showTransactions} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Show Admins</label>
                                        <Switch name="show_admins" onChange={() => setShowAdmins(!showAdmins)} checked={showAdmins} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Show Transporters</label>
                                        <Switch name="show_transporters" onChange={() => setShowTransporters(!showTransporters)} checked={showTransporters} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Show Reports</label>
                                        <Switch name="show_reports" onChange={() => setShowReports(!showReports)} checked={showReports} className="react-switch" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </section>

                    {/*<section className="mt-5">*/}
                    {/*    <div className="d-flex align-items-center mb-2">*/}
                    {/*        <SteppedNumber>3</SteppedNumber>*/}
                    {/*        <SteppedTitle className="mx-2">Profile Image</SteppedTitle>*/}
                    {/*    </div>*/}
                    {/*    <Dropzone onDrop={onDrop} accept={"image/*"} />*/}
                    {/*</section>*/}

                    <section className="mt-5">
                        <div className="d-flex align-items-center mb-2">
                            <SteppedNumber>3</SteppedNumber>
                            <SteppedTitle className="mx-2">Profile Image</SteppedTitle>
                        </div>
                        <input type="file" id="profile-image" className="form-control-file"  />
                    </section>

                    <div className="d-flex flex-row my-5 justify-content-end">
                        <SubmitButton onClick={(e)=> uploadProfileImage()} type="button" className="confirm-button">
                            {isCreatingAdmin === false ? (
                                <span className="button-text">CREATE ADMIN</span>
                            ) : (
                                <span className="spinner-border spinner-grow-sm"/>
                            )}
                        </SubmitButton>
                    </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAdmin;
