import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { registerWhatsappPhoneURL } from "../../library/URLs";
import axios from "axios";
import { UserProfileData } from "../../library/constants";

const SubmitButton = styled.button`
  width: 250px;
`;


const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #fcd2d2;
    margin-bottom:20px;
    text-align:center;
    margin-top:20px;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #c2eddd;
    margin-bottom:20px;
    text-align:center;
    margin-top:20px;
`;

const VerifyPhone = ({ setSubmitted, setCurrentStep, formData:phoneData }) => {
  const [formData, setFormData] = useState({
    code: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submit = () => {
    setCurrentStep(4);
    setSubmitted(3);
    setFormData({
      code: "",
    });
  };

  const registerNumber = (e) => {
    e.preventDefault();

    if(formData.code === "") {
      setErrorVisibility(true);
      setErrorMessage("Provide verification code");
      return
    }

    setErrorVisibility(false);

    const newData = { params: {
      phone_number: `${phoneData?.country}${phoneData?.phone_number}`, code: formData?.code
    }, ...UserProfileData(), level: "3" };

    console.log(newData);

    setIsLoading(true);

    axios
      .post(registerWhatsappPhoneURL, newData)
      .then(function (response) {
        console.log(response);
        setIsLoading(false);

        if (response["data"]["success"] === false) {
          // window.scrollTo(0, 0);
          // submit();
          setErrorVisibility(true);
          setErrorMessage(response["data"]["message"]);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        } else if (response["data"]["success"] === true) {
          // window.scrollTo(0, 0);
          submit();

          setSuccessVisibility(true);
          setSuccessMessage("Level01 updated successfully.");

          setTimeout(() => {
            setSuccessVisibility(false);
          }, 3000);
        }
      })
      .catch(function (error) {
        return error;
      });
  };
  return (
    <div>
      {
                isError === false ? null :
                    (<ErrorMessageContainer>
                        {errorMessage}
                    </ErrorMessageContainer>)
            }

            {
                isSuccess=== false ? null :
                    (<SuccessMessageContainer>
                        {successMessage}
                    </SuccessMessageContainer>)
            }
      <p className="Add-phone-form-info">
        Please enter the verification code below
      </p>
      <form>
        <Row className="mb-5">
          <Col className="form-group col-xl-12 col-md-12">
            <label htmlFor="Full Name">Verification Code</label>
            <input
              name="code"
              className="form-control"
              type="text"
              onChange={handleInput}
              value={formData.code}
            />
          </Col>
        </Row>
        <div className="d-flex flex-row pt-5 my-5 justify-content-end">
          <SubmitButton
            type="submit"
            className="confirm-button"
            onClick={registerNumber}
          >
            {isLoading === false ? (
                                <span className="button-text">VERIFY CODE</span>
                            ) : (
                                <span className="spinner-border spinner-grow-sm"/>
                            )}
          </SubmitButton>
        </div>
      </form>
    </div>
  );
};

export default VerifyPhone;
