import "../styles/home.scss";
import "../styles/single-shipment.scss";
import "../styles/components.scss";
import "../styles/create-admin.scss"
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import {useCallback, useEffect, useRef, useState} from "react";
import {Col, Row} from "react-bootstrap";
import * as React from "react";
import styled from "styled-components";
import {Switch} from "@mui/material";
import axios from "axios";
import {createAdminURL, createMarketerURL, getStationsURL, uploadImageURL} from "../library/URLs";
import {UserProfileData} from "../library/constants";
import {useNavigate} from "react-router-dom";
import SubscriptionInactive from "../components/SubscriptionInactive";

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #fcd2d2;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #c2eddd;
    margin-bottom:20px;
    text-align:center;
`;

const SteppedNumber = styled.div`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #008561;
`;

const SteppedTitle = styled.span`
  font-size: 20px;
`;

const SubmitButton = styled.button`
  width: 250px;
`;

const CreateMarketer = () => {
    const navigator = useNavigate();

  const [isCreatingAdmin, setIsCreatingAdmin] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

    const [stations, setStations] = useState([]);

  const form = useRef(null);

    const data = {
        limit: 20,
        status: "All",
    };

    const newData = { ...data, ...UserProfileData() };

    useEffect(() => {
        axios
            .post(getStationsURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                if (response["data"]["success"] === false) return;

                const stations = response["data"]["data"]["data"];

                setStations(stations);
            });
    }, []);

  const createNewMarketer = (profileImageURL) => {
      const formValues = {};
      formValues["profile_image_url"] = profileImageURL;
      formValues["privileges"] = {};

      const data = new FormData(form.current);

      for (const [key, value] of data.entries()) {
          formValues[key] = value;
      }

      const newData = { ...formValues, ...UserProfileData() };

      console.log(newData);
      axios
          .post(createMarketerURL, newData)
          .then((response) => {
              console.log(response);
              setIsCreatingAdmin(false);
              window.scrollTo(0, 0);

              if (response["data"]["success"] === true){
                  setSuccessVisibility(true);
                  setSuccessMessage("Successfully created marketer. ");

                  setTimeout(() => {
                      navigator("/marketers");
                  }, 3000);
              } else {
                  const message = response['data']['message'];

                  setErrorVisibility(true);
                  setErrorMessage(message);
                  setTimeout(() => {
                      // setErrorVisibility(false);
                  }, 3000);
              }
          })
          .catch(error => console.log(error));
  }

  function showError(message){
      window.scrollTo(0, 0);
      setErrorVisibility(true);
      setErrorMessage(message);

      setTimeout(() => {
          setErrorVisibility(false);
      }, 3000);
  }

    const uploadProfileImage = () => {
        const name = document.querySelector('#name') ;
        const emailAddress = document.querySelector('#email') ;
        const phoneNumber = document.querySelector('#phone') ;
        const fileInput = document.querySelector('#profile-image') ;

        if (name.value === ""){
            showError("Please enter valid name");
            return false;
        }

        if (emailAddress.value === ""){
            showError("Please enter valid email address");
            return false;
        }

        if (phoneNumber.value === ""){
            showError("Please enter valid phone number");
            return false;
        }

        if (fileInput.files[0] === undefined){
            showError("Please upload a picture");
            return false;
        }

        const formValues = {};
        formValues["document"] = fileInput.files[0];

        setIsCreatingAdmin(true);

        axios
            .post(uploadImageURL, formValues, {
                headers:{
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                const profileImageURL = response["data"];

                createNewMarketer(profileImageURL);
            })
            .catch(error => console.log(error));
    }

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
           <SubscriptionInactive />

          <div className="d-flex flex-row justify-content-between mb-2 mx-3">
            <h5 className="header">Create Marketer</h5>
            <span>Home</span>
          </div>
          <div className="container-fluid">
            <div className="card bg-light border-0 shadow p-4">
              <div className="">
                {
                  isError === false ? null :
                      (<ErrorMessageContainer>
                        {errorMessage}
                      </ErrorMessageContainer>)
                }

                {
                  isSuccess=== false ? null :
                      (<SuccessMessageContainer>
                        {successMessage}
                      </SuccessMessageContainer>)
                }


                <form ref={form}>
                    <section>
                        <div className="d-flex align-items-center mb-2">
                            <SteppedNumber>1</SteppedNumber>
                            <SteppedTitle className="mx-2">Basic Details</SteppedTitle>
                        </div>
                        <Row className="form-row">
                            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                                <label htmlFor="Full Name">Full Name</label>
                                <input id="name" name="name" className="form-control" type="text" />
                            </Col>

                            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                                <label htmlFor="Email">Email</label>
                                <input id="email" name="email" className="form-control" type="text" placeholder=""
                                />
                            </Col>

                            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                                <label htmlFor="Phone Number">Phone Number</label>
                                <input id="phone" name="phone" className="form-control" type="text" placeholder=""
                                />
                            </Col>
                        </Row>

                        <Row className="form-row mt-3">
                            <Col className="form-group col-xl-6 col-md-6 col-12 mb-3 mb-xl-0">
                                <label htmlFor="Full Name">Username</label>
                                <input id="username" name="username" className="form-control" type="text" />
                            </Col>

                            <Col className="form-group col-xl-6 col-md-6 col-12 mb-3 mb-xl-0">
                                <label htmlFor="Width">Station</label>
                                <select name="station" className="form-select">
                                    <option value="">Choose Station</option>
                                    {stations.map((station) => {
                                        return (<option key={station.id}
                                                        value={station.id}>{station.address + " " + station.address_2 + " " + station.city_name + " " + station.state}
                                        </option>);
                                    })}
                                </select>
                            </Col>
                        </Row>
                    </section>

                    <section className="mt-5">
                        <div className="d-flex align-items-center mb-2">
                            <SteppedNumber>3</SteppedNumber>
                            <SteppedTitle className="mx-2">Profile Image</SteppedTitle>
                        </div>
                        <input type="file" id="profile-image" className="form-control-file"  />
                    </section>

                    <div className="d-flex flex-row my-5 justify-content-end">
                        <SubmitButton onClick={(e)=> uploadProfileImage()} type="button" className="confirm-button">
                            {isCreatingAdmin === false ? (
                                <span className="button-text">CREATE MARKETER</span>
                            ) : (
                                <span className="spinner-border spinner-grow-sm"/>
                            )}
                        </SubmitButton>
                    </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateMarketer;
