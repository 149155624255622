import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { registerWhatsappPhoneURL } from "../../library/URLs";
import axios from "axios";
import { UserProfileData } from "../../library/constants";
import countryCodes from "../../files/countryPhoneCodes.json"

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #fcd2d2;
    margin-bottom:20px;
    text-align:center;
    margin-top:20px;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #c2eddd;
    margin-bottom:20px;
    text-align:center;
    margin-top:20px;
`;

const CreatePhone = ({ setSubmitted, setCurrentStep, formData, setFormData }) => {
  
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  function removeLeadingZero(str) {
    // Check if the string starts with "0"
    if (str.startsWith("0")) {
        // Remove the leading "0" using substring
        return str.substring(1);
    } else if (str.startsWith(formData?.country)) {
      let result = str.substring(formData?.country?.length);
      if (result.startsWith("0")) {
        // Remove the leading "0" using substring
        return result.substring(1);
      }
      return result;
    } else {
        // If the string does not start with "0", return the original string
        return str;
    }
  }

  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submit = () => {
    setCurrentStep(2);
    setSubmitted(1);
  };

  const registerNumber = (e) => {
    e.preventDefault();

    if(formData.display_name === "") {
      setErrorVisibility(true);
      setErrorMessage("Provide your business name");
      return
    }

    if(formData.phone_number === "") {
      setErrorVisibility(true);
      setErrorMessage("Provide your phone number");
      return
    }

    setErrorVisibility(false);

    const { country, display_name, phone_number } = formData;

    const newData = { params: {display_name, country_code:country, phone_number: removeLeadingZero(phone_number)}, ...UserProfileData(), level: "1" };

    console.log(newData);

    setIsLoading(true);

    axios
      .post(registerWhatsappPhoneURL, newData)
      .then(function (response) {
        console.log(response);
        setIsLoading(false);

        if (response["data"]["success"] === false) {
          // window.scrollTo(0, 0);
          // submit();
          setErrorVisibility(true);
          setErrorMessage(response["data"]["message"]);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        } else if (response["data"]["success"] === true) {
          // window.scrollTo(0, 0);
          submit();

          setSuccessVisibility(true);
          setSuccessMessage("Level01 updated successfully.");

          setTimeout(() => {
            setSuccessVisibility(false);
          }, 3000);
        }
      })
      .catch(function (error) {
        return error;
      });
  };
  return (
    <div>
      {
                isError === false ? null :
                    (<ErrorMessageContainer>
                        {errorMessage}
                    </ErrorMessageContainer>)
            }

            {
                isSuccess=== false ? null :
                    (<SuccessMessageContainer>
                        {successMessage}
                    </SuccessMessageContainer>)
            }
      <p className="Add-phone-form-info">
        This would help you add your phone number to your WhatsApp business
        profile and allow your customers reach your easily.
      </p>
      <form>
        <Row className="mb-3">
          <Col className="form-group col-12">
            <label htmlFor="Email">Country</label>
            <select name="country" className="form-control" value={formData.country} onChange={handleInput}>
              <option value="" hidden>Select Country</option>
              {
                countryCodes.map((country) => (
                  <option key={country.code} value={country.code}>{country.country}</option>
                ))
              }
            </select>
            {/* <input
              name="country"
              className="form-control"
              type="text"
              placeholder=""
              onChange={handleInput}
              value={formData.country}
            /> */}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className="form-group col-12">
            <label htmlFor="Full Name">Phone Number</label>
            <input
              name="phone_number"
              className="form-control"
              type="text"
              placeholder="e.g 8133344444"
              onChange={handleInput}
              value={formData.phone_number}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className="form-group col-12">
            <label htmlFor="Email">Whatsapp Business Name</label>
            <input
              name="display_name"
              className="form-control"
              type="text"
              placeholder=""
              onChange={handleInput}
              value={formData.display_name}
            />
          </Col>
        </Row>
        <div className="d-flex flex-row my-5 justify-content-end">
          <SubmitButton
            type="submit"
            className="confirm-button"
            onClick={registerNumber}
          >
            {isLoading === false ? (
              <span className="button-text">CREATE NUMBER</span>
            ) : (
              <span className="spinner-border spinner-grow-sm" />
            )}
          </SubmitButton>
        </div>
      </form>
    </div>
  );
};

export default CreatePhone;
