import {Col, Row} from "react-bootstrap";
import * as React from "react";
import styled from "styled-components";
import {useState} from "react";
import {Switch} from "@mui/material";
import ReactDatePicker from "react-datepicker";

const SteppedNumber = styled.div`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0095ff;
  margin-top:10px;
  margin-right:10px;
`;

const SteppedTitle = styled.span`
  font-size: 20px;
`;

const SubmitButton = styled.button`
  width: 250px;
`;

const ShipmentSettings = () => {
    const [isUpdatingShipmentType, setIsUpdatingShipmentType] = useState(false);
    const [isUpdatingShipmentVehicle, setIsUpdatingShipmentVehicle] = useState(false);
    const [isUpdatingShipmentTime, setIsUpdatingShipmentTime] = useState(false);

    const [localShipments, setLocalShipments] = useState(false);
    const [interstateShipments, setInterstateShipments] = useState(false);
    const [internationalShipments, setInternationalShipments] = useState(false);

    const [motorbikeVehicle, setMotorbikeVehicle] = useState(false);
    const [carVehicle, setCarVehicle] = useState(false);
    const [minivanVehicle, setMinivanVehicle] = useState(false);
    const [truckVehicle, setTruckVehicle] = useState(false);

    const [acceptShipmentNow, setAcceptShipmentNow] = useState(false);
    const [dailyNumberLimit, setDailyNumberLimit] = useState(false);
    const [dailyTimeLimit, setDailyTimeLimit] = useState(false);

    const [startDate, setStartDate] = useState(new Date());

    return (
        <>
            <div className="row">
                <div className="col-xl-12 col-md-12 col-sm-12 col">
                    <div style={{height:20}}/>

                    <div className="d-flex align-items-center mb-2">
                        <SteppedNumber>1</SteppedNumber>
                        <p style={{fontSize:20, marginTop:10, marginBottom:0, fontWeight:"bold"}}>Shipment Type</p>
                        <span style={{fontSize:14, marginTop:13, marginLeft:7}}>  -  Choose what types of shipment you want to process</span>
                    </div>
                    <Row>
                        <Col className="form-group col-xl-4 col-md-4">
                            <label htmlFor="Full Name">Local Shipments</label>
                            <Switch name="send_push_notifications" onChange={() => setLocalShipments(!localShipments)} checked={localShipments} className="react-switch" />
                        </Col>

                        <Col className="form-group col-xl-4 col-md-4">
                            <label htmlFor="Email">Interstate Shipments</label>
                            <Switch name="send_push_notifications" onChange={() => setInterstateShipments(!interstateShipments)} checked={interstateShipments} className="react-switch" />
                        </Col>

                        <Col className="form-group col-xl-4 col-md-4">
                            <label htmlFor="Email">International Shipments</label>
                            <Switch name="send_push_notifications" onChange={() => setInternationalShipments(!internationalShipments)} checked={internationalShipments} className="react-switch" />
                        </Col>
                    </Row>

                    <div style={{height:20}}/>

                    <div className="d-flex align-items-center mb-2">
                        <SteppedNumber>2</SteppedNumber>
                        <p style={{fontSize:20, marginTop:10, marginBottom:0, fontWeight:"bold"}}>Shipment Vehicle</p>
                        <span style={{fontSize:14, marginTop:13, marginLeft:7}}>  -  Choose what types of shipment you want to process</span>
                    </div>
                    <Row>
                        <Col className="form-group col-xl-3 col-md-6">
                            <label htmlFor="Full Name">Motorbike</label>
                            <Switch name="send_push_notifications" onChange={() => setMotorbikeVehicle(!motorbikeVehicle)} checked={motorbikeVehicle} className="react-switch" />
                        </Col>

                        <Col className="form-group col-xl-3 col-md-6">
                            <label htmlFor="Email">Car</label>
                            <Switch name="send_push_notifications" onChange={() => setCarVehicle(!carVehicle)} checked={carVehicle} className="react-switch" />
                        </Col>

                        <Col className="form-group col-xl-3 col-md-6">
                            <label htmlFor="Email">Minivan</label>
                            <Switch name="send_push_notifications" onChange={() => setMinivanVehicle(!minivanVehicle)} checked={minivanVehicle} className="react-switch" />
                        </Col>

                        <Col className="form-group col-xl-3 col-md-6">
                            <label htmlFor="Email">Truck</label>
                            <Switch name="send_push_notifications" onChange={() => setTruckVehicle(!truckVehicle)} checked={truckVehicle} className="react-switch" />
                        </Col>
                    </Row>

                    <div style={{height:20}}/>

                    <div className="d-flex align-items-center mb-2">
                        <SteppedNumber>3</SteppedNumber>
                        <p style={{fontSize:20, marginTop:10, marginBottom:0, fontWeight:"bold"}}>Shipment Time</p>
                        <span style={{fontSize:14, marginTop:13, marginLeft:7}}>  -  Choose what types of shipment you want to process</span>
                    </div>

                    <Row>
                        <Col className="form-group col-xl-4 col-md-12 col-sm-12">
                            <label htmlFor="Full Name">Accept Shipment Now</label>
                            <Switch name="send_push_notifications" onChange={() => setAcceptShipmentNow(!acceptShipmentNow)} checked={acceptShipmentNow} className="react-switch" />
                        </Col>

                        {
                            acceptShipmentNow === false ? null :
                            <Col className="form-group col-xl-4 col-md-12 col-sm-12">
                                <label htmlFor="Email">Daily Number Limit</label>
                                <Switch name="send_push_notifications" onChange={() => setDailyNumberLimit(!dailyNumberLimit)} checked={dailyNumberLimit} className="react-switch" />
                                {
                                    dailyNumberLimit === false ? null :
                                    <div>
                                        <input name="daily_limit_number" className="form-control" type="number"/>
                                    </div>
                                }
                            </Col>
                        }


                        {
                            acceptShipmentNow === false ? null :
                            <Col className="form-group col-xl-4 col-md-12 col-sm-12">
                                <label htmlFor="Email">Daily Time Limit</label>
                                <Switch name="send_push_notifications" onChange={() => setDailyTimeLimit(!dailyTimeLimit)} checked={dailyTimeLimit} className="react-switch" />
                                {
                                    dailyTimeLimit === false ? null :
                                        <div>
                                            <ReactDatePicker
                                                name="shipping_date"
                                                className="form-control"
                                                selected={startDate}
                                                dateFormat="h:mm aa"
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeCaption="Time"
                                                timeIntervals={15}
                                                onChange={(date) => setStartDate(date)}
                                            />
                                        </div>
                                }
                            </Col>
                        }
                    </Row>

                    <div className="d-flex flex-row my-5 justify-content-end">
                        <SubmitButton type="button" className="confirm-button">
                            {isUpdatingShipmentType === false ? (
                                <span className="button-text">UPDATE SHIPMENT SETTINGS</span>
                            ) : (
                                <span className="spinner-border spinner-grow-sm"/>
                            )}
                        </SubmitButton>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ShipmentSettings;