import { Col, Row } from "react-bootstrap";
import { Switch } from "@mui/material";
import { styled as mui_styled } from '@mui/material/styles';
import { useState, useEffect } from "react";
import styled from "styled-components";
import {
    getNotificationSettingsUrl,
    setNotificationSettingsUrl,
} from "../../../library/URLs";
import axios from "axios";
import { UserProfileData } from "../../../library/constants";
import { toast } from "react-toastify";
import NotificationIcon from "../../../assets/settings/notifications/notifications_active.svg";
import Package02 from "../../../assets/settings/notifications/package_2.svg";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #fcd2d2;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #c2eddd;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
`;

const ParcelflowSwitch = mui_styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 34,
    height: 16,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#15AB68',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 14,
        height: 12,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#98A2B3',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const NotificationSettings = () => {
    const [isCreatingAdmin, setIsCreatingAdmin] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);
    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [createShipment, setCreateShipment] = useState(false);
    const [approveShipment, setApproveShipment] = useState(false);
    const [topupUserWallet, setTopupUserWallet] = useState(false);
    const [deductUserWallet, setDeductUserWallet] = useState(false);

    const [customerCreateAccount, setCustomerCreateAccount] = useState(false);
    const [customerCalculateCost, setCustomerCalculateCost] = useState(false);
    const [customerCreateShipment, setCustomerCreateShipment] = useState(false);
    const [customerSendsMessage, setCustomerSendsMessage] = useState(false);
    const [customerLogsIn, setCustomerLogsIn] = useState(false);

    const [transporterAcceptsShipments, setTransporterAcceptsShipments] =
        useState(false);
    const [transporterPicksUp, setTransporterPicksUp] = useState(false);
    const [transporterDelivers, setTransporterDelivers] = useState(false);
    const [transporterRejects, setTransporterRejects] = useState(false);
    const [transporterFailsToAccept, setTransporterFailsToAccept] =
        useState(false);

    const [optionLoading, setOptionLoading] = useState([]);

    const handleNotificationStatus = (status) => {
        if (typeof status === "string") {
            if (status === "1") {
                return true;
            } else {
                return false;
            }
        } else if (typeof status === "boolean") {
            if (status) {
                return "1";
            } else {
                return "0";
            }
        }
        return;
    };

    const fetchNotificationSettings = () => {
        const data = { ...UserProfileData() };
        // setIsLoading(true);
        axios
            .post(getNotificationSettingsUrl, data, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response["data"]);
                // setIsLoading(false);

                if (response["data"]["success"] === false) return;

                const notificationSettings = response["data"]["data"];

                setCreateShipment(
                    handleNotificationStatus(
                        notificationSettings["create_employee_shipment"]
                    )
                );
                setApproveShipment(
                    handleNotificationStatus(
                        notificationSettings["approve_route_shipment"]
                    )
                );
                setTopupUserWallet(
                    handleNotificationStatus(notificationSettings["approve_wallet_topup"])
                );
                console.log(
                    "app wall",
                    handleNotificationStatus(
                        notificationSettings["create_customer_shipment"]
                    )
                );

                setCustomerCreateAccount(
                    handleNotificationStatus(notificationSettings["create_account"])
                );
                setCustomerCalculateCost(
                    handleNotificationStatus(notificationSettings["calculate_cost"])
                );
                setCustomerCreateShipment(
                    handleNotificationStatus(
                        notificationSettings["create_customer_shipment"]
                    )
                );
                setCustomerSendsMessage(
                    handleNotificationStatus(notificationSettings["sends_message"])
                );

                setTransporterAcceptsShipments(
                    handleNotificationStatus(
                        notificationSettings["rider_accepts_shipment"]
                    )
                );
                setTransporterPicksUp(
                    handleNotificationStatus(notificationSettings["rider_picks_up"])
                );
                setTransporterDelivers(
                    handleNotificationStatus(notificationSettings["rider_delivers"])
                );
                setTransporterRejects(
                    handleNotificationStatus(
                        notificationSettings["rider_rejects_shipment"]
                    )
                );
                setTransporterFailsToAccept(
                    handleNotificationStatus(
                        notificationSettings["rider_fails_to_accept"]
                    )
                );
            })
            .catch(() => {
                // setIsLoading(false);
            });
    };

    const updateNotificationSettings = (field_name, value, num) => {
        setOptionLoading(optionLoading.concat(num));

        const newData = {
            ...UserProfileData(),
            notification_key: field_name,
            notification_value: value,
        };

        axios
            .post(setNotificationSettingsUrl, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                let selected = [...optionLoading];
                selected.pop(num);
                setOptionLoading(selected);
                if (response["data"]["success"] === false) {
                    window.scrollTo(0, 0);
                    setErrorVisibility(true);
                    setErrorMessage(response["data"]["message"]);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                } else if (response["data"]["success"] === true) {
                    window.scrollTo(0, 0);
                    toast.success("Notifications settings updated successfully");
                    fetchNotificationSettings();

                    // setSuccessVisibility(true);
                    // setSuccessMessage("Notifications settings updated successfully");
                    // fetchNotificationSettings();

                    // setTimeout(() => {
                    //     setSuccessVisibility(false);
                    // }, 3000);
                }
            });
    };

    useEffect(() => {
        fetchNotificationSettings();
    }, []);

    return (
        <>
            {isError === false ? null : (
                <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
            )}

            {isSuccess === false ? null : (
                <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
            )}
            <div className="settings_section" >
                <div className="d-flex align-items-center justify-content-between" >
                    <div className="d-flex gap-3 align-items-start" >
                        <img src={NotificationIcon} alt="notification" className="mt-2" />
                        <div>
                            <p style={{ fontSize: 22, marginBottom: 8, fontWeight: "bold" }}>Notification Settings</p>
                            <span>Edit the notification alerts to your preferences</span>
                        </div>
                    </div>
                </div>
                <Row className="form-row mt-4">
                    <Col className="form-group col-xl-4 col-md-6">
                        <div
                            style={{
                                paddingLeft: 10,
                            }}
                            className="notifications-card"
                        >
                            <div className="top-banner">
                                <img src={Package02} alt="icon" />
                                <p className="m-0" >Employee Notifications</p>
                            </div>
                            <div className="options-card" >
                                <div className="d-flex flex-row py-2 px-3 border-bottom justify-content-between align-items-center">
                                    <label htmlFor="Full Name">Create Shipment</label>
                                    {optionLoading?.includes(1) ? (
                                        <div className="pe-4" >
                                            <span className="spinner-border spinner-grow-sm" />
                                        </div>
                                    ) : (
                                        <ParcelflowSwitch
                                            defaultValue="0"
                                            name="create_employee_shipment"
                                            onChange={() =>
                                                updateNotificationSettings(
                                                    "create_employee_shipment",
                                                    handleNotificationStatus(!createShipment),
                                                    1
                                                )
                                            }
                                            checked={createShipment}
                                            className="react-switch"
                                        />
                                    )}
                                </div>

                                <div className="d-flex px-3 border-bottom py-2 flex-row justify-content-between align-items-center">
                                    <label htmlFor="Full Name">Approve Shipment</label>
                                    {optionLoading?.includes(2) ? (
                                        <div className="pe-4" >
                                            <span className="spinner-border spinner-grow-sm" />
                                        </div>
                                    ) : (
                                        <ParcelflowSwitch
                                            name="approve_route_shipment"
                                            onChange={() =>
                                                updateNotificationSettings(
                                                    "approve_route_shipment",
                                                    handleNotificationStatus(!approveShipment),
                                                    2
                                                )
                                            }
                                            checked={approveShipment}
                                            className="react-switch"
                                        />
                                    )}
                                </div>

                                <div className="d-flex px-3 flex-row py-2 justify-content-between align-items-center">
                                    <label htmlFor="Full Name">Topup User Wallet</label>
                                    {optionLoading?.includes(3) ? (
                                        <div className="pe-4" >
                                            <span className="spinner-border spinner-grow-sm" />
                                        </div>
                                    ) : (
                                        <ParcelflowSwitch
                                            name="approve_wallet_topup"
                                            onChange={() =>
                                                updateNotificationSettings(
                                                    "approve_wallet_topup",
                                                    handleNotificationStatus(!topupUserWallet),
                                                    3
                                                )
                                            }
                                            checked={topupUserWallet}
                                            className="react-switch"
                                        />
                                    )}
                                </div>
                            </div>

                            {/* <div className="d-flex flex-row justify-content-between align-items-center">
                            <label htmlFor="Full Name">Deduct User Wallet</label>
                            <Switch
                                name="route_shipment"
                                onChange={() => setDeductUserWallet(!deductUserWallet)}
                                checked={deductUserWallet}
                                className="react-switch"
                            />
                        </div> */}
                        </div>
                    </Col>

                    <Col className="form-group col-xl-4 col-md-6">
                        <div
                            style={{
                                paddingLeft: 10,
                            }}
                            className="notifications-card"
                        >
                            <div className="top-banner">
                                <img src={Package02} alt="icon" />
                                <p className="m-0" >Customer Notifications</p>
                            </div>
                            <div className="options-card" >
                                <div className="d-flex px-3 border-bottom py-2 flex-row justify-content-between align-items-center">
                                    <label htmlFor="Full Name">Create Account</label>
                                    {optionLoading?.includes(4) ? (
                                        <div className="pe-4" >
                                            <span className="spinner-border spinner-grow-sm" />
                                        </div>
                                    ) : (
                                        <ParcelflowSwitch
                                            name="create_account"
                                            onChange={() =>
                                                updateNotificationSettings(
                                                    "create_account",
                                                    handleNotificationStatus(!customerCreateAccount),
                                                    4
                                                )
                                            }
                                            checked={customerCreateAccount}
                                            className="react-switch"
                                        />
                                    )}
                                </div>

                                <div className="d-flex px-3 border-bottom py-2 flex-row justify-content-between align-items-center">
                                    <label htmlFor="Full Name">Calculate Cost</label>
                                    {optionLoading?.includes(5) ? (
                                        <div className="pe-4" >
                                            <span className="spinner-border spinner-grow-sm" />
                                        </div>
                                    ) : (
                                        <ParcelflowSwitch
                                            name="calculate_cost"
                                            onChange={() =>
                                                updateNotificationSettings(
                                                    "calculate_cost",
                                                    handleNotificationStatus(!customerCalculateCost),
                                                    5
                                                )
                                            }
                                            checked={customerCalculateCost}
                                            className="react-switch"
                                        />
                                    )}
                                </div>

                                <div className="d-flex px-3 border-bottom py-2 flex-row justify-content-between align-items-center">
                                    <label htmlFor="Full Name">Create Shipment</label>
                                    {optionLoading?.includes(6) ? (
                                        <div className="pe-4" >
                                            <span className="spinner-border spinner-grow-sm" />
                                        </div>
                                    ) : (
                                        <ParcelflowSwitch
                                            name="create_customer_shipment"
                                            onChange={() =>
                                                updateNotificationSettings(
                                                    "create_customer_shipment",
                                                    handleNotificationStatus(!customerCreateShipment),
                                                    6
                                                )
                                            }
                                            checked={customerCreateShipment}
                                            className="react-switch"
                                        />
                                    )}
                                </div>

                                <div className="d-flex px-3 border-bottom py-2 flex-row justify-content-between align-items-center">
                                    <label htmlFor="Full Name">Sends Message</label>

                                    {optionLoading?.includes(7) ? (
                                        <div className="pe-4" >
                                            <span className="spinner-border spinner-grow-sm" />
                                        </div>
                                    ) : (
                                        <ParcelflowSwitch
                                            name="sends_message"
                                            onChange={() =>
                                                updateNotificationSettings(
                                                    "sends_message",
                                                    handleNotificationStatus(!customerSendsMessage),
                                                    7
                                                )
                                            }
                                            checked={customerSendsMessage}
                                            className="react-switch"
                                        />
                                    )}
                                </div>

                                <div className="d-flex px-3 py-2 flex-row justify-content-between align-items-center">
                                    <label htmlFor="Full Name">Logs In</label>
                                    {optionLoading?.includes(8) ? (
                                        <div className="pe-4" >
                                            <span className="spinner-border spinner-grow-sm" />
                                        </div>
                                    ) : (
                                        <ParcelflowSwitch
                                            name="logs_in"
                                            onChange={() =>
                                                updateNotificationSettings(
                                                    "logs_in",
                                                    handleNotificationStatus(!customerLogsIn),
                                                    8
                                                )
                                            }
                                            checked={customerLogsIn}
                                            className="react-switch"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col className="form-group col-xl-4 col-md-6">
                        <div
                            style={{
                                paddingLeft: 10,
                            }}
                            className="notifications-card"
                        >
                            <div className="top-banner">
                                <img src={Package02} alt="icon" />
                                <p className="m-0" >Transporter Notifications</p>
                            </div>
                            <div className="options-card" >
                                <div className="d-flex flex-row px-3 border-bottom py-2 justify-content-between align-items-center">
                                    <label htmlFor="Full Name">Accepts Shipments</label>
                                    {optionLoading?.includes(9) ? (
                                        <div className="pe-4" >
                                            <span className="spinner-border spinner-grow-sm" />
                                        </div>
                                    ) : (
                                        <ParcelflowSwitch
                                            name="rider_accepts_shipment"
                                            onChange={() =>
                                                updateNotificationSettings(
                                                    "rider_accepts_shipment",
                                                    handleNotificationStatus(!transporterAcceptsShipments),
                                                    9
                                                )
                                            }
                                            checked={transporterAcceptsShipments}
                                            className="react-switch"
                                        />
                                    )}
                                </div>

                                <div className="d-flex px-3 border-bottom py-2 flex-row justify-content-between align-items-center">
                                    <label htmlFor="Full Name">Picks Up Shipments</label>
                                    {optionLoading?.includes(10) ? (
                                        <div className="pe-4" >
                                            <span className="spinner-border spinner-grow-sm" />
                                        </div>
                                    ) : (
                                        <ParcelflowSwitch
                                            name="rider_picks_up"
                                            onChange={() =>
                                                updateNotificationSettings(
                                                    "rider_picks_up",
                                                    handleNotificationStatus(!transporterPicksUp),
                                                    10
                                                )
                                            }
                                            checked={transporterPicksUp}
                                            className="react-switch"
                                        />
                                    )}
                                </div>

                                <div className="d-flex px-3 border-bottom py-2 flex-row justify-content-between align-items-center">
                                    <label htmlFor="Full Name">Delivers Shipments</label>
                                    {optionLoading?.includes(11) ? (
                                        <div className="pe-4" >
                                            <span className="spinner-border spinner-grow-sm" />
                                        </div>
                                    ) : (
                                        <ParcelflowSwitch
                                            name="rider_delivers"
                                            onChange={() =>
                                                updateNotificationSettings(
                                                    "rider_delivers",
                                                    handleNotificationStatus(!transporterDelivers),
                                                    11
                                                )
                                            }
                                            checked={transporterDelivers}
                                            className="react-switch"
                                        />
                                    )}
                                </div>

                                <div className="d-flex px-3 border-bottom py-2 flex-row justify-content-between align-items-center">
                                    <label htmlFor="Full Name">Fails to Accept Shipment</label>
                                    {optionLoading?.includes(12) ? (
                                        <div className="pe-4" >
                                            <span className="spinner-border spinner-grow-sm" />
                                        </div>
                                    ) : (
                                        <ParcelflowSwitch
                                            name="rider_fails_to_accept"
                                            onChange={() =>
                                                updateNotificationSettings(
                                                    "rider_fails_to_accept",
                                                    handleNotificationStatus(!transporterFailsToAccept),
                                                    12
                                                )
                                            }
                                            checked={transporterFailsToAccept}
                                            className="react-switch"
                                        />
                                    )}
                                </div>

                                <div className="d-flex px-3 py-2 flex-row justify-content-between align-items-center">
                                    <label htmlFor="Full Name">Rejects Shipment</label>
                                    {optionLoading?.includes(13) ? (
                                        <div className="pe-4" >
                                            <span className="spinner-border spinner-grow-sm" />
                                        </div>
                                    ) : (
                                        <ParcelflowSwitch
                                            name="rider_rejects_shipment"
                                            onChange={() =>
                                                updateNotificationSettings(
                                                    "rider_rejects_shipment",
                                                    handleNotificationStatus(!transporterRejects),
                                                    13
                                                )
                                            }
                                            checked={transporterRejects}
                                            className="react-switch"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            {/* <div className="d-flex flex-row my-5 justify-content-end">
                <SubmitButton type="submit" className="confirm-button">
                    {isCreatingAdmin === false ? (
                        <span className="button-text">UPDATE NOTIFICATIONS</span>
                    ) : (
                        <span className="spinner-border spinner-grow-sm"/>
                    )}
                </SubmitButton>
            </div> */}
        </>
    );
};

export default NotificationSettings;
