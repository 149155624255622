import { Col, Row } from "react-bootstrap";
import * as React from "react";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { SessionManagement } from "../../../library/SessionManagement";
import PriceSettingsDialog from "../../../modals/MotorbikePriceSettingsDialog";
import ChangePasswordModal from "../../../modals/ChangePasswordModal";
import { generateTransactionID } from "../../../library/utilities";
import { UserProfileData } from "../../../library/constants";
import axios from "axios";
import {
    createInternationalShipmentURL,
    updateProfileURL,
    changePasswordURL,
} from "../../../library/URLs";
import CompanyDetails from "./CompanyDetails";
import User from "../../../assets/settings/account_details/user.svg";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { BsCheck } from "react-icons/bs";
import { PasswordBox, TextBox } from "../../../components/InputFields";

const SubmitButton = styled.button`
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #fcd2d2;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #c2eddd;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
`;

const PersonalDetails = () => {
    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [editProfile, setEditProfile] = useState(false);

    const [isUpdating, setIsUpdating] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [passwordForm, setPasswordForm] = useState({
        current_password: "",
        new_password: "",
        confirm_password: "",
    });

    const handlePassword = (e) => {
        const { name, value } = e.target;

        setPasswordForm({
            ...passwordForm,
            [name]: value,
        });
    };

    const [changePasswordDialogVisibility, setChangePasswordDialogVisibility] =
        useState(false);

    const firstName = SessionManagement.getFirstName();
    const lastName = SessionManagement.getLastName();
    const phoneNumber = SessionManagement.getPhone();
    const emailAddress = SessionManagement.getEmail();
    const profileImageURL = SessionManagement.getProfileImageURL();

    // useEffect(() => {
    //     const objectUrl = URL.createObjectURL(selectedFile)
    //     setPreview(objectUrl)
    //
    //     // free memory when ever this component is unmounted
    //     return () => URL.revokeObjectURL(objectUrl)
    // }, [selectedFile])

    const handleCloseModal = () => {
        setChangePasswordDialogVisibility(false);
    };

    const changePassword = () => {
        const formValues = {};

        const data = new FormData(form.current);

        for (const [key, value] of data.entries()) {
            formValues[key] = value;
        }

        const newData = { ...formValues, ...UserProfileData() };

        setErrorVisibility(false);
        setIsSubmitting(true);

        console.log(formValues);

        axios
            .post(changePasswordURL, newData, {
                method: "POST",
            })
            .then(function (response) {
                console.log(response);
                setIsSubmitting(false);

                if (response["data"]["success"] === false) {
                    window.scrollTo(0, 0);
                    setErrorVisibility(true);
                    setErrorMessage(response["data"]["message"]);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                } else if (response["data"]["success"] === true) {
                    setSuccessVisibility(true);
                    setSuccessMessage(
                        "User has successfully been set to a contract user"
                    );

                    setTimeout(() => {
                        setSuccessVisibility(false);
                    }, 3000);
                }
            })
            .catch(function (error) {
                setIsSubmitting(false);
                return error;
            });
    };

    const showChangePasswordDialog = () => {
        setChangePasswordDialogVisibility(true);
    };

    const form = useRef(null);

    const updateProfile = (e) => {
        e.preventDefault();

        setErrorVisibility(false);

        const data = new FormData(form.current);

        const formValues = {};

        for (const [key, value] of data.entries()) {
            formValues[key] = value;
        }

        const newData = { ...formValues, ...UserProfileData() };

        console.log(newData);

        setIsUpdating(true);

        axios
            .post(updateProfileURL, newData)
            .then(function (response) {
                console.log(response);
                setIsUpdating(false);

                if (response["data"]["success"] === false) {
                    window.scrollTo(0, 0);
                    setErrorVisibility(true);
                    setErrorMessage(response["data"]["message"]);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                } else if (response["data"]["success"] === true) {
                    window.scrollTo(0, 0);
                    setEditProfile(true);

                    const firstName = response["data"]["data"]["first_name"];
                    const lastName = response["data"]["data"]["last_name"];
                    const email = response["data"]["data"]["email"];
                    const phoneNumber = response["data"]["data"]["phone"];

                    console.log(phoneNumber);

                    SessionManagement.setFirstName(firstName);
                    SessionManagement.setLastName(lastName);
                    SessionManagement.setEmail(email);
                    SessionManagement.setPhone(phoneNumber);

                    setSuccessVisibility(true);
                    setSuccessMessage("Profile has been updated successfully.");

                    setTimeout(() => {
                        setSuccessVisibility(false);
                    }, 3000);
                }
            })
            .catch(function (error) {
                return error;
            });
    };

    const passwordValidation = () => {
        if (passwordForm.confirm_password === "") {
            return true;
        } else if (passwordForm.new_password === "") {
            return true;
        } else if (passwordForm.new_password !== passwordForm.confirm_password) {
            return true;
        }

        return false;
    };

    return (
        <>
            {isError === false ? null : (
                <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
            )}

            {isSuccess === false ? null : (
                <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
            )}

            <ChangePasswordModal
                isModalVisible={changePasswordDialogVisibility}
                handleCloseDialog={handleCloseModal}
            />

            <div className="settings_section">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex gap-3 align-items-start">
                        <img src={User} alt="user" className="mt-2" />
                        <div>
                            <p style={{ fontSize: 22, marginBottom: 8, fontWeight: "bold" }}>
                                Personal Details
                            </p>
                            <span>Edit your profile details below</span>
                        </div>
                    </div>
                    {editProfile ? (
                        <div
                            style={{ marginTop: 20 }}
                            onClick={() => setEditProfile(false)}
                            className=" settings_btn"
                        >
                            {" "}
                            Cancel
                        </div>
                    ) : (
                        <div
                            style={{ marginTop: 20 }}
                            onClick={() => setEditProfile(true)}
                            className=" settings_btn"
                        >
                            {" "}
                            <TbEdit /> Edit Details
                        </div>
                    )}
                </div>
                <form ref={form} onSubmit={updateProfile}>
                    <div className="row mt-4">
                        <div className="col-xl-4 col-md-6 mt-4 mt-md-0 ps-md-5 col-12 col">
                            <div className="d-flex flex-column align-items-start ">
                                <img
                                    style={{ width: 110, height: 110, borderRadius: "50%" }}
                                    src={profileImageURL}
                                    alt="profile"
                                />
                                <span style={{ marginTop: 15, fontSize: 14 }}>
                                    Change Profile Image
                                </span>
                                <div style={{ marginTop: 20 }} className=" settings_btn">
                                    <MdOutlineAddPhotoAlternate /> Choose Image
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-8 col-md-6 col-12 col">
                            <Row>
                                <Col className="form-group col-12 col-xl-6 col-md-6">
                                    <label htmlFor="Full Name">First Name</label>
                                    <TextBox
                                        name="first_name"
                                        disabled={!editProfile}
                                        type="text"
                                        defaultValue={firstName}
                                    />
                                </Col>

                                <Col className="form-group col-12 col-xl-6 col-md-6">
                                    <label htmlFor="Email">Last Name</label>
                                    <TextBox
                                        name="last_name"
                                        disabled={!editProfile}
                                        type="text"
                                        defaultValue={lastName}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="form-group col-12 mb-3 mb-md-0 col-xl-6 col-md-6">
                                    <label htmlFor="Email Address">Email Address</label>
                                    <TextBox
                                        name="email"
                                        disabled={!editProfile}
                                        type="email"
                                        defaultValue={emailAddress}
                                    />
                                </Col>

                                <Col className="form-group col-12 col-xl-6 col-md-6">
                                    <label htmlFor="Email">Phone Number</label>
                                    <TextBox
                                        name="phone_number"
                                        disabled={!editProfile}
                                        type="tel"
                                        defaultValue={phoneNumber}
                                    />
                                </Col>
                            </Row>
                        </div>

                        <div
                            className="d-flex flex-row my-5 justify-content-end"
                            style={{
                                borderBottom: "1px dashed #BFC9CA",
                                paddingBottom: "2rem",
                            }}
                        >
                            <SubmitButton
                                type="submit"
                                className=" btn-primary"
                            >
                                {isUpdating === false ? (
                                    <span className="button-text">Update Profile</span>
                                ) : (
                                    <span className="spinner-border spinner-grow-sm" />
                                )}
                            </SubmitButton>
                        </div>

                        <div className="col-xl-4 col-md-6 mt-4 mt-md-0 ps-md-5 col-12 col">
                            <div className="d-flex flex-column align-items-start ">
                                <div>
                                    <p
                                        style={{
                                            fontSize: 22,
                                            marginBottom: 8,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Passwords
                                    </p>
                                    <span className="pe-md-4">
                                        Edit your account password in three steps.
                                    </span>
                                </div>
                                <button
                                    style={{ marginTop: 20 }}
                                    className=" settings_btn"
                                    disabled={passwordValidation()}
                                >
                                    {isSubmitting ? (
                                        <span className="spinner-border spinner-grow-sm" />
                                    ) : (
                                        <span>
                                            <BsCheck /> Save Changes
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>

                        <div className="col-xl-8 col-md-6 col-12 col">
                            <Row>
                                <Col className="form-group col-12 col-xl-12 col-md-12">
                                    <label htmlFor="Full Name">Current Password</label>
                                    <PasswordBox
                                        name="current_password"
                                        onChange={handlePassword}
                                        value={passwordForm.current_password}
                                        placeholder="Enter Current Password"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="form-group col-12 mb-3 mb-md-0 col-xl-6 col-md-6">
                                    <label htmlFor="Email Address">New Password</label>
                                    <PasswordBox
                                        name="new_password"
                                        onChange={handlePassword}
                                        value={passwordForm.new_password}
                                        placeholder="Enter New Password"
                                    />
                                </Col>

                                <Col className="form-group col-12 col-xl-6 col-md-6">
                                    <label htmlFor="Email">Confirm Password</label>
                                    <PasswordBox
                                        name="confirm_password"
                                        onChange={handlePassword}
                                        value={passwordForm.confirm_password}
                                        placeholder="Confirm New Password"
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </form>
            </div>
            {SessionManagement.getEmployeeType() === "Super Admin" && (
                <div>
                    <CompanyDetails />
                </div>
            )}
        </>
    );
};

export default PersonalDetails;
