import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import {
  getLocalMarginsUrl,
  updateLocalMarginsUrl,
} from "../library/URLs";
import { useEffect, useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import { SelectBox, TextBox } from "../components/InputFields";
import { BsX } from "react-icons/bs";

const SubmitButton = styled.button``;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const ContentFieldsActionButton = styled.div`
  border-radius: 50%;
  height: 40px;
  min-width: 40px;
  color: #ffffff;
  display: flex;
  font-size: 30px;
  margin-right: 13px;
  align-items: center;
  justify-content: center;
  background-color: #008561;
  cursor: pointer;

  svg {
    font-size: 18px;
  }
`;

const UpdateLocalMarginDialog = ({
  isModalVisible,
  handleCloseDialog,
  carrier,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initState = {
    shipment_type: "Local",
    pricing_type: "Base",
    base_from: "",
    base_to: "",
    base_amount: "",
    per_km_amount: "",
    price_ranges: [],
  };

  const [carrierForm, setCarrierForm] = useState(initState);

  useEffect(() => {
    if (carrier) {
      fetchLocalMargin();
    }
    // eslint-disable-next-line
  }, [carrier?.shipment_type]);

  const fetchLocalMargin = async () => {
    let data = {
      ...UserProfileData(),
      carrier_id: carrier?.carrier_id,
    };
    await axios
      .post(getLocalMarginsUrl, data)
      .then((res) => {
        if (res?.data?.success) {
          let response = res?.data;
          if (response?.pricing_type === "Range") {
            setCarrierForm({
              ...carrierForm,
              pricing_type: response?.pricing_type,
              price_ranges: response?.data?.map((price) => {
                return {
                  from_km: price?.from_km,
                  to_km: price?.to_km,
                  amount: price?.amount,
                };
              }),
            });
          } else {
            setCarrierForm({
              ...carrierForm,
              pricing_type: response?.pricing_type,
              base_from: response?.data[0]?.base_from_distance,
              base_to: response?.data[0]?.base_to_distance,
              base_amount: response?.data[0]?.base_amount,
              per_km_amount: response?.data[0]?.per_km_amount,
            });
          }
        }
      })
      .catch((err) => {});
  };

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleInput = (e) => {
    const { name, value } = e.target;

    setCarrierForm({
      ...carrierForm,
      [name]: value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const formdata = {
      ...UserProfileData(),
      ...carrierForm,
      shipment_type: carrier?.shipment_type,
      carrier_id: carrier?.carrier_id,
    };
    setIsSubmitting(true);

    axios
      .post(updateLocalMarginsUrl, formdata)
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.success) {
          window.scrollTo(0, 0);

          setSuccessVisibility(true);
          setSuccessMessage("Local margin updated successfully!");

          setTimeout(() => {
            setSuccessVisibility(false);
          }, 3000);
          setTimeout(() => {
            setCarrierForm(initState);
            handleCloseDialog();
          }, 4000);
        } else {
          window.scrollTo(0, 0);
          setErrorVisibility(true);
          setErrorMessage(res?.data?.message);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const pricing_options = [
    { label: "Base", value: "Base" },
    { label: "Range", value: "Range" },
  ];

  const handleRangeInput = (e, id) => {
    const { name, value } = e.target;

    let pricing_ranges = [...carrierForm.price_ranges];

    pricing_ranges[id] = {
      ...pricing_ranges[id],
      [name]: value
    };

    setCarrierForm({
      ...carrierForm,
      price_ranges: pricing_ranges
    });
  };

  const addRangeValue = () => {
    const price_ranges = [...carrierForm.price_ranges];

    price_ranges.push({
      from_km: "",
      to_km: "",
      amount: "",
    });

    setCarrierForm({
      ...carrierForm,
      price_ranges: price_ranges,
    });
  };

  const removeRangeValue = () => {
    if (carrierForm.price_ranges.length < 1) {
      return;
    };

    const price_ranges = [...carrierForm.price_ranges];
    price_ranges.pop();
    setCarrierForm({
      ...carrierForm,
      price_ranges: price_ranges,
    });
  };

  return (
    <Modal
      size="lg"
      show={isModalVisible}
      onHide={() => {
        handleCloseDialog();
        setCarrierForm(initState);
      }}
    >
      <Modal.Header>
        <h5 className="header">UPDATE LOCAL MARGIN</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={() => {
            handleCloseDialog();
            setCarrierForm(initState);
          }}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        <p>Fill the details below to add a container</p>
        <div className="query-result-container">
          <Row className="mb-2 gy-3">
            <Col md={6}>
              <div className="w-100">
                <label htmlFor="Description" className="mb-2">
                  Shipment Type
                </label>
                <TextBox
                  type="text"
                  name="shipment_type"
                  disabled
                  value={carrier?.shipment_type}
                  onChange={handleInput}
                  placeholder="Enter Shipment Type"
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label htmlFor="Description" className="mb-2">
                  Pricing Type
                </label>
                <SelectBox
                  name="pricing_type"
                  value={carrierForm?.pricing_type}
                  options={pricing_options}
                  onChange={handleInput}
                  placeholder="Enter Pricing Type"
                />
              </div>
            </Col>
          </Row>

          {carrierForm.pricing_type === "Base" && (
            <Row className="mb-2 gy-3">
              <Col sm={12} md={6}>
                <div className="">
                  <label htmlFor="Description" className="mb-2">
                    Base From(KM)
                  </label>
                  <TextBox
                    type="text"
                    name="base_from"
                    value={carrierForm.base_from}
                    onChange={handleInput}
                    placeholder="Enter Base From"
                  />
                </div>
              </Col>
              <Col sm={12} md={6}>
                <div className="">
                  <label htmlFor="Description" className="mb-2">
                    Base To(KM)
                  </label>
                  <TextBox
                    type="text"
                    name="base_to"
                    value={carrierForm.base_to}
                    onChange={handleInput}
                    placeholder="Enter Base To"
                  />
                </div>
              </Col>

              <Col sm={12} md={6}>
                <div className="">
                  <label htmlFor="Description" className="mb-2">
                    Base Amount(NGN)
                  </label>
                  <TextBox
                    type="text"
                    name="base_amount"
                    value={carrierForm.base_amount}
                    onChange={handleInput}
                    placeholder="Enter Base Amount"
                  />
                </div>
              </Col>

              <Col sm={12} md={6}>
                <div className="">
                  <label htmlFor="Description" className="mb-2">
                    Per KM amount
                  </label>
                  <TextBox
                    type="text"
                    name="per_km_amount"
                    value={carrierForm.per_km_amount}
                    onChange={handleInput}
                    placeholder="Enter amount charged per additional km"
                  />
                </div>
              </Col>
            </Row>
          )}

          {carrierForm.pricing_type === "Range" && (
            <div>
              {
                carrierForm?.price_ranges?.map((price, id) => (
                  <Row className="mb-2" key={id}>
                    <Col sm={12} md={4}>
                      <div className="">
                        <label htmlFor="Description" className="mb-2">
                          From(KM)
                        </label>
                        <TextBox
                          type="text"
                          name="from_km"
                          value={price.from_km}
                          onChange={(e) => handleRangeInput(e, id)}
                          placeholder="Enter Distance (KM)"
                        />
                      </div>
                    </Col>

                    <Col sm={12} md={4}>
                      <div className="">
                        <label htmlFor="Description" className="mb-2">
                          To(KM)
                        </label>
                        <TextBox
                          type="text"
                          name="to_km"
                          value={price.to_km}
                          onChange={(e) => handleRangeInput(e, id)}
                          placeholder="Enter Distance (KM)"
                        />
                      </div>
                    </Col>

                    <Col sm={12} md={4}>
                      <div className="">
                        <label htmlFor="Description" className="mb-2">
                          Amount(NGN)
                        </label>
                        <TextBox
                          type="text"
                          name="amount"
                          value={price.amount}
                          onChange={(e) => handleRangeInput(e, id)}
                          placeholder="Enter Amount"
                        />
                      </div>
                    </Col>
                  </Row>
                ))
              }
              <div className="d-flex gap-3 align-items-center mb-2">
                <div className="">
                  <ContentFieldsActionButton onClick={addRangeValue}>
                    +
                  </ContentFieldsActionButton>
                </div>
                <div className="">
                  <ContentFieldsActionButton onClick={removeRangeValue}>
                    -
                  </ContentFieldsActionButton>
                </div>
              </div>
            </div>
          )
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn-secondary mx-3"
          onClick={() => {
            handleCloseDialog();
            setCarrierForm(initState);
          }}
        >
          <BsX />
          Cancel
        </button>
        <SubmitButton onClick={onSubmit} type="submit" className="btn-primary">
          {isSubmitting === false ? (
            <span className="button-text d-flex align-items-center gap-2">
              Proceed
            </span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateLocalMarginDialog;
