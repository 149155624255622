import styled from "styled-components";
import { useEffect, useState } from "react";
import { UserProfileData } from "../../library/constants";
import { getShipmentTimelineURL } from "../../library/URLs";
import axios from "axios";
import moment from "moment";

const Ring = styled.div`
  border: 2px solid #008561;
  width: 11px;
  border-radius: 50%;
  height: 11px;
  margin-right: 8px;
  margin-top: 7px;
`;

const Box = styled.div`
  border: 1px solid #139C5F;
  background: #E8F7F0;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin-right: 16px;
  margin-top: 7px;
`;

const Timestamp = styled.span`
  font-size: 13px;
  margin-top: -25px;
  color: #98A2B3;
`;

const VerticalLine = styled.div`
  height: 65px;
  border-left: 2px solid #D6F9E4;
  margin-left: 5px;
`;

const ActivityDesc = styled.p`
  font-size: 13px;
  line-height: 17.4px;
  color: #1D2739;
  margin: 0;
`;

const ShipmentTimeline = ({ shipmentID }) => {
  const [shipmentTimeline, setShipmentTimeline] = useState([]);

  const data = {
    shipment_id: shipmentID,
  };

  const newData = { ...data, ...UserProfileData() };

  useEffect(() => {
    axios
      .post(getShipmentTimelineURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
          console.log(response);
        if (response["data"]["success"] === false) return;

        var timeline = response["data"]["data"];

        setShipmentTimeline(timeline);
      });
  }, []);

  return (
    <div className="col-xl-6 col-md-12">
      <div className="section-wrap-secondary">
        <div className=" shipment-timeline-container">
            {
                shipmentTimeline.map((timeline, index) => {
                    const dateTime = moment(timeline.timestamp);
                    const timestamp = dateTime.format('ddd MMM Do, h:mm A');
                    return (
                        <div style={{marginTop:-7}} key={timeline.id} className="d-flex align-items-start">
                            <div>
                                <Box/>
                                {shipmentTimeline.length === 1 || (shipmentTimeline.length - 1) === index ? null : <VerticalLine/>}
                            </div>
                            <div>
                                <Timestamp>{timestamp}</Timestamp>
                                <ActivityDesc>{timeline.description} </ActivityDesc>
                            </div>
                        </div>
                    );
                })
            }
        </div>
      </div>
    </div>
  );
};

export default ShipmentTimeline;
