import "../styles/home.scss";
import "../styles/single-shipment.scss";
import "../styles/components.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useEffect, useState } from "react";
import { getCompanyWalletDetails } from "../library/URLs";
import axios from "axios";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import {numberWithCommas} from "../library/utilities";
import FundWalletDialog from "../modals/FundWalletDialog";
import SubscriptionInactive from "../components/SubscriptionInactive";
import RegisterWalletDialog from "../modals/RegisterWallet";

const CustomDiv = styled.div`
  cursor:pointer;
`;

const AccountOfficerProfileImage = styled.img`
  border-radius:50%;
  height:70px;
  width:70px;
`;

const Accounts = () => {
  const [fundWalletVisibility, setFundWalletVisibility] = useState(false);

  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [bankName, setBankName] = useState("");
  const [availableBalance, setAvailableBalance] = useState("");
  const [bookBalance, setBookBalance] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [walletAvailable, setWalletAvailable] = useState(false);
  const [registerWallet, setRegisterWallet] = useState(false);


  const handleCloseModal = () => {
    setFundWalletVisibility(false);
  };

  const showFundWalletDialog = () => {
    setFundWalletVisibility(!fundWalletVisibility);
  }

  const showWithdrawWalletDialog = () => {

  }

  const fetchWalletDetails = () => {
    setIsLoading(true);
    axios
      .post(getCompanyWalletDetails, UserProfileData(), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);
        console.log(response);
        if (response["data"]["success"] === false) return;

        if (response["data"]["data"] === false) {
          setWalletAvailable(false); 
          return;
        }



        setWalletAvailable(true);
        const shipmentDetails = response["data"];

        const accountName = shipmentDetails["data"]["acc_name"];
        const bankName = shipmentDetails["data"]["bank_name"];
        const accountNumber = shipmentDetails["data"]["acc_no"];
        const availableBalance = shipmentDetails["data"]["available_balance"];
        const bookBalance = shipmentDetails["data"]["book_balance"];

        setAccountName(accountName);
        setBankName(bankName);
        setAccountNumber(accountNumber);
        setAvailableBalance(availableBalance);
        setBookBalance(bookBalance);
      })
      .catch(() => {
        setIsLoading(false);
      })
  }

  useEffect(() => {
    fetchWalletDetails();
  }, []);

  const handleRegisterClose = () => {
    setRegisterWallet(false);
  }

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />

          <div className="d-flex flex-row justify-content-between mb-2 mx-3">
            <h5 className="header">Account Details</h5>
            <span>Home</span>
          </div>

          <FundWalletDialog
            isModalVisible={fundWalletVisibility}
            handleCloseDialog={handleCloseModal}
          />

          <RegisterWalletDialog 
            handleSubmit={fetchWalletDetails}
            isModalVisible={registerWallet}
            handleCloseDialog={handleRegisterClose}
          />

          <div className="container-fluid">
            {
              isLoading ? (<div className="w-100 d-flex justify-content-center" >
                <span className="spinner-border spinner-grow-lg" />
              </div>) : walletAvailable ? (
                <div className="row mt-4">
              <div className="col-xl-7 col-md-12">
                <span className="header">Balance Overview</span>
                <div className="card bg-light border-0 shadow">
                  <div className="card-body shipper-container">
                    <div className="d-flex justify-content-between my-4">
                      <div>
                        <span className="title">Available Balance</span>
                        {availableBalance === "" ? <h1>...</h1> : <h1>₦{numberWithCommas(availableBalance)}</h1>}
                      </div>
                      <div>
                        <span className="title">Book Balance</span>
                        {bookBalance === "" ? <h1>...</h1> : <h1>₦{numberWithCommas(bookBalance)}</h1>}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row  mt-3">
                  <div className="col-xl-6 col-md-3">
                    <div className="card bg-light border-0 shadow">
                      <CustomDiv onClick={showFundWalletDialog} className="card-body">
                        <div className="d-flex align-items-center justify-content-center my-1">
                          <div>
                            <span className="title">Fund Wallet</span>
                          </div>
                        </div>
                      </CustomDiv>
                    </div>
                  </div>

                  <div className="col-xl-6 col-md-3">
                    <div className="card bg-light border-0 shadow">
                      <CustomDiv onClick={showWithdrawWalletDialog} className="card-body">
                        <div className="d-flex align-items-center justify-content-center my-1">
                          <div>
                            <span className="title">Withdraw</span>
                          </div>
                        </div>
                      </CustomDiv>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-5 col-md-12">
                <span className="header">Bank Details</span>
                <div className="card bg-light border-0 shadow">
                  <div className="card-body shipper-container">
                    {/*<div className="d-flex justify-content-center align-items-center">*/}
                    {/*  <div className="d-flex flex-column align-items-center">*/}
                    {/*    <AccountOfficerProfileImage src="https://app.getorbeet.com/img/adetoun.a214c52c.jpg"/>*/}
                    {/*    <p>Adetoun</p>*/}
                    {/*    <p>adetoun@getorbeet.com</p>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    <div className="mt-4 d-flex justify-content-between">
                      <span className="title">Account Name</span>
                      <p style={{maxWidth:200, textAlign:"end"}}>{accountName}</p>
                    </div>
                    <div className="mt-1 d-flex justify-content-between">
                      <span className="title">Account Number</span>
                      <p>{accountNumber}</p>
                    </div>
                    <div className="mt-1 d-flex justify-content-between">
                      <span className="title">Bank Name</span>
                      <p>{bankName}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              ) : (
                <div className="row mt-4 h-100 w-100" >
                  <div className="col-xl-10 col-md-12">
                    <div className="card bg-light py-5 ps-2 ps-md-4 border-0 shadow">
                      <div className="card-body shipper-container">
                        <span className="title">No wallet Available</span>
                        <p>To set up your company's wallet, please click the button below and fill out the details.</p>
                        <button className="btn confirm-button text-uppercase" onClick={()=>setRegisterWallet(true)} >Activate Wallet</button>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }

            <div className="row mt-4">

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accounts;
