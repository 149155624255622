import {Button, Col, Modal, Row} from "react-bootstrap";
import {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import axios from "axios";
import {
    createInternationalShipmentURL,
    fundUserWalletURL, getAramexAccountURL,
    getContractDetailsUserURL,
    getInternational3PLPricingURL,
    getInterstate3PLPricingURL,
    getShipmentPricingURL,
    getShipmentsURL,
    makeContractUserURL,
    topupUserWalletURL, updateInternational3PLPricingURL,
    updateInterstate3PLPricingURL
} from "../library/URLs";
import {UserProfileData} from "../library/constants";
import {HiOutlineDotsVertical} from "react-icons/hi";
import {BiX} from "react-icons/bi";
import * as React from "react";
import {SessionManagement} from "../library/SessionManagement";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    border-color: #fcd2d2;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    border-color: #c2eddd;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const AramexAccountDialog = ({ isModalVisible, handleCloseDialog, shipmentPricing}) => {
    const form = useRef(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [accountPIN, setAccountPIN] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [othersPricing, setOthersPricing] = useState("");

    useEffect(() => {
        axios
            .post(getAramexAccountURL, UserProfileData())
            .then(function (response) {
                console.log(response);
                if (response["data"]["success"] === true) {
                    if (response["data"]["data"] === false){

                    } else {
                        const dhl = response["data"]["data"]["data"]["dhl"];
                        const fedex = response["data"]["data"]["data"]["fedex"];
                        const ups = response["data"]["data"]["data"]["ups"];
                        const aramex = response["data"]["data"]["data"]["aramex"];

                        setUsername(dhl)
                        setAccountPIN(ups)
                        setPassword(aramex)
                        setAccountNumber(fedex)
                    }
                }
            })
            .catch(function (error) {
                return error;
            });
    }, []);

    const onChangeInput = (type, event) => {
        if (type === "username"){
            setUsername(event.target.value);
        } else if (type === "password"){
            setPassword(event.target.value);
        } else if (type === "accountNumber"){
            setAccountNumber(event.target.value);
        } else if (type === "accountPIN"){
            setAccountPIN(event.target.value);
        }
    }

    const setInternational3PLPricing = () => {
        const formValues = {};

        const data = new FormData(form.current);

        for (const [key, value] of data.entries()) {
            formValues[key] = value;
        }

        console.log(data)

        const newData = { ...formValues, ...UserProfileData() };

        setErrorVisibility(false);
        setIsSubmitting(true);

        console.log(formValues);

        axios
            .post(updateInternational3PLPricingURL, newData)
            .then(function (response) {
                console.log(response);
                setIsSubmitting(false);

                if (response["data"]["success"] === false) {
                    window.scrollTo(0, 0);
                    setErrorVisibility(true);
                    setErrorMessage(response["data"]["message"]);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                } else if (response["data"]["success"] === true) {
                    setSuccessVisibility(true);
                    setSuccessMessage(response["data"]["data"]["data"]);

                    setTimeout(() => {
                        setSuccessVisibility(false);
                    }, 3000);
                }
            })
            .catch(function (error) {
                setIsSubmitting(false);
                return error;
            });
    }

  return (
          <Modal show={isModalVisible} onHide={handleCloseDialog}>
              <Modal.Header>
                  <h5 style={{marginTop:10}} className="header">SET ARAMEX ACCOUNT</h5>
                  <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
              </Modal.Header>
              <Modal.Body>
                  {
                      isError === false ? null :
                          (<ErrorMessageContainer>
                              {errorMessage}
                          </ErrorMessageContainer>)
                  }

                  {
                      isSuccess=== false ? null :
                          (<SuccessMessageContainer>
                              {successMessage}
                          </SuccessMessageContainer>)
                  }

                  <form ref={form}>
                      <div>
                          <div>
                              <Row className="mt-3">
                                  <Col>
                                      <label htmlFor="Base From">Username</label>
                                      <input value={username} onChange={(e) => onChangeInput("username", e)} name="username" className="form-control" type="number" />
                                  </Col>
                                  <Col>
                                      <label htmlFor="Base To">Password</label>
                                      <input value={password} onChange={(e) => onChangeInput("password", e)} name="password" className="form-control" type="number" />
                                  </Col>
                              </Row>

                              <Row className="mt-3">
                                  <Col>
                                      <label htmlFor="Base From">Account Number</label>
                                      <input value={accountNumber} onChange={(e) => onChangeInput("accountNumber", e)}  name="account_number" className="form-control" type="number" />
                                  </Col>
                                  <Col>
                                      <label htmlFor="Base To">Account PIN</label>
                                      <input value={accountPIN} onChange={(e) => onChangeInput("accountPIN", e)}  name="account_pin" className="form-control" type="number" />
                                  </Col>
                              </Row>

                              <Row className="mt-3">
                                  <Col>
                                      <label htmlFor="Sender Postal">Others (%)</label>
                                      <input value={othersPricing} onChange={(e) => onChangeInput("others", e)}  name="others" className="form-control" type="number" />
                                  </Col>
                              </Row>
                          </div>
                      </div>
                  </form>


              </Modal.Body>
              <Modal.Footer>
                  <button className="cancel-button mx-3" onClick={handleCloseDialog}>
                      CANCEL
                  </button>
                  <SubmitButton onClick={(e) => setInternational3PLPricing()}  type="button" className="confirm-button">
                      {isSubmitting === false ? (
                          <span className="button-text">SET ARAMEX ACCOUNT</span>
                      ) : (
                          <span className="spinner-border spinner-grow-sm"/>
                      )}
                  </SubmitButton>
              </Modal.Footer>
          </Modal>

  );
};

export default AramexAccountDialog;
