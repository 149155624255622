import "../../styles/login.scss";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { generateTransactionID } from "../../library/utilities";
import axios from "axios";
import {
  loginURL,
  requestResetCodeURL,
  resetPasswordURL,
} from "../../library/URLs";
import { SessionManagement } from "../../library/SessionManagement";
import { ErrorNotification } from "../../components/Notifications";
import * as React from "react";
import { TextBox } from "../../components/InputFields";

const PageHeader = styled.h4`
  font-family: Prompt, sans-serif;
  font-size: 32px;
  margin-bottom: 8px;
  color: #000;
  text-align: center;

  @media (max-width: 556px) {
    font-size: 2.4rem;
  }
`;

const PageSubheader = styled.h4`
  font-size: 17px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: normal;
`;

const PageFooter = styled.h4`
  font-size: 17px;
  text-align: center;
  margin-bottom: 20px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #fcd2d2;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #c2eddd;
  margin-bottom: 20px;
  text-align: center;
`;

const ResetPassword = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();
  const form = useRef(null);
  const [formData, setFormData] = useState({
    reset_code: "",
    new_password: "",
    confirm_password: "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const search = useLocation().search;
  const emailAddress = new URLSearchParams(search).get("email");

  const clearErrorMessage = () => {
    setErrorVisibility(false);
    setErrorMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    clearErrorMessage();


    const formValues = {...formData};

    formValues["email"] = emailAddress;
    formValues["device_id"] = "value";
    formValues["device_name"] = "value";
    formValues["token"] = "value";
    formValues["transaction_id"] = generateTransactionID(15);

    if(formData.reset_code === "") {
      setErrorMessage("Enter a valid reset code!");
      setErrorVisibility(true);

      setTimeout(() => {
        setErrorVisibility(false);
      }, 5000);
      return;
    }

    if(formData.new_password === "") {
      setErrorMessage("Enter a valid password!");
      setErrorVisibility(true);

      setTimeout(() => {
        setErrorVisibility(false);
      }, 5000);
      return;
    }

    if(formData.new_password !== formData.confirm_password) {
      setErrorMessage("Ensure password and confirm password match!");
      setErrorVisibility(true);

      setTimeout(() => {
        setErrorVisibility(false);
      }, 5000);
      return;
    }

    setIsSubmitting(true);

    axios
      .post(resetPasswordURL, formValues)
      .then(function (response) {
        console.log(response);
        setIsSubmitting(false);

        if (response["data"]["success"] === false) {
          setErrorMessage(response["data"]["message"]);
          setErrorVisibility(true);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        } else if (response["data"]["success"] === true) {
          setSuccessMessage(
            "Password has been successfully updated. Redirecting you to login page..."
          );
          setSuccessVisibility(true);

          setTimeout(() => {
            setSuccessVisibility(false);
            navigate("/login");
          }, 5000);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  return (
    <div className="box-container">
      <PageHeader className="page-header feature-header">
        Choose Password
      </PageHeader>
      {isError === false ? null : (
        <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
      )}
      {isSuccess === false ? null : (
        <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
      )}

      <PageSubheader>
        Use the code sent to your email to reset your password
      </PageSubheader>
      <form ref={form} onSubmit={handleSubmit} autoComplete="none">
        <section>
          <div className="">
            <TextBox
              placeholder="Access Code"
              value={formData.reset_code}
              onChange={handleInput}
              type="number"
              name="reset_code"
              autoComplete="new-password"
            />
          </div>

          <div className="mt-4 ">
            <TextBox
              value={formData.new_password}
              placeholder="New Password"
              autoComplete="new-password"
              name="new_password"
              type="password"
              onChange={handleInput}
            />
          </div>

          <div className="mt-4">
            <TextBox
              value={formData.new_password}
              onChange={handleInput}
              placeholder="Confirm Password"
              name="confirm_password"
              type="password"
            />
          </div>

          <input
            type="text"
            autoComplete="on"
            value=""
            style={{
              display: "none",
              opacity: 0,
              position: "absolute",
              left: "-100000px",
            }}
            readOnly={true}
          />
        </section>

        <div className="d-flex flex-row my-5 justify-content-end">
          <button type="submit" className="login-button btn-primary">
            {isSubmitting === false ? (
              <span className="button-text">RESET PASSWORD</span>
            ) : (
              <span className="spinner-border spinner-grow-sm" />
            )}
          </button>
        </div>

        <PageSubheader>
          Remember your password? <Link to="/login">Login</Link>
        </PageSubheader>
      </form>
    </div>
  );
};

export default ResetPassword;
