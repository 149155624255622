import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import {
  addPackagingUrl,
  addShipmentsToBatchUrl,
  searchBatchUrl,
} from "../library/URLs";
import { useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import { TextBox } from "../components/InputFields";
import { BsSdCard, BsX } from "react-icons/bs";

const SubmitButton = styled.button``;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const AddPackageDialog = ({ isModalVisible, handleCloseDialog, onSubmit }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [existingBatch, setExistingBatch] = useState("");
  const [packageInfo, setPackageInfo] = useState({
    name: "",
    length: "",
    width: "",
    height: "",
    weight: "",
  });

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const addPackage = (e) => {
    e.preventDefault();
    const formValues = {
      ...packageInfo,
    };
    // formValues['customer_id'] = adminID;

    const newData = { ...formValues, ...UserProfileData() };

    setErrorVisibility(false);
    setIsSubmitting(true);

    axios
      .post(addPackagingUrl, newData)
      .then(function (response) {
        setIsSubmitting(false);
        if (response["data"]["success"] === true) {
          setSuccessVisibility(true);
          setSuccessMessage("Package added successfully");

          setTimeout(() => {
            setPackageInfo({
              length: "",
              width: "",
              height: "",
              weight: "",
            });
            onSubmit();
            handleCloseDialog();
          }, 5000);
        } else {
          setErrorMessage(response["data"]["message"]);
          setErrorVisibility(true);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        setIsSubmitting(false);
        console.log(error);
        return error;
      });
  };

  const handleInput = (e) => {
    const { value, name } = e.target;

    setPackageInfo({
      ...packageInfo,
      [name]: value,
    });
  };

  return (
    <Modal size="lg" show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">ADD NEW CONTAINER</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        <p>Fill the details below to add a container</p>
        <div className="query-result-container">
          <div>
            <div>
              <label htmlFor="Description" className="mb-2">
                Container Name
              </label>
              <TextBox
                type="text"
                name="name"
                value={packageInfo.name}
                onChange={handleInput}
                placeholder="Input Container Name"
              />
            </div>
          </div>
          <Row className="gap-3 gap-md-0 my-3">
            <Col className=" col-12 col-md-6">
              <label className="mb-2" htmlFor="Description">
                Weight
              </label>
              <TextBox
                name="weight"
                type="tel"
                value={packageInfo.weight}
                onChange={handleInput}
                prefix="KG"
                placeholder="Input item weight"
              />
            </Col>
            <Col className=" col-12 col-md-6">
              <label className="mb-2" htmlFor="Description">
                Length
              </label>
              <TextBox
                name="length"
                type="tel"
                value={packageInfo.length}
                onChange={handleInput}
                prefix="CM"
                placeholder="Input package length"
              />
            </Col>
          </Row>
          <Row className="gap-3 gap-md-0 my-3">
            <Col className=" col-12 col-md-6">
              <label className="mb-2" htmlFor="Description">
                Width
              </label>
              <TextBox
                name="width"
                type="tel"
                value={packageInfo.width}
                onChange={handleInput}
                prefix="CM"
                placeholder="Input package width"
              />
            </Col>
            <Col className=" col-12 col-md-6">
              <label className="mb-2" htmlFor="Description">
                Height
              </label>
              <TextBox
                name="height"
                type="tel"
                value={packageInfo.height}
                onChange={handleInput}
                prefix="CM"
                placeholder="Input package height"
              />
            </Col>
          </Row>
        </div>
        <p className="mt-3">Would you like to continue?</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-secondary mx-3" onClick={handleCloseDialog}>
          <BsX />
          Cancel
        </button>
        <SubmitButton
          onClick={addPackage}
          type="submit"
          className="btn-primary"
        >
          {isSubmitting === false ? (
            <span className="button-text d-flex align-items-center gap-2">
              <BsSdCard /> Save Container
            </span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AddPackageDialog;
