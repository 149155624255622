import "../../styles/home.scss";
import "../../styles/shipments.scss";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserProfileData } from "../../library/constants";
import {
  getShipmentsURL,
  searchForCustomersURL,
  searchForShipmentsURL,
  getPendingKycUrl,
} from "../../library/URLs";
import axios from "axios";
import styled from "styled-components";
import * as React from "react";
import moment from "moment";
import * as BiIcons from "react-icons/bi";
import RouteShipmentDialog from "../../modals/RouteShipmentDialog";
import CustomDateRangeDialog from "../../modals/CustomDateRangeDialog";
import { SessionManagement } from "../../library/SessionManagement";
import SubscriptionInactive from "../../components/SubscriptionInactive";
import SearchShipmentsDialog from "../../modals/SearchShipmentsDialog";
import { BsDot } from "react-icons/bs";
import ApproveDialog from "../../modals/AddPhone/kyc/ApproveDialog";
import RejectDialog from "../../modals/AddPhone/kyc/RejectDialog";

function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const KycApprovals = () => {
  const form = useRef(null);
  const navigator = useNavigate();
  const params = useParams();

  const [chosenPaymentStatus, setChosenPaymentStatus] = useState("");
  const [chosenDate, setChosenDate] = useState("");
  const [approveKyc, setApproveKyc] = useState(null);
  const [rejectKyc, setRejectKyc] = useState(null);
  const [queryData, setQueryData] = useState("");
  const [accountType, setAccountType] = useState("");

  const shipmentType = "all-shipments";

  const [isLoading, setIsLoading] = useState(true);
  const [currentOffsetDate, setCurrentOffsetDate] = useState("");
  const [currentOffsetID, setCurrentOffsetID] = useState(0);
  const [isRetrievingMore, setIsRetrievingMore] = useState(false);

  const [isSearchShipmentDialogVisible, setIsSearchShipmentDialogVisible] =
    useState(false);
  const [isCustomDateRangeDialogVisible, setIsCustomDateRangeDialogVisible] =
    useState(false);

  const [resultContainerVisibility, setResultContainerVisibility] =
    useState(false);
  const [customerQueryResults, setCustomerQueryResults] = useState([]);
  const [isOptionSelected, setIsOptionSelected] = useState("");

  const [userKycs, setUserKycs] = useState([]);

  const [isQuerySearching, setIsQuerySearching] = useState(false);

  const data = {
    limit: 20,
    offset_date: currentOffsetDate,
    offset_id: currentOffsetID,
    status: shipmentType === "All Shipments" ? "All" : shipmentType,
    payment_status: chosenPaymentStatus,
    date: chosenDate,
  };

  const newData = { ...data, ...UserProfileData() };
  console.log(newData);

  const fetchKyc = () => {
    setIsLoading(true);
    axios
      .post(getPendingKycUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response["data"]);

        setIsRetrievingMore(false);
        setIsLoading(false);

        if (response["data"]["success"] === false) return;

        const newShipments = response["data"]["data"];

        setUserKycs(newShipments);
      });
  };

  useEffect(() => {
    fetchKyc();
  }, [isRetrievingMore, isOptionSelected]);

  const handleClick = (shipmentID) => {
    navigator("/shipment/" + shipmentID);
  };

//   const setScroll = () => {
//     if (
//       window.innerHeight + window.scrollY >= document.body.offsetHeight &&
//       isRetrievingMore === false
//     ) {
//       if (isRetrievingMore === false) {
//         console.log("It's receiving");
//         setIsRetrievingMore(true);
//       } else {
//         console.log("It's not receiving");
//       }
//     }
//   };

//   React.useEffect(() => {
//     window.addEventListener("scroll", setScroll);
//     return () => {
//       window.removeEventListener("scroll", setScroll);
//     };
//   }, []);

  const onChangePaymentStatus = (event) => {
    setChosenPaymentStatus(event.target.value);
  };

  const toggleCustomDateRangeDialog = () => {
    setIsCustomDateRangeDialogVisible(!isCustomDateRangeDialogVisible);
  };

  const onChangeShipmentDate = (event) => {
    if (event.target.value === "custom") {
      toggleCustomDateRangeDialog();
    } else {
      setChosenDate(event.target.value);
    }
  };

  const getReports = () => {
    if (chosenPaymentStatus !== "" && chosenDate !== "") {
      setIsLoading(true);
      setCurrentOffsetID(0);
      setCurrentOffsetDate("");
      setIsOptionSelected(chosenPaymentStatus + chosenDate);
    }
  };

  const handleCloseSearchShipmentsDialog = () => {
    setIsSearchShipmentDialogVisible(false);
  };

  const openSearchShipmentsDialog = () => {
    setIsSearchShipmentDialogVisible(true);
  };

  const searchForCustomers = (e) => {
    e.preventDefault();
  };

  const handleQueryChange = () => {
    const formData = new FormData(form.current);
    const query = formData.get("query");

    if (query.toString().trim() === "") {
      setIsQuerySearching(false);
      setResultContainerVisibility(false);

      return null;
    }

    const formValues = {};
    formValues["query"] = query;
    formValues["limit"] = "5";

    const newData = { ...formValues, ...UserProfileData() };

    setIsQuerySearching(true);
    setResultContainerVisibility(true);

    axios.post(searchForShipmentsURL, newData).then(function (response) {
      console.log(response);

      if (response["data"]["success"] === false) return;

      const customers = response["data"]["data"];

      setCustomerQueryResults(customers);
    });
  };

  const searchByname = (e) => {
    const { value } = e.target;
  };

  const filteredKyc = userKycs?.filter(
    (kyc) =>
      kyc?.fullname?.toLowerCase()?.includes(queryData?.toLowerCase()) &&
      kyc?.account_type?.toLowerCase()?.includes(accountType?.toLowerCase())
  );

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <ApproveDialog
          isModalVisible={approveKyc !== null}
          handleCloseDialog={() => setApproveKyc(null)}
          userID={approveKyc}
          callback={fetchKyc}
        />
        <RejectDialog
          isModalVisible={rejectKyc !== null}
          handleCloseDialog={() => setRejectKyc(null)}
          userID={rejectKyc}
          callback={fetchKyc}
        />
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />

          <div className="d-flex flex-row justify-content-between mb-2 mx-3">
            <h5>Pending KYC</h5>
            <span>Home</span>
          </div>
          <div className="container-fluid shipments-container">
            {/* <SearchShipmentsDialog
              isModalVisible={isSearchShipmentDialogVisible}
              handleCloseDialog={handleCloseSearchShipmentsDialog}
            /> */}

            <CustomDateRangeDialog
              isModalVisible={isCustomDateRangeDialogVisible}
              handleCloseDialog={(e) => {
                if (typeof e === "string") {
                  console.log("The chosen date is " + e.toString());
                  setChosenDate(e);
                }
                toggleCustomDateRangeDialog("close");
              }}
            />

            <div className="card bg-light border-0 shadow p-4 min-vh-100">
              <div className="d-flex justify-content-between">
                <div
                  className="d-md-flex align-items-center gap-md-2 gap-4 top-filter-section mb-3 mb-md-0"
                >
                  <div
                    className="query-result-container mb-2 mb-md-0 "
                    style={{ width: "100%" }}
                    // ref={form}
                    // onSubmit={searchForCustomers}
                  >
                    <input
                      onChange={(e) => setQueryData(e.target.value)}
                      name="queryData"
                      value={queryData}
                      className="form-control w-100"
                      type="text"
                      placeholder="Search using customer's name"
                    />
                    {/* <div
                      className={`query-result ${
                        resultContainerVisibility === true
                          ? "result-visible"
                          : null
                      }`}
                    >
                      {setIsQuerySearching === true
                        ? null
                        : customerQueryResults.map((customer) => {
                            return (
                              <div
                                key={customer.id}
                                className="d-flex flex-column"
                                onClick={() => handleClick(customer.id)}
                              >
                                <span className="d-flex align-items-center">
                                  
                                  <span style={{ fontSize: 13 }}>
                                    {customer.recipient_name}
                                  </span>
                                </span>
                                <span style={{ fontSize: 15 }}>
                                  {customer.drop_off_location}
                                </span>
                              </div>
                            );
                          })}
                    </div> */}
                  </div>
                  <select
                      onChange={(e) => setAccountType(e.target.value)}
                      name="accountType"
                      value={accountType}
                      className="form-control w-100"
                      type="text"
                      placeholder="Search using customer's name"
                    >
                      <option value="">All Customers</option>
                      <option value="Individual">Individual</option>
                      <option value="Business">Business</option>
                      <option value="3P Logistics">3P Logistics</option>
                    </select>
                  
                </div>
              </div>

              <div className="table-contain w-100">
                <table className="shipments-table w-100">
                  <thead>
                    <tr>
                      <th scope="col">Full Name</th>
                      <th scope="col">Account Type</th>
                      <th scope="col">Nin</th>
                      <th scope="col">CAC</th>
                      <th scope="col">Utility Bill</th>
                      <th scope="col">Identification</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  {isLoading === true ? null : (
                    <tbody>
                      {filteredKyc.map((kyc) => {
                        return (
                          <tr key={kyc.id}>
                            <td>{kyc.fullname}</td>
                            <td>{kyc.account_type}</td>
                            <td>
                              {kyc?.nin?.length > 2 ? (
                                <a
                                  href={kyc?.nin}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  View
                                </a>
                              ) : (
                                "Nil"
                              )}
                            </td>
                            <td>
                              {kyc?.cac_url?.length > 2 ? (
                                <a
                                  href={kyc?.cac_url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  View
                                </a>
                              ) : (
                                "Nil"
                              )}
                            </td>
                            <td>
                              {kyc?.utility_bill_url?.length > 2 ? (
                                <a
                                  href={kyc?.utility_bill_url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  View
                                </a>
                              ) : (
                                "Nil"
                              )}
                            </td>
                            <td>
                              {kyc?.identification_url?.length > 2 ? (
                                <a
                                  href={kyc?.identification_url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  View
                                </a>
                              ) : (
                                "Nil"
                              )}
                            </td>
                            <td>
                              <div className="d-flex gap-2">
                                <span
                                  className="text-success"
                                  onClick={() => setApproveKyc(kyc?.id)}
                                >
                                  Approve
                                </span>
                                <span>.</span>
                                <span
                                  className="text-danger"
                                  onClick={() => setRejectKyc(kyc?.id)}
                                >
                                  Reject
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
              {isRetrievingMore === false ? null : (
                <div style={{ textAlign: "center", marginTop: 40 }}>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />{" "}
                  Retrieving More..
                </div>
              )}

              {isLoading === false ? null : (
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KycApprovals;
