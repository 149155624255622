import { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { SubMenu } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";

const SingleMenu = ({ item }) => {
  const pathname = useLocation().pathname;
  const [isOpened, setIsOpened] = useState(false);

  const openSubMenu = () => {
    setIsOpened(!isOpened);
  };

  return (
    <>
      {item.submenu ? (
        <div onClick={openSubMenu}>
          <li className="menu-item">
            <div className={`d-flex gap-2 justify-content-center align-items-center ${isOpened && "menu-active"}`}>
              {
                !isOpened && (
                  <img src={item?.icon} alt="icon" />
                )
              }
              {item.title}
            </div>
            {isOpened ? <BsChevronUp style={{fontSize:10}} /> : <BsChevronDown style={{fontSize:10}} />}
          </li>
        </div>
      ) : (
        <Link to={`${item.path}`}>
          <li className={`menu-item ${pathname === item.path && "menu-item-active"}`}>
            <div className="d-flex gap-2 justify-content-center align-items-center">
              {pathname === item.path ? (
                <img src={item?.active} alt="icon" />
              ) : (
                <img src={item?.icon} alt="icon" />
              )}
              {item.title}
            </div>
          </li>
        </Link>
      )}
      <ul className={`submenu-list ${isOpened ? "submenu-list-active" : "submenu-list-inactive"}`} >
        {
          item.submenu &&
          item.submenu.map((item, index) => {
            return (
              <Link
                key={index}
                to={item.path}
              >
                <li className={`submenu-item ${(item.path === pathname && isOpened) && "submenu-item-active"}`}>
                  {pathname === item.path ? (
                    <img src={item?.active} alt="icon" />
                  ) : (
                    <img src={item?.icon} alt="icon" />
                  )}
                  {item.title}
                </li>
              </Link>
            );
          })}
      </ul>
    </>
  );
};

export default SingleMenu;
