import {Navigate, Outlet} from "react-router-dom";
import { SessionManagement } from "../../library/SessionManagement";

export const PrivateRoute = () => {
    let isAuthenticated = SessionManagement.isAuthenticated();

    return isAuthenticated === true ? <Outlet /> : <Navigate to="/login" />;
};

export const PublicRoute = () => {
    let isAuthenticated = SessionManagement.isAuthenticated();

    return isAuthenticated === true ? <Navigate to="/" /> : <Outlet />;
};