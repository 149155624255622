import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import "../styles/notifications.scss";
import * as React from "react";
import styled from "styled-components";
import {useEffect, useState} from "react";
import electricity from "../images/electricity.webp"
import * as BoxIcons from "react-icons/bi";
import axios from "axios";
import {getNotificationsURL, markNotificationsAsReadURL} from "../library/URLs";
import {UserProfileData} from "../library/constants";
import moment from "moment";
import {useNavigate} from "react-router-dom";

const Ring = styled.div`
  border: 3px solid #5b73e8;
  width: 17px;
  border-radius: 50%;
  height: 17px;
  margin-right: 8px;
  margin-top: 7px;
`;

const Timestamp = styled.span`
  font-size: 13px;
`;

const VerticalLine = styled.div`
  height: 75px;
  border-left: 1px solid #d7dbdd;
  margin-left: 5px;
`;

const LoadingContainer = styled.div`
  height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const EmptyContainer = styled.div`
  height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Notifications = ()=> {
    const navigator = useNavigate();

    const [currentOffset, setCurrentOffset] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isEmpty, setIsEmpty] = useState(true);
    const [notifications, setNotifications] = useState([]);

    const markNotificationsAsRead = () => {
        axios
            .post(markNotificationsAsReadURL, UserProfileData(), {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log("Marking result" + response);
            });
    }

    const data = {
        limit: 50,
        offset: currentOffset
    };

    const newData = { ...data, ...UserProfileData() };

    useEffect(() => {
        axios
            .post(getNotificationsURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response);
                setIsLoading(false);

                if (response["data"]["success"] === false) return;

                const referredUsers = response["data"]["data"];

                if (referredUsers.length === 0)
                    setIsEmpty(true);

                markNotificationsAsRead();

                setNotifications(referredUsers);
            });
    }, []);
    
    const goToPage = (type, typeID) => {
        if (type === "Shipment"){
            navigator("/shipment/" + typeID);
        } else if (type === "Account"){
            navigator("/transaction/" + typeID);
        } else if (type === "User"){
            navigator("/customer/" + typeID);
        } else if (type === "Discount"){

        } else if (type === "Reminder"){

        }
    }

    return (
        <div className="home">
            <Sidebar />
            <div className="main">
                <Navbar />
                <div className="page pb-5">
                    <div className="d-flex flex-row justify-content-between mb-2 mx-3">
                        <h5 className="header">Notifications</h5>
                        <span>Home</span>
                    </div>

                    <div className="container-fluid">
                        <div className="d-flex flex-column align-items-center">
                            {isLoading === false ? null : (
                                <LoadingContainer>
                                    <LoadingSpinner className="spinner-border spinner-grow-sm"/>
                                </LoadingContainer>
                            )}

                            {
                                notifications.map((notification, index) => {
                                    const dateTime = moment(notification.date_time);
                                    const timestamp = dateTime.format('ddd MMM Do, h:mm A');

                                    let iconImage;
                                    if (notification.type === "Shipment"){
                                        iconImage = <div className="shipment-class"><BoxIcons.BiPackage className="icon" /></div>;
                                    } else if (notification.type === "Account"){
                                        iconImage = <div className="account-class"><BoxIcons.BiMoney className="icon" /></div>;
                                    } else if (notification.type === "User"){
                                        iconImage = <div className="user-class"><BoxIcons.BiUser className="icon" /></div>;
                                    }
                                    return (
                                        <div onClick={(e) => goToPage(notification.type, notification.type_id)} key={notification.id} className="d-flex single-notification-row">
                                            {/*<div>*/}
                                            {/*    <Ring/>*/}
                                            {/*</div>*/}
                                            <div className="single-notification-body">
                                                <div className="d-flex flex-row">
                                                    {iconImage}
                                                    <div style={{width:"100%"}}>
                                                        <div className="d-flex flex-row justify-content-between">
                                                            <span>{notification.type}</span>
                                                            <Timestamp>{timestamp}</Timestamp>
                                                        </div>
                                                        <p>{notification.message} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Notifications;