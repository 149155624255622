import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { updateInsuranceInfoUrl } from "../library/URLs";
import { useEffect, useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import { SelectBox, TextBox } from "../components/InputFields";
import { BsX } from "react-icons/bs";
import { styled as mui_styled } from "@mui/material/styles";
import { Switch } from "@mui/material";

const SubmitButton = styled.button``;

const ParcelflowSwitch = mui_styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 34,
  height: 16,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#15AB68",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 12,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#98A2B3",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const UpdateInsuranceDialog = ({
  isModalVisible,
  handleCloseDialog,
  onSubmit,
  insureInfo,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [insuranceInfo, setInsuranceInfo] = useState({
    insurance_company: "",
    active_local: "",
    local_margin_type: "",
    local_margin_value: "",
    active_interstate: "",
    interstate_margin_type: "",
    interstate_margin_value: "",
    active_international: "",
    international_margin_type: "",
    international_margin_value: "",
  });

  useEffect(() => {
    if (insureInfo) {
      setInsuranceInfo({
        ...insuranceInfo,
        insurance_company: insureInfo?.insurance_company,
        active_local: insureInfo?.active_local,
        local_margin_type: insureInfo?.local_margin_type,
        local_margin_value: insureInfo?.local_margin_value,
        active_interstate: insureInfo?.active_interstate,
        interstate_margin_type: insureInfo?.interstate_margin_type,
        interstate_margin_value: insureInfo?.interstate_margin_value,
        active_international: insureInfo?.active_international,
        international_margin_type: insureInfo?.international_margin_type,
        international_margin_value: insureInfo?.international_margin_value,
      });
    }

    //eslint-disable-next-line
  }, [insureInfo]);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const updateInsure = (e) => {
    e.preventDefault();
    const formValues = {
      ...insuranceInfo,
    };
    // formValues['customer_id'] = adminID;

    const newData = { ...formValues, ...UserProfileData() };

    setErrorVisibility(false);
    setIsSubmitting(true);

    axios
      .post(updateInsuranceInfoUrl, newData)
      .then(function (response) {
        setIsSubmitting(false);
        if (response["data"]["success"] === true) {
          setSuccessVisibility(true);
          setSuccessMessage("Service updated successfully");

          setTimeout(() => {
            onSubmit();
            handleCloseDialog();
          }, 5000);
        } else {
          setErrorMessage(response["data"]["message"]);
          setErrorVisibility(true);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        setIsSubmitting(false);
        console.log(error);
        return error;
      });
  };

  const handleInput = (e) => {
    const { value, name } = e.target;

    setInsuranceInfo({
      ...insuranceInfo,
      [name]: value,
    });
  };

  const handleToggle = (name, value) => {
    setInsuranceInfo({
      ...insuranceInfo,
      [name]: value,
    });
  };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">UPDATE INSURANCE INFO</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        {/* <p>Fill the details below to add a container</p> */}
        <div className="query-result-container">
          <div className="mb-2 d-flex gap-3 align-items-center">
            <label htmlFor="Description" className="">
              For Intracity
            </label>
            <ParcelflowSwitch
              name="active_local"
              onChange={() =>
                handleToggle(
                  "active_local",
                  insuranceInfo?.active_local === "Yes" ? "No" : "Yes"
                )
              }
              checked={insuranceInfo?.active_local === "Yes"}
              className="react-switch"
            />
          </div>
          <Row className="mb-5">
            <Col md={6}>
              <div>
                <label htmlFor="Description" className="mb-2">
                  Margin Type
                </label>
                <SelectBox
                  type="text"
                  name="local_margin_type"
                  options={[
                    { label: "Fixed", value: "Fixed" },
                    { label: "Percentage", value: "Percentage" },
                  ]}
                  value={insuranceInfo.local_margin_type}
                  onChange={handleInput}
                  placeholder="Choose a margin type"
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label htmlFor="Description" className="mb-2">
                  Margin Value
                </label>
                <TextBox
                  type="text"
                  name="local_margin_value"
                  prefix={
                    insuranceInfo?.local_margin_type === "Fixed" ? "NGN" : "%"
                  }
                  value={insuranceInfo.local_margin_value}
                  onChange={handleInput}
                  placeholder="Enter margin value"
                />
              </div>
            </Col>
          </Row>
          <div className="mb-2 d-flex gap-3 align-items-center">
            <label htmlFor="Description" className=" ">
              For Interstate
            </label>
            <ParcelflowSwitch
              name="active_interstate"
              onChange={() =>
                handleToggle(
                  "active_interstate",
                  insuranceInfo?.active_interstate === "Yes" ? "No" : "Yes"
                )
              }
              checked={insuranceInfo?.active_interstate === "Yes"}
              className="react-switch"
            />
          </div>
          <Row className="mb-5">
            <Col md={6}>
              <div>
                <label htmlFor="Description" className="mb-2">
                  Margin Type
                </label>
                <SelectBox
                  type="text"
                  name="interstate_margin_type"
                  options={[
                    { label: "Fixed", value: "Fixed" },
                    { label: "Percentage", value: "Percentage" },
                  ]}
                  value={insuranceInfo.interstate_margin_type}
                  onChange={handleInput}
                  placeholder="Choose a margin type"
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label htmlFor="Description" className="mb-2">
                  Margin Value
                </label>
                <TextBox
                  type="text"
                  name="interstate_margin_value"
                  prefix={
                    insuranceInfo?.interstate_margin_type === "Fixed"
                      ? "NGN"
                      : "%"
                  }
                  value={insuranceInfo.interstate_margin_value}
                  onChange={handleInput}
                  placeholder="Enter margin value"
                />
              </div>
            </Col>
          </Row>

          <div className="mb-2 d-flex gap-3 align-items-center">
            <label htmlFor="Description">For International</label>
            <ParcelflowSwitch
              name="active_international"
              onChange={() =>
                handleToggle(
                  "active_international",
                  insuranceInfo?.active_international === "Yes" ? "No" : "Yes"
                )
              }
              checked={insuranceInfo?.active_interstate === "Yes"}
              className="react-switch"
            />
          </div>
          <Row className="mb-5">
            <Col md={6}>
              <div>
                <label htmlFor="Description" className="mb-2">
                  Margin Type
                </label>
                <SelectBox
                  type="text"
                  name="international_margin_type"
                  options={[
                    { label: "Fixed", value: "Fixed" },
                    { label: "Percentage", value: "Percentage" },
                  ]}
                  value={insuranceInfo.international_margin_type}
                  onChange={handleInput}
                  placeholder="Choose a margin type"
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label htmlFor="Description" className="mb-2 mr-3">
                  Margin Value
                </label>
                <TextBox
                  type="text"
                  name="international_margin_value"
                  prefix={
                    insuranceInfo?.international_margin_type === "Fixed"
                      ? "NGN"
                      : "%"
                  }
                  value={insuranceInfo.international_margin_value}
                  onChange={handleInput}
                  placeholder="Enter margin value"
                />
              </div>
            </Col>
          </Row>
        </div>
        <p className="mt-3">Would you like to continue?</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-secondary mx-3" onClick={handleCloseDialog}>
          <BsX />
          Cancel
        </button>
        <SubmitButton
          onClick={updateInsure}
          type="submit"
          className="btn-primary"
        >
          {isSubmitting === false ? (
            <span className="button-text d-flex align-items-center gap-2">
              Proceed
            </span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateInsuranceDialog;
