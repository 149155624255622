import React, { useEffect, useState } from "react";
import PriceIcon from "../../../assets/settings/price_settings/credit_card_gear.svg";
import { UserProfileData } from "../../../library/constants";
import { getAPICarriersUrl } from "../../../library/URLs";
import axios from "axios";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import Dhl from "./Couriers/DHL";
import Fedex from "./Couriers/Fedex";
import Ups from "./Couriers/UPS";
import Aramex from "./Couriers/Aramex";

const CourierSettings = () => {
  const [carriers, setCarriers] = useState([]);
  const [selectedCourier, setSelectedCourier] = useState();

  const fetchApiCarriers = async () => {
    await axios
      .post(getAPICarriersUrl, UserProfileData())
      .then((res) => {
        if (res?.data?.success) {
          let api_carriers = res?.data?.data;

          setCarriers(api_carriers);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchApiCarriers();
  }, []);

  const handleSelectCourier = (id) => {
    if (selectedCourier === id) {
      setSelectedCourier(null);
    } else {
      setSelectedCourier(id);
    }
  };

  return (
    <div>
      <div className="settings_section d-flex flex-xl-row flex-column align-items-start">
        <div className="d-flex align-items-center justify-content-between price-settings-left">
          <div className="d-flex gap-3 align-items-start">
            <img src={PriceIcon} alt="price" className="mt-2" />
            <div>
              <p style={{ fontSize: 22, marginBottom: 8, fontWeight: "bold" }}>
                Courier Settings
              </p>
              <span>Customize your carriers accounts.</span>
            </div>
          </div>
        </div>
        <div className=" price-settings-right">
          <div className=" col-12 courier-list">
            {carriers?.map((courier) => (
              <div className="w-100 courier-list-item">
                <div
                  className="d-flex justify-content-between align-items-center courier-list-item-header"
                  onClick={() => handleSelectCourier(courier?.id)}
                >
                  <div className="d-flex gap-2 align-items-md-center flex-column flex-md-row">
                    <img
                      className="courier-list-item-img"
                      src={courier?.image_url}
                      alt={courier?.name}
                    />
                    <p className="courier-list-item-name">{courier?.name} </p>
                  </div>
                  {selectedCourier === courier?.id ? (
                    <BsChevronUp />
                  ) : (
                    <BsChevronDown />
                  )}
                </div>
                <div
                  className={`courier-list-item-bottom ${
                    selectedCourier === courier?.id
                      ? "courier-list-item-bottom-active"
                      : "courier-list-item-bottom-inactive"
                  }`}
                >
                  <div className="courier-list-item-form-wrap">
                    {courier?.id === 1 ? (
                      <Dhl opened={selectedCourier===1} />
                    ) : courier?.id === 2 ? (
                      <Fedex opened={selectedCourier===2} />
                    ) : courier?.id === 4 ? (
                      <Ups opened={selectedCourier === 4} />
                    ) : courier?.id === 5 ? (
                      <Aramex opened={selectedCourier===5} />
                    ) : (
                      <p>Not Available!</p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourierSettings;
