import React, { useEffect, useState } from "react";
import PaymentIcon from "../../../assets/create-shipment/payment-page.svg";
import TransporterIcon from "../../../assets/create-shipment/transport.svg";
import CarrierIcon from "../../../assets/create-shipment/carrier.svg";
import { SelectBox, TextBox } from "../../../components/InputFields";
import { Col, Row } from "react-bootstrap";
import { getAllTransportersURL } from "../../../library/URLs";
import axios from "axios";
import { UserProfileData } from "../../../library/constants";
import { SessionManagement } from "../../../library/SessionManagement";

const Payment = ({
  nextPage,
  previousPage,
  formData,
  setFormData,
  carriers,
  pricingMethod
}) => {
  const [transporters, setTransporters] = useState([]);
  const companyLogoURL = SessionManagement.getCompanyLogoURL();

  const getRiders = async () => {
    await axios
      .post(getAllTransportersURL, UserProfileData())
      .then(function (response) {
        console.log(response);
        if (response["data"]["success"] === true) {
          const transporters = response["data"]["data"].map((transporter) => {
            return {
              label: transporter?.name,
              value: transporter?.id,
            };
          });

          setTransporters(transporters);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  useEffect(() => {
    getRiders();
    // eslint-disable-next-line
  }, []);

  const handlePayment = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const payment_options = [
    { label: "Cash On Delivery", value: "Cash On Delivery" },
    { label: "Cash On Pickup", value: "Cash On Pickup" },
    { label: "Card Payment", value: "Card Payment" },
    { label: "Direct Transfer", value: "Direct Transfer" },
    { label: "Wallet", value: "Wallet" },
  ];

  const vehicle_options = [
    { label: "Motorbike", value: "Motorbike" },
    { label: "Van", value: "Van" }
  ];

  const validatePage = () => {
    if (formData.payment_method === "") {
      return true;
    } else if (formData.transporter === "") {
      return true;
    }

    return false;
  };

  const selectCarrier = (carrier) => {
    setFormData({
      ...formData,
      amount: carrier?.amount,
      shipment_plan: carrier?.name,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if(transporters?.length > 0) {
      setFormData({
        ...formData,
        transporters: transporters,
      });
    }

    nextPage();
  };

  return (
    <div>
      <div className="create-shipment-grid">
        <div className="create-shipment-info">
          <img src={PaymentIcon} alt="icon" />
          <div>
            <div className="create-shipment-info-top">
              <p className="create-shipment-info-title">Carriers</p>
              <p className="create-shipment-info-sub">
                Which carrier service would you like to use?
              </p>
            </div>
          </div>
        </div>
        <div className="create-shipment-info-form">
          {
            pricingMethod === "Yes" ? (
              <ul className="create-shipment-info-form-row carriers-list">
            {carriers?.map((carrier, id) => (
              <li key={id}>
                <div className="carriers-list-option">
                  <div className="carriers-list-option-first-row">
                    <div className="carriers-list-option-logo">
                      <img src={companyLogoURL} alt="carrier" />
                    </div>
                    <div>
                      <p className="carriers-list-option-carrier-name">
                        {carrier?.name}{" "}
                      </p>
                      <p className="carriers-list-option-transit">
                        Transit Time:{" "}
                        <span className="carriers-list-option-transit-time">
                          {carrier?.transit_time}{" "}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="carriers-list-option-price-label">PRICE</p>
                    <p className="carriers-list-option-price">
                      &#8358;{" "}
                      {parseFloat(carrier?.amount)?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                    </p>
                  </div>
                  <div>
                    <input
                      type="radio"
                      className=""
                      checked={carrier?.name === formData?.shipment_plan}
                      onChange={() => selectCarrier(carrier)}
                    />
                  </div>
                </div>
              </li>
            ))}
          </ul>
            ) : (
              <Row className="create-shipment-info-form-row">
                <Col sm={12} md={6} className="create-shipment-info-form-row-input">
                  <label htmlFor="payment_method">Shipment Price</label>
                  <TextBox
                    name="amount"
                    value={formData.amount}
                    onChange={handlePayment}
                    placeholder="Enter shipment price"
                  />
                </Col>
              </Row>
            )
          }
          
        </div>
      </div>
      <div className="create-shipment-grid">
        <div className="create-shipment-info">
          <img src={PaymentIcon} alt="icon" />
          <div>
            <div className="create-shipment-info-top">
              <p className="create-shipment-info-title">Payment Method</p>
              <p className="create-shipment-info-sub">
                What payment method should be assigned to this Shipment?
              </p>
            </div>
          </div>
        </div>
        <div className="create-shipment-info-form">
          <Row className="create-shipment-info-form-row">
            <Col sm={12} className="create-shipment-info-form-row-input">
              <label htmlFor="payment_method">Payment Method</label>
              <SelectBox
                name="payment_method"
                value={formData.payment_method}
                onChange={handlePayment}
                options={payment_options}
                placeholder="Select payment method"
              />
            </Col>
          </Row>
        </div>
      </div>
      <div className="create-shipment-grid">
        <div className="create-shipment-info">
          <img src={TransporterIcon} alt="icon" />
          <div>
            <div className="create-shipment-info-top">
              <p className="create-shipment-info-title">Transporter Details</p>
              <p className="create-shipment-info-sub">
                Who would you like to deliver this package?
              </p>
            </div>
          </div>
        </div>
        <div className="create-shipment-info-form">
          <Row className="create-shipment-info-form-row">
            <Col sm={12} className="create-shipment-info-form-row-input">
              <label htmlFor="transporter">Transporter</label>
              <SelectBox
                name="transporter"
                value={formData.transporter}
                onChange={handlePayment}
                options={transporters}
                placeholder="Select transporter"
              />
            </Col>
            <Col sm={12} md={6} className="create-shipment-info-form-row-input">
              <label htmlFor="payment_method">Vehicle Type</label>
              <SelectBox
                name="vehicle_type"
                value={formData.vehicle_type}
                onChange={handlePayment}
                options={vehicle_options}
                placeholder="Select vehicle option"
              />
            </Col>
            <Col sm={12} md={6} className="create-shipment-info-form-row-input">
              <label htmlFor="payment_method">Pickup Date</label>
              <TextBox
                name="shipping_date"
                value={formData.shipping_date}
                onChange={handlePayment}
                placeholder="Enter pickup date"
                type="date"
              />
            </Col>
            <Col sm={12} className="create-shipment-info-form-row-input">
              <label htmlFor="payment_method">Description</label>
              <TextBox
                name="description"
                value={formData.description}
                onChange={handlePayment}
                placeholder="Enter package description"
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end gap-2">
            <button
              className="create-shipment-info-form-btn-secondary"
              onClick={previousPage}
            >
              Previous
            </button>
            <button
              className="create-shipment-info-form-btn"
              onClick={handleSubmit}
              disabled={validatePage()}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
