import React from "react";
import "../styles/paginate.scss";

const Pagination = ({
  total_pages,
  current_page,
  paginate,
  prevPage,
  nextPage,
}) => {
  let page_numbers = [];

  for (let i = 1; i <= parseInt(total_pages); i++) {
    page_numbers.push(i);
  }

  return (
    <div className="py-4 d-flex justify-content-end">
      <div className="paginate-wrapper">
        <button
          className={`paginate-wrapper-btn`}
          onClick={prevPage}
          disabled={current_page === 1}
        >
          Previous
        </button>
        <div className="">
          {total_pages < 8 ? (
            <ul className="paginate-bullet-btn-wrapper">
              {page_numbers.map((item) => (
                <li key={item}>
                  <button
                    className={`paginate-bullet-btn ${
                      item === current_page && "paginate-bullet-btn-active"
                    }`}
                    onClick={() => paginate(item)}
                  >
                    {item}
                  </button>
                </li>
              ))}
            </ul>
          ) : total_pages >= 8 &&
            (current_page < 4 || current_page >= total_pages - 3) ? (
            <ul className="paginate-bullet-btn-wrapper">
              {page_numbers.slice(0, 3).map((item) => (
                <li key={item}>
                  <button
                    className={`paginate-bullet-btn ${
                      item === current_page && "paginate-bullet-btn-active"
                    }`}
                    onClick={() => paginate(item)}
                  >
                    {item}
                  </button>
                </li>
              ))}
              <li>
                <button className={`paginate-bullet-btn`}>...</button>
              </li>
              {page_numbers.slice(-3).map((item) => (
                <li key={item}>
                  <button
                    className={`paginate-bullet-btn ${
                      item === current_page && "paginate-bullet-btn-active"
                    }`}
                    onClick={() => paginate(item)}
                  >
                    {item}
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <ul className="paginate-bullet-btn-wrapper">
              <li>
                <button
                  className={`paginate-bullet-btn`}
                  onClick={() => paginate(1)}
                >
                  1
                </button>
              </li>
              <li>
                <button className={`paginate-bullet-btn`}>...</button>
              </li>
              {page_numbers
                .slice(current_page - 2, current_page)
                .map((item) => (
                  <li key={item}>
                    <button
                      className={`paginate-bullet-btn ${
                        item === current_page && "paginate-bullet-btn-active"
                      }`}
                      onClick={() => paginate(item)}
                    >
                      {item}
                    </button>
                  </li>
                ))}
              <li>
                <button className={`paginate-bullet-btn`}>...</button>
              </li>
              <li>
                <button
                  className={`paginate-bullet-btn`}
                  onClick={() => paginate(total_pages)}
                >
                  {total_pages}
                </button>
              </li>
            </ul>
          )}
        </div>
        <button
          className={`paginate-wrapper-btn`}
          onClick={nextPage}
          disabled={current_page === total_pages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;
