import React from 'react'
import * as BoxIcons from "react-icons/bi";
import { SessionManagement } from "../../../../library/SessionManagement";

const WebMobile = ({ showSubscriptionPlanDialog }) => {
    return (
        <div className="row mt-5 pt-4">
            <div className="col-xl-4 px-1 te col-md-6 col-12 mb-4 mb-md-0 col">
                <div className="single-plan">
                    <div>
                        <div>
                            <span style={{ fontSize: 16, fontWeight: 700, color: "#343a40" }}>Basic</span>
                        </div>
                        <div style={{ height: 52 }}>
                            <span style={{ fontSize: 14 }}>
                                Perfect for new starters and solo businesses
                            </span>
                        </div>
                        <div style={{ marginTop: 10 }} className="">
                            <span className="plan-pricing"><span className='text-green' >₦</span>0</span>
                            <span></span> <span style={{ fontSize: 12 }}>/shipment</span>
                        </div>

                        <div className='mt-3 pt-3 border-top' >
                            <div style={{ marginTop: 3, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> 1
                                Administrator
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> 5 transporters
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Up to 200 monthly
                                shipments
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Additional shipment cost of 200/shipment
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Intracity shipments
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> WhatsApp available
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> No Website
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column gap-2' >
                        {SessionManagement.getCurrentPlan() === "Basic" ? (
                            <>
                                <div className="current-plan-button">Current Plan</div>
                            </>
                        ) : (
                            <>
                                <div
                                    onClick={(event) =>
                                        showSubscriptionPlanDialog(
                                            "Basic",
                                            "PLN_w672ws7bqq4uz3j",
                                            10000000
                                        )
                                    }
                                    className="active-plan-button btn-primary"
                                >
                                    Get Started
                                </div>
                            </>
                        )}
                        <button className=' btn-secondary' >
                            Get In Touch
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-md-6 px-1 col-12 col mb-4 mb-md-0">
                <div className="single-plan">
                    <div>
                        <div>
                            <span style={{ fontSize: 16, fontWeight: 700, color: "#343a40" }}>Standard</span>
                        </div>
                        <div style={{ height: 52 }}>
                            <span style={{ fontSize: 14 }}>Best for small businesses</span>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <span className="plan-pricing"><span className="text-green">₦</span>600</span>
                            <span style={{ fontSize: 12 }}>/shipment</span>
                        </div>
                        <div className='mt-3 pt-3 border-top' >
                            <div style={{ marginTop: 3, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> 10
                                Administrators
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> 20
                                transporters
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Unlimited monthly shipment
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Intracity shipments
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Interstate shipments
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> International shipments
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Whatsapp available
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> 150 naira per local shipment
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Available apps: Frontdesk
                            </div>
                        </div>
                    </div>
                    <div className='d-flex gap-2 flex-column' >
                        {SessionManagement.getCurrentPlan() === "Standard" ? (
                            <>
                                <div className="current-plan-button">Current Plan</div>
                            </>
                        ) : (
                            <>
                                <div
                                    onClick={(event) =>
                                        showSubscriptionPlanDialog(
                                            "Standard",
                                            "PLN_p9cr0yg7y64nem3",
                                            20000000
                                        )
                                    }
                                    className="active-plan-button btn-primary"
                                >
                                    Get Started
                                </div>
                            </>
                        )}
                        <button className=' btn-secondary' >
                            Get In Touch
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-xl-4 px-1 col-md-6 col-12 col mb-4 mb-md-0">
                <div className="single-plan">
                    <div>
                        <div>
                            <span style={{ fontSize: 16, fontWeight: 700, color: "#343a40" }}>Premium</span>
                        </div>
                        <div style={{ height: 52 }}>
                            <span style={{ fontSize: 14 }}>Suitable for expanding firms</span>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <span className="plan-pricing"><span className="text-green">₦</span>1,000</span>
                            <span style={{ fontSize: 12 }}>/shipment</span>
                        </div>
                        <div className='mt-3 pt-3 border-top'>
                            <div style={{ marginTop: 3, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Unlimited
                                Administrators
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Unlimited
                                transporters
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Unlimited monthly shipments
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Intracity shipments
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Interstate shipments
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> International shipments
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Whatsapp available
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> 100 naira per local shipment
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Available apps: Frontdesk, SalesPush, Workforce, AirMail, Ledger
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column gap-2' >
                        {SessionManagement.getCurrentPlan() === "Premium" ? (
                            <>
                                <div className="current-plan-button">Current Plan</div>
                            </>
                        ) : (
                            <>
                                <div
                                    onClick={(event) =>
                                        showSubscriptionPlanDialog(
                                            "Premium",
                                            "PLN_9tq1s159ov1f4r4",
                                            30000000
                                        )
                                    }
                                    className="active-plan-button btn-primary"
                                >
                                    Get Started
                                </div>
                            </>
                        )}
                        <button className=' btn-secondary' >
                            Get In Touch
                        </button>
                    </div>
                </div>
            </div>
            {/* <div className="col-xl-4 px-1 col-md-6 col-12 mb-4 mb-md-0 col">
                <div className="single-plan">
                    <div>
                        <div>
                            <span style={{ fontSize: 16, fontWeight: 700, color: "#343a40" }}>Enterprise</span>
                        </div>
                        <div style={{ height: 52 }}>
                            <span style={{ fontSize: 14 }}>
                                Perfect for independent companies
                            </span>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <span className="plan-pricing">Custom</span>
                        </div>

                        <div>
                            <div style={{ marginTop: 3, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Everything in
                                premium
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> 1
                                Administrator
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Unlimited
                                transporters
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Up to 50
                                shipments
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column gap-2' >
                        {SessionManagement.getCurrentPlan() === "Custom" ? (
                            <>
                                <div className="current-plan-button">Current Plan</div>
                            </>
                        ) : (
                            <>
                                <div className="active-plan-button btn-primary">Get Started</div>
                            </>
                        )}
                        <button className=' btn-secondary' >
                            Get In Touch
                        </button>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default WebMobile