import React from "react";
import SenderIcon from "../../../assets/create-shipment/sender-page.svg";
import { Col, Row } from "react-bootstrap";
import {
  PhoneFieldBox,
  SelectTextBox,
  TextBox,
} from "../../../components/InputFields";
import {
  getCitiesByStateNameUrl,
  getStates2Url,
  searchForCustomersURL,
} from "../../../library/URLs";
import { useState } from "react";
import axios from "axios";
import { UserProfileData } from "../../../library/constants";
import { useEffect } from "react";
import { BsDot } from "react-icons/bs";
import nigeriaStates from "../../../files/nigeriaStates.json";

const Sender = ({ nextPage, formData, setFormData }) => {
  const [statesOptions, setStateOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [statesPending, setStatesPending] = useState(false);
  const [citiesPending, setCitiesPending] = useState(false);
  const [showSenderSuggestions, setShowSenderSuggestions] = useState(false);
  const [senderSuggestions, setSenderSuggestions] = useState([]);

  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      sender_details: {
        ...formData.sender_details,
        [name]: value,
      },
    });
  };

  const getStates = async () => {
    const data = {
      country: formData.sender_details?.country,
    };

    let country = formData.sender_details?.country;

    const newData = { ...data, ...UserProfileData() };
    setStatesPending(true);
    await axios
      .post(getStates2Url, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setStatesPending(false);

        if (response["data"]["success"] === false) return;

        let states = [];
        if(country === "Nigeria") {
          states = nigeriaStates?.data?.map((state) => {
            return {
              label: state?.name,
              value: state?.name
            }
          })
        } else {
          states = response?.data?.data?.map((state) => {
            return {
              label: state?.name,
              value: state?.name
            }
          });
        }
        setStateOptions(states);
      });
  };

  const getCities = async () => {
    const data = {
      country: formData.sender_details?.country,
      state: formData?.sender_details?.state,
    };

    const newData = { ...data, ...UserProfileData() };
    setCitiesPending(true);
    await axios
      .post(getCitiesByStateNameUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setCitiesPending(false);

        if (response["data"]["success"] === false) return;

        let cities = response?.data?.data?.map((city) => {
          return {
            label: city,
            value: city,
          };
        });
        setCitiesOptions(cities);
      });
  };

  useEffect(() => {
    getStates();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCities();
    //eslint-disable-next-line
  }, [formData?.sender_details?.state]);

  const validatePage = () => {
    if (formData.sender_details.fullname === "") {
      return true;
    } else if (formData.sender_details.email === "") {
      return true;
    } else if (formData.sender_details.phone === "") {
      return true;
    } else if (formData.sender_details.address === "") {
      return true;
    } else if (formData.sender_details.country === "") {
      return true;
    } else if (formData.sender_details.state === "") {
      return true;
    } else if (formData.sender_details.city === "") {
      return true;
    } else if (formData.sender_details.postal_code === "") {
      return true;
    }

    return;
  };

  const country_options = [{ label: "Nigeria", value: "Nigeria" }];

  const handleSelectTextbox = (name, value) => {
    setFormData({
      ...formData,
      sender_details: {
        ...formData.sender_details,
        [name]: value,
      },
    });
  };

  const handlePhoneInput = (code) => {
    setFormData({
      ...formData,
      sender_details: {
        ...formData.sender_details,
        phone: code,
      },
    });
  };

  const searchForCustomers = (e) => {
    const query = e.target.value;

    if (query.toString().trim() === "") {
      return null;
    }

    const formValues = {};
    formValues["query"] = query;
    formValues["limit"] = "5";

    const newData = { ...formValues, ...UserProfileData() };

    console.log(newData);

    axios.post(searchForCustomersURL, newData).then(function (response) {
      console.log(response);

      if (response["data"]["success"] === false) return;

      const customers = response["data"]["data"];

      setShowSenderSuggestions(true);
      setSenderSuggestions(customers);
    });
  };

  const selectSender = (customerName, customerNumber, emailAddress) => {
    setFormData({
      ...formData,
      sender_details: {
        ...formData.sender_details,
        fullname: customerName,
        email: emailAddress,
        phone: customerNumber,
      },
    });

    setShowSenderSuggestions(false);
  };

  const handleSenderName = (e) => {
    const { value } = e.target;

    setFormData({
      ...formData,
      sender_details: {
        ...formData.sender_details,
        fullname: value,
      },
    });
    searchForCustomers(e);
  };

  return (
    <div className="create-shipment-grid">
      <div className="create-shipment-info">
        <img src={SenderIcon} alt="icon" />
        <div>
          <div className="create-shipment-info-top">
            <p className="create-shipment-info-title">Sender Details</p>
            <p className="create-shipment-info-sub">
              Who is sending this package and from where?
            </p>
          </div>
          <div>
            <p>Saved Customers</p>
          </div>
        </div>
      </div>
      <div className="create-shipment-info-form">
        <Row className="create-shipment-info-form-row w-100">
          <Col sm={12} lg={12} className="create-shipment-info-form-row-input">
            <label htmlFor="first_name">Fullname</label>
            <TextBox
              name="fullname"
              value={formData.sender_details.fullname}
              onChange={handleSenderName}
              placeholder="Sender's fullname"
            />
            <div
              style={{ width: "31%" }}
              className={`suggestions-field ${
                showSenderSuggestions ? "suggestions-field-visible" : null
              }`}
            >
              {senderSuggestions.map((suggestions) => {
                const fullName = suggestions.fullname;
                const phoneNumber = suggestions.phone;
                const emailAddress = suggestions.email;
                return (
                  <div
                    onClick={(e) =>
                      selectSender(fullName, phoneNumber, emailAddress)
                    }
                    key={suggestions.id}
                    className="single-suggestion"
                  >
                    <span>{fullName}</span>
                    <div className="d-flex flex-row align-items-center">
                      <span style={{ fontSize: 13, marginTop: -5 }}>
                        {phoneNumber}
                      </span>
                      <BsDot />
                      <span style={{ fontSize: 13, marginTop: -5 }}>
                        {emailAddress}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </Col>
          <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
            <label htmlFor="email">Email Address</label>
            <TextBox
              name="email"
              type="email"
              value={formData.sender_details.email}
              onChange={handleInput}
              placeholder="Sender's email"
            />
          </Col>
          <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
            <label htmlFor="phone">Phone Number</label>
            <PhoneFieldBox
              value={formData.sender_details.phone}
              onChange={handlePhoneInput}
            />
          </Col>
          <Col sm={12} className="create-shipment-info-form-row-input">
            <label htmlFor="address">Address [Pickup]</label>
            <TextBox
              name="address_1"
              value={formData.sender_details.address_1}
              onChange={handleInput}
              placeholder="Sender's address [pickup]"
            />
          </Col>
          <Col sm={12} className="create-shipment-info-form-row-input">
            <label htmlFor="address">Address 2 [Pickup] (Optional)</label>
            <TextBox
              name="address_2"
              value={formData.sender_details.address_2}
              onChange={handleInput}
              placeholder="Sender's address 2 [pickup]"
            />
          </Col>
          <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
            <label htmlFor="country">Country</label>
            <SelectTextBox
              name="country"
              value={formData.sender_details.country}
              onChange={handleSelectTextbox}
              disabled={true}
              placeholder="Select available countries"
              options={country_options}
            />
          </Col>
          <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
            <label htmlFor="state">State</label>
            <div className="d-flex gap-1">
              {statesPending && (
                <span className="spinner-border spinner-grow-sm" />
              )}
              <SelectTextBox
                name="state"
                value={formData.sender_details.state}
                onChange={handleSelectTextbox}
                placeholder="Select available states"
                options={statesOptions}
              />
            </div>
          </Col>
          <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
            <label htmlFor="city">City</label>
            <div className="d-flex gap-1">
              {citiesPending && (
                <span className="spinner-border spinner-grow-sm" />
              )}
              <SelectTextBox
                name="city"
                value={formData.sender_details.city}
                onChange={handleSelectTextbox}
                placeholder="Select available cities"
                options={citiesOptions}
              />
            </div>
          </Col>
          <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
            <label htmlFor="postal_code">Postal Code</label>
            <TextBox
              name="postal_code"
              value={formData.sender_details.postal_code}
              onChange={handleInput}
              placeholder="Sender's postal code"
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-end gap-2">
          <button
            className="create-shipment-info-form-btn"
            onClick={nextPage}
            disabled={validatePage()}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sender;
