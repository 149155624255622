import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import * as React from "react";
import { useNavigate } from "react-router-dom";

const SubmitButton = styled.button`
  width: 250px;
`;

const InActiveWhatsappDialog = ({ isModalVisible, handleCloseDialog }) => {

    const navigate = useNavigate();

    return (
        <Modal show={isModalVisible} onHide={handleCloseDialog}>
            <Modal.Header>
                <h5 style={{ marginTop: 10 }} className="header">
                    SETUP WHATSAPP
                </h5>
                <BiX
                    style={{ fontSize: 25, cursor: "pointer" }}
                    onClick={handleCloseDialog}
                />
            </Modal.Header>
            <Modal.Body>
                
                <form >
                    <div>
                        {/* <div>
                            <label htmlFor="Subject">Phone Number To Be Used</label>
                            <input
                                name="current-password"
                                className="form-control"
                                type="text"
                            />
                        </div> */}

                        {/* <div className="mt-2">
                            <label htmlFor="Amount">Name of Contact Person</label>
                            <input name="new-password" className="form-control" type="text" />
                        </div> */}

                        {/* <div className="mt-2">
                            <label htmlFor="Amount">Phone Number of Contact Person</label>
                            <input
                                name="confirm-password"
                                className="form-control"
                                type="text"
                            />
                        </div> */}

                        <div
                            className="d-flex flex-row justify-content-center"
                            style={{ marginTop: 2 }}
                        >
                            <span style={{ fontSize: 16, textAlign: "left" }}>
                                Sorry you are not an active subscriber. <br />
                                Please click the button below and subscribe to standard, premium or enterprise plan to access this feature.<br/><br/>
                                Do you want to continue?
                            </span>
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className="cancel-button mx-3" onClick={handleCloseDialog}>
                    CANCEL
                </button>
                <SubmitButton
                    onClick={() => navigate("/settings?page=subscriptions")}
                    type="submit"
                    className="confirm-button"
                >
                        <span className="button-text">SUBSCRIBE TO PLAN</span>
                    
                </SubmitButton>
            </Modal.Footer>
        </Modal>
    );
};

export default InActiveWhatsappDialog;
