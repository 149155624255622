import React from 'react'
import * as BoxIcons from "react-icons/bi";
import { SessionManagement } from "../../../../library/SessionManagement";

const WhatsappOnly = ({ showSubscriptionPlanDialog }) => {
    return (
        <div className="row mt-5 pt-4">
            <div className="col-xl-4 px-1 te col-md-6 col-12 col mb-4 mb-md-0">
                <div className="single-plan">
                    <div>
                        <div>
                            <span style={{ fontSize: 16, fontWeight: 700, color: "#343a40" }}>Basic</span>
                        </div>
                        <div style={{ height: 52 }}>
                            <span style={{ fontSize: 14 }}>
                                Perfect for new starters and solo businesses
                            </span>
                        </div>
                        <div style={{ marginTop: 10 }} className="">
                            {/* <span className="pricing-discount" >₦100k{" "} </span> */}
                            <span className="plan-pricing"><span className='text-green' >₦</span>100,000</span>
                            <span></span> <span style={{ fontSize: 12 }}>/month</span>
                        </div>
                        <div>
                            <div style={{ marginTop: 3, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> 1
                                Administrator
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> 2 transporters
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Zero naira setup fee
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> 1 free website
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> 150 Naira per shipment
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> 3 Question Template
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Intracity shipment types only
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column gap-2' >
                        {SessionManagement.getCurrentPlan() === "Basic" ? (
                            <>
                                <div className="current-plan-button">Current Plan</div>
                            </>
                        ) : (
                            <>
                                <div
                                    onClick={(event) =>
                                        showSubscriptionPlanDialog(
                                            "Basic",
                                            "PLN_w672ws7bqq4uz3j",
                                            10000000
                                        )
                                    }
                                    className="active-plan-button"
                                >
                                    Get Started
                                </div>
                            </>
                        )}
                        <button className=' btn-secondary' >
                            Get In Touch
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-md-6 col-12 col mb-4 mb-md-0">
                <div className="single-plan">
                    <div>
                        <div>
                            <span style={{ fontSize: 20, color: "#343a40" }}>Standard</span>
                        </div>
                        <div style={{ height: 52 }}>
                            <span style={{ fontSize: 14 }}>Best for small businesses</span>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            {/* <span className="pricing-discount" >₦300k{" "} </span> */}
                            <span className="plan-pricing"><span className="text-green">₦</span>300,000</span>
                            <span style={{ fontSize: 12 }}>/month</span>
                        </div>
                        <div>
                            <div style={{ marginTop: 3, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> 3
                                Administrators
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> 10
                                transporters
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> 100,000 naira setup fee
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> 150 Naira per shipment
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Custom Question Template
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> All shipment type
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column gap-2">
                        {SessionManagement.getCurrentPlan() === "Standard" ? (
                            <>
                                <div className="current-plan-button">Current Plan</div>
                            </>
                        ) : (
                            <>
                                <div
                                    onClick={(event) =>
                                        showSubscriptionPlanDialog(
                                            "Standard",
                                            "PLN_p9cr0yg7y64nem3",
                                            20000000
                                        )
                                    }
                                    className="active-plan-button"
                                >
                                    Get Started
                                </div>
                            </>
                        )}
                        <button className=' btn-secondary' >
                            Get In Touch
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-md-6 col-12 col mb-4 mb-md-0">
                <div className="single-plan">
                    <div>
                        <div>
                            <span style={{ fontSize: 20, color: "#343a40" }}>PRO</span>
                        </div>
                        <div style={{ height: 52 }}>
                            <span style={{ fontSize: 14 }}>Suitable for expanding firms</span>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <span className="plan-pricing">Custom</span>
                        </div>


                        <div>
                            <div style={{ marginTop: 3, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Unlimited Admins
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Unlimited
                                transporters
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Custom Question Template
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> All shipment types
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> 300,000 naira setup fee
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> 300 Naira per shipment
                            </div>
                            <div style={{ marginTop: 20, fontSize: 14 }}>
                                <BoxIcons.BiCheck style={{ color: "#008561" }} /> Advanced AI
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column gap-2">
                        <>
                            <div
                                onClick={(event) =>
                                    showSubscriptionPlanDialog(
                                        "Premium",
                                        "PLN_9tq1s159ov1f4r4",
                                        30000000
                                    )
                                }
                                className="active-plan-button"
                            >
                                Get Started
                            </div>
                        </>
                        <button className=' btn-secondary' >
                            Get In Touch
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhatsappOnly