import "../styles/home.scss";
import "../styles/shipments.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserProfileData } from "../library/constants";
import {
    getAllCustomerWalletURL,
    getCustomerWalletHistoryURL,
    getStationsURL,
    getTransactionsURL,
    searchForCustomersURL,
} from "../library/URLs";
import axios from "axios";
import styled from "styled-components";
import * as React from "react";
import moment from "moment";
import * as BiIcons from "react-icons/bi";
import { GoPrimitiveDot } from "react-icons/go";
import CustomDateRangeDialog from "../modals/CustomDateRangeDialog";
import SubscriptionInactive from "../components/SubscriptionInactive";
import { BsDot } from "react-icons/bs";
import { Col, Row } from "react-bootstrap";
import DeleteStationDialog from "../modals/DeleteStationDialog";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const SingleStation = () => {
    const navigator = useNavigate();
    const params = useParams();
    const stationID = params["stationID"];

    const [isLoading, setIsLoading] = useState(true);
    const [currentOffset, setCurrentOffset] = useState(0);
    const [isRetrievingMore, setIsRetrievingMore] = useState(false);
    const [deleteStation, setDeleteStation] = useState(false);

    const [station, setStation] = useState({});

    const data = {
        limit: 40,
        offset: currentOffset,
        status: "All",
    };

    const newData = { ...data, ...UserProfileData() };

    useEffect(() => {
        axios
            .post(getStationsURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                if (isLoading === false && isRetrievingMore === false) {
                    console.log("Should not retrieve");
                    return;
                }
                console.log(response);

                setIsRetrievingMore(false);
                setIsLoading(false);

                if (response["data"]["success"] === false) return;

                const newCustomers = response["data"]["data"]["data"]?.find(
                    (item) => item.id === parseInt(stationID)
                );

                setStation(newCustomers);
            });
    }, []);

    return (
        <div className="home">
            <Sidebar />
            <div className="main">
                <Navbar />
                <div className="page pb-5">
                    <DeleteStationDialog 
                        isModalVisible={deleteStation}
                        handleCloseDialog={()=>setDeleteStation(false)}
                        stationID={stationID}
                    />
                    <SubscriptionInactive />
                    <div className="d-flex flex-row justify-content-between mb-2 mx-3">
                        <h5>Station</h5>
                        <span onClick={()=>navigator("/stations")} >Home</span>
                    </div>
                    <div className="container-fluid shipments-container">
                        <div className="card bg-light border-0 shadow p-4 min-vh-100">
                            <div className="d-grid d-md-flex justify-content-end gap-2">
                                <input className="confirm-button" type="button" onClick={() => navigator("/stations/edit-station/" + stationID)} value="EDIT STATION" />

                                <input className="confirm-button bg-danger" type="button" onClick={() => setDeleteStation(true)} value="DELETE" />
                            </div>
                            {!isLoading && (
                                <div>
                                    <section className="mb-5" >
                                        <Row className="form-row mt-3">
                                            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                                                <p htmlFor="Length" className="mb-1" >Contact Full Name:</p>
                                                <p>{station?.fullname} </p>
                                            </Col>

                                            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                                                <p htmlFor="Length" className="mb-1" >Phone Number:</p>
                                                <p>{station?.phone} </p>
                                            </Col>

                                            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                                                <p htmlFor="Length" className="mb-1" >Email Address:</p>
                                                <p>{station?.email} </p>
                                            </Col>
                                        </Row>
                                    </section>
                                    <section>
                                        <Row className="form-row mt-3">
                                            <Col className="form-group col-xl-3 col-md-6 col-12 mb-3 mb-xl-0">
                                                <p htmlFor="Length" className="mb-1" >Country:</p>
                                                <p>{station?.country} </p>
                                            </Col>

                                            <Col className="form-group col-xl-3 col-md-6 col-12 mb-3 mb-xl-0">
                                                <p htmlFor="Length" className="mb-1" >State:</p>
                                                <p>{station?.state} </p>
                                            </Col>

                                            <Col className="form-group col-xl-3 col-md-6 col-12 mb-3 mb-xl-0">
                                                <p htmlFor="Length" className="mb-1" >City:</p>
                                                <p>{station?.city_name} </p>
                                            </Col>

                                            <Col className="form-group col-xl-3 col-md-6 col-12 mb-3 mb-xl-0">
                                                <p htmlFor="Length" className="mb-1" >Postal Code:</p>
                                                <p>{station?.postal_code} </p>
                                            </Col>
                                        </Row>
                                        <Row className="form-row mt-3">
                                            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                                                <p htmlFor="Length" className="mb-1" >Address:</p>
                                                <p>{station?.address} </p>
                                            </Col>

                                        </Row>
                                        <Row className="form-row mt-3">
                                            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                                                <p htmlFor="Length" className="mb-1" >Address 2:</p>
                                                <p>{station?.address_2} </p>
                                            </Col>
                                        </Row>
                                    </section>
                                </div>
                            )}

                            {isLoading === false ? null : (
                                <LoadingContainer>
                                    <LoadingSpinner className="spinner-border spinner-grow-sm" />
                                </LoadingContainer>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleStation;
