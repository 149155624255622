import React from "react";
import CalculateIcon from "../../assets/calculate-estimates/calculate.svg";
import { Col, Row } from "react-bootstrap";
import { SelectBox, SelectTextBox, TextBox } from "../../components/InputFields";
import countries from "../../files/countries.json";
import { getStates2Url, getCitiesByStateNameUrl } from "../../library/URLs";
import nigeriaStates from "../../files/nigeriaStates.json";
import { useState } from "react";
import { UserProfileData } from "../../library/constants";
import axios from "axios";
import { useEffect } from "react";
import { Switch } from "@mui/material";
import { styled as mui_styled } from '@mui/material/styles';

const ParcelflowSwitch = mui_styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 34,
  height: 16,
  padding: 0,
  '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
              backgroundColor: '#15AB68',
              opacity: 1,
              border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
          },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: '#33cf4d',
          border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
          color:
              theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
          opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
  },
  '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 14,
      height: 12,
  },
  '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#98A2B3',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
          duration: 500,
      }),
  },
}));

const EstimateForm = ({ 
  nextPage, 
  formData, 
  setFormData, 
  isPending,
  defaultSenderInfo,
  setDefaultSenderInfo,
  defaultReceiverInfo,
  setDefaultReceiverInfo,
  defaultPackageInfo,
  setDefaultPackageInfo
}) => {
  const [senderStatesOptions, setSenderStateOptions] = useState([]);
  const [senderStatesPending, setSenderStatesPending] = useState(false);

  const [senderCitiesOptions, setSenderCitiesOptions] = useState([]);
  const [senderCitiesPending, setSenderCitiesPending] = useState(false);

  const [receiverStatesOptions, setReceiverStateOptions] = useState([]);
  const [receiverStatesPending, setReceiverStatesPending] = useState(false);

  const [receiverCitiesOptions, setReceiverCitiesOptions] = useState([]);
  const [receiverCitiesPending, setReceiverCitiesPending] = useState(false);

  const [rateType, setRateType] = useState("International");

  const toggleRates = () => {
    if(rateType === "International") {
      setRateType("Interstate");

      setFormData({
        ...formData,
        sender: {
          country: "Nigeria",
          state: "",
          city: ""
        },
        receiver: {
          country: "Nigeria",
          state: "",
          city: ""
        }
      });
    } else {
      setRateType("International");

      setFormData({
        ...formData,
        sender: {
          country: "",
          state: "",
          city: "",
          postcode: ""
        },
        receiver: {
          country: "",
          state: "",
          city: "",
          postcode: ""
        }
      });
    }
  };

  const getSenderStates = async() => {
    const data = {
      country: formData.sender?.country
    };
    let country = formData.sender?.country;

    const newData = { ...data, ...UserProfileData() };
    setSenderStatesPending(true);
    await axios
      .post(getStates2Url, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSenderStatesPending(false);

        if (response["data"]["success"] === false) return;

        let states = [];
        if(country === "Nigeria") {
          states = nigeriaStates?.data?.map((state) => {
            return {
              label: state?.name,
              value: state?.name
            }
          })
        } else {
          states = response?.data?.data?.map((state) => {
            return {
              label: state?.name,
              value: state?.name
            }
          });
        }
        setSenderStateOptions(states);
      });
  };

  const getSenderCities = async() => {
    const data = {
      country: formData.sender?.country,
      state: formData?.sender?.state
    };

    const newData = { ...data, ...UserProfileData() };
    setSenderCitiesPending(true);
    await axios
      .post(getCitiesByStateNameUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSenderCitiesPending(false);

        if (response["data"]["success"] === false) return;

        let cities = response?.data?.data?.map((city) => {
          return {
            label: city,
            value: city
          }
        });
        setSenderCitiesOptions(cities);
      });
  };

  useEffect(() => {
    getSenderStates();
    // eslint-disable-next-line
  }, [
    formData.sender.country
  ]);

  useEffect(() => {
    getSenderCities();
    //eslint-disable-next-line
  },[
    formData?.sender?.state
  ]);

  const getReceiverStates = async() => {
    const data = {
      country: formData.receiver?.country
    };

    let country = formData.receiver?.country;

    const newData = { ...data, ...UserProfileData() };
    setReceiverStatesPending(true);
    await axios
      .post(getStates2Url, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setReceiverStatesPending(false);

        if (response["data"]["success"] === false) return;

        let states = [];
        if(country === "Nigeria") {
          states = nigeriaStates?.data?.map((state) => {
            return {
              label: state?.name,
              value: state?.name
            }
          })
        } else {
          states = response?.data?.data?.map((state) => {
            return {
              label: state?.name,
              value: state?.name
            }
          });
        }
        setReceiverStateOptions(states);
      });
  };

  const getReceiverCities = async() => {
    const data = {
      country: formData.receiver?.country,
      state: formData?.receiver?.state
    };

    const newData = { ...data, ...UserProfileData() };
    setReceiverCitiesPending(true);
    await axios
      .post(getCitiesByStateNameUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setReceiverCitiesPending(false);

        if (response["data"]["success"] === false) return;

        let cities = response?.data?.data?.map((city) => {
          return {
            label: city,
            value: city
          }
        });
        setReceiverCitiesOptions(cities);
      });
  };

  useEffect(() => {
    getReceiverStates();
    // eslint-disable-next-line
  }, [
    formData.receiver.country
  ]);

  useEffect(() => {
    getReceiverCities();
    //eslint-disable-next-line
  },[
    formData?.receiver?.state
  ]);


  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSenderDetails = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      sender: {
        ...formData.sender,
        [name]: value
      }
    });
  };

  const handleSenderDetailSelection = (name, value) => {
    setFormData({
      ...formData,
      sender: {
        ...formData.sender,
        [name]: value
      }
    });
  };

  const handleReceiverDetails = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      receiver: {
        ...formData.receiver,
        [name]: value
      }
    });
  };

  const handleReceiverDetailSelection = (name, value) => {

    setFormData({
      ...formData,
      receiver: {
        ...formData.receiver,
        [name]: value
      }
    });
  };

  const handlePackageDetails = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      package_details: {
        ...formData.package_details,
        [name]: value
      }
    });
  };

  const country_options = countries?.map((cntry) => {
    return {
      label: cntry?.name,
      value: cntry?.name
    }
  });

  let currency = formData.sender.country === "Nigeria" && formData.receiver.country === "Nigeria" ? "NGN" : "USD";

  return (
    <div>
    <div className="mb-5" >
      <div className="rates-toggle-bar" onClick={toggleRates} >
        <div className={`rate-toggle-switch ${rateType !== "International" && "rate-toggle-switch-state"}`} ></div>
        <div className={`toggle-option ${rateType === "International" && "toggle-option-active"}`} >
          International
        </div>
        <div className={`toggle-option ${rateType === "Interstate" && "toggle-option-active"}`} >
          Interstate
        </div>
      </div>
    </div>
      <div className="mb-4">
        <p className="estimate-text">From (Sender)</p>
        <Row className="calculate-estimate-form">
          <Col xs={12} lg={6}>
            <label htmlFor="country_from">Country</label>
            <SelectTextBox
              name="country"
              value={formData.sender.country}
              disabled={rateType === "Interstate"}
              onChange={handleSenderDetailSelection}
              placeholder="Select available countries"
              options={country_options} 
            />
          </Col>
          {
            (!defaultSenderInfo || rateType === "Interstate") && (
              <Col xs={12} lg={6}>
                <label htmlFor="state_from">State</label>
                <div className='d-flex gap-1' >
                  {
                    senderStatesPending && (
                      <span className="spinner-border spinner-grow-sm"/>
                    )
                  }
                  <SelectTextBox
                    name="state"
                    value={formData.sender.state}
                    onChange={handleSenderDetailSelection}
                    placeholder="Select available states"
                    options={senderStatesOptions}
                  />
                </div>
              </Col>
            )
          }
          {
            (!defaultSenderInfo || rateType === "Interstate") && (
              <Col xs={12} lg={6}>
                <label htmlFor="city_from">City</label>
                <div className='d-flex gap-1' >
                  {
                    senderCitiesPending && (
                      <span className="spinner-border spinner-grow-sm"/>
                    )
                  }
                  <SelectTextBox
                    name="city"
                    value={formData.sender.city}
                    onChange={handleSenderDetailSelection}
                    placeholder="Select available cities"
                    options={senderCitiesOptions}
                  />
                </div>
              </Col>
            )
          }
          {
            !defaultSenderInfo && (
              <Col xs={12} lg={6}>
                <label htmlFor="postcode">Postal code (optional)</label>
                <TextBox
                  name="postcode"
                  value={formData.sender.postcode}
                  onChange={handleSenderDetails}
                  placeholder="Sender's postal code"
                />
              </Col>
            )
          }
          <Col xs={12}>
            <div className="d-flex flex-row gap-2 align-items-center">
              <label htmlFor="Full Name" className="m-0">Use default sender information</label>
              <ParcelflowSwitch 
                name="sender_info" 
                onChange={() => setDefaultSenderInfo(!defaultSenderInfo)} 
                checked={defaultSenderInfo} 
                className="react-switch" 
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="mb-4">
        <p className="estimate-text">To (Reciver)</p>
        <Row className="calculate-estimate-form">
          <Col xs={12} lg={6}>
            <label htmlFor="country_to">Country</label>
            <SelectTextBox
              name="country"
              value={formData.receiver.country}
              disabled={rateType === "Interstate"}
              onChange={handleReceiverDetailSelection}
              placeholder="Select available countries"
              options={country_options}
            />
          </Col>
          {
            (!defaultReceiverInfo || rateType === "Interstate") && (
              <Col xs={12} lg={6}>
                <label htmlFor="state_to">State</label>
                <div className="d-flex gap-1">
                  {receiverStatesPending && (
                    <span className="spinner-border spinner-grow-sm" />
                  )}
                  <SelectTextBox
                    name="state"
                    value={formData.receiver.state}
                    onChange={handleReceiverDetailSelection}
                    placeholder="Select available states"
                    options={receiverStatesOptions}
                  />
                </div>
              </Col>
            )
          }
          {
            (!defaultReceiverInfo || rateType === "Interstate") && (
              <Col xs={12} lg={6}>
                <label htmlFor="city_to">City</label>
                <div className="d-flex gap-1">
                  {receiverCitiesPending && (
                    <span className="spinner-border spinner-grow-sm" />
                  )}
                  <SelectTextBox
                    name="city"
                    value={formData.receiver.city}
                    onChange={handleReceiverDetailSelection}
                    placeholder="Select available cities"
                    options={receiverCitiesOptions}
                  />
                </div>
              </Col>
            )
          }
          {
            !defaultReceiverInfo && (
              <Col xs={12} lg={6}>
                <label htmlFor="postcode">Postal code (optional)</label>
                <TextBox
                  name="postcode"
                  value={formData.receiver.postcode}
                  onChange={handleReceiverDetails}
                  placeholder="Receiver's postal code"
                />
              </Col>
            )
          }
          <Col xs={12}>
            <div className="d-flex flex-row gap-2 align-items-center">
              <label htmlFor="Full Name" className="m-0">Use default receiver information</label>
              <ParcelflowSwitch 
                name="receiver_info" 
                onChange={() => setDefaultReceiverInfo(!defaultReceiverInfo)} 
                checked={defaultReceiverInfo} 
                className="react-switch" 
              />
            </div>
          </Col>
        </Row>
      </div>
      <div>
        <p className="estimate-text">Shipment Information</p>
        <Row className="calculate-estimate-form">
          <Col xs={12} lg={6}>
            <label htmlFor="weight">Weight</label>
            <TextBox
              name="weight"
              value={formData.weight}
              onChange={handleInput}
              type="tel"
              placeholder="Enter package weight"
              prefix="KG"
            />
          </Col>
          {
            !defaultPackageInfo && (
              <Col xs={12} lg={6}>
                <label htmlFor="length">Length (optional)</label>
                <TextBox
                  name="length"
                  value={formData.package_details.length}
                  onChange={handlePackageDetails}
                  placeholder="Enter package length"
                  prefix="CM"
                  type="tel"
                />
              </Col>
            )
          }
          {
            !defaultPackageInfo && (
              <Col xs={12} lg={6}>
                <label htmlFor="width">Breadth (optional)</label>
                <TextBox
                  name="breadth"
                  type="tel"
                  value={formData.package_details.breadth}
                  onChange={handlePackageDetails}
                  placeholder="Enter package breadth"
                  prefix="CM"
                />
              </Col>
            )
          }
          {
            !defaultPackageInfo && (
              <Col xs={12} lg={6}>
                <label htmlFor="height">Height (optional)</label>
                <TextBox
                  name="height"
                  type="tel"
                  value={formData.package_details.height}
                  onChange={handlePackageDetails}
                  placeholder="Enter package height"
                  prefix="CM"
                />
              </Col>
            )
          }
          {
            !defaultPackageInfo && (
              <Col xs={12} lg={6}>
                <label htmlFor="item_value">Package Value (optional)</label>
                <TextBox
                  name="item_value"
                  value={formData.package_details.item_value}
                  onChange={handlePackageDetails}
                  type="tel"
                  placeholder="Enter package value"
                  prefix={currency}
                />
              </Col>
            )
          }
          <Col xs={12}>
            <div className="d-flex flex-row gap-2 align-items-center">
              <label htmlFor="Full Name" className="m-0">Use default package information</label>
              <ParcelflowSwitch 
                name="package_info" 
                onChange={() => setDefaultPackageInfo(!defaultPackageInfo)} 
                checked={defaultPackageInfo} 
                className="react-switch" 
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="mt-5 d-flex justify-content-end">
        <button className="btn-primary" type="button" onClick={nextPage}>
          {
            isPending ? (
              <span className="spinner-border spinner-grow-sm" />
            ) : (
              <span className="d-flex gap-2 align-items-center justify-content-center" >
                <img src={CalculateIcon} alt="icon" />
                Calculate Estimate
              </span>
            )
          }
        </button>
      </div>
    </div>
  );
};

export default EstimateForm;
