import "../../styles/home.scss";
import "../../styles/reports.scss";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserProfileData } from "../../library/constants";
import { getShipmentsURL, getShipmentReportURL } from "../../library/URLs";
import axios from "axios";
import styled from "styled-components";
import moment from "moment";
import * as BiIcons from "react-icons/bi";
import dataIcon from "../../images/no-data.png";
import { Dropdown } from "react-bootstrap";
import { saveAs } from "file-saver";
import { utils, write } from "xlsx";
import * as ExcelJS from "exceljs";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const ShipmentReports = () => {
  const navigator = useNavigate();

  const [reportType, setReportType] = useState("");
  const [reportTime, setReportTime] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  const [isOptionSelected, setIsOptionSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [isRetrievingMore, setIsRetrievingMore] = useState(false);

  const [shipments, setShipments] = useState([]);

  const data = {
    limit: 30,
    offset: currentOffset,
    report_type: reportType,
    report_time: reportTime,
    status: "All",
  };

  const newData = { ...data, ...UserProfileData() };

  const getReports = () => {
    if (reportTime !== "" && reportType !== "") {
      setIsLoading(true);
      setCurrentOffset(0);
      setIsOptionSelected(reportType + reportTime);
    }
  };

  const fetchShipmentReport = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const reportData = {
      ...UserProfileData(),
      // "type": "Today||Week||Month||Year||2023-08-01|202-08-04",
      type:
        reportTime === "custom"
          ? `${reportStartDate}|${reportEndDate}`
          : reportTime,
      mode: "web",
    };
    if (
      (reportTime === "custom" &&
        reportStartDate !== "" &&
        reportEndDate !== "") ||
      reportTime !== "custom"
    ) {
      axios
        .post(getShipmentReportURL, reportData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          setIsRetrievingMore(false);
          setIsLoading(false);

          if (response["data"]["success"] === false) return;

          const newShipments = response["data"]["data"];

          const index = newShipments[newShipments.length - 1].id;
          setShipments(newShipments);

          setCurrentOffset(index);

          // if (currentOffset === 0){
          //   setShipments(newShipments);
          // } else {
          //   setShipments([...shipments, ...newShipments]);
          // }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  // useEffect(() => {
  //   if (isOptionSelected === "") {
  //     console.log("Wow... not going through");
  //     return;
  //   }

  //   console.log(newData);
  //   axios
  //     .post(getShipmentsURL, newData, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     .then((response) => {
  //       if (isLoading === false && isRetrievingMore === false) {
  //         console.log("Should not retrieve");
  //         return;
  //       }
  //       console.log(response);
  //       setIsRetrievingMore(false);
  //       setIsLoading(false);

  //       if (response["data"]["success"] === false) return;

  //       const newShipments = response["data"]["data"];

  //       const index = newShipments[newShipments.length - 1].id;

  //       setCurrentOffset(index);

  //       if (currentOffset === 0) {
  //         setShipments(newShipments);
  //       } else {
  //         setShipments([...shipments, ...newShipments]);
  //       }
  //     });
  // }, [isRetrievingMore, isOptionSelected]);

  const onChangeReportType = (event) => {
    setReportType(event.target.value);
  };

  const onChangeReportTime = (event) => {
    setReportTime(event.target.value);
  };

  const handleClick = (shipmentID) => {
    navigator("/shipment/" + shipmentID);
  };

  const setScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      isRetrievingMore === false
    ) {
      setIsRetrievingMore(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", setScroll);
    return () => {
      window.removeEventListener("scroll", setScroll);
    };
  }, []);

  const exportToXlsx = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Report");
    sheet.properties.defaultRowHeight = 40;

    sheet.getRow(1).border = {
      // top: { style: "thick", color: { argb: "074B8A" } },
      // left: { style: "thick", color: { argb: "074B8A" } },
      bottom: { style: "thick", color: { argb: "074B8A" } },
      right: { style: "thin", color: { argb: "074B8A" } },
    };

    sheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "008561" },
    };

    sheet.getRow(1).font = {
      // name: "Comic Sans MS",
      // family: 4,
      size: 16,
      bold: true,
      color: { argb: "000000" },
    };

    sheet.columns = [
      {
        header: "S/N",
        key: "sn",
        width: 5,
      },
      {
        header: "SHIPMENT ID",
        key: "id",
        width: 30,
      },
      {
        header: "SENDER TYPE",
        key: "shipment_type",
        width: 40,
      },
      {
        header: "DATE",
        key: "date_time",
        width: 40,
      },
      {
        header: "SENDER NAME",
        key: "sender_name",
        width: 40,
      },
      {
        header: "SENDER PHONE NUMBER",
        key: "sender_phone_no",
        width: 40,
      },
      {
        header: "PICKUP ADDRESS",
        key: "pick_up_location",
        width: 60,
      },
      { header: "RECEIVER NAME", key: "recipient_name", width: 40 },
      { header: "RECEIVER PHONE NUMBER", key: "recipient_phone_no", width: 40 },
      {
        header: "DROPOFF ADDRESS",
        key: "drop_off_location",
        width: 60,
      },
      {
        header: "AMOUNT",
        key: "total_amount",
        width: 60,
      },
      // {
      //   header: "PICKUP",
      //   key: "pickup",
      //   width: 40,
      // },
      // {
      //   header: "DROPOFF",
      //   key: "dropoff",
      //   width: 40,
      // },
      {
        header: "PAYMENT METHOD",
        key: "payment_method",
        width: 40,
      },
      {
        header: "PAYMENT STATUS",
        key: "paid_status",
        width: 40,
      },
      {
        header: "SHIPMENT STATUS",
        key: "status",
        width: 40,
      },
      {
        header: "WEIGHT",
        key: "weight",
        width: 40,
      },
      {
        header: "ITEM DESCRIPTION",
        key: "item_name",
        width: 70,
      },
      {
        header: "ITEM VALUE",
        key: "item_value",
        width: 40,
      },
      {
        header: "APPROVED BY",
        key: "approved_by",
        width: 40,
      },
      {
        header: "SHIPMENT TRACKING NUMBER",
        key: "shipment_tracking_number",
        width: 40,
      },
      {
        header: "RIDER",
        key: "rider",
        width: 40,
      },
      {
        header: "CARRIER",
        key: "carrier",
        width: 40,
      },
      {
        header: "INSURED",
        key: "insurance",
        width: 40,
      },
      {
        header: "INSURANCE FEE",
        key: "insurance_fee",
        width: 40,
      },
    ];

    sheet.insertRow(1, [
      "",
      "",
      "",
      `
    SHIPMENT REPORT
    `,
    ]);
    const first_row = sheet.getRow(1);
    first_row.height = 120;
    first_row.font = {
      size: 18,
      bold: true,
      color: { argb: "074B8A" },
    };
    first_row.border = {
      // top: { style: "thick", color: { argb: "074B8A" } },
      left: { style: "thick", color: { argb: "FFFFFF" } },
      bottom: { style: "thick", color: { argb: "074B8A" } },
      // right: { style: "thick", color: { argb: "074B8A" } },
    };
    first_row.alignment = { horizontal: "center" };

    // const result = toDataURL(User?.company_logo_url);
    // const result = User?.company_logo_url;

    // worksheet.addImage(imageId2, {
    //   tl: { col: 1.5, row: 1.5 },
    //   br: { col: 3.5, row: 5.5 }
    // });
    shipments?.forEach((shipment, id) => {
      sheet.addRow({
        sn: id + 1,
        id: `${shipment.id ?? "N/A"}`,
        shipment_type: `${shipment?.shipment_type ?? "N/A"}`,
        date_time: `${shipment?.date_time ?? "N/A"}`,
        sender_name: `${shipment?.sender_name ?? "N/A"}`,
        sender_phone_no: `${shipment?.sender_phone_no ?? "N/A"}`,
        pick_up_location: `${shipment?.pick_up_location ?? "N/A"}`,
        recipient_name: `${shipment?.recipient_name ?? "N/A"}`,
        recipient_phone_no: `${shipment?.recipient_phone_no ?? "N/A"}`,
        drop_off_location: `${shipment?.drop_off_location ?? "N/A"}`,
        item_name: `${shipment?.item_name ?? "N/A"}`,
        total_amount: `₦${shipment?.total_amount ?? "0"}`,
        payment_method: `${shipment?.payment_method ?? ""}`,
        paid_status: `${shipment?.paid_status ?? ""}`,
        status: `${shipment?.status ?? ""}`,
        weight: `${shipment?.weight ?? ""}`,
        shipment_tracking_number: `${
          shipment?.shipment_tracking_number ?? "N/A"
        }`,
        item_value: `${shipment?.item_value ?? ""}`,
        approved_by: `${shipment?.approved_by ?? ""}`,
        rider: `${shipment?.rider_name ?? ""}`,
        // pickup: `₦${shipment?.total_amount ?? "0"}`,
        // dropoff: `₦${shipment?.total_amount ?? "0"}`,
        carrier: `${shipment?.processor}`,
        insurance: shipment?.insurance,
        insurance_fee: shipment?.insurance_fee
      });
    });
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `Shipment_Report.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const exportToCSV = () => {
    if (shipments.length === 0) return;

    let csvContent = "data:text/csv;charset=utf-8,";

    csvContent +=
      "Shipment ID, Shipment Type, Shipment Date, Sender Name,Sender Phone Number,Pickup Address,Receiver Name,Receiver Phone Number,Dropoff Address,Amount,Weight,Shipment Tracking Number,Approved By,Payment Method, Payment Status, Shipment Status, Item Description, Item Value, Rider, Carrier, Insured, Insurance fee" +
      "\r\n";

    shipments.forEach(function (shipment) {
      let id = shipment.id;

      let senderDetails =
        shipment.sender_name +
        "," +
        shipment.sender_phone_no +
        "," +
        shipment.pick_up_location.replaceAll(",", "");
      let receiverDetails =
        shipment.recipient_name +
        "," +
        shipment.recipient_phone_no +
        "," +
        shipment.drop_off_location.replaceAll(",", "");
      let packageDetails =
        shipment.total_amount.replaceAll(",", "") + "," + shipment.weight ?? "";

      let track_number = shipment.shipment_tracking_number ?? "";
      let shipment_date = shipment?.date_time ?? "";
      let shipment_type = shipment.shipment_type ?? "";
      let approve = shipment.approved_by;
      let pay_method = shipment.payment_method;
      let pay_Status = shipment.paid_status;
      let ship_status = shipment.status;
      let desc = shipment.item_name ?? "";
      let value = shipment.item_value ?? "";
      let rider = shipment.rider_name??"";
      let carrier = shipment.processor ?? "";
      let insured = shipment?.insurance ?? "";
      let insurance_fee = shipment?.insurance_fee ?? "";

      const row =
        id +
        "," +
        shipment_type + 
        "," +
        shipment_date + 
        "," +
        senderDetails +
        "," +
        receiverDetails +
        "," +
        packageDetails +
        "," +
        track_number +
        "," +
        approve +
        "," +
        pay_method +
        "," +
        pay_Status +
        "," +
        ship_status +
        "," +
        desc +
        "," +
        value +
        "," +
        rider +
        "," +
        carrier +
        "," +
        insured +
        "," +
        insurance_fee;

      csvContent += row + "\r\n";
    });

    const encodedURI = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedURI);
    link.setAttribute("download", "shipments1.csv");
    document.body.appendChild(link);

    link.click();
  };

  const [reportStartDate, setReportStartDate] = useState("");
  const [reportEndDate, setReportEndDate] = useState("");

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <div className="d-flex flex-row justify-content-between mb-2 mx-3">
            <h5>Shipment Reports</h5>
            <span>Home</span>
          </div>
          <div className="container shipments-container">
            <div className="card bg-light border-0 shadow p-4 min-vh-100">
              <div className="d-flex gap-3 mb-3 mb-md-0 flex-column flex-md-row justify-content-between">
                <div className="d-flex flex-column flex-md-row align-items-md-end gap-3">
                  {/* <select onChange={onChangeReportType} style={{width:230, marginRight:10}} name="pickup_detail_id" className="form-select" >
                    <option value="">Choose report</option>
                    <option value="delivered shipments">Delivered Shipments</option>
                    <option value="incomplete shipments">Incomplete Shipments</option>
                    <option value="cancelled shipments">Cancelled Shipments</option>
                    <option value="unpaid shipments">Unpaid Shipments</option>
                  </select> */}

                  <select
                    onChange={onChangeReportTime}
                    // style={{ width: 200, marginRight: 10 }}
                    name="pickup_detail_id"
                    className="form-select input-query"
                  >
                    <option value="">Choose date</option>
                    <option value="Today">Today</option>
                    <option value="Yesterday">Yesterday</option>
                    <option value="Week">This Week</option>
                    <option value="Month">This Month</option>
                    {/* <option value="Year">This Year</option> */}
                    <option value="custom">Custom Date</option>
                  </select>

                  {reportTime === "custom" && (
                    <div className="d-md-flex d-grid col gap-3">
                      <div>
                        <label className="form-label" htmlFor="startdate">
                          Start Date
                        </label>
                        <input
                          type="date"
                          className="form-select input-query"
                          value={reportStartDate}
                          onChange={(e) => setReportStartDate(e.target.value)}
                        />
                      </div>
                      <div>
                        <label className="form-label" htmlFor="startdate">
                          End Date
                        </label>
                        <input
                          type="date"
                          className="form-select input-query"
                          value={reportEndDate}
                          onChange={(e) => setReportEndDate(e.target.value)}
                        />
                      </div>
                    </div>
                  )}

                  <button
                    onClick={fetchShipmentReport}
                    className="confirm-button"
                  >
                    {isLoading ? (
                      <LoadingSpinner className="spinner-border spinner-grow-sm" />
                    ) : "Generate"
                    // (
                    //   <BiIcons.BiSearch style={{ fontSize: 20 }} />
                    // )
                    }
                  </button>
                </div>

                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    EXPORT
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={exportToCSV}>
                      Export To CSV
                    </Dropdown.Item>
                    <Dropdown.Item onClick={exportToXlsx}>
                      Export To Spreadsheet
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="table-contain" >
                <table className="reports-table w-100">
                  <thead>
                    <tr>
                      <th scope="col">Sender</th>
                      <th scope="col">Dropoff Location</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Carrier</th>
                      <th scope="col">Tracking No.</th>
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  {isLoading === true ? null : (
                    <tbody>
                      {shipments.map((shipment) => {
                        const dateTime = moment(shipment.date_time);
                        const timestamp = dateTime.format("ddd MMM Do, h:mm A");

                        return (
                          <tr
                            key={shipment.id}
                            onClick={() => handleClick(shipment.id)}
                          >
                            <td>{shipment.sender_name}</td>
                            <td>{shipment.drop_off_location}</td>
                            <td>
                              ₦
                              {parseFloat(shipment.total_amount).toLocaleString(
                                "en"
                              )}
                            </td>
                            <td>{shipment.processor ?? "N/A"}</td>
                            <td>{shipment.shipment_tracking_number ?? "N/A"}</td>
                            <td>{timestamp}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
              {isRetrievingMore === false ||
              isOptionSelected === "" ||
              isLoading === true ? null : (
                <div style={{ textAlign: "center", marginTop: 40 }}>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />{" "}
                  Retrieving More..
                </div>
              )}
              {isOptionSelected !== "" ? null : (
                <LoadingContainer>
                  <div>
                    <img
                      style={{ width: 50, height: 50, marginBottom: 10 }}
                      src={dataIcon}
                      alt=""
                    />
                    <p>No option has been selected</p>
                  </div>
                </LoadingContainer>
              )}
              {isLoading === false ? null : (
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipmentReports;
