import "../styles/home.scss";
import "../styles/components.scss";
import "../styles/settings.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import * as React from "react";
import { useState } from "react";
import PersonalDetails from "./Widgets/Settings/PersonalDetails";
import CompanyDetails from "./Widgets/Settings/CompanyDetails";
import SubscriptionPlans from "./Widgets/Settings/SubscriptionPlans";
import NotificationSettings from "./Widgets/Settings/NotificationSettings";
import ShipmentSettings from "./Widgets/Settings/ShipmentSettings";
import PriceSettings from "./Widgets/Settings/PriceSettings";
import AccountSettings from "./Widgets/Settings/AccountSettings";
import SubscriptionInactive from "../components/SubscriptionInactive";
import { useSearchParams } from "react-router-dom";
import { SessionManagement } from "../library/SessionManagement";
import PaystackSettings from "./Widgets/Settings/PaystackSettings";
import StripeSettings from "./Widgets/Settings/StripeSettings";
import PersonAdd from '../assets/settings/person_add.svg';
// Icons
import AccountIcon from '../assets/settings/account_circle.svg';
import AccountActiveIcon from '../assets/settings/account_circle_active.svg';
import NotificationsIcon from '../assets/settings/notifications.svg';
import NotificationsActiveIcon from '../assets/settings/notifications_active.svg'
import AccountSettingIcon from '../assets/settings/manage_accounts.svg'
import AccountSettingActiveIcon from '../assets/settings/manage_accounts_active.svg'
import PriceIcon from '../assets/settings/credit_card_gear.svg'
import PriceActiveIcon from '../assets/settings/credit_card_gear_active.svg'
import PaymentIcon from '../assets/settings/credit_card.svg'
import PaymentActiveIcon from '../assets/settings/credit_card_active.svg'
import SubscriptionIcon from '../assets/settings/loyalty.svg'
import SubscriptionActiveIcon from '../assets/settings/loyalty_active.svg'

const Settings = () => {
  const [searchParam] = useSearchParams();

  const [activeTab, setActiveTab] = useState("AccountDetails");

  const pageParam = searchParam.get("page");

  React.useEffect(() => {
    if (pageParam === "subscriptions") {
      setActiveTab("SubscriptionPlans");
    }
  }, [pageParam]);

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />

          <div className="container-fluid">
            <div className="row mt-4">
              <div className="col-xl-12 col-md-12">
                <div
                  className="card  p-4 border-0"
                  style={{ backgroundColor: "#FFF" }}
                >
                  <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                    <div className="d-flex align-items-center flex-row gap-2 ">
                      <img src={PersonAdd} alt="personadd" />
                      <h5 className="m-0">Settings</h5>
                    </div>
                    <div className="d-flex flex-column flex-md-row gap-3">
                    </div>
                  </div>
                  <div className="card-body">
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflowX: "auto",
                      }}
                      className="settingsTab"
                    >
                      <span
                        onClick={(e) => setActiveTab("AccountDetails")}
                        className={`settings-profile-tab ${
                          activeTab === "AccountDetails"
                            ? "active-profile"
                            : null
                        }`}
                      >
                        {
                          activeTab === "AccountDetails" ? (
                            <img src={AccountActiveIcon} alt="icon" />
                          ) : <img src={AccountIcon} alt="icon" />
                        }
                        Account Details
                      </span>
                      
                      <span
                        onClick={(e) => setActiveTab("NotificationSettings")}
                        className={`settings-profile-tab ${
                          activeTab === "NotificationSettings"
                            ? "active-profile"
                            : null
                        }`}
                      >
                        {
                          activeTab === "NotificationSettings" ? (
                            <img src={NotificationsActiveIcon} alt="icon" />
                          ) : <img src={NotificationsIcon} alt="icon" />
                        }
                        Notifications
                      </span>
                      <span
                        onClick={(e) => setActiveTab("AccountSettings")}
                        className={`settings-profile-tab ${
                          activeTab === "AccountSettings"
                            ? "active-profile"
                            : null
                        }`}
                      >
                        {
                          activeTab === "AccountSettings" ? (
                            <img src={AccountSettingActiveIcon} alt="icon" />
                          ) : <img src={AccountSettingIcon} alt="icon" />
                        }
                        Account Settings
                      </span>
                      <span
                        onClick={(e) => setActiveTab("PriceSettings")}
                        className={`settings-profile-tab ${
                          activeTab === "PriceSettings"
                            ? "active-profile"
                            : null
                        }`}
                      >
                        {
                          activeTab === "PriceSettings" ? (
                            <img src={PriceActiveIcon} alt="icon" />
                          ) : <img src={PriceIcon} alt="icon" />
                        }
                        Shipment Settings
                      </span>
                      {SessionManagement.getEmployeeType() ===
                        "Super Admin" && (
                        <span
                          onClick={(e) => setActiveTab("PaymentSettings")}
                          className={`settings-profile-tab ${
                            activeTab === "PaymentSettings" ? "active-profile" : null
                          }`}
                        >
                          {
                          activeTab === "PaymentSettings" ? (
                            <img src={PaymentActiveIcon} alt="icon" />
                          ) : <img src={PaymentIcon} alt="icon" />
                        }
                          Payments
                        </span>
                      )}

                      <span
                        onClick={(e) => setActiveTab("SubscriptionPlans")}
                        className={`settings-profile-tab ${
                          activeTab === "SubscriptionPlans"
                            ? "active-profile"
                            : null
                        }`}
                      >
                        {
                          activeTab === "SubscriptionPlans" ? (
                            <img src={SubscriptionActiveIcon} alt="icon" />
                          ) : <img src={SubscriptionIcon} alt="icon" />
                        }
                        Subscription Plans
                      </span>
                    </div>
                    <div
                      style={{ minHeight: 500 }}
                      className={`${
                        activeTab === "AccountDetails"
                          ? "show-customer-tab"
                          : "hide-customer-tab"
                      }`}
                    >
                      <PersonalDetails />
                    </div>
                    <div
                      style={{ minHeight: 500 }}
                      className={`${
                        activeTab === "CompanyDetails"
                          ? "show-customer-tab"
                          : "hide-customer-tab"
                      }`}
                    >
                      <CompanyDetails />
                    </div>
                    <div
                      style={{ minHeight: 500 }}
                      className={`${
                        activeTab === "NotificationSettings"
                          ? "show-customer-tab"
                          : "hide-customer-tab"
                      }`}
                    >
                      <NotificationSettings />
                    </div>
                    <div
                      style={{ minHeight: 500 }}
                      className={`${
                        activeTab === "AccountSettings"
                          ? "show-customer-tab"
                          : "hide-customer-tab"
                      }`}
                    >
                      <AccountSettings />
                    </div>
                    <div
                      style={{ minHeight: 500 }}
                      className={`${
                        activeTab === "PriceSettings"
                          ? "show-customer-tab"
                          : "hide-customer-tab"
                      }`}
                    >
                      <PriceSettings />
                    </div>

                    <div
                      style={{ minHeight: 500 }}
                      className={`${
                        activeTab === "ShipmentSettings"
                          ? "show-customer-tab"
                          : "hide-customer-tab"
                      }`}
                    >
                      <ShipmentSettings />
                    </div>

                    <div
                      style={{ minHeight: 500 }}
                      className={`${
                        activeTab === "PaymentSettings"
                          ? "show-customer-tab"
                          : "hide-customer-tab"
                      }`}
                    >
                      <PaystackSettings />
                    </div>

                    <div
                      style={{ minHeight: 500 }}
                      className={`${
                        activeTab === "Stripe"
                          ? "show-customer-tab"
                          : "hide-customer-tab"
                      }`}
                    >
                      <StripeSettings />
                    </div>

                    <div
                      className={`${
                        activeTab === "SubscriptionPlans"
                          ? "show-customer-tab"
                          : "hide-customer-tab"
                      }`}
                    >
                      <SubscriptionPlans />
                    </div>
                  </div>
                </div>

                <div className="row  mt-3">
                  <div className="col-xl-6 col-md-3">
                    <div className="card bg-light border-0 shadow"></div>
                  </div>

                  <div className="col-xl-6 col-md-3">
                    <div className="card bg-light border-0 shadow"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
