import { Modal } from "react-bootstrap";
import axios from "axios";
import { approveShipmentURL, makeRegularUserURL, registerWhatsappPhoneURL } from "../../library/URLs";
import { useState } from "react";
import { UserProfileData } from "../../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import { deleteWhatsappTemplatesURL } from "../../library/URLs";
import { useNavigate } from "react-router-dom";
import CreatePhone from "./CreatePhone";
import ConfirmPhone from "./ConfirmPhone";
import VerifyPhone from "./VerifyPhone";
import Complete from "./Complete";
import { BsCheck } from "react-icons/bs";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const AddPhoneDialog = ({
    isModalVisible,
    handleCloseDialog,
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [submitted, setSubmitted] = useState([]);
    const navigator = useNavigate();

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [formData, setFormData] = useState({
        phone_number: "",
        display_name: "",
        country: "",
    });


    const handleSubmitted = (id) => {
        let submittedCopy = [...submitted];
        submittedCopy.push(id);
        setSubmitted(submittedCopy)
    }

    const completedFlow = () => {
        setFormData({
            phone_number: "",
            display_name: "",
            country: "",
        });
        setCurrentStep(1);
        setSubmitted([]);
        handleCloseDialog();
    };

    return (
        <Modal show={isModalVisible} onHide={handleCloseDialog} size="lg" >
            {/* <Modal.Header>
        <h5 className="header">DELETE WHATSAPP TEMPLATE</h5>
          <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
      </Modal.Header> */}
            <div className="Add-phone-modal" >
                <div className="Add-phone-menu d-none d-md-block" >
                    <h2>Add Phone Number</h2>
                    <ul className="Add-Phone" >
                        <li className={`Create-phone-menu-item ${currentStep === 1 ? "active-menu-item" : "inactive-menu-item"}`} >
                            <div className={submitted.includes(1) && "Submitted-menu"} >
                                {
                                    submitted.includes(1) && (
                                        <BsCheck />
                                    )
                                }
                            </div>
                            <p>Create the phone number</p>
                        </li>
                        <li className={`Create-phone-menu-item ${currentStep === 2 ? "active-menu-item" : "inactive-menu-item"}`} >
                            <div className={submitted.includes(2) && "Submitted-menu"} >
                                {
                                    submitted.includes(2) && (
                                        <BsCheck />
                                    )
                                }
                            </div>
                            <p>Confirm phone number</p>
                        </li>
                        <li className={`Create-phone-menu-item ${currentStep === 3 ? "active-menu-item" : "inactive-menu-item"}`} >
                            <div className={submitted.includes(3) && "Submitted-menu"} >
                                {
                                    submitted.includes(3) && (
                                        <BsCheck />
                                    )
                                }
                            </div>
                            <p>Verify phone number</p>
                        </li>
                        <li className={`Create-phone-menu-item ${currentStep === 4 ? "active-menu-item" : "inactive-menu-item"}`} >
                            <div className={submitted.includes(4) && "Submitted-menu"} >
                                {
                                    submitted.includes(4) && (
                                        <BsCheck />
                                    )
                                }
                            </div>
                            <p>Complete registration</p>
                        </li>
                    </ul>
                </div>
                <div className="Add-phone-forms" >
                    <h2 >
                        Create your WhatsApp business profile
                    </h2>
                    {
                        currentStep === 1 ? (
                            <CreatePhone setSubmitted={handleSubmitted} setCurrentStep={setCurrentStep} formData={formData} setFormData={setFormData} />
                        ) : currentStep === 2 ? (
                            <ConfirmPhone setSubmitted={handleSubmitted} setCurrentStep={setCurrentStep} formData={formData} />
                        ) : currentStep === 3 ? (
                            <VerifyPhone setSubmitted={handleSubmitted} setCurrentStep={setCurrentStep} formData={formData} />
                        ) : currentStep === 4 ? (
                            <Complete setSubmitted={handleSubmitted} setCurrentStep={setCurrentStep} formData={formData} handleCloseDialog={completedFlow} />
                        ) :
                            null
                    }
                </div>
            </div>
            {/* <Modal.Footer>
        <button className="cancel-button mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>
          <SubmitButton onClick={deleteWhatsappTemplate} type="submit" className="confirm-button">
              {isSubmitting === false ? (
                  <span className="button-text">DELETE TEMPLATE</span>
              ) : (
                  <span className="spinner-border spinner-grow-sm"/>
              )}
          </SubmitButton>
      </Modal.Footer> */}
        </Modal>
    );
};

export default AddPhoneDialog;
