import React from "react";
import { useNavigate } from "react-router-dom";

const RatesResult = ({ prevPage, carrierOptions }) => {
  const navigate = useNavigate();

  return (
    <div className="rates-result-wrap">
      <div className="rates-result-carriers">
        `
        <ul className=" carriers-list">
          {carrierOptions?.length < 1 ? (
            <li>No carrier available</li>
          ) : (
            carrierOptions?.map((carrier) => (
              <li key={carrier?.carrier_id}>
                <div className="carriers-list-option">
                  <div className="carriers-list-option-first-row">
                    <div className="carriers-list-option-logo">
                      <img
                        src={carrier.carrier_logo}
                        alt={carrier.carrier_name}
                      />
                    </div>
                    <div>
                      <p className="carriers-list-option-carrier-name">
                        {carrier.carrier_name}
                      </p>
                      <p className="carriers-list-option-transit">
                        Transit Time:{" "}
                        <span className="carriers-list-option-transit-time">
                          {carrier.delivery_time}{" "}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="carriers-list-option-price-label">PRICE</p>
                    <p className="carriers-list-option-price">
                      &#8358;{" "}
                      {carrier.selling_price?.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                </div>
              </li>
            ))
          )}
        </ul>
        <div className="d-flex mt-5 justify-content-end align-items-center gap-3">
          <button
            className="btn-primary"
            type="button"
            onClick={() => navigate("/create-shipment")}
          >
            Create New Shipment
          </button>
          <button className="btn-secondary" type="button" onClick={prevPage}>
            Calculate Estimate
          </button>
        </div>
      </div>
    </div>
  );
};

export default RatesResult;
