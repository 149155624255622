import { Modal } from "react-bootstrap";
import axios from "axios";
import {activateCarrierUrl} from "../library/URLs";
import {useState} from "react";
import {UserProfileData} from "../library/constants";
import styled from "styled-components";
import {BiX} from "react-icons/bi";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    border-color: #fcd2d2;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    border-color: #c2eddd;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const ActivateCarrierDialog = ({ isModalVisible, handleCloseDialog, carrierID, fetchCarriers }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const activateCarrier = (e) => {
        const formValues = {};
        formValues['carrier_id'] = carrierID;

        const newData = { ...formValues, ...UserProfileData() };

        setErrorVisibility(false);
        setIsSubmitting(true);

        axios
            .post(activateCarrierUrl, newData)
            .then(function (response) {
                setIsSubmitting(false);
                console.log(response);

                if (response["data"]["success"] === true) {
                    setSuccessVisibility(true);
                    setSuccessMessage("Carrier has been activated!");

                    setTimeout(() => {
                        setSuccessVisibility(false);
                        fetchCarriers();
                        handleCloseDialog();
                    }, 5000);
                } else {
                    setErrorMessage(response["data"]["message"]);
                    setErrorVisibility(true);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 3000);
                }
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">ACTIVATE CARRIER</h5>
          <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
      </Modal.Header>
      <Modal.Body>
          {
              isError === false ? null :
                  (<ErrorMessageContainer>
                      {errorMessage}
                  </ErrorMessageContainer>)
          }

          {
              isSuccess=== false ? null :
                  (<SuccessMessageContainer>
                      {successMessage}
                  </SuccessMessageContainer>)
          }

          <p>You are about to activate this carrier</p>
          <p>This means this carrier would be among the options available when booking a shipment.</p>
          <p>Would you like to continue?</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>
          <SubmitButton onClick={activateCarrier} type="submit" className="btn-primary">
              {isSubmitting === false ? (
                  <span className="button-text">Proceed</span>
              ) : (
                  <span className="spinner-border spinner-grow-sm"/>
              )}
          </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ActivateCarrierDialog;
