import React from "react";
import { Col, Row } from "react-bootstrap";
import { BsChevronLeft, BsChevronRight, BsQuestionCircleFill } from "react-icons/bs";
import styled from "styled-components";
import { CheckBox, RadioCheck } from "../../../components/InputFields";

const LogisticsWeb = ({ prevPage, answers, setAnswers }) => {
  const PageHeader = styled.h4`
    font-family: Prompt, sans-serif;
    font-size: 32px;
    line-height: 28.8px;
    margin-bottom: 8px;
    color: #000;

    @media (max-width: 556px) {
      font-size: 2.4rem;
    }
  `;

  const PageSubheader = styled.h4`
    font-size: 14px;
    color: #667185;
    margin-bottom: 48px;
    font-weight: normal;
  `;

  const SubLight = styled.span`
    color: #667185;
  `

  const answerQuestion = (option) => {
    if(answers?.logistics_web?.interest_services) {
      let interest_services = [...answers?.logistics_web?.interest_services];
      if(interest_services?.includes(option)) {
        let option_format = interest_services?.filter(opt => opt !== option);
        setAnswers({
          ...answers,
          logistics_web: {
            ...answers?.logistics_web,
            interest_services: option_format
          }
        })
      } else {
        interest_services.push(option);
        setAnswers({
          ...answers,
          logistics_web: {
            ...answers?.logistics_web,
            interest_services: interest_services
          }
        })
      }
    } else {
      setAnswers({
        ...answers,
        logistics_web: {
          ...answers?.logistics_web,
          interest_services: [option]
        }
      })
    }
  };

  const que2Answer = (answer) => {
    setAnswers({
      ...answers,
      logistics_web: {
        ...answers?.logistics_web,
        new_logistics: answer
      }
    })
  };

  const que3Answer = (e) => {
    const { value } = e.target;

    setAnswers({
      ...answers,
      logistics_web: {
        ...answers?.logistics_web,
        web_url: value
      }
    })
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("logistics web answers ", answers);
  };

  return (
    <>
      <PageHeader className="page-header feature-header">Operations - <SubLight>Logistics Website</SubLight></PageHeader>
      <PageSubheader>
        This information will be used to assist you in setting up your account and would never be shared
      </PageSubheader>
      <div className="progress-bar">
        <div className={`progress-bar-inactive progress-bar-active`}></div>
        <div className={`progress-bar-active`}></div>
      </div>
      <form>
        <div className="question-wrap mb-3" >
          <div className="question-text" >
            <BsQuestionCircleFill />
            What services are you interested in? Select all that apply
          </div>
          <ul className="multi-answer" >
            <li>
              <CheckBox 
                checked={answers?.logistics_web?.interest_services?.includes("Intracity services using bikes")}
                onChange={()=>answerQuestion("Intracity services using bikes")}
              />
              Intracity services using bikes
            </li>
            <li>
              <CheckBox 
                checked={answers?.logistics_web?.interest_services?.includes("Interstate deliveries using buses")}
                onChange={()=>answerQuestion("Interstate deliveries using buses")}
              />
              Interstate deliveries using buses
            </li>
            <li>
              <CheckBox 
                checked={answers?.logistics_web?.interest_services?.includes("Interstate deliveries using API e.g DHL")}
                onChange={()=>answerQuestion("Interstate deliveries using API e.g DHL")}
              />
              Interstate deliveries using API e.g DHL
            </li>
            <li>
              <CheckBox 
                checked={answers?.logistics_web?.interest_services?.includes("International deliveries using Cargo")}
                onChange={()=>answerQuestion("International deliveries using Cargo")}
              />
              International deliveries using Cargo
            </li>
            <li>
              <CheckBox 
                checked={answers?.logistics_web?.interest_services?.includes("International deliveries using API e.g. DHL, FedEx, UPS")}
                onChange={()=>answerQuestion("International deliveries using API e.g. DHL, FedEx, UPS")}
              />
              International deliveries using API e.g. DHL, FedEx, UPS
            </li>
          </ul>
        </div>
        <div className="question-wrap mb-3" >
          <div className="question-text" >
            <BsQuestionCircleFill />
            Do you already have a website?
          </div>
          <ul className="multi-answer mb-3" >
            <li>
              <RadioCheck 
                checked={answers?.logistics_web?.new_logistics === "Yes"}
                onChange={()=>que2Answer("Yes")}
              />
              Yes
            </li>
            <li>
              <RadioCheck 
                checked={answers?.logistics_web?.new_logistics === "No"}
                onChange={()=>que2Answer("No")}
              />
              No
            </li>
          </ul>
        </div>
        {
          answers?.logistics_web?.new_logistics === "Yes" && (
            <div className="question-wrap mb-3" >
              <div className="question-text" >
                <BsQuestionCircleFill />
                Enter the url of your ecommerce website
              </div>
              <input 
                type="text"
                name="web_url"
                value={answers?.logistics_web?.web_url}
                onChange={que3Answer}
                placeholder="Enter your answer"
                className="question-textbox"
              />
            </div>
          )
        }
        <div className="d-flex flex-row my-5 gap-4 justify-content-end">
          <button type="button" className="btn-secondary" onClick={prevPage} >
            <BsChevronLeft />
          </button>
          <button type="submit" onClick={handleSubmit} className="login-button btn-primary rounded">
            Proceed <BsChevronRight />
          </button>
        </div>

        {/* <PageSubheader>
                      Already have an account? <a href="/login">Login</a>
                    </PageSubheader> */}
      </form>
    </>
  );
};

export default LogisticsWeb;
