import { Modal } from "react-bootstrap";
import axios from "axios";
import {makeRegularUserURL} from "../library/URLs";
import {useState} from "react";
import {UserProfileData} from "../library/constants";
import styled from "styled-components";
import {BiX} from "react-icons/bi";
import {usePaystackPayment} from "react-paystack";
import {SessionManagement} from "../library/SessionManagement";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    border-color: #fcd2d2;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    border-color: #c2eddd;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const SubscribeToPlan = ({ isModalVisible, handleCloseDialog, planName, planCode, amount }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const config = {
        reference: (new Date()).getTime().toString(),
        email: SessionManagement.getEmail(),
        amount: amount,
        plan: planCode,
        publicKey: 'pk_live_9a4d639092733cf1df856176995f732738f7b1e2',
    };

    const onSuccess = (reference) => {
        // Implementation for whatever you want to do with reference and after success call.
        handleCloseDialog("SubscriptionSuccessful");
        console.log(reference);
    };

    // you can call this function anything
    const onClose = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        console.log('closed')
    }

    const PaystackHookExample = () => {
        const initializePayment = usePaystackPayment(config);
        return (
            <div>
                <button style={{width:250}} className="confirm-button" onClick={() => {
                    initializePayment(onSuccess, onClose)
                }}>MAKE PAYMENT</button>
            </div>
        );
    };

    const purchasePlan = (e) => {
        const formValues = {};
        // formValues['customer_id'] = customerID;

        const newData = { ...formValues, ...UserProfileData() };

        setErrorVisibility(false);
        setIsSubmitting(true);

        axios
            .post(makeRegularUserURL, newData)
            .then(function (response) {
                console.log(response);
                if (response["data"]["success"] === true) {
                    setSuccessVisibility(true);
                    setIsSubmitting(false);
                    setSuccessMessage("User is now a regular user");

                    setTimeout(() => {
                        setSuccessVisibility(false);
                        handleCloseDialog("Refresh");
                    }, 5000);
                } else {
                    setErrorMessage(response["data"]["message"]);
                    setErrorVisibility(true);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                }
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header" style={{textTransform:"uppercase"}}>PURCHASE  {planName} PLAN</h5>
          <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
      </Modal.Header>
      <Modal.Body>
          {
              isError === false ? null :
                  (<ErrorMessageContainer>
                      {errorMessage}
                  </ErrorMessageContainer>)
          }

          {
              isSuccess=== false ? null :
                  (<SuccessMessageContainer>
                      {successMessage}
                  </SuccessMessageContainer>)
          }

          <p>You are about to make this user a regular user</p>
          <p>This means the user would no longer enjoy any pricing benefit.</p>
          <p>Would you like to continue?</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={(e) => handleCloseDialog("ewooo...")}>
          CANCEL
        </button>
          <PaystackHookExample />
          {/*<SubmitButton onClick={ContactPaystack} type="submit" className="confirm-button">*/}
          {/*    {isSubmitting === false ? (*/}
          {/*        <span className="button-text">MAKE PAYMENT</span>*/}
          {/*    ) : (*/}
          {/*        <span className="spinner-border spinner-grow-sm"/>*/}
          {/*    )}*/}
          {/*</SubmitButton>*/}
      </Modal.Footer>
    </Modal>
  );
};

export default SubscribeToPlan;
