import * as React from "react";
import {useEffect, useState} from "react";
import {UserProfileData} from "../../../library/constants";
import axios from "axios";
import {
    getCustomerKYCDocUrl
} from "../../../library/URLs";
import { FaRegFile } from "react-icons/fa";
import styled from "styled-components";


const LoadingContainer = styled.div`
  height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const EmptyContainer = styled.div`
  height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomerKycDocs = ({customerID}) => {
    const [kycDocs, setKycDocs] = useState(null);
   

    const [isLoading, setIsLoading] = useState(true);

    const data = {
        customer_id: customerID,
        limit: 20,
        offset: 0,
        status: "All",
    };

    const newData = { ...data, ...UserProfileData() };

    useEffect(() => {
        setIsLoading(true);
        axios
            .post(getCustomerKYCDocUrl, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                setIsLoading(false);
                if (response["data"]["success"] === false) return;
                console.log(response["data"]);
                setKycDocs(response["data"]["data"]);
                
            })
            .catch(() => {setIsLoading(false)})
    }, []);


    return (
        <>
            <div className="card bg-light border-0 shadow mt-4">
                <div className="card-body">
                    <span className="header">Customer KYC Documents</span>

                    {isLoading === false ? null : (
                        <LoadingContainer>
                            <LoadingSpinner className="spinner-border spinner-grow-sm"/>
                        </LoadingContainer>
                    )}

                    {isLoading === false && kycDocs ?
                        <div className="documents-contain">
                            {
                                kycDocs?.nin !== "" && (
                                    <a href={kycDocs?.nin} target="_blank" rel="noreferrer" >
                                        <div className="document-card" >
                                            <FaRegFile />
                                            <div>
                                                <p className="m-0" >Nin</p>
                                            </div>
                                        </div>
                                    </a>
                                )
                            }
                            {
                                kycDocs?.cac_url !== "" && (
                                    <a href={kycDocs?.cac_url} target="_blank" rel="noreferrer" >
                                        <div className="document-card" >
                                            <FaRegFile />
                                            <div>
                                                <p className="m-0" >CAC</p>
                                            </div>
                                        </div>
                                    </a>
                                )
                            }
                            {
                                kycDocs?.utility_bill_url !== "" && (
                                    <a href={kycDocs?.utility_bill_url} target="_blank" rel="noreferrer" >
                                        <div className="document-card" >
                                            <FaRegFile />
                                            <div>
                                                <p className="m-0" >Utility Bill</p>
                                            </div>
                                        </div>
                                    </a>
                                )
                            }
                            {
                                kycDocs?.identification_url !== "" && (
                                    <a href={kycDocs?.identification_url} target="_blank" rel="noreferrer" >
                                        <div className="document-card" >
                                            <FaRegFile />
                                            <div>
                                                <p className="m-0" >Identification</p>
                                            </div>
                                        </div>
                                    </a>
                                )
                            }
                        </div>
                        :
                        <EmptyContainer>
                            <p>Oops... Sorry, customer has no document uploaded</p>
                        </EmptyContainer>
                    }
                </div>
            </div>
        </>
    );
}

export default CustomerKycDocs;