import React, { useEffect, useState } from "react";
import { getUPSAccountsUrl } from "../../../../library/URLs";
import axios from "axios";
import { UserProfileData } from "../../../../library/constants";
import { TbPencil, TbTrash } from "react-icons/tb";
import { Tooltip } from "@mui/material";
import styled from "styled-components";
import { BsCheckCircleFill, BsPlusCircleFill } from "react-icons/bs";
import AddUPSAccountDialog from "../../../../modals/AddUPSAccountDialog";
import EditUPSAccountDialog from "../../../../modals/EditUPSAccountDialog";
import DeleteUPSAccountDialog from "../../../../modals/DeleteUPSAccountDialog";

const LoadingContainer = styled.div`
  height: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const Ups = ({ opened }) => {
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addUPSAccount, setAddUPSAccount] = useState(false);
  const [editUPSAccount, setEditUPSAccount] = useState(null);
  const [deleteUPSAccount, setDeleteUPSAccount] = useState(null);

  const openAddUPSAccount = () => {
    setAddUPSAccount(true);
  };

  const closeAddUPSAccount = () => {
    setAddUPSAccount(false);
  };

  const openEditUPSAccount = (account) => {
    setEditUPSAccount(account);
  };

  const closeEditUPSAccount = () => {
    setEditUPSAccount(null);
  };

  const openDeleteUPSAccount = (id) => {
    setDeleteUPSAccount(id);
  };

  const closeDeleteUPSAccount = () => {
    setDeleteUPSAccount(null);
  };

  const fetchAccounts = async () => {
    setIsLoading(true);
    await axios
      .post(getUPSAccountsUrl, UserProfileData())
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.success) {
          let ups_accounts = res?.data?.data;

          setAccounts(ups_accounts);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (opened) {
      fetchAccounts();
    }
  }, [opened]);

  return (
    <div>
      <AddUPSAccountDialog 
        isModalVisible={addUPSAccount}
        handleCloseDialog={closeAddUPSAccount}
        onSubmit={fetchAccounts}
      />
      <EditUPSAccountDialog 
        isModalVisible={editUPSAccount !== null}
        handleCloseDialog={closeEditUPSAccount}
        onSubmit={fetchAccounts}
        editAccount={editUPSAccount}
      />
      <DeleteUPSAccountDialog 
        isModalVisible={deleteUPSAccount !== null}
        handleCloseDialog={closeDeleteUPSAccount}
        onSubmit={fetchAccounts}
        accountID={deleteUPSAccount}
      />
      {isLoading ? (
        <LoadingContainer>
          <LoadingSpinner className="spinner-border spinner-grow-sm" />
        </LoadingContainer>
      ) : (
        <ul className="p-0" style={{listStyle: "none"}} >
          {accounts?.length < 1 ? (
            <li className="mb-4" >
              No account available
            </li>
          ) : accounts?.map((ups) => (
            <li
              key={ups?.id}
              className="d-flex justify-content-between mb-4 align-items-center"
            >
              <label className="form-check-label d-flex gap-4 align-items-center" htmlFor="">
                {ups?.name}
                {
                  ups?.is_primary_account === "Yes" && (
                    <BsCheckCircleFill style={{color: "#0F973D", fontSize: 12}} />
                  )
                }
              </label>
              <div className="d-flex gap-4 align-items-center">
                <Tooltip title="Edit Account" placement="top">
                  <span className="d-flex align-items-start">
                    <TbPencil
                      style={{ fontSize: 18, cursor: "pointer" }}
                      onClick={() => openEditUPSAccount(ups)}
                    />
                  </span>
                </Tooltip>
                <Tooltip title="Delete Account" placement="top">
                  <span className="d-flex align-items-start">
                    <TbTrash
                      style={{ fontSize: 18, cursor: "pointer" }}
                      onClick={() => openDeleteUPSAccount(ups?.id)}
                    />
                  </span>
                </Tooltip>
              </div>
            </li>
          ))}
          <button
            className="mt-2 add-carrier"
            type="button"
            onClick={openAddUPSAccount}
          >
            <BsPlusCircleFill /> Add Account
          </button>
        </ul>
      )}
    </div>
  );
};

export default Ups;
