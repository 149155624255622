import "../styles/home.scss";
import "../styles/shipments.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserProfileData } from "../library/constants";
import { getAllBatchUrl, searchBatchUrl } from "../library/URLs";
import axios from "axios";
import styled from "styled-components";
import * as React from "react";
import moment from "moment";
import SubscriptionInactive from "../components/SubscriptionInactive";

function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
}

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const Batches = () => {
    const form = useRef(null);
    const navigator = useNavigate();
    const params = useParams();

    const [chosenPaymentStatus, setChosenPaymentStatus] = useState("");
    const [chosenDate, setChosenDate] = useState("");

    const [isLoading, setIsLoading] = useState(true);
    const [currentOffsetDate, setCurrentOffsetDate] = useState("");
    const [currentOffsetID, setCurrentOffsetID] = useState(0);
    const [isRetrievingMore, setIsRetrievingMore] = useState(false);

    const [resultContainerVisibility, setResultContainerVisibility] = useState(false);
    const [customerQueryResults, setCustomerQueryResults] = useState([]);
    const [isOptionSelected, setIsOptionSelected] = useState("");

    const [shipments, setShipments] = useState([]);

    const [isQuerySearching, setIsQuerySearching] = useState(false);

    const data = {
        limit: 20,
        offset_date: currentOffsetDate,
        offset_id: currentOffsetID,
        payment_status: chosenPaymentStatus,
        date: chosenDate
    };

    const newData = { ...data, ...UserProfileData() };
    console.log(newData);

    useEffect(() => {
        axios
            .post(getAllBatchUrl, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                if (isLoading === false && isRetrievingMore === false) {
                    console.log("Should not retrieve");
                    return;
                }
                console.log(response["data"]);

                setIsRetrievingMore(false)
                setIsLoading(false);

                if (response["data"]["success"] === false) return;

                const newShipments = response["data"]["data"];

                if (newShipments.length > 0) {
                    const offsetID = newShipments[newShipments.length - 1].id;
                    const offsetDate = newShipments[newShipments.length - 1].date_time;

                    setCurrentOffsetID(offsetID);
                    setCurrentOffsetDate(offsetDate);
                }

                if (currentOffsetID === 0) {
                    setShipments(newShipments);
                } else {
                    setShipments([...shipments, ...newShipments]);
                }
            });
    }, [isRetrievingMore, isOptionSelected]);

    const handleClick = (shipmentID) => {
        navigator("/shipments/batches/" + shipmentID);
    };

    const setScroll = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && isRetrievingMore === false) {
            if (isRetrievingMore === false) {
                console.log("It's receiving");
                setIsRetrievingMore(true);
            } else {
                console.log("It's not receiving");
            }
        }
    };

    React.useEffect(() => {
        window.addEventListener("scroll", setScroll);
        return () => {
            window.removeEventListener("scroll", setScroll);
        };
    }, []);

    const searchForCustomers = (e) => {
        e.preventDefault();
    }

    const handleQueryChange = () => {
        const formData = new FormData(form.current);
        const query = formData.get("query");

        if (query.toString().trim() === "") {
            setIsQuerySearching(false);
            setResultContainerVisibility(false);

            return null;
        }

        const formValues = {}
        formValues["query"] = query;
        formValues["limit"] = "5";

        const newData = { ...formValues, ...UserProfileData() };

        setIsQuerySearching(true);
        setResultContainerVisibility(true);

        axios
            .post(searchBatchUrl, newData)
            .then(function (response) {
                console.log(response);

                if (response["data"]["success"] === false) return;

                const customers = response["data"]["data"];

                setCustomerQueryResults(customers);
            });
    }

    return (
        <div className="home">
            <Sidebar />
            <div className="main">
                <Navbar />
                <div className="page pb-5">
                    <SubscriptionInactive />

                    <div className="d-flex flex-row justify-content-between mb-2 mx-3">
                        <h5>Shipment Batches</h5>
                        <span>Home</span>
                    </div>
                    <div className="container-fluid shipments-container">
                
                        <div className="card bg-light border-0 shadow p-4 min-vh-100">

                            <div className="d-flex flex-column flex-md-row gap-3 justify-content-between">
                                <div className="d-flex flex align-items-center" style={{ width: "70%" }}>
                                    <form className="query-result-container" style={{ width: "50%" }} ref={form}
                                        onSubmit={searchForCustomers}>
                                        <input onChange={handleQueryChange} name="query" className="form-control"
                                            type="text"
                                            placeholder="Search using batch number" />
                                        <div
                                            className={`query-result ${resultContainerVisibility === true ? "result-visible" : null}`}>
                                            {setIsQuerySearching === true ? null :
                                                customerQueryResults.map((customer) => {
                                                    return (
                                                        <div key={customer.id} className="d-flex flex-column"
                                                            onClick={() => handleClick(customer.batch_number)}>
                                                            <span className="d-flex align-items-center">
                                                                {/*<span style={{fontSize: 13}}>{customer.sender_name}</span>*/}
                                                                {/*<BsDot/>*/}
                                                                <span style={{ fontSize: 13 }}>{customer.batch_number}</span>
                                                            </span>
                                                            {/* <span style={{ fontSize: 15 }}>{customer.drop_off_location}</span> */}
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </form>

                                   
                                </div>

                                
                            </div>

                            <div className="table-contain w-100">
                                <table className="shipments-table w-100">
                                    <thead>
                                        <tr>
                                            <th scope="col">Batch Number</th>
                                            <th scope="col">Origin</th>
                                            <th scope="col">Destination</th>
                                            <th scope="col">Date</th>
                                        </tr>
                                    </thead>
                                    {isLoading === true ? null : (
                                        <tbody>
                                            {shipments.map((shipment) => {
                                                const dateTime = moment(shipment.creation_date);
                                                const timestamp = dateTime.format('ddd MMM Do, h:mm A');


                                                return (
                                                    <tr key={shipment.id}>
                                                        <td onClick={() => handleClick(shipment.id)}>{shipment.batch_number}</td>
                                                        <td onClick={() => handleClick(shipment.id)}>{shipment.origin}</td>
                                                        <td onClick={() => handleClick(shipment.id)}>{shipment.destination}</td>
                                                        <td onClick={() => handleClick(shipment.id)}>{timestamp}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                            {isRetrievingMore === false ? null :
                                <div style={{ textAlign: "center", marginTop: 40 }}>
                                    <LoadingSpinner className="spinner-border spinner-grow-sm" /> Retrieving More..
                                </div>
                            }

                            {isLoading === false ? null : (
                                <LoadingContainer>
                                    <LoadingSpinner className="spinner-border spinner-grow-sm" />
                                </LoadingContainer>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Batches;
