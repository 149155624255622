import React, { useEffect, useState } from "react";
import PriceIcon from "../../../../assets/settings/price_settings/credit_card_gear.svg";
import { Switch } from "@mui/material";
import { styled as mui_styled } from "@mui/material/styles";
import {
  getPaymentMethodsUrl,
  setPaymentMethodsUrl,
} from "../../../../library/URLs";
import axios from "axios";
import { UserProfileData } from "../../../../library/constants";
import { toast } from "react-toastify";

const ParcelflowSwitch = mui_styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 34,
  height: 16,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#15AB68",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 12,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#98A2B3",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const PaymentMethods = () => {
  const [paymentMethods, setPaymentMethods] = useState({});
  const [statusPending, setStatusPending] = useState(null);

  const fetchAllPaymentMethods = async () => {
    await axios
      .post(getPaymentMethodsUrl, UserProfileData())
      .then((res) => {
        if (res?.data?.success) {
          let payment_methods = res?.data?.data;
          setPaymentMethods(payment_methods);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchAllPaymentMethods();
    // eslint-disable-next-line
  }, []);

  const updateMethod = async (key, value) => {
    const formData = {
      ...UserProfileData(),
      payment_key: key,
      payment_value: value,
    };

    setStatusPending(key);

    await axios
      .post(setPaymentMethodsUrl, formData)
      .then((res) => {
        setStatusPending(null);
        if (res?.data?.success) {
          setPaymentMethods({
            ...paymentMethods,
            [key]: value,
          });
          toast.success("Payment method updated successfully.");
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {});
  };

  const updatePayment = (key, status) => {
    let payment_value = status === "Yes" ? "No" : "Yes";
    updateMethod(key, payment_value);
  };

  return (
    <div>
      <div className="settings_section d-flex flex-xl-row flex-column align-items-start">
        <div className="d-flex align-items-center justify-content-between price-settings-left">
          <div className="d-flex gap-3 align-items-start">
            <img src={PriceIcon} alt="price" className="mt-2" />
            <div>
              <p style={{ fontSize: 22, marginBottom: 8, fontWeight: "bold" }}>
                Payment Methods
              </p>
              <span>
                Customize what payment methods are available when booking
                shipments.
              </span>
            </div>
          </div>
        </div>
        <div className=" price-settings-right">
          <div className=" col-12">
            <div className="d-flex flex-row justify-content-between align-items-center py-3 px-4">
              <div className="d-flex flex-column">
                <span style={{ fontSize: 18, color: "#2C3E50" }}>Wallet</span>
                <span style={{ fontSize: 14 }}>
                  Payment via customer's virtual wallet
                </span>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                {statusPending === "wallet" ? (
                  <div className="pe-4">
                    <span className="spinner-border spinner-grow-sm" />
                  </div>
                ) : (
                  <ParcelflowSwitch
                    defaultValue="0"
                    name="wallet"
                    onChange={() =>
                      updatePayment("wallet", paymentMethods?.wallet)
                    }
                    checked={paymentMethods?.wallet === "Yes"}
                    className="react-switch"
                  />
                )}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center py-3 px-4">
              <div className="d-flex flex-column">
                <span style={{ fontSize: 18, color: "#2C3E50" }}>Cash</span>
                <span style={{ fontSize: 14 }}>Direct cash payment</span>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                {statusPending === "cash" ? (
                  <div className="pe-4">
                    <span className="spinner-border spinner-grow-sm" />
                  </div>
                ) : (
                  <ParcelflowSwitch
                    defaultValue="0"
                    name="cash"
                    onChange={() => updatePayment("cash", paymentMethods?.cash)}
                    checked={paymentMethods?.cash === "Yes"}
                    className="react-switch"
                  />
                )}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center py-3 px-4">
              <div className="d-flex flex-column">
                <span style={{ fontSize: 18, color: "#2C3E50" }}>
                  Bank Transfer
                </span>
                <span style={{ fontSize: 14 }}>
                  Payment via direct bank transfer
                </span>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                {statusPending === "bank_transfer" ? (
                  <div className="pe-4">
                    <span className="spinner-border spinner-grow-sm" />
                  </div>
                ) : (
                  <ParcelflowSwitch
                    defaultValue="0"
                    name="bank_transfer"
                    onChange={() =>
                      updatePayment(
                        "bank_transfer",
                        paymentMethods?.bank_transfer
                      )
                    }
                    checked={paymentMethods?.bank_transfer === "Yes"}
                    className="react-switch"
                  />
                )}
              </div>
            </div>

            <div className="d-flex flex-row justify-content-between align-items-center py-3 px-4">
              <div className="d-flex flex-column">
                <span style={{ fontSize: 18, color: "#2C3E50" }}>
                  Card Payment
                </span>
                <span style={{ fontSize: 14 }}>Payment via card payment</span>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                {statusPending === "card_payment" ? (
                  <div className="pe-4">
                    <span className="spinner-border spinner-grow-sm" />
                  </div>
                ) : (
                  <ParcelflowSwitch
                    defaultValue="0"
                    name="card_payment"
                    onChange={() =>
                      updatePayment(
                        "card_payment",
                        paymentMethods?.card_payment
                      )
                    }
                    checked={paymentMethods?.card_payment === "Yes"}
                    className="react-switch"
                  />
                )}
              </div>
            </div>
            {/* <div className="d-flex flex-row justify-content-between align-items-center py-3 px-4">
              <div className="d-flex flex-column">
                <span style={{ fontSize: 18, color: "#2C3E50" }}>Paystack</span>
                <span style={{ fontSize: 14 }}>Payment via paystack</span>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                <ParcelflowSwitch
                  defaultValue="0"
                  name="create_employee_shipment"
                  // onChange={() =>
                  //     updateNotificationSettings(
                  //         "create_employee_shipment",
                  //         handleNotificationStatus(!createShipment),
                  //         1
                  //     )
                  // }
                  // checked={createShipment}
                  className="react-switch"
                />
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center py-3 px-4">
              <div className="d-flex flex-column">
                <span style={{ fontSize: 18, color: "#2C3E50" }}>Stripe</span>
                <span style={{ fontSize: 14 }}>Payment via stripe</span>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                <ParcelflowSwitch
                  defaultValue="0"
                  name="create_employee_shipment"
                  // onChange={() =>
                  //     updateNotificationSettings(
                  //         "create_employee_shipment",
                  //         handleNotificationStatus(!createShipment),
                  //         1
                  //     )
                  // }
                  // checked={createShipment}
                  className="react-switch"
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethods;
