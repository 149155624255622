import { Col, Modal, Row } from "react-bootstrap";
import { createRef, useRef, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { changePasswordURL, createWhatsappAccountURL, topupUserWalletURL } from "../library/URLs";
import { UserProfileData } from "../library/constants";
import { BiX } from "react-icons/bi";
import * as React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const SubmitButton = styled.button`
  width: 250px;
  background-color: #1877f2; 
  border: 0; 
  border-radius: 4px; 
  color: #fff; 
  cursor: pointer; 
  font-family: Helvetica, Arial, sans-serif; 
  font-size: 16px; 
  font-weight: bold; 
  height: 40px; 
  padding: 0 24px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const LoadingContainer = styled.div`
  height: 365px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

const SetupWhatsappModal = ({ isModalVisible, handleCloseDialog }) => {
    const form = useRef(null);
    const urlPromoRef = createRef();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const changePassword = () => {
        const formValues = {};

        const data = new FormData(form.current);

        for (const [key, value] of data.entries()) {
            formValues[key] = value;
        }

        const newData = { ...formValues, ...UserProfileData() };

        setErrorVisibility(false);
        setIsSubmitting(true);

        console.log(formValues);

        axios
            .post(changePasswordURL, newData, {
                method: "POST",
            })
            .then(function (response) {
                console.log(response);
                setIsSubmitting(false);

                if (response["data"]["success"] === false) {
                    window.scrollTo(0, 0);
                    setErrorVisibility(true);
                    setErrorMessage(response["data"]["message"]);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                } else if (response["data"]["success"] === true) {
                    setSuccessVisibility(true);
                    setSuccessMessage(
                        "User has successfully been set to a contract user"
                    );

                    setTimeout(() => {
                        setSuccessVisibility(false);
                        handleCloseDialog();
                    }, 3000);
                }
            })
            .catch(function (error) {
                setIsSubmitting(false);
                return error;
            });
    };

    const onChangeImage = () => { }; 

    const createWhatsappAccount = (code) => {

        const newData = { ...UserProfileData(), access_token: code };

        console.log("creating whatsapp");

        axios
            .post(createWhatsappAccountURL, newData)
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                return error;
            });
    };

    // Facebook Login with JavaScript SDK
    function launchWhatsAppSignup() {
        // Launch Facebook login
        window.FB.login(
            function (response) {
                console.log("powa", response);
                if (response.authResponse) {
                    console.log("powlaww", response);
                    createWhatsappAccount(response?.authResponse?.code)
                    console.log("powla", response?.authResponse?.code);
                    const accessToken = response.authResponse.code;
                    //Use this token to call the debug_token API and get the shared WABA's ID
                } else {
                    console.log("User cancelled login or did not fully authorize.");
                }
            },
            {
                config_id: "1529219351201353", // configuration ID obtained in the previous step goes here
                response_type: "code", // must be set to 'code' for System User access token
                override_default_response_type: true,
                extras: {
                    setup: {},
                },
            }
        );
    }

    return (
        <Modal show={isModalVisible} onHide={handleCloseDialog}>
            <Modal.Header>
                <h5 style={{ marginTop: 10 }} className="header">
                    SETUP WHATSAPP
                </h5>
                <BiX
                    style={{ fontSize: 25, cursor: "pointer" }}
                    onClick={handleCloseDialog}
                />
            </Modal.Header>
            <Modal.Body>
                {isError === false ? null : (
                    <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
                )}

                {isSuccess === false ? null : (
                    <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
                )}

                <form ref={form} onSubmit={changePassword}>
                    <div>
                        {/* <div>
                            <label htmlFor="Subject">Phone Number To Be Used</label>
                            <input
                                name="current-password"
                                className="form-control"
                                type="text"
                            />
                        </div> */}

                        {/* <div className="mt-2">
                            <label htmlFor="Amount">Name of Contact Person</label>
                            <input name="new-password" className="form-control" type="text" />
                        </div> */}

                        {/* <div className="mt-2">
                            <label htmlFor="Amount">Phone Number of Contact Person</label>
                            <input
                                name="confirm-password"
                                className="form-control"
                                type="text"
                            />
                        </div> */}

                        <div
                            className="d-flex flex-row justify-content-center"
                            style={{ marginTop: 10 }}
                        >
                            <span style={{ fontSize: 16, textAlign: "center" }}>
                                By clicking the “Setup Whatsapp button, you agree to
                                Parcelflows's
                                <a style={{ textDecoration: "none" }} href="" target="_blank">
                                    {" "}
                                    terms of acceptable use
                                </a>{" "}
                                as regards Whatsapp.
                            </span>
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className="cancel-button mx-3" onClick={handleCloseDialog}>
                    CANCEL
                </button>
                <SubmitButton
                    onClick={launchWhatsAppSignup}
                    type="submit"
                    className="confirm-button"
                >
                    {isSubmitting === false ? (
                        <span className="button-text">LOGIN WITH FACEBOOK</span>
                    ) : (
                        <span className="spinner-border spinner-grow-sm" />
                    )}
                </SubmitButton>
            </Modal.Footer>
        </Modal>
    );
};

export default SetupWhatsappModal;
