import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { addShipmentsToBatchUrl, searchBatchUrl } from "../library/URLs";
import { useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const AddBatchDialog = ({
    isModalVisible,
    handleCloseDialog,
    shipments,
    onSubmit,
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [addToExistingBatch, setAddToExistingBatch] = useState(false);
    const [existingBatch, setExistingBatch] = useState("");
    const [location, setLocation] = useState({
        origin: "",
        destination: "",
    });
    const [suggestedBatches, setSuggestedBatches] = useState([]);
    const [suggestionsVisibility, setSuggestionsVisibility] = useState(false);
    const [suggestionClick, setSuggestionClick] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const searchBatch = (value) => {
        const newData = { ...UserProfileData(), query: value };
        axios
            .post(searchBatchUrl, newData)
            .then(function (response) {
                console.log(response);
                if (response["data"]["success"] === true) {
                    setSuggestedBatches(response["data"]["data"]);
                    setSuggestionsVisibility(true);
                } else {
                    setErrorMessage(response["data"]["message"]);
                    setErrorVisibility(true);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                }
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };

    const addShipmentsToBatch = (e) => {
        e.preventDefault();
        const formValues = {
            ...location,
            batch_number: existingBatch,
            shipment_numbers: shipments,
        };
        // formValues['customer_id'] = adminID;

        const newData = { ...formValues, ...UserProfileData() };

        setErrorVisibility(false);
        setIsSubmitting(true);

        axios
            .post(addShipmentsToBatchUrl, newData)
            .then(function (response) {
                console.log(response);
                if (response["data"]["success"] === true) {
                    setSuccessVisibility(true);
                    setIsSubmitting(false);
                    setSuccessMessage(response["data"]["data"]["message"]);

                    setTimeout(() => {
                        setSuccessVisibility(false);
                        setLocation({
                            origin: "",
                            destination: "",
                        });
                        setAddToExistingBatch(false);
                        setExistingBatch("");
                        setSuggestionsVisibility(false);
                        onSubmit();
                        handleCloseDialog();
                    }, 5000);
                } else {
                    setErrorMessage(response["data"]["message"]);
                    setErrorVisibility(true);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                }
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };

    const handleInput = (e) => {
        const { value, name } = e.target;

        setLocation({
            ...location,
            [name]: value,
        });
    };

    const handleExistingBatch = (e) => {
        const { value } = e.target;
        setSuggestionClick(false);

        setExistingBatch(value);

        // if batch number is empty or starts with a space, close suggestions
        if (value.toString().trim() === "") {
            setSuggestionsVisibility(false);

            return null;
        }

        searchBatch(value);
    };

    const handleClick = (id) => {
        setExistingBatch(id);
        setSuggestionsVisibility(false);
        setSuggestionClick(true);
    };

    return (
        <Modal show={isModalVisible} onHide={handleCloseDialog}>
            <Modal.Header>
                <h5 className="header">ADD SHIPMENTS TO BATCH</h5>
                <BiX
                    style={{ fontSize: 25, cursor: "pointer" }}
                    onClick={handleCloseDialog}
                />
            </Modal.Header>
            <Modal.Body>
                {isError === false ? null : (
                    <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
                )}

                {isSuccess === false ? null : (
                    <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
                )}

                <p>You selected {shipments?.length} shipments to be added to a batch</p>
                <div className="query-result-container">
                    <div className="d-flex justify-content-between">
                        <p>Add to existing batch</p>
                        <input
                            type="checkbox"
                            name="addToExistingBatch"
                            value={addToExistingBatch}
                            onClick={() => setAddToExistingBatch(!addToExistingBatch)}
                            id="addToExistingBatch"
                        />
                    </div>
                    {addToExistingBatch && (
                        <div>
                            <div>
                                <label htmlFor="Description">Batch Number</label>
                                <input
                                    type="text"
                                    name="existingBatch"
                                    id="existingBatch"
                                    className="form-control"
                                    value={existingBatch}
                                    onChange={handleExistingBatch}
                                />
                            </div>
                            <div
                                className={`query-result 
                                ${suggestionsVisibility === true && existingBatch !== "" && !suggestionClick
                                        ? "result-visible"
                                        : null
                                    }`}
                            >
                                {suggestedBatches.map((batch) => {
                                    return (
                                        <div
                                            key={batch.id}
                                            className="d-flex flex-column"
                                            onClick={() => handleClick(batch.batch_number)}
                                        >
                                            <span className="d-flex align-items-center">
                                                {/*<span style={{fontSize: 13}}>{batch.sender_name}</span>*/}
                                                {/*<BsDot/>*/}
                                                <span style={{ fontSize: 13 }}>
                                                    {batch?.batch_number}
                                                </span>
                                            </span>
                                            {/* <span style={{ fontSize: 15 }}>{customer?.drop_off_location}</span> */}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    <Row className="gap-3 mt-3 gap-md-0">
                        <Col className=" col-12 col-md-6">
                            <label htmlFor="Description">Origin</label>
                            <input
                                name="origin"
                                className="form-control"
                                value={location.origin}
                                onChange={handleInput}
                            />
                        </Col>
                        <Col className=" col-12 col-md-6">
                            <label htmlFor="Description">Destination</label>
                            <input
                                name="destination"
                                className="form-control"
                                value={location.destination}
                                onChange={handleInput}
                            />
                        </Col>
                    </Row>
                </div>
                <p className="mt-3">Would you like to continue?</p>
            </Modal.Body>
            <Modal.Footer>
                <button className="cancel-button mx-3" onClick={handleCloseDialog}>
                    CANCEL
                </button>
                <SubmitButton
                    onClick={addShipmentsToBatch}
                    type="submit"
                    className="confirm-button"
                >
                    {isSubmitting === false ? (
                        <span className="button-text">ADD SHIPMENT(S)</span>
                    ) : (
                        <span className="spinner-border spinner-grow-sm" />
                    )}
                </SubmitButton>
            </Modal.Footer>
        </Modal>
    );
};

export default AddBatchDialog;
