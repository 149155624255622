import * as BoxIcons from "react-icons/bi";
import * as React from "react";
import { SessionManagement } from "../../../library/SessionManagement";
import { getCompanySettingsURL } from "../../../library/URLs";
import SubscribeToPlan from "../../../modals/SubscribeToPlanDialog";
import { useState } from "react";
import SubscriptionSuccessful from "../../../modals/SubscriptionSuccessfulDialog";
import SubscriptionSuccessfulDialog from "../../../modals/SubscriptionSuccessfulDialog";
import { URLConnection } from "../../../library/URLConnection";
import { UserProfileData } from "../../../library/constants";
import WebMobile from "./Subs/WebMobile";
import WhatsappOnly from "./Subs/WhatsappOnly";
import CurrencyIcon from "../../../assets/settings/payments/price_change.svg";

const SubscriptionPlans = () => {
    const [isSubscriptionPlanDialogVisible, setIsSubscriptionPlanDialogVisible] =
        useState(false);
    const [
        isSubscriptionSuccessfulDialogVisible,
        setIsSubscriptionSuccessfulDialogVisible,
    ] = useState(false);
    const [selectedPlanName, setSelectedPlanName] = useState("");
    const [selectedPlanCode, setSelectedPlanCode] = useState("");
    const [selectedAmount, setSelectedAmount] = useState(100);
    const [togglePlan, setTogglePlan] = useState("Web-Mobile");

    React.useEffect(() => {
        URLConnection(
            getCompanySettingsURL,
            UserProfileData(),
            function (response) {
                if (response["data"]["success"] === false) return;

                const currentPlan = response["data"]["data"]["current_plan"];

                SessionManagement.setCurrentPlan(currentPlan);
            }
        );
    }, []);

    const handleCloseModal = (refresh) => {
        setIsSubscriptionPlanDialogVisible(false);

        if (refresh === "SubscriptionSuccessful") {
            SessionManagement.setCurrentPlan(selectedPlanName);
            setIsSubscriptionSuccessfulDialogVisible(true);
            window.location.reload();
        }
    };

    const closeSubscriptionSuccessfulDialog = () => {
        setIsSubscriptionSuccessfulDialogVisible(false);
    };

    const showSubscriptionPlanDialog = (planName, planCode, amount) => {
        setSelectedPlanName(planName);
        setSelectedAmount(amount);
        setSelectedPlanCode(planCode);
        setIsSubscriptionPlanDialogVisible(true);
    };

    const handleTogglePlan = () => {
        if (togglePlan === "Web-Mobile") {
            setTogglePlan("WhatsappOnly");
        } else {
            setTogglePlan("Web-Mobile");
        }
    };

    return (
        <>
            <SubscribeToPlan
                isModalVisible={isSubscriptionPlanDialogVisible}
                handleCloseDialog={handleCloseModal}
                planName={selectedPlanName}
                planCode={selectedPlanCode}
                amount={selectedAmount}
            />

            <SubscriptionSuccessfulDialog
                isModalVisible={isSubscriptionSuccessfulDialogVisible}
                handleCloseDialog={closeSubscriptionSuccessfulDialog}
            />

            <div className="settings_section">
                <div className="d-flex align-items-center justify-content-between" >
                    <div className="d-flex gap-3 align-items-start" >
                        <img src={CurrencyIcon} alt="currency" className="mt-2" />
                        <div>
                            <p style={{fontSize:22, marginBottom:8, fontWeight:"bold"}}>Subscription Plans</p>
                            <span>Simple pricing. No hidden fees.<br /> Advanced features for your business</span>
                        </div>
                    </div>
                </div>
                {/* <div className="row mb-5 w-100 mt-40 px-4">
                    <div className="d-flex justify-content-center p-40-24 border-top border-bottom">
                        <div
                            className="toggle-contain col-xl-4 col-sm-12 ms-3 p-1 d-flex"
                            onClick={handleTogglePlan}
                        >
                            <div className={`w-50 h-100 toggle-text d-flex align-items-center justify-content-center ${togglePlan === "Web-Mobile" && "active"}`}>
                                Web and Mobile
                            </div>
                            <div className={`w-50 h-100 toggle-text d-flex align-items-center justify-content-center ${togglePlan === "WhatsappOnly" && "active"}`}>
                                Whatsapp Only
                            </div>
                            <div
                                className={` w-50 h-100 position-absolute top-0 p-1 toggle-bar-contain
                                ${togglePlan === "Web-Mobile" ? "start-0" : "start-50"
                                    }`}
                            >
                                <div className="toggle-bar h-100 w-100"></div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="px-2" >
                    {
                        togglePlan === "Web-Mobile" ? (
                            <WebMobile showSubscriptionPlanDialog={showSubscriptionPlanDialog} />
                        ) : (
                            <WhatsappOnly showSubscriptionPlanDialog={showSubscriptionPlanDialog} />
                        )
                    }
                </div>
            </div>
        </>
    );
};

export default SubscriptionPlans;
