import "../../styles/home.scss";
import "../../styles/components.scss";
import "../../styles/whatsapp-settings.scss";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import * as React from "react";
import {useState} from "react";
import GeneralSettings from "../Widgets/Whatsapp/GeneralSettings";
import PhoneNumbers from "../Widgets/Whatsapp/PhoneNumbers";
import SubscriptionInactive from "../../components/SubscriptionInactive";




const WhatsappSettings = () => {
    const [activeTab, setActiveTab] = useState("GeneralSettings");

    return (
        <div className="home">
            <Sidebar />
            <div className="main">
                <Navbar />
                <div className="page pb-5">
                    <SubscriptionInactive />

                    <div className="d-flex flex-row justify-content-between mb-2 mx-3">
                        <h5 className="header">Settings</h5>
                        <span>Whatsapp</span>
                    </div>

                    <div className="container-fluid">
                        <div className="row mt-4">
                            <div className="col-xl-12 col-md-12">
                                <div className="card bg-light border-0 shadow">
                                    <div className="card-body">
                                        <div>
                                        <span style={{paddingLeft:0}} onClick={(e)=> setActiveTab("GeneralSettings")}
                                              className={`profile-tab ${activeTab === "GeneralSettings" ? "active-profile" : null}`}>
                                            General Settings
                                        </span>
                                        <span onClick={(e)=> setActiveTab("PhoneNumbers")}
                                                  className={`profile-tab ${activeTab === "PhoneNumbers" ? "active-profile" : null}`}>
                                            Phone Numbers
                                        </span>


                                        </div>
                                        <div style={{minHeight:500}} className={`${activeTab === "GeneralSettings" ? "show-customer-tab" : "hide-customer-tab"}`}>
                                            <GeneralSettings />
                                        </div>
                                        <div style={{minHeight:500}} className={`${activeTab === "PhoneNumbers" ? "show-customer-tab" : "hide-customer-tab"}`}>
                                            <PhoneNumbers/>
                                        </div>
                                    </div>
                                </div>

                                <div className="row  mt-3">
                                    <div className="col-xl-6 col-md-3">
                                        <div className="card bg-light border-0 shadow">

                                        </div>
                                    </div>

                                    <div className="col-xl-6 col-md-3">
                                        <div className="card bg-light border-0 shadow">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhatsappSettings;
