import React, { useState } from "react";
import ConfirmIcon from "../../../assets/create-shipment/payment-page.svg";
import { HiOutlineSwitchVertical } from "react-icons/hi";
import { toast } from "react-toastify";
import axios from "axios";
import { createLocalShipmentUrl } from "../../../library/URLs";
import moment from "moment";
import { UserProfileData } from "../../../library/constants";
import { useNavigate } from "react-router-dom";
import { SessionManagement } from "../../../library/SessionManagement";

const Confirm = ({ previousPage, formData }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!term) {
      toast.error("Click the checkbox to confirm your shipment!");
      return;
    }

    let data = {
      sender_details: {
        ...formData?.sender_details,
        email: formData.sender_details.email
          ? formData.sender_details.email
          : SessionManagement.getEmail(),
      },
      receiver_details: {
        ...formData?.receiver_details,
        email: formData?.receiver_details?.email
          ? formData?.receiver_details?.email
          : SessionManagement.getEmail(),
      },
      payment_method: formData?.payment_method,
      amount: formData?.amount,
      transporter: formData?.transporter,
      description: formData?.description,
      insurance: "No",
      insurance_fee: "0",
      vehicle_type: formData?.vehicle_type,
      insurance_type: "",
      shipment_plan: formData?.shipment_plan,
      shipping_date: moment(formData?.shipping_date).format("MM/DD/YYYY"),
    };

    setIsSubmitting(true);

    const newData = { ...data, ...UserProfileData() };
    await axios
      .post(createLocalShipmentUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsSubmitting(false);

        if (response["data"]["success"]) {
          let shipmentID = response["data"]["data"]["shipment-id"];
          window.scrollTo(0, 0);

          toast.success(response["data"]["data"]["message"]);
          setTerm(false);
          setTimeout(() => {
            navigate("/shipment/" + shipmentID);
          }, 2000);
        } else {
          toast.error(response["data"]["message"]);
        }
      });
    console.log("book ", formData);
  };
  const [term, setTerm] = useState(false);

  return (
    <div className="create-shipment-grid">
      <div className="create-shipment-info">
        <img src={ConfirmIcon} alt="icon" />
        <div>
          <div className="create-shipment-info-top">
            <p className="create-shipment-info-title">Confirm Details</p>
            <p className="create-shipment-info-sub">
              Confirm details accuracy before proceeding
            </p>
          </div>
        </div>
      </div>
      <div className="create-shipment-info-form">
        <div className="confirm-details-wrapper">
          <div className="confirm-details">
            <p className="confirm-details-title">Shipment Summary</p>
            <div className="confirm-details-address">
              {/* Sender Info */}
              <div className="confirm-details-address-option">
                <p className="confirm-details-address-option-text-1">
                  From (sender details)
                </p>
                <div>
                  <p className="confirm-details-address-option-text-2">
                    {formData.sender_details.fullname}{" "}
                  </p>
                  <p className="confirm-details-address-option-text-1">
                    {formData.sender_details.address}{" "}
                  </p>
                </div>
              </div>
              {/* Receiver Info */}
              <div className="confirm-details-address-option">
                <p className="confirm-details-address-option-text-1">
                  To (receiver details)
                </p>
                <div>
                  <p className="confirm-details-address-option-text-2">
                    {formData.receiver_details.fullname}{" "}
                  </p>
                  <p className="confirm-details-address-option-text-1">
                    {formData.receiver_details.address}{" "}
                  </p>
                </div>
              </div>
              <div className="confirm-details-address-icon">
                <HiOutlineSwitchVertical />
              </div>
            </div>
            <div>
              <div className="confirm-details-payment">
                <div className="confirm-details-payment-option">
                  <p className="confirm-details-payment-option-text-1">
                    Payment Method
                  </p>
                  <p className="confirm-details-payment-option-text-2">
                    {formData.payment_method}
                  </p>
                </div>
                <div className="confirm-details-payment-option">
                  <p className="confirm-details-payment-option-text-1">
                    Transporter
                  </p>
                  <p className="confirm-details-payment-option-text-2">
                    {
                      formData?.transporters?.find(
                        (transporter) =>
                          transporter.value === formData.transporter
                      )?.label
                    }
                  </p>
                </div>
              </div>
              <div className="confirm-summary">
                <p className="confirm-summary-title">Package Summary</p>
                <div className="confirm-summary-cost">
                  <p className="confirm-summary-cost-text-1">Description</p>
                  <div>
                    <p className="confirm-summary-cost-text-2">
                      {formData?.description}
                    </p>
                  </div>
                </div>
                <div className="confirm-summary-cost">
                  <p className="confirm-summary-cost-text-1">Shipment Cost</p>
                  <div>
                    <p className="confirm-summary-cost-text-2">
                      &#8358;{" "}
                      {parseFloat(formData?.amount)?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                    <p className="confirm-summary-cost-text-3">Inc. VAT</p>
                  </div>
                </div>
                <div className="confirm-summary-term">
                  <input
                    type="checkbox"
                    checked={term}
                    onChange={() => setTerm(!term)}
                    name=""
                    id=""
                  />
                  <p>
                    I accept that the declaration above is a proper and accurate
                    description of the contents of my package.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end gap-2">
            <button
              className="create-shipment-info-form-btn-secondary"
              onClick={previousPage}
            >
              Previous
            </button>
            <button
              className="create-shipment-info-form-btn"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <span className="spinner-border spinner-grow-sm" />
              ) : (
                "Process Shipment"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
