import { Col, Row } from "react-bootstrap";
import * as React from "react";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { UserProfileData } from "../../../library/constants";
import axios from "axios";
import {
    updatePaystackDetailsUrl,
    getPaystackDetailsUrl,
} from "../../../library/URLs";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import PaystackPasswordModal from "../../../modals/PaystackPassword";
import { TbEdit, TbPencil, TbPencilOff } from "react-icons/tb";
import CardIcon from "../../../assets/settings/payments/credit_card.svg";
import { TextBox } from "../../../components/InputFields";
import StripeSettings from "./StripeSettings";

const SubmitButton = styled.button`
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #fcd2d2;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #c2eddd;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
`;

const PaystackSettings = () => {
    const [paystackInfo, setPaystackInfo] = useState({
        paystack_public_key: "",
        paystack_secret_key: "",
    });
    const [showSecretKey, setShowSecretKey] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [isUpdating, setIsUpdating] = useState(false);

    const [isEditable, setIsEditable] = useState(false);

    const [changePasswordDialogVisibility, setChangePasswordDialogVisibility] =
        useState(false);

    useEffect(() => {
        getPaystackDetails();
        // eslint-disable-next-line
    }, []);

    const getPaystackDetails = () => {
        const newData = { ...UserProfileData() };

        console.log(newData);

        axios
            .post(getPaystackDetailsUrl, newData)
            .then(function (response) {
                if (response?.data?.success) {
                    let response_data = response?.data?.data;
                    setPaystackInfo({
                        ...paystackInfo,
                        paystack_public_key: response_data?.paystack_public_key,
                        paystack_secret_key: response_data?.paystack_secret_key,
                    });
                }
            })
            .catch(function (error) {
                return error;
            });
    };

    const handleCloseModal = () => {
        setChangePasswordDialogVisibility(false);
    };

    const form = useRef(null);

    const updatePaystackDetails = (e) => {
        e.preventDefault();

        setErrorVisibility(false);

        const formValues = { ...paystackInfo };

        const newData = { ...formValues, ...UserProfileData() };

        console.log(newData);

        setIsUpdating(true);

        axios
            .post(updatePaystackDetailsUrl, newData)
            .then(function (response) {
                console.log(response);
                setIsUpdating(false);

                if (response["data"]["success"] === false) {
                    window.scrollTo(0, 0);
                    setErrorVisibility(true);
                    setErrorMessage(response["data"]["message"]);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                } else if (response["data"]["success"] === true) {
                    window.scrollTo(0, 0);

                    setSuccessVisibility(true);
                    setSuccessMessage("Paystack Keys updated successfully.");

                    setTimeout(() => {
                        setSuccessVisibility(false);
                    }, 3000);
                    setTimeout(() => {
                        window.location.reload();
                    }, 4000);
                }
            })
            .catch(function (error) {
                return error;
            });
    };

    const handleInput = (e) => {
        const { name, value } = e.target;

        setPaystackInfo({
            ...paystackInfo,
            [name]: value,
        });
    };

    return (
        <>
            {isError === false ? null : (
                <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
            )}

            {isSuccess === false ? null : (
                <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
            )}

            <PaystackPasswordModal
                isModalVisible={changePasswordDialogVisibility}
                handleCloseDialog={handleCloseModal}
                setIsEditable={setIsEditable}
            />
            <div className="settings_section d-flex flex-xl-row flex-column align-items-start" >
                <div className="d-flex align-items-center justify-content-between price-settings-left" >
                    <div className="d-flex gap-3 align-items-start" >
                        <img src={CardIcon} alt="card" className="mt-2" />
                        <div>
                            <p style={{ fontSize: 22, marginBottom: 8, fontWeight: "bold" }}>Paystack Settings</p>
                            <span>Edit your company paystack keys to manage customer payments</span>
                        </div>
                    </div>
                </div>
                <form ref={form} className="price-settings-right" style={{backgroundColor:"transparent"}} >
                    <div className="row">
                        <div className="col-xl-12 col-md-12 col-sm-12 col">
                            {/* <Row className="mb-5">
                                <Col className="form-group col-xl-3 col-md-3">
                                    <SubmitButton
                                        type="button"
                                        onClick={
                                            isEditable ? ()=>setIsEditable(false) :
                                            () => setChangePasswordDialogVisibility(!changePasswordDialogVisibility)
                                        }
                                        className="confirm-button"
                                    >
                                        <span className="button-text">Edit{" "} {
                                            isEditable ? (
                                                <TbPencilOff />
                                            ) : (
                                                <TbPencil />
                                            )
                                        }</span>
                                    </SubmitButton>
                                </Col>
                            </Row> */}
                            <Row className="mb-4">
                                <Col className="form-group col-xl-12 col-md-12">
                                    <label htmlFor="Business name">Public API Key</label>
                                    <TextBox 
                                        name="paystack_public_key"
                                        type="text"
                                        value={paystackInfo.paystack_public_key}
                                        onChange={handleInput}
                                        placeholder="pk_live_*************"
                                        disabled={!isEditable}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="form-group col-xl-12 col-md-12 position-relative">
                                    <label htmlFor="Business name">Private API Key</label>
                                    <TextBox
                                        name="paystack_secret_key"
                                        type={showSecretKey ? "text" : "password"}
                                        value={paystackInfo.paystack_secret_key}
                                        onChange={handleInput}
                                        placeholder="sk_live_****************"
                                        disabled={!isEditable}
                                    />
                                    {showSecretKey ? (
                                        <BsEyeFill
                                            className="position-absolute"
                                            style={{ cursor: "pointer", right: "2rem", top: "55%" }}
                                            onClick={() => setShowSecretKey(!showSecretKey)}
                                        />
                                    ) : (
                                        <BsEyeSlashFill
                                            className="position-absolute"
                                            style={{ cursor: "pointer", right: "2rem", top: "55%" }}
                                            onClick={() => setShowSecretKey(!showSecretKey)}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </div>
                        <div className="col-xl-4 col-md-6 col-sm-12 col"></div>

                        <div className="d-flex flex-row my-5 gap-2 justify-content-end">
                            {
                                !isEditable && (
                                    <div
                                        style={{ marginTop: 20 }}
                                        onClick={
                                            isEditable ? ()=>setIsEditable(false) :
                                            () => setChangePasswordDialogVisibility(!changePasswordDialogVisibility)
                                        }
                                        className=" settings_btn"
                                    >
                                        {" "}
                                        <TbEdit /> Edit Details
                                    </div>
                                )
                            }
                            {
                                isEditable && (
                                    <div
                                        onClick={() => setIsEditable(false)}
                                        className=" settings_btn"
                                    >
                                        {" "}
                                        Cancel
                                    </div>
                                )
                            }
                            {
                                isEditable && (
                                    <SubmitButton
                                        disabled={isUpdating}
                                        type="submit"
                                        onClick={updatePaystackDetails}
                                        className="btn-primary"
                                    >
                                        {isUpdating === false ? (
                                            <span className="button-text">Update API Keys</span>
                                        ) : (
                                            <span className="spinner-border spinner-grow-sm" />
                                        )}
                                    </SubmitButton>
                                )
                            }
                            
                        </div>
                    </div>
                </form>
            </div>
            <StripeSettings />
        </>
    );
};

export default PaystackSettings;
