import "../styles/home.scss";
import Search from "../assets/Search.svg";
import { useState } from "react";
import { BsCheck, BsChevronDown, BsChevronUp, BsEye, BsEyeSlash } from "react-icons/bs";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useRef } from "react";
import Select from "react-select";

export const SearchBox = ({ value, name="", onChange, placeholder="" }) => {
  return (
    <div className="search-input-contain" >
      <input 
        name={name}
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="search-input"
      />
      <div className="search-icon" >
        <img src={Search} alt="search" />
      </div>
    </div>
  )
}

export const TextBox = ({ value, name="", onChange, placeholder="", type="text", prefix="", defaultValue, disabled=false, ...props }) => {
  return (
    <div className="text-input-contain" >
      {
        prefix &&
        <p className="text-prefix" >{prefix}</p>
      }
      <input 
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        className={`text-input ${prefix && "prefix-input"}`}
        disabled={disabled}
        {...props}
      />
    </div>
  )
};

export const PasswordBox = ({ value, name="", onChange, placeholder="", defaultValue, disabled=false }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="text-input-contain" >
      <input 
        name={name}
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        className={`text-input`}
        disabled={disabled}
      />
      {
        showPassword ? (
          <BsEyeSlash onClick={()=>setShowPassword(false)} className="password-icon" />
        ) : (
          <BsEye onClick={()=>setShowPassword(true)} className="password-icon" />
        )
      }
    </div>
  )
}

export const SelectBox = ({ value, name="", onChange, placeholder="", defaultValue, disabled=false, options=[] }) => {

  return (
    <div className="text-input-contain" >
      <select 
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        className={`text-input`}
        disabled={disabled}
      >
        <option value="" hidden>{placeholder}</option>
        {
          options.map((opt) => (
            <option value={opt.value} key={opt.value}>
              {opt.label}
            </option>
          ))
        }
      </select>
    </div>
  )
};



export const TextFieldBox = ({ value, name="", onChange, placeholder="", defaultValue, disabled=false }) => {
  return (
    <div className="text-input-contain" >
      <textarea 
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        className={`text-input`}
        disabled={disabled}
        rows={3}
      ></textarea>
    </div>
  )
}

export const CheckBox = ({onChange, checked}) => {
  return (
    <div>
      <div onClick={onChange} className={`input-check ${checked && "input-check-checked"}`} >
        {
          checked && (
            <BsCheck />
          )
        }
      </div>
    </div>
  )
}

export const RadioCheck = ({onChange, checked}) => {
  return (
    <div>
      <div onClick={onChange} className={`radio-check`} >
        {
          checked && (
            <div className="radio-check-checked" ></div>
          )
        }
      </div>
    </div>
  )
};

export const PhoneFieldBox = ({ value, onChange, disabled=false, defaultCountry="NG" }) => {
  return (
    <div className="text-input-contain" >
      <PhoneInput
        defaultCountry={defaultCountry}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  )
};

export const SelectTextBox = ({ value, name="", onChange, placeholder="", options=[], disabled=false }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const listRef = useRef();

  const selectOption = (name, value) => {
    onChange(name, value);
    setShowDropdown(false);
  }

  return (
    <div 
      className="select-text-input-contain" 
    >
      {showDropdown && (
        <div
          className={`select-text-close`}
          onClick={(e) => {
            e.stopPropagation();
            setShowDropdown(false);
          }}
        ></div>
      )}
      <div className={`select-text-input-contain ${showDropdown && "select-text-input-contain-index"}`} >
        <input 
          name={name}
          type="text"
          value={value}
          onChange={(e) => onChange(name, e.target.value)}
          placeholder={placeholder}
          className={`text-input`}
          onFocus={()=>setShowDropdown(true)}
          disabled={disabled}
        />
        {
          showDropdown ? (
            <BsChevronUp className="select-text-arrow" />
          ) : (
            <BsChevronDown className="select-text-arrow" />
          )
        }
        {
          showDropdown && !disabled && (
            <div ref={listRef} className="select-text-dropdown" >
              <ul className="select-text-dropdown-list" >
                {
                  options?.filter((opt) => opt?.value?.toLowerCase()?.includes(value?.toLowerCase()))?.map((opt) => (
                    <li key={opt?.value} onClick={()=>selectOption(name, opt?.value)} >
                      {opt?.label}
                    </li>
                  ))
                }
              </ul>
            </div>
          )
        }
      </div>
    </div>
  )
};

export const SelectTextBox2 = ({ value, name="", onChange, placeholder="", options=[], disabled=false, id }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const listRef = useRef();

  const selectOption = (id, name, value) => {
    onChange(id, name, value);
    setShowDropdown(false);
  }

  return (
    <div 
      className="select-text-input-contain" 
    >
      {showDropdown && (
        <div
          className={`select-text-close`}
          onClick={(e) => {
            e.stopPropagation();
            setShowDropdown(false);
          }}
        ></div>
      )}
      <div className={`select-text-input-contain ${showDropdown && "select-text-input-contain-index"}`} >
        <input 
          name={name}
          type="text"
          value={value}
          onChange={(e) => onChange(name, e.target.value)}
          placeholder={placeholder}
          className={`text-input`}
          onFocus={()=>setShowDropdown(true)}
          disabled={disabled}
        />
        {
          showDropdown ? (
            <BsChevronUp className="select-text-arrow" />
          ) : (
            <BsChevronDown className="select-text-arrow" />
          )
        }
        {
          showDropdown && !disabled && (
            <div ref={listRef} className="select-text-dropdown" >
              <ul className="select-text-dropdown-list" >
                {
                  options?.filter((opt) => opt?.value?.toLowerCase()?.includes(value?.toLowerCase()))?.map((opt) => (
                    <li key={opt?.value} onClick={()=>selectOption(id, name, opt?.value)} >
                      {opt?.label}
                    </li>
                  ))
                }
              </ul>
            </div>
          )
        }
      </div>
    </div>
  )
};

export const MultiSelectBox = ({ value, name="", onChange, placeholder="", options=[], disabled=false }) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      borderRadius: '10px',
      border: '1px solid #D0D5DD',
      fontSize: "14px",
      padding: "6px 20px;"
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'lightgray' : 'white',
      color: 'black',
      padding: 10,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black',
    }),
  };

  return (
    <div className="text-input-contain" >
      <Select
        styles={customStyles}
        value={value}
        isMulti
        name={name}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        isDisabled={disabled}
        className="basic-multi-select"
        classNamePrefix="select"
      />
    </div>
  )
};