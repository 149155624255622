import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import SubscriptionInactive from "../components/SubscriptionInactive";
import HeaderIcon from "../assets/calculate-estimates/corporate_fare.svg";
import "../styles/calculate-estimate.scss";
import EstimateForm from "./CalculateEstimates/EstimateForm";
import RatesResult from "./CalculateEstimates/RatesResult";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { calculateEstimatesUrl } from "../library/URLs";
import { UserProfileData } from "../library/constants";
import axios from "axios";
import { toast } from "react-toastify";

const CalculateEstimates = () => {
  const navigate = useNavigate();
  const [ratePage, setRatePage] = useState(0);
  const [isPending, setIsPending] = useState(false);
  const [carrierOptions, setCarrierOptions] = useState([]);
  const [formData, setFormData] = useState({
    sender: {
      country: "Nigeria",
      state: "",
      city: "",
      postcode: "",
    },
    receiver: {
      country: "",
      state: "",
      city: "",
      postcode: "",
    },
    package_details: {
      length: "",
      breadth: "",
      height: "",
      item_value: "",
      parcel_type: "Package",
      insurance: "No",
    },
    weight: "",
  });

  const [defaultSenderInfo, setDefaultSenderInfo] = useState(true);
  const [defaultReceiverInfo, setDefaultReceiverInfo] = useState(true);
  const [defaultPackageInfo, setDefaultPackageInfo] = useState(true);

  const calculateEstimates = async () => {
    let shipment_type =
      formData.sender.country !== "Nigeria" ||
      formData.receiver.country !== "Nigeria"
        ? formData.sender.country === "Nigeria"
          ? "International"
          : "Import"
        : "Interstate";

    const data = {
      ...formData,
      shipment_type
    };

    const newData = { ...data, ...UserProfileData() };
    setIsPending(true);
    await axios
      .post(calculateEstimatesUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsPending(false);

        if (response["data"]["success"] === false) {
          toast.error(response["data"]["message"]);
          return;
        };

        let carriers = response?.data?.data?.carriers;
        setCarrierOptions(carriers);
        setRatePage(1);
        window.scrollTo(0, 0);
      });
  };

  const nextPage = () => {
    calculateEstimates();
    // setRatePage(1);
  };

  const prevPage = () => {
    setRatePage(0);
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />
          <div className="container-fluid shipments-container">
            <div className="navigation-contain">
              <button className="back-btn" onClick={() => navigate(-1)}>
                <BsChevronLeft />
              </button>
              <ul className="nav-options">
                <li>Shipments</li>
                <li className="nav-options-arrow">
                  <BsChevronRight />{" "}
                </li>
                <li className="nav-options-active">
                  Calculate Shipment Estimate
                </li>
              </ul>
            </div>
            <div
              className="card border-0 p-4 min-vh-100"
              style={{ backgroundColor: "#FFF" }}
            >
              <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  <img src={HeaderIcon} alt="icon" />
                  <h5 className="m-0">Calculate Shipment Estimate</h5>
                </div>
                <div className="d-flex flex-column flex-md-row gap-3"></div>
              </div>
              <div className="estimate-wrap">
                <div className="estimate-info">
                  <img
                    src={HeaderIcon}
                    style={{ width: 20, height: 18 }}
                    alt="icon"
                  />
                  <div>
                    <div className="estimate-info-top">
                      <p className="estimate-info-title">Calculate Estimate</p>
                      <p className="estimate-info-sub">
                        Get an estimated
                        <br /> price for shipments.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="estimate-wrap-form">
                  {ratePage === 0 ? (
                    <EstimateForm
                      nextPage={nextPage}
                      formData={formData}
                      setFormData={setFormData}
                      isPending={isPending}
                      defaultSenderInfo={defaultSenderInfo}
                      setDefaultSenderInfo={setDefaultSenderInfo}
                      defaultReceiverInfo={defaultReceiverInfo}
                      setDefaultReceiverInfo={setDefaultReceiverInfo}
                      defaultPackageInfo={defaultPackageInfo}
                      setDefaultPackageInfo={setDefaultPackageInfo}
                    />
                  ) : (
                    <RatesResult prevPage={prevPage} carrierOptions={carrierOptions} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculateEstimates;
