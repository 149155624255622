import "../../styles/login.scss";
import styled from "styled-components";
import { loginURL, verifyOTPURL } from "../../library/URLs";
import { useRef, useState } from "react";
import axios from "axios";
import { ErrorNotification } from "../../components/Notifications";
import { useNavigate } from "react-router-dom";
import { generateTransactionID } from "../../library/utilities";
import { SessionManagement } from "../../library/SessionManagement";
import OtpInput from "react-otp-input";

const PageHeader = styled.h4`
  font-family: Prompt, sans-serif;
  font-size: 55px;
  text-align: center;
  margin-bottom: 10px;
  color: #0b613a;

  @media(max-width:556px) {
    font-size: 2.4rem;
  }
`;

const PageSubheader = styled.h4`
  font-size: 17px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: normal;
`;

const PageFooter = styled.h4`
  font-size: 17px;
  text-align: center;
  margin-bottom: 20px;
`;

const Verify = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [otp, setOtp] = useState("");

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const form = useRef(null);

  const clearErrorMessage = () => {
    setErrorVisibility(false);
    setErrorMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    clearErrorMessage();
    const formValues = {};

    // for (let [key, value] of data.entries()) {
    //   formValues[key] = value;
    // }

    formValues["device_id"] = "value";
    formValues["device_name"] = "WEB";
    formValues["token"] = "value";
    formValues["transaction_id"] = generateTransactionID(15);

    setIsSubmitting(true);
    setIsLoading(true);
    const company_info = JSON.parse(localStorage.getItem("register_company"));

    axios
      .post(verifyOTPURL, {
        ...formValues,
        code: otp,
        first_name: company_info?.first_name,
        last_name: company_info?.last_name,
        email: company_info?.email,
        password: company_info?.password,
      })
      .then(function (response) {
        console.log(response);
        setIsLoading(false);
        setIsSubmitting(false);

        if (response["data"]["success"] === false) {
          setErrorMessage(response["data"]["message"]);
          setErrorVisibility(true);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        } else if (response["data"]["success"] === true) {
          setIsLoading(false);
          localStorage.removeItem("register_company")
          const name = response["data"]["data"]["name"];
          const email = response["data"]["data"]["email"];
          const phone = response["data"]["data"]["phone"];
          const companyID = response["data"]["data"]["company_id"];
          const companyName = response["data"]["data"]["company_name"];
          const companyLogoURL = response["data"]["data"]["company_logo_url"];
          const companyOperatorID = response["data"]["data"]["operator_id"];
          const profileImageURL = response["data"]["data"]["profile_image_url"];
          const userID = response["data"]["data"]["id"];
          const sessionID = response["data"]["data"]["user_ses_id"];

          const privileges = response["data"]["privileges"];

          SessionManagement.setName(name);
          SessionManagement.setEmail(email);
          SessionManagement.setPhone(phone);
          SessionManagement.setCompanyID(companyID);
          SessionManagement.setCompanyName(companyName);
          SessionManagement.setProfileImageURL(profileImageURL);
          SessionManagement.setUserID(userID);
          SessionManagement.setSessionID(sessionID);
          SessionManagement.setCompanyLogoURL(companyLogoURL);
          SessionManagement.setCompanyOperatorID(companyOperatorID);
          // SessionManagement.setAuthenticationStatus("yes");

          SessionManagement.setUserPrivileges(privileges);

          navigate("/update-operator", { replace: true });
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  return (
    <div className="box-container">
      {isError === true ? (
        <ErrorNotification message={errorMessage}>
          This is a notification
        </ErrorNotification>
      ) : null}
      <PageHeader className="page-header">Verify OTP</PageHeader>
      <PageSubheader>
        Already have an account? <a href="/">Login</a>
      </PageSubheader>
      <div>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          inputStyle={"inputField"}
          containerStyle={"enclose"}
          renderSeparator={<span className="ml-4"></span>}
          renderInput={(props) => <input {...props} />}
        />
      </div>
      <div className="d-flex flex-row my-5 justify-content-end">
        <button type="submit" className="login-button" onClick={handleSubmit}>
          {isLoading === false ? (
            <span className="button-text">VERIFY</span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </button>
      </div>
    </div>
  );
};

export default Verify;
