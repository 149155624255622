import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {UserProfileData} from "../../../library/constants";
import axios from "axios";
import {getShipmentsByUserURL, getShipmentsURL} from "../../../library/URLs";
import styled from "styled-components";
import PersonIcon from "../../../assets/single-customer/person_apron.svg";
import { TbEdit } from "react-icons/tb";


const CustomerOverview = ({primaryPickupAddress, secondaryPickupAddress, registrationTimestamp, lastActivityTimestamp}) => {
    const editCustomerProfile = () => {

    };

    return (
        <div className="card border-0 bg-transparent" >
            <div className="card-body">
                <div className="d-flex flex-row justify-content-between">
                    <div className="tab-header-container">
                        <img src={PersonIcon} alt="overview" />
                        Customer Overview
                    </div>
                    <button className="customer_tab_btn" type="button" onClick={editCustomerProfile} >
                        <TbEdit />
                        Edit Profile
                    </button>
                </div>

                <div className="overview-container pt-3 " >
                    <div className="row overview-list border-bottom">
                        <label className="col-lg-4 overview-label">Registration Date</label>
                        <div className="col-lg-8 overview-value">{registrationTimestamp}</div>
                    </div>
                    <div className="row overview-list border-bottom">
                        <label className="col-lg-4 overview-label">Last Activity Date</label>
                        <div className="col-lg-8 overview-value">{lastActivityTimestamp}</div>
                    </div>
                    <div className="row overview-list border-bottom">
                        <label className="col-lg-4 overview-label">Primary Pickup Address</label>
                        <div className="col-lg-8 overview-value">{primaryPickupAddress === "" ? "Undefined" : primaryPickupAddress}</div>
                    </div>
                    <div className="row overview-list">
                        <label className="col-lg-4 overview-label">Secondary Pickup Address</label>
                        <div className="col-lg-8 overview-value">{secondaryPickupAddress === "" ? "Undefined" : secondaryPickupAddress}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomerOverview;