import {SessionManagement} from "../library/SessionManagement";

const SubscriptionInactive = () => {
    return <>
        {
            SessionManagement.getCurrentPlan() === "Inactive" ?
                <div className="mx-3 mb-3" style={{color: "#924040", backgroundColor: "#fde1e1", borderColor:"#fbe9c9",
                    padding:"5px 40px", textAlign:"center", border:"1px solid #fcd2d2", borderRadius:4, fontSize:15}}>
                    You have no active subscription. Customers and admins won't be able to calculate and create shipments.
                </div> :
                null
        }
    </>
}

export default SubscriptionInactive