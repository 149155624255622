import "../styles/home.scss";
import "../styles/single-customer.scss";
import "../styles/components.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getCustomerDetailsURL, getCustomerStatsURL, getBatchShipmentsUrl, getAllBatchUrl } from "../library/URLs";
import axios from "axios";
import { UserProfileData } from "../library/constants";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { BsDot } from "react-icons/bs";
import styled from "styled-components";
import * as React from "react";
import contractIcon from "../images/contract-icon.png";
import moment from "moment";
import SubscriptionInactive from "../components/SubscriptionInactive";


const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const SingleBatch = () => {
    const params = useParams();
    const batchID = params["batchID"];

    const [selectedBatch, setSelectedBatch] = useState({});
    const [shipments, setShipments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [shipmentsLoading, setShipmentsLoading] = useState(false);


    const data = {
        customer_id: batchID,
    };

    const newData = { ...data, ...UserProfileData() };

    const fetchBatch = () => {
        setIsLoading(true);
        axios
            .post(getAllBatchUrl, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {

                setIsLoading(false);

                if (response["data"]["success"] === false) return;

                const newBatches = response["data"]["data"];

                let filteredBatch = newBatches?.find(batch => batch?.id === batchID);


                fetchBatchShipments(filteredBatch?.batch_number)
                setSelectedBatch(filteredBatch);
            })
            .catch(() => {
                setIsLoading(false);
            })
    }

    const fetchBatchShipments = (batch_number) => {
        setShipmentsLoading(true);
        axios
            .post(getBatchShipmentsUrl, {...newData, batch_number}, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {

                setShipmentsLoading(false);

                if (response["data"]["success"] === false) return;

                const newBatches = response["data"]["data"];

                setShipments(newBatches);
            })
            .catch(() => {
                setShipmentsLoading(false);
            })
    }
    

    useEffect(() => {
        fetchBatch();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="home">
            <Sidebar />
            <div className="main">
                <Navbar />
                <div className="page pb-5">
                    <SubscriptionInactive />

                    <div className="d-flex flex-row justify-content-between mb-2 mx-3">
                        <h5 className="header">Batch</h5>
                        <span>Home</span>
                    </div>

                    <div className="container-fluid">
                        <div className="row mt-4">
                            <div className="col-xl-12 col-md-12">
                                <span className="header">Batch Detail - {selectedBatch?.batch_number} </span>
                                <div className="card bg-light border-0 shadow">
                                    <div className="card-body">
                                        {isLoading === false ? null : (
                                            <LoadingContainer>
                                                <LoadingSpinner className="spinner-border spinner-grow-sm" />
                                            </LoadingContainer>
                                        )}
                                        {
                                            isLoading ? null : (
                                                <div className="row">
                                                    <div className="col-xl-3">
                                                        <span className="title">Batch Number</span>
                                                        <p>{selectedBatch?.batch_number} </p>
                                                    </div>

                                                    <div className="col-xl-3">
                                                        <span className="title">Origin</span>
                                                        <p>{selectedBatch?.origin} </p>
                                                    </div>

                                                    <div className="col-xl-3">
                                                        <span className="title">Destination</span>
                                                        <p>{selectedBatch?.destination} </p>
                                                    </div>

                                                    <div className="col-xl-3">
                                                        <span className="title">No. of shipments</span>
                                                        <p>{shipments?.length} </p>
                                                    </div>

                                                    <div className="col-xl-3">
                                                        <span className="title">Date</span>
                                                        <p>
                                                            {moment(selectedBatch?.creation_date).format('ddd MMM Do, h:mm A')} 
                                                        </p>
                                                    </div>
                                                    
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-xl-12 col-md-12">
                                {shipmentsLoading === false ? null : (
                                    <LoadingContainer>
                                        <LoadingSpinner className="spinner-border spinner-grow-sm" />
                                    </LoadingContainer>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleBatch;
