import "../styles/home.scss";
import "../styles/dashboard.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import DashboardGraph from "./Widgets/DashboardGraph";
import OverallStatistics from "./Widgets/OverallStatistics";
import TransporterActivities from "./Widgets/TransporterActivities";
import RecentShipments from "./Widgets/RecentShipments";
import RecentTransactions from "./Widgets/RecentTransactions";
// import { SessionManagement } from "../library/SessionManagement";
import UserActivities from "./Widgets/UserActivities";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  getDashboardShipmentStatsUrl,
  getDashboardStatsUrl,
  getAdminDetailsURL,
  toggleStatsVisibilityUrl,
} from "../library/URLs";
import { UserProfileData } from "../library/constants";
import { URLConnection } from "../library/URLConnection";
import SubscriptionInactive from "../components/SubscriptionInactive";
import Overview from "../assets/dashboard/overview.svg";
import Graph from "../assets/dashboard/bar_chart.svg";
import Activities from "../assets/dashboard/network_intelligence_history.svg";
import { BsChevronDoubleDown } from "react-icons/bs";
import { useRef } from "react";
import { Switch } from "@mui/material";
import { styled as mui_styled } from "@mui/material/styles";

const ParcelflowSwitch = mui_styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 34,
  height: 16,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#15AB68",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 12,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#98A2B3",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const Dashboard = () => {
  const [totalRevenue, setTotalRevenue] = useState("...");
  const [totalShipments, setTotalShipments] = useState("...");
  const [totalCustomers, setTotalCustomers] = useState("...");
  const [totalTransactions, setTotalTransactions] = useState("...");
  const [mode, setMode] = useState("daily");
  const [cardMode, setCardMode] = useState("All");
  const [statsVisibility, setStatsVisibility] = useState("No");
  const [visibilityLoading, setVisibilityLoading] = useState(false);

  const ref = useRef();

  const [shipmentLabels, setShipmentLabels] = useState([]);
  const [shipmentValues, setShipmentValues] = useState([]);

  const getShipmentStats = (selectedMode = "daily") => {
    URLConnection(
      getDashboardShipmentStatsUrl,
      { ...UserProfileData(), mode: selectedMode },
      function (response) {
        if (response["data"]["success"] === false) return;

        let shipmentsValues = [];
        let shipmentLabels = [];

        let shipments = response["data"]["data"];

        shipments?.forEach((shipment) => {
          shipmentsValues.push(shipment.count);
          shipmentLabels.push(shipment.date);
        });

        setShipmentLabels(shipmentLabels);
        setShipmentValues(shipmentsValues);
      }
    );
  };

  const getDashboardStats = (selectedMode = "All") => {
    URLConnection(
      getDashboardStatsUrl,
      { ...UserProfileData(), type: selectedMode },
      function (response) {
        if (response["data"]["success"] === false) return;

        const totalRevenue = response["data"]["data"]["total_revenue"];
        const totalShipments = response["data"]["data"]["total_shipments"];
        const totalCustomers = response["data"]["data"]["total_customers"];
        const totalTransactions =
          response["data"]["data"]["total_transactions"];

        setTotalRevenue(totalRevenue ? parseFloat(totalRevenue).toLocaleString("en") : 0);
        setTotalShipments(parseFloat(totalShipments).toLocaleString("en"));
        setTotalCustomers(parseFloat(totalCustomers).toLocaleString("en"));
        setTotalTransactions(
          parseFloat(totalTransactions).toLocaleString("en")
        );
      }
    );
  };

  const getAdminDetails = () => {
    URLConnection(
      getAdminDetailsURL,
      { ...UserProfileData(), employee_id: UserProfileData().user_id },
      function (response) {
        if (response["data"]["success"] === false) return;

        let visibility = response["data"]["data"]["dashboard_stats_visibility"];
        setStatsVisibility(visibility);
      }
    );
  };

  const toggleVisibility = (visibilityStats) => {
    setVisibilityLoading(true);
    URLConnection(
      toggleStatsVisibilityUrl,
      { ...UserProfileData(), visibility: visibilityStats },
      function (response) {
        setVisibilityLoading(false);
        if (response["data"]["success"] === false) return;

        setStatsVisibility(visibilityStats);
      }
    );
  };

  const handleMode = (e) => {
    const { value } = e.target;
    setMode(value);
    getShipmentStats(value);
  };

  const handleCardMode = (e) => {
    const { value } = e.target;
    setCardMode(value);
    getDashboardStats(value);
  };

  useEffect(() => {
    getShipmentStats();
    getDashboardStats();
    getAdminDetails();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />

          <div className="container ">
            <div
              className="card border-0 py-2"
              style={{ backgroundColor: "#FFF", borderRadius: "16px" }}
            >
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center flex-row gap-2 mb-2 mx-3">
                  <img src={Overview} alt="overview" />
                  <h5 className="mb-2">Overview</h5>
                </div>
                <div className="d-flex gap-4 align-items-center">
                  <div className="d-flex gap-2 align-items-center">
                    <label htmlFor="Full Name">Show Stats</label>
                    {visibilityLoading ? (
                      <div className="pe-4">
                        <span className="spinner-border spinner-grow-sm" />
                      </div>
                    ) : (
                      <ParcelflowSwitch
                        name="stats_visibility"
                        onChange={() =>
                          toggleVisibility(
                            statsVisibility === "Yes" ? "No" : "Yes"
                          )
                        }
                        checked={statsVisibility === "Yes"}
                        className="react-switch"
                      />
                    )}
                  </div>
                  <div className="graph-select">
                    <select
                      name="cardMode"
                      value={cardMode}
                      style={{
                        position: "relative",
                        zIndex: 2,
                        background: "transparent",
                      }}
                      onChange={handleCardMode}
                      id=""
                    >
                      <option value="All">All Time</option>
                      <option value="Today">Today</option>
                      <option value="Month">This Month</option>
                    </select>

                    <BsChevronDoubleDown
                      style={{ position: "absolute", zIndex: 1 }}
                    />
                  </div>
                </div>
              </div>
              <OverallStatistics
                totalCustomers={totalCustomers}
                totalRevenue={totalRevenue}
                totalShipments={totalShipments}
                totalTransactions={totalTransactions}
                showStats={statsVisibility}
              />
            </div>

            <div className=" d-flex mt-4 gap-2">
              <div
                className=" card w-100 border-0 pt-2 mb-4 mb-md-0"
                style={{ backgroundColor: "#FFF", borderRadius: "16px" }}
              >
                <div
                  className=" border-0"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center flex-row gap-2 mb-2 mx-3">
                      <img src={Graph} alt="graph" />
                      <h5 className="m-0">Shipments Analytics</h5>
                    </div>

                    <div className="graph-select">
                      <select
                        name="mode"
                        value={mode}
                        ref={ref}
                        style={{
                          position: "relative",
                          zIndex: 2,
                          background: "transparent",
                        }}
                        onChange={handleMode}
                        id=""
                      >
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                      </select>

                      <BsChevronDoubleDown
                        style={{ position: "absolute", zIndex: 1 }}
                      />
                    </div>
                  </div>
                  <div className="p-3">
                    <div className="graph-bg rounded-4">
                      <DashboardGraph
                        label={shipmentLabels}
                        shipmentsData={shipmentValues}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <RecentTransactions />
            </div>

            <div
              className="row mt-4 mx-1 activities-contain"
              style={{ background: "#FFF" }}
            >
              <div className="d-flex align-items-center flex-row gap-2 mb-2 mx-3">
                <img src={Activities} alt="graph" />
                <h5 className="m-0">Activities</h5>
              </div>
              <div className="row d-flex gap-2 flex-nowrap">
                <RecentShipments />

                <TransporterActivities />

                <UserActivities />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
