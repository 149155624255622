import { useLocation, useNavigate } from "react-router-dom";
import { UserProfileData } from "../library/constants";
import { SessionManagement } from "../library/SessionManagement";
import { URLConnection } from "../library/URLConnection";
import { getAdminPrivilegesURL } from "../library/URLs";
import { useEffect } from "react";

export const useUpdateRolesAndPermissions = () => {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();

  useEffect(() => {
    URLConnection(
      getAdminPrivilegesURL,
      {
        ...UserProfileData(),
        employee_id: UserProfileData().user_id
      },
      function (response) {
        if (response["data"]["success"] === false) return;
  
        let privileges = response["data"]["data"];
  
        for (const item of page_permissions) {
          if(privileges[item.permission] !== 1 && item.urls.includes(pathname)) {
            navigate("/stations");
          }
        };
  
        SessionManagement.setUserPrivileges(privileges);
      }
    );
    // eslint-disable-next-line
  }, [pathname])
};

export const page_permissions = [
  {
    permission: "show_dashboard",
    urls: ["/"]
  },
  {
    permission: "show_shipments",
    urls: [
      "/shipments/all-shipments",
      "/create-shipment",
      "/calculate-estimates",
      "/shipments/packages",
      "/shipments/charged-services",
      "/shipments/batches"
    ]
  },
  {
    permission: "show_customers",
    urls: [
      "/customers",
      "/customers-wallet"
    ]
  },
  {
    permission: "show_admins",
    urls: [
      "/admins"
    ]
  },
  {
    permission: "show_transactions",
    urls: [
      "/transactions"
    ]
  },
  {
    permission: "show_reports",
    urls: [
      "/reports",
      "/reports/shipments/options",
      "/reports/shipments",
      "/reports/sales",
      "/reports/customers",
      "/reports/transporters"
    ]
  },
  {
    permission: "show_transporters",
    urls: [
      "/transporters"
    ]
  }
];