import React, { useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import styled from "styled-components";
import { createCompanyWalletDetails } from "../library/URLs";
import axios from "axios";
import { UserProfileData } from "../library/constants";
import { SessionManagement } from "../library/SessionManagement";
import { BiX } from "react-icons/bi";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #fcd2d2;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #c2eddd;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
`;

const RegisterWalletDialog = ({
  handleSubmit,
  handleCloseDialog,
  isModalVisible,
}) => {
  const initialData = {
    first_name: "",
    last_name: "",
    phone: "",
    company_name: SessionManagement.getCompanyName(),
    bvn: ""
  };
  const [formData, setFormData] = useState(initialData);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submit = () => {
    setTimeout(() => {
      setFormData(initialData);
      handleSubmit();
      handleCloseDialog();
    }, 3000);
  };

  const registerNumber = (e) => {
    e.preventDefault();

    if (formData.bvn.length < 11) {
      setErrorVisibility(true);
      setErrorMessage("Provide a valid bvn");
      return;
    }

    setErrorVisibility(false);

    const newData = {
      ...UserProfileData(),
      ...formData
    };

    setIsLoading(true);

    axios
      .post(createCompanyWalletDetails, newData)
      .then(function (response) {
        console.log(response);
        setIsLoading(false);

        if (response["data"]["success"] === false) {
          // window.scrollTo(0, 0);
          // submit();
          setErrorVisibility(true);
          setErrorMessage(response["data"]["message"]);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        } else if (response["data"]["success"] === true) {
          // window.scrollTo(0, 0);
          submit();

          setSuccessVisibility(true);
          setSuccessMessage("Wallet activated successfully.");

          setTimeout(() => {
            setSuccessVisibility(false);
          }, 3000);
        }
      })
      .catch(function (error) {
        return error;
      });
  };
  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">ACTIVATE WALLET</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        <div>
          {isError === false ? null : (
            <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
          )}

          {isSuccess === false ? null : (
            <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
          )}
          <p className="Add-phone-form-info">
            Fill out all details to activate wallet
          </p>
          <form>
            <Row className="mb-3">
              <Col className="form-group position-relative" xs={12} md={6}>
                <label htmlFor="New Pin">First Name</label>
                <input
                  name="first_name"
                  className="form-control"
                  type="text"
                  onChange={handleInput}
                  value={formData.first_name}
                />
              </Col>
              <Col className="form-group position-relative" xs={12} md={6}>
                <label htmlFor="New Pin">Last Name</label>
                <input
                  name="last_name"
                  className="form-control"
                  type="text"
                  onChange={handleInput}
                  value={formData.last_name}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="form-group col-xl-12 col-md-12">
                <label htmlFor="Confirm Pin">Company Name</label>
                <input
                  name="company_name"
                  className="form-control"
                  type="text"
                  onChange={handleInput}
                  value={formData.company_name}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="form-group col-xl-12 col-md-12">
                <label htmlFor="Confirm Pin">Phone Number</label>
                <input
                  name="phone"
                  className="form-control"
                  type="tel"
                  onChange={handleInput}
                  value={formData.phone}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="form-group col-xl-12 col-md-12">
                <label htmlFor="Confirm Pin">BVN</label>
                <input
                  name="bvn"
                  className="form-control"
                  type="text"
                  maxLength={11}
                  onChange={handleInput}
                  value={formData.bvn}
                />
              </Col>
            </Row>
            <div className="d-flex flex-row my-5 justify-content-end">
              <SubmitButton
                type="button"
                className={`confirm-button ${
                  formData.first_name === "" ||
                  formData.last_name === "" ||
                  formData.company_name === "" ||
                  formData.phone === "" ||
                  formData.bvn === ""
                    ? "opacity-50"
                    : ""
                }`}
                onClick={registerNumber}
                disabled={
                  formData.new_pin === "" ||
                  formData.new_pin !== formData.confirm_pin
                }
              >
                {isLoading === false ? (
                  <span className="button-text">COMPLETE REGISTRATION</span>
                ) : (
                  <span className="spinner-border spinner-grow-sm" />
                )}
              </SubmitButton>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RegisterWalletDialog;
