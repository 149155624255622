import "../styles/home.scss";
import "../styles/single-admin.scss";
import "../styles/components.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAdminDetailsURL, getCustomerDetailsURL } from "../library/URLs";
import axios from "axios";
import { UserProfileData } from "../library/constants";
import { HiOutlineDotsVertical } from "react-icons/hi";
import styled from "styled-components";
import * as React from "react";
import TopupUserWalletDialog from "../modals/TopupUserWallet";
import AdminActivities from "./Widgets/Admin/AdminActivities";
import { SessionManagement } from "../library/SessionManagement";
import SuspendAdminDialog from "../modals/SuspendAdminDialog";
import UnsuspendAdminDialog from "../modals/UnsuspendAdminDialog";
import DeleteAdminDialog from "../modals/DeleteAdminDialog";
import moment from "moment";
import SubscriptionInactive from "../components/SubscriptionInactive";
import AdminIcon from "../assets/admins/group.svg";
import SuperAdminIcon from "../assets/super-admin.png";
import AdminTypeIcon from "../assets/admin.png";


const ProfileImage = styled.img`
    border-radius:50%;
    width:100px;
    height:100px;  
`;

const CommunicationContainer = styled.div`
    padding:4px 8px;
    border-radius:5px;
    background-color:#E5E7E9;
    color:#2E4053;
    font-size:12px;
    margin-right:3px;
    margin-left:3px;
    cursor:pointer
`;

const SingleAdmin = () => {
    const params = useParams();
    const adminID = params["adminID"];
    const navigate = useNavigate();

    const [isFundUserWalletModalVisible, setIsFundUserWalletModalVisibility] = useState(false);
    const [isDeductUserWalletModalVisible, setIsDeductUserWalletModalVisibility] = useState(false);
    const [isUnsuspendAdminModalVisible, setUnsuspendAdminModalVisibility] = useState(false);
    const [isSuspendAdminModalVisible, setSuspendAdminModalVisibility] = useState(false);
    const [isDeleteAdminModalVisible, setDeleteAdminModalVisibility] = useState(false);

    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [memberType, setMemberType] = useState("");
    const [memberStatus, setMemberStatus] = useState("");
    const [profileImageURL, setProfileImageURL] = useState("https://users.mylogisticsapp.com/engine/v1/actions/profiles/default-profile-image.png");
    const [creationTimestamp, setCreationTimestamp] = useState("");
    const [lastActivityTimestamp, setLastActivityTimestamp] = useState("");

    const [adminActionMenuVisibility, setAdminActionMenuVisibility] = useState(false);

    const toggleFundUserWalletDialog = (type) => {
        type === "open" ? setIsFundUserWalletModalVisibility(true) : setIsFundUserWalletModalVisibility(false);
        setAdminActionMenuVisibility(false);
    }

    const toggleDeductUserWalletDialog = (type) => {
        type === "open" ? setIsDeductUserWalletModalVisibility(true) : setIsDeductUserWalletModalVisibility(false);
        setAdminActionMenuVisibility(false);
    }

    const toggleUserActionMenu = () => {
        setAdminActionMenuVisibility(!adminActionMenuVisibility);
    }

    const unsuspendAdminModal = () => {
        setAdminActionMenuVisibility(!adminActionMenuVisibility);
        setUnsuspendAdminModalVisibility(!isUnsuspendAdminModalVisible);
    };

    const suspendAdminModal = () => {
        setAdminActionMenuVisibility(!adminActionMenuVisibility);
        setSuspendAdminModalVisibility(!isSuspendAdminModalVisible);
    }

    const deleteAdminModal = () => {
        setAdminActionMenuVisibility(!adminActionMenuVisibility);
        setDeleteAdminModalVisibility(!isDeleteAdminModalVisible);
    }

    const handleCloseModal = () => {
        setUnsuspendAdminModalVisibility(false);
        setSuspendAdminModalVisibility(false);
        setDeleteAdminModalVisibility(false);
    };

    const closeDialogAndRefresh = () => {
        setUnsuspendAdminModalVisibility(false);
        setSuspendAdminModalVisibility(false);
        setDeleteAdminModalVisibility(false);
    };

    const closeDeleteDialog = (refresh) => {
        setDeleteAdminModalVisibility(false);

        if (refresh === true) {
            window.location.href = "/admins";
        }
    }

    const adminPrivileges = SessionManagement.getUserPrivileges();

    const data = {
        employee_id: adminID,
    };

    const newData = { ...data, ...UserProfileData() };

    console.log(newData);

    useEffect(() => {
        axios
            .post(getAdminDetailsURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response);
                if (response["data"]["success"] === false) return;

                const adminDetails = response["data"];

                const name = adminDetails["data"]["full_name"];
                const phoneNumber = adminDetails["data"]["phone"];
                const email = adminDetails["data"]["email"];
                const memberType = adminDetails["data"]["employee_type"];
                const memberStatus = adminDetails["data"]["status"];
                const profileImageURL = adminDetails["data"]["profile_image_url"];

                const creationTimestamp = new Date(adminDetails["data"]["date_created"]).toDateString();
                const lastActivityTimestamp = adminDetails["data"]["last_seen_timestamp"] === undefined ? "Not Available" :
                    moment(adminDetails["data"]["last_seen_timestamp"], "YYYY-MM-DD h:mm:ss").fromNow();

                setName(name);
                setPhoneNumber(phoneNumber);
                setEmail(email);
                setMemberType(memberType);
                setMemberStatus(memberStatus);
                setProfileImageURL(profileImageURL);
                setCreationTimestamp(creationTimestamp);
                setLastActivityTimestamp(lastActivityTimestamp);
            });
    }, []);

    return (
        <div className="home">
            <Sidebar />
            <div className="main">
                <Navbar />
                <div className="page pb-5">
                    <SubscriptionInactive />

                    <TopupUserWalletDialog
                        customerID={adminID}
                        phoneNumber={phoneNumber}
                        customerName={name}
                        isModalVisible={isFundUserWalletModalVisible}
                        handleCloseDialog={(e) => toggleFundUserWalletDialog("close")}
                    />

                    <DeleteAdminDialog
                        isModalVisible={isDeleteAdminModalVisible}
                        handleCloseDialog={closeDeleteDialog}
                        adminID={adminID}
                    />

                    <UnsuspendAdminDialog
                        isModalVisible={isUnsuspendAdminModalVisible}
                        handleCloseDialog={handleCloseModal}
                        customerID={adminID}
                    />

                    <SuspendAdminDialog
                        isModalVisible={isSuspendAdminModalVisible}
                        handleCloseDialog={handleCloseModal}
                        customerID={adminID}
                    />

                    <div className="container-fluid shipments-container">
                        <div
                            className="card border-0 p-4 min-vh-100"
                            style={{ backgroundColor: "#FFF" }}
                        >
                            <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                                <div className="d-flex align-items-center flex-row gap-2 ">
                                    <img src={AdminIcon} alt="group" />
                                    <h5 className="m-0">Admin Details</h5>
                                </div>
                            </div>
                            <div className="row admin-wrapper">
                                <div className="col-xl-4 col-md-12">
                                    <div className="card border-0" style={{ background: "transparent" }}>
                                        <div className="card-body shipper-container">
                                            <div className="d-flex flex-column">
                                                <div style={{ width: "100%" }} className="d-flex justify-content-between mb-3">
                                                    <ProfileImage src={profileImageURL} />
                                                    <div className="user-menu-container">
                                                        {
                                                            adminPrivileges["delete_employee"] === 1 ||
                                                                adminPrivileges["suspend_employee"] === 1 ||
                                                                adminPrivileges["unsuspend_employee"] === 1 ?
                                                                <HiOutlineDotsVertical onClick={toggleUserActionMenu} style={{ cursor: "pointer", fontSize:"20px" }} /> :
                                                                <div style={{ height: "25px" }} />
                                                        }

                                                        <div className={`user-menu ${adminActionMenuVisibility ? "user-menu-visible" : null}`}>
                                                            {
                                                                memberStatus === "Suspended" ?
                                                                    (adminPrivileges["unsuspend_employee"] === 1 ? <span onClick={unsuspendAdminModal}>Unsuspend Admin</span> : null) :
                                                                    (adminPrivileges["suspend_employee"] === 1 ? <span onClick={suspendAdminModal}>Suspend Admin</span> : null)
                                                            }
                                                            {
                                                                adminPrivileges["edit_employee"] === 1 ?
                                                                    <span onClick={() => navigate(`/admin/edit/${adminID}`)} >Edit Admin Details</span> : null
                                                            }
                                                            {
                                                                adminPrivileges["delete_employee"] === 1 ? <span onClick={() => deleteAdminModal()}>Delete Admin</span> : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={`admin-type 
                                                        ${memberType === "Admin" ? "admin-type-admin" : "admin-type-super-admin"}`
                                                    }
                                                    >
                                                        {
                                                            memberType === "Admin" ? (
                                                                <img src={AdminTypeIcon} alt="icon" />
                                                            ) : (
                                                                <img src={SuperAdminIcon} alt="icon" />
                                                            )
                                                        }
                                                        {memberType}
                                                    </div>
                                                    <p className="admin-name">{name}</p>
                                                </div>
                                            </div>
                                            <div className="mt-4 border-bottom">
                                                <p className="admin-label">Email</p>
                                                <p className="admin-value" >{email}</p>
                                            </div>
                                            <div className="mt-2 border-bottom">
                                                <p className="admin-label">Phone Number</p>
                                                <p className="admin-value" >{phoneNumber}</p>
                                            </div>
                                            <div className="mt-2 border-bottom">
                                                <p className="admin-label">Creation Date</p>
                                                <p className="admin-value" >{creationTimestamp}</p>
                                            </div>
                                            <div className="mt-2">
                                                <p className="admin-label">Last Seen</p>
                                                <p className="admin-value" >{lastActivityTimestamp}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-8 col-md-12">
                                    <AdminActivities adminID={adminID} adminPrivileges={adminPrivileges} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default SingleAdmin;
