import "../../styles/home.scss";
import "../../styles/reports.scss";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserProfileData } from "../../library/constants";
import { getSalesReportsUrl } from "../../library/URLs";
import axios from "axios";
import styled from "styled-components";
import moment from "moment";
import dataIcon from "../../images/no-data.png";
import { Dropdown } from "react-bootstrap";
import * as ExcelJS from "exceljs";
import { toast } from "react-toastify";
import ReactApexChart from "react-apexcharts";
import { TextBox } from "../../components/InputFields";
import ReportIcon from "../../assets/reports/ReportIcon.svg";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import ExportIcon from "../../assets/shipments/file_save.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Funded from "../../assets/single-customer/payments.svg";
import Spent from "../../assets/single-customer/Currency.svg";
import Balance from "../../assets/single-customer/deployed_code_active.svg";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const SalesReports = () => {
  const navigator = useNavigate();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(new Date());

  const [isLoading, setIsLoading] = useState(false);

  const [shipments, setShipments] = useState([]);

  const [visualizeShipment, setVisualizeShipment] = useState([]);

  const [totalCarrierCost, setTotalCarrierCost] = useState(0);
  const [totalCharged, setTotalCharged] = useState(0);
  const [totalSales, setTotalSales] = useState(0);

  const [openExport, setOpenExport] = useState(null);
  const open = Boolean(openExport);
  const handleClickExport = (event) => {
    setOpenExport(event.currentTarget);
  };
  const handleClose = () => {
    setOpenExport(null);
  };

  const series = [
    {
      name: "Carrier Price",
      data: visualizeShipment?.map((viz) => viz?.totalCarrierCostPrice),
    },
    {
      name: "Shipment Amount",
      data: visualizeShipment?.map((viz) => viz?.totalAmount),
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "string",
      categories: visualizeShipment?.map((viz) => viz?.date),
    },
  };

  const groupShipmentsByDate = (shipments) => {
    // Create an empty object to hold grouped data
    const grouped = {};

    // Loop through all shipments
    shipments.forEach((shipment) => {
      // Extract the date part from the date_time string (YYYY-MM-DD)
      const date = shipment.date_time.split(" ")[0];

      // If the date does not exist in the grouped object, initialize it
      if (!grouped[date]) {
        grouped[date] = {
          date,
          totalCarrierCostPrice: 0,
          totalAmount: 0,
          shipments: [],
        };
      }

      // Add the current shipment to the date's shipment list
      grouped[date].shipments.push(shipment);

      // Sum carrier_cost_price and total_amount, converting strings to numbers
      grouped[date].totalCarrierCostPrice += parseFloat(
        shipment.carrier_cost_price?.replace(",", "")
      );
      grouped[date].totalAmount += parseFloat(
        shipment.total_amount?.replace(",", "")
      );
    });

    // Ensure the totals are rounded to 2 decimal places
    Object.values(grouped).forEach((group) => {
      group.totalCarrierCostPrice = parseFloat(
        group.totalCarrierCostPrice.toFixed(2)
      );
      group.totalAmount = parseFloat(group.totalAmount.toFixed(2));
    });

    // Convert the grouped object into an array
    return Object.values(grouped);
  };

  const fetchSalesReport = () => {
    setIsLoading(true);
    const reportData = {
      ...UserProfileData(),
      from: startDate,
      to: moment(endDate).add("day", 1).format("YYYY-MM-DD"),
    };

    axios
      .post(getSalesReportsUrl, reportData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);

        if (response["data"]["success"] === false) {
          toast.error(response["data"]["message"]);
          return;
        }

        const newShipments = response["data"]["data"];
        const shipmentChart = groupShipmentsByDate(newShipments);

        let totalCarrierCost = newShipments?.reduce((acc, curr) => {
          let total =
            parseFloat(`${curr?.carrier_cost_price}`?.replaceAll(",", "")) +
            acc;
          return total;
        }, 0);

        let totalSales = newShipments?.reduce((acc, curr) => {
          let total = parseFloat(`${curr?.sales}`?.replaceAll(",", "")) + acc;
          return total;
        }, 0);

        let totalCharged = newShipments?.reduce((acc, curr) => {
          let total = parseFloat(`${curr?.total_amount}`?.replaceAll(",", "")) + acc;
          return total;
        }, 0);

        setTotalCarrierCost(totalCarrierCost);
        setTotalSales(totalSales);
        setTotalCharged(totalCharged);
        setShipments(newShipments);
        setVisualizeShipment(shipmentChart);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleSalesReport = (e) => {
    e.preventDefault();
    fetchSalesReport();
  };

  const handleClick = (shipmentID) => {
    navigator("/shipment/" + shipmentID);
  };

  const exportToXlsx = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Sales Report");
    sheet.properties.defaultRowHeight = 40;

    sheet.getRow(1).border = {
      // top: { style: "thick", color: { argb: "074B8A" } },
      // left: { style: "thick", color: { argb: "074B8A" } },
      bottom: { style: "thick", color: { argb: "074B8A" } },
      right: { style: "thin", color: { argb: "074B8A" } },
    };

    sheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "008561" },
    };

    sheet.getRow(1).font = {
      // name: "Comic Sans MS",
      // family: 4,
      size: 16,
      bold: true,
      color: { argb: "000000" },
    };

    sheet.columns = [
      {
        header: "S/N",
        key: "sn",
        width: 5,
      },
      {
        header: "SHIPMENT NUMBER",
        key: "shipment_no",
        width: 50,
      },
      {
        header: "CARRIER COST PRICE",
        key: "carrier_cost_price",
        width: 50,
      },
      {
        header: "SHIPMENT TOTAL AMOUNT",
        key: "total_amount",
        width: 50,
      },
      {
        header: "SALES INTEREST",
        key: "sales",
        width: 50,
      },
      {
        header: "PERCENTAGE VALUE",
        key: "percentage_value",
        width: 50,
      },
      {
        header: "SALES STATUS",
        key: "sales_status",
        width: 50,
      },
      {
        header: "TIMESTAMP",
        key: "date_time",
        width: 50,
      },
    ];

    sheet.insertRow(1, [
      "",
      "",
      "",
      `
    SALES REPORT
    `,
    ]);
    const first_row = sheet.getRow(1);
    first_row.height = 120;
    first_row.font = {
      size: 18,
      bold: true,
      color: { argb: "074B8A" },
    };
    first_row.border = {
      // top: { style: "thick", color: { argb: "074B8A" } },
      left: { style: "thick", color: { argb: "FFFFFF" } },
      bottom: { style: "thick", color: { argb: "074B8A" } },
      // right: { style: "thick", color: { argb: "074B8A" } },
    };
    first_row.alignment = { horizontal: "center" };

    // const result = toDataURL(User?.company_logo_url);
    // const result = User?.company_logo_url;

    // worksheet.addImage(imageId2, {
    //   tl: { col: 1.5, row: 1.5 },
    //   br: { col: 3.5, row: 5.5 }
    // });
    shipments?.forEach((shipment, id) => {
      sheet.addRow({
        sn: id + 1,
        shipment_no: `${shipment.shipment_no ?? "N/A"}`,
        carrier_cost_price: `₦ ${shipment?.carrier_cost_price ?? "N/A"}`,
        total_amount: `₦ ${shipment?.total_amount ?? "N/A"}`,
        sales: `₦ ${shipment?.sales ?? "N/A"}`,
        percentage_value: `${shipment?.percentage_value ?? "N/A"}%`,
        sales_status: `${shipment?.sales_status ?? "N/A"}`,
        date_time: `${shipment?.date_time ?? "N/A"}`,
      });
    });
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `SALES_REPORT.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const exportToCSV = () => {
    if (shipments.length === 0) return;

    let csvContent = "data:text/csv;charset=utf-8,";

    csvContent +=
      "Shipment NO.,CARRIER COST PRICE,SHIPMENT TOTAL AMOUNT,SALES INTEREST,PERCENTAGE VALUE,SALES STATUS,TIMESTAMP" +
      "\r\n";

    shipments.forEach(function (shipment) {
      let shipment_no = shipment.shipment_no;

      let carrier_cost_price = `${shipment.carrier_cost_price}`;
      let total_amount = `${shipment.total_amount}`;
      let sales = `${shipment.sales}`;
      let percentage_value = `${shipment.percentage_value}`;
      let sales_status = shipment.sales_status;
      let date_time = shipment.date_time;

      const row =
        shipment_no +
        "," +
        carrier_cost_price +
        "," +
        total_amount +
        "," +
        sales +
        "," +
        percentage_value +
        "," +
        sales_status +
        "," +
        date_time;

      csvContent += row + "\r\n";
    });

    const encodedURI = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedURI);
    link.setAttribute("download", "SalesReports.csv");
    document.body.appendChild(link);

    link.click();
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <div className="container shipments-container">
            <div
              className="card border-0 p-4 min-vh-100"
              style={{ backgroundColor: "#FFF" }}
            >
              <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  <img src={ReportIcon} alt="group" />
                  <h5 className="m-0">Sales Report</h5>
                </div>
                <div className="d-flex flex-column flex-md-row gap-3"></div>
              </div>
              <div className="d-flex gap-3 mb-3 mb-md-0 flex-column flex-md-row justify-content-between">
                <div className="d-flex flex-column flex-md-row align-items-md-end gap-3">
                  <div className="d-md-flex d-grid col gap-3">
                    <div>
                      <label className="form-label" htmlFor="startdate">
                        Start Date
                      </label>
                      <TextBox
                        type="date"
                        min={"2024-09-24"}
                        style={{ padding: "8px 20px" }}
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className="form-label" htmlFor="startdate">
                        End Date
                      </label>
                      <TextBox
                        type="date"
                        style={{ padding: "8px 20px" }}
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                  </div>

                  <button onClick={handleSalesReport} className="btn-primary">
                    {isLoading ? (
                      <LoadingSpinner className="spinner-border spinner-grow-sm" />
                    ) : (
                      "SEARCH"
                    )}
                  </button>
                </div>

                <div>
                  <button
                    className="feature-btn"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClickExport}
                  >
                    <img src={ExportIcon} alt="export" />
                    Export
                    {open ? <BsChevronUp /> : <BsChevronDown />}
                  </button>
                  <Menu
                    id="basic-menu"
                    anchorEl={openExport}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        exportToCSV();
                        handleClose();
                      }}
                      style={{ fontSize: 12 }}
                    >
                      Export as .csv
                    </MenuItem>
                    <MenuItem
                      style={{ fontSize: 12 }}
                      onClick={() => {
                        exportToXlsx();
                        handleClose();
                      }}
                    >
                      Export as .xlsx
                    </MenuItem>
                  </Menu>
                </div>
              </div>

              {visualizeShipment?.length > 0 && (
                <div className="sales-wrap my-3">
                  <div className="sales-wrap-inner">
                    <ReactApexChart
                      options={options}
                      series={series}
                      type="area"
                      height={350}
                    />
                  </div>
                </div>
              )}

              {shipments?.length > 0 && (
                <div className="table-contain sales-wrap my-4">
                  <div className="sales-wrap-inner">
                    <div className="d-flex flex-row p-4 gap-3">

                      <div className="customer-info-card">
                        <div className="top-section">
                          <div className="info-card-icon">
                            <img src={Funded} alt="icon" />
                          </div>
                          <span>Total Carrier Cost</span>
                        </div>
                        <span className="bottom-section">
                          ₦{parseFloat(totalCarrierCost).toLocaleString("en")}
                        </span>
                      </div>

                      <div className="customer-info-card">
                        <div className="top-section">
                          <div className="info-card-icon">
                            <img src={Funded} alt="icon" />
                          </div>
                          <span>Total Charged</span>
                        </div>
                        <span className="bottom-section">
                          ₦{parseFloat(totalCharged).toLocaleString("en")}
                        </span>
                      </div>

                      <div className="customer-info-card">
                        <div className="top-section">
                          <div className="info-card-icon">
                            <img src={Spent} alt="icon" />
                          </div>
                          <span>Total Price Diff</span>
                        </div>
                        <span className="bottom-section">
                          ₦{parseFloat(totalSales).toLocaleString("en")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {shipments?.length > 0 && (
                <div className="table-contain sales-wrap">
                  <div className="sales-wrap-inner">
                    <table className="reports-table w-100">
                      <thead>
                        <tr>
                          <th scope="col">Shipment Number</th>
                          <th scope="col">Carrier Price</th>
                          <th scope="col">Shipment Amount</th>
                          <th scope="col">Price Diff</th>
                          <th scope="col">Percentage Diff</th>
                          <th scope="col">Status</th>
                          <th scope="col">Date</th>
                        </tr>
                      </thead>
                      {isLoading === true ? null : (
                        <tbody>
                          {shipments.map((shipment, id) => {
                            const dateTime = moment(shipment.date_time);
                            const timestamp =
                              dateTime.format("ddd MMM Do, h:mm A");

                            return (
                              <tr
                                key={id}
                                onClick={() => handleClick(shipment.id)}
                              >
                                <td>{shipment.shipment_no}</td>
                                <td>₦ {shipment.carrier_cost_price}</td>
                                <td>₦{shipment.total_amount}</td>
                                <td>
                                  {shipment.sales
                                    ? `₦ ${shipment.sales}`
                                    : "N/A"}
                                </td>
                                <td>
                                  {shipment.percentage_value
                                    ? `${shipment.percentage_value}%`
                                    : "N/A"}
                                </td>
                                <td>
                                  <span
                                    style={{
                                      color:
                                        shipment.sales_status === "Profit"
                                          ? "#1f7556"
                                          : "#924040",
                                      background:
                                        shipment.sales_status === "Profit"
                                          ? "#d6f3e9"
                                          : "#fcd2d2",
                                      padding: "4px 12px",
                                      borderRadius: "16px",
                                      height: "fit-content",
                                      fontSize: 14,
                                    }}
                                  >
                                    {shipment.sales_status}
                                  </span>
                                </td>
                                <td>{timestamp}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              )}

              {startDate !== "" || endDate !== "" ? null : (
                <LoadingContainer>
                  <div>
                    <img
                      style={{ width: 50, height: 50, marginBottom: 10 }}
                      src={dataIcon}
                      alt=""
                    />
                    <p>No option has been selected</p>
                  </div>
                </LoadingContainer>
              )}
              {isLoading === false ? null : (
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesReports;
