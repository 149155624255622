import "../../styles/home.scss";
import "../../styles/single-shipment.scss";
import "../../styles/components.scss";
import "../../styles/create-admin.scss"
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import { useState } from "react";
import {Col, Row} from "react-bootstrap";
import * as React from "react";
import styled from "styled-components";
import axios from "axios";
import { createWhatsappTemplatesURL } from "../../library/URLs";
import {useNavigate} from "react-router-dom";
import SubscriptionInactive from "../../components/SubscriptionInactive";
import { UserProfileData } from "../../library/constants";

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #fcd2d2;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #c2eddd;
    margin-bottom:20px;
    text-align:center;
`;

const SteppedNumber = styled.div`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #008561;
`;

const SteppedTitle = styled.span`
  font-size: 20px;
`;

const SubmitButton = styled.button`
  width: 250px;
`;

const CreateWhatsappTemplate = () => {
    const navigator = useNavigate();

  const [isCreatingAdmin, setIsCreatingAdmin] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    category: "",
    message: ""
  });

  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormData({
        ...formData,
        [name]: name==="name"? value?.toLowerCase().replace(/ /g, "_") : value
    })
  };

  function showError(message){
      window.scrollTo(0, 0);
      setErrorVisibility(true);
      setErrorMessage(message);

      setTimeout(() => {
          setErrorVisibility(false);
      }, 3000);
  }

  const createWhatsappTemplate = () => {
    
    if (formData.name === ""){
        showError("Please enter valid name");
        return false;
    }

    if (formData.category === ""){
        showError("Please enter valid category");
        return false;
    }

    if (formData.message === ""){
        showError("Please enter message");
        return false;
    };

    setIsCreatingAdmin(true);


    axios
        .post(createWhatsappTemplatesURL, {...UserProfileData() ,...formData})
        .then((response) => {
            setIsCreatingAdmin(false);
            window.scrollTo(0, 0);

            if (response["data"]["success"] === true){
                setSuccessVisibility(true);
                setSuccessMessage("Successfully created whatsapp template.");

                setTimeout(() => {
                    navigator("/whatsapp/templates");
                }, 3000);
            } else {
                const message = response['data']['message'];

                setErrorVisibility(true);
                setErrorMessage(message);
                setTimeout(() => {
                    // setErrorVisibility(false);
                }, 3000);
            }
        })
        .catch(error => console.log(error));
}

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
           <SubscriptionInactive />

          <div className="d-flex flex-row justify-content-between mb-2 mx-3">
            <h5 className="header">Create Template</h5>
            <span>Home</span>
          </div>
          <div className="container-fluid">
            <div className="card bg-light border-0 shadow p-4">
              <div className="">
                {
                  isError === false ? null :
                      (<ErrorMessageContainer>
                        {errorMessage}
                      </ErrorMessageContainer>)
                }

                {
                  isSuccess=== false ? null :
                      (<SuccessMessageContainer>
                        {successMessage}
                      </SuccessMessageContainer>)
                }


                <form>
                    <section>
                        <div className="d-flex align-items-center mb-2">
                            <SteppedNumber>1</SteppedNumber>
                            <SteppedTitle className="mx-2">Basic Details</SteppedTitle>
                        </div>
                        <Row className="form-row">
                            <Col className="form-group col-xl-6 col-md-6 col-12 mb-3 mb-md-0">
                                <label htmlFor="Full Name">Name</label>
                                <input id="name" name="name" value={formData.name} onChange={handleInput} className="form-control" type="text" />
                            </Col>

                            <Col className="form-group col-xl-6 col-md-6 col-12 ">
                                <label htmlFor="Width">Category</label>
                                <select name="category" onChange={handleInput} className="form-select">
                                    <option value="" hidden>Choose Category</option>
                                    <option value="AUTHENTICATION">AUTHENTICATION</option>
                                    <option value="MARKETING">MARKETING</option>
                                    <option value="UTILITY">UTILITY</option>
                                </select>
                            </Col>
                        </Row>
                        <Row className="form-row mt-3">
                            <Col className="form-group col-xl-12 col-md-12">
                                <label htmlFor="Full Name">Message</label>
                                <textarea id="message" name="message" value={formData.message} onChange={handleInput} className="form-control" type="text" ></textarea>
                            </Col>

                        </Row>

                    </section>

                    <div className="d-flex flex-row my-5 justify-content-end">
                        <SubmitButton type="button" onClick={createWhatsappTemplate} className="confirm-button">
                            {isCreatingAdmin === false ? (
                                <span className="button-text">CREATE TEMPLATE</span>
                            ) : (
                                <span className="spinner-border spinner-grow-sm"/>
                            )}
                        </SubmitButton>
                    </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateWhatsappTemplate;
