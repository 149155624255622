import * as BoxIcons from "react-icons/bi";
import * as HiIcons from "react-icons/hi";
import * as BsIcons from "react-icons/bs";
import * as TbIcons from "react-icons/tb";
import * as RiIcons from "react-icons/ri";
import DashboardIcon from "../assets/sidebar/Dashboard.svg";
import DashboardActiveIcon from "../assets/sidebar/DashboardActive.svg";
import ShipmentsIcon from "../assets/sidebar/Shipments.svg";
import CustomerIcon from "../assets/sidebar/Customers.svg";
import CustomerActiveIcon from "../assets/sidebar/CustomersActive.svg";
import ExtraIcon from "../assets/sidebar/Extras.svg";
import StationsIcon from "../assets/sidebar/Stations.svg";
import AdminsIcon from "../assets/sidebar/Admins.svg";
import AdminsActiveIcon from "../assets/sidebar/AdminsActive.svg";
import TransactionsIcon from "../assets/sidebar/Transactions.svg";
import ReportsIcon from "../assets/sidebar/Reports.svg";
import ReportsActiveIcon from "../assets/sidebar/ReportsActive.svg";
import SettingsIcon from "../assets/sidebar/Settings.svg";
import SettingsActiveIcon from "../assets/sidebar/SettingsActive.svg";
import ShipmentsHistoryIcon from "../assets/sidebar/ShipmentsHistory.svg";
import ShipmentsHistoryActiveIcon from "../assets/sidebar/ShipmentsHistoryActive.svg";
import CreateShipmentIcon from "../assets/sidebar/CreateShipment.svg";
import CreateShipmentActiveIcon from "../assets/sidebar/CreateShipmentActive.svg";
import GetEstimatesIcon from "../assets/sidebar/GetEstimates.svg";
import GetEstimatesActiveIcon from "../assets/sidebar/GetEstimatesActive.svg";
import ContainerIcon from "../assets/sidebar/Containers.svg";
import ContainerActiveIcon from "../assets/sidebar/ContainersActive.svg";
import BatchesIcon from "../assets/sidebar/Batches.svg";
import BatchesActiveIcon from "../assets/sidebar/BatchesActive.svg";
import CustomerWalletIcon from "../assets/sidebar/CustomerWallets.svg";

import {SessionManagement} from "../library/SessionManagement";

const whatsappSetStatus = SessionManagement.getIsWhatsappSet();
console.log("Whatsapp set ==>>>" + whatsappSetStatus);

export const SidebarData = [
  {
    title: "Dashboard",
    path: "/",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown />,
    iconOpened: <BoxIcons.BiChevronUp />,
    icon: <BoxIcons.BiLayout className="icon" />,
  },
  // {
  //   title: "Account",
  //   path: "/account",
  //   isOpened: false,
  //   iconClosed: <BoxIcons.BiChevronDown />,
  //   iconOpened: <BoxIcons.BiChevronUp />,
  //   icon: <BoxIcons.BiMoney className="icon" />,
  // },
  whatsappSetStatus === "Active" ?
  {
    title: "Whatsapp",
    path: "/whatsapp",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown className="chevron-arrow" />,
    iconOpened: <BoxIcons.BiChevronUp className="chevron-arrow" />,
    icon: <BsIcons.BsWhatsapp className="icon" />,
    submenu: [
      {
        title: "Messages",
        path: "/whatsapp/messages",
      },
      {
        title: "Templates",
        path: "/whatsapp/templates",
      },
      {
        title: "Settings",
        path: "/whatsapp/settings",
      },
    ],
  } :
  {
    title: "Whatsapp",
    path: "/whatsapp/messages",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown />,
    iconOpened: <BoxIcons.BiChevronUp />,
    icon: <BsIcons.BsWhatsapp className="icon" />,
  },
  {
    title: "Shipments",
    path: "/shipments",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown className="chevron-arrow" />,
    iconOpened: <BoxIcons.BiChevronUp className="chevron-arrow" />,
    icon: <BoxIcons.BiPackage className="icon" />,
    submenu: [
      {
        title: "Create Shipment",
        path: "/create-shipment",
      },
      {
        title: "All Shipments",
        path: "/shipments/all-shipments",
      },
      {
        title: "Get Estimates",
        path: "/calculate-estimates",
      },
      {
        title: "Pending Approval",
        path: "/shipments/pending-approval",
      },
      {
        title: "Pending Pickup",
        path: "/shipments/pending-pickup",
      },
      {
        title: "Picked Up",
        path: "/shipments/picked-up",
      },
      {
        title: "Pending Delivery",
        path: "/shipments/pending-delivery",
      },
      {
        title: "Delivered",
        path: "/shipments/delivered",
      },
      {
        title: "Rejected",
        path: "/shipments/rejected",
      },
      {
        title: "Cancelled",
        path: "/shipments/cancelled",
      },
      {
        title: "Packages",
        path: "/shipments/packages",
      },
      {
        title: "Batches",
        path: "/shipments/batches",
      },
    ],
  },
  {
    title: "Pending Approvals",
    path: "/pending-kyc",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown className="chevron-arrow" />,
    iconOpened: <BoxIcons.BiChevronUp className="chevron-arrow" />,
    icon: <BoxIcons.BiUserCheck className="icon" />,
    submenu: [
      {
        title: "KYC",
        path: "/pending-kyc",
      },
    ]
  },
  // {
  //   title: "Restaurant",
  //   path: "/restaurant",
  //   isOpened: false,
  //   iconClosed: <BoxIcons.BiChevronDown className="chevron-arrow" />,
  //   iconOpened: <BoxIcons.BiChevronUp className="chevron-arrow" />,
  //   icon: <BoxIcons.BiPackage className="icon" />,
  //   submenu: [
  //     {
  //       title: "All Orders",
  //       path: "/restaurant/orders",
  //     }
  //   ],
  // },
  {
    title: "Transactions",
    path: "/transactions",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown />,
    iconOpened: <BoxIcons.BiChevronUp />,
    icon: <BoxIcons.BiCoinStack className="icon" />,
  },
  {
    title: "Customers",
    path: "/customers",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown />,
    iconOpened: <BoxIcons.BiChevronUp />,
    icon: <BoxIcons.BiUser className="icon" />,
  },
  {
    title: "Customers Wallet",
    path: "/customers-wallet",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown />,
    iconOpened: <BoxIcons.BiChevronUp />,
    icon: <BsIcons.BsWallet2 className="icon" />,
  },
  {
    title: "Admins",
    path: "/admins",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown />,
    iconOpened: <BoxIcons.BiChevronUp />,
    icon: <BoxIcons.BiUserCircle className="icon" />,
  },
  {
    title: "Marketers",
    path: "/marketers",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown />,
    iconOpened: <BoxIcons.BiChevronUp />,
    icon: <BoxIcons.BiUserCircle className="icon" />,
  },
  {
    title: "Stations & Hubs",
    path: "/stations",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown />,
    iconOpened: <BoxIcons.BiChevronUp />,
    icon: <HiIcons.HiOutlineOfficeBuilding className="icon" />,
    submenu: [
      {
        title: "Create Station",
        path: "/stations/create",
      },
      {
        title: "All Stations",
        path: "/stations",
      }
    ],
  },
  {
    title: "Partners",
    path: "/partners",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown />,
    iconOpened: <BoxIcons.BiChevronUp />,
    icon: <RiIcons.RiUserStarLine className="icon" />,
  },
  // {
  //   title: "Discounts & Promos",
  //   path: "/discounts-promos",
  //   isOpened: false,
  //   iconClosed: <BoxIcons.BiChevronDown />,
  //   iconOpened: <BoxIcons.BiChevronUp />,
  //   icon: <AiIcons.AiOutlineTag className="icon" />,
  // },
  {
    title: "Transporters",
    path: "/transporters",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown />,
    iconOpened: <BoxIcons.BiChevronUp />,
    icon: <BoxIcons.BiRun className="icon" />,
  },
  {
    title: "Reports",
    path: "/reports",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown />,
    iconOpened: <BoxIcons.BiChevronUp />,
    icon: <TbIcons.TbBrandGoogleAnalytics className="icon" />,
  },
  {
    title: "Settings",
    path: "/settings",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown />,
    iconOpened: <BoxIcons.BiChevronUp />,
    icon: <BoxIcons.BiCog className="icon" />,
  },
];

export const newSidebarData = [
  {
    title: "Dashboard",
    path: "/",
    isOpened: false,
    icon: DashboardIcon,
    active: DashboardActiveIcon,
    permission: "show_dashboard"
  },
  {
    title: "Shipments",
    path: "/shipments/all-shipments",
    isOpened: false,
    icon: ShipmentsIcon,
    permission: "show_shipments",
    submenu: [
      {
        title: "Shipment History",
        path: "/shipments/all-shipments",
        icon: ShipmentsHistoryIcon,
        active: ShipmentsHistoryActiveIcon
      },
      {
        title: "Create Shipments",
        path: "/create-shipment",
        icon: CreateShipmentIcon,
        active: CreateShipmentActiveIcon
      },
      {
        title: "Get Estimates",
        path: "/calculate-estimates",
        icon: GetEstimatesIcon,
        active: GetEstimatesActiveIcon
      },
      {
        title: "Containers",
        path: "/shipments/packages",
        icon: ContainerIcon,
        active: ContainerActiveIcon
      },
      {
        title: "Services",
        path: "/shipments/charged-services",
        icon: BatchesIcon,
        active: BatchesActiveIcon
      },
      {
        title: "Batches",
        path: "/shipments/batches",
        icon: BatchesIcon,
        active: BatchesActiveIcon
      },
    ],
  },
  {
    title: "Customers",
    path: "/customers",
    isOpened: false,
    icon: CustomerIcon,
    permission: "show_customers",
    submenu: [
      {
        title: "All Customers",
        path: "/customers",
        icon: CustomerIcon,
        active: CustomerActiveIcon
      },
      {
        title: "Customer Wallets",
        path: "/customers-wallet",
        icon: CustomerWalletIcon,
        active: CustomerWalletIcon
      },
    ]
  },
  // {
  //   title: "External/ Extras",
  //   path: "/customers-wallet",
  //   isOpened: false,
  //   icon: ExtraIcon,
  //   submenu: []
  // },
  {
    title: "Stations & Hubs",
    path: "/stations",
    isOpened: false,
    icon: StationsIcon,
    active: StationsIcon,
    permission: ""
  },
  {
    title: "Admins",
    path: "/admins",
    isOpened: false,
    icon: AdminsIcon,
    active: AdminsActiveIcon,
    permission: "show_admins",
  },
  {
    title: "Transactions",
    path: "/transactions",
    isOpened: false,
    icon: TransactionsIcon,
    active: TransactionsIcon,
    permission: "show_transactions"
  },
  {
    title: "Transporters",
    path: "/transporters",
    isOpened: false,
    icon: AdminsIcon,
    active: AdminsActiveIcon,
    permission: "show_transporters"
  },
  {
    title: "Reports",
    path: "/reports",
    isOpened: false,
    icon: ReportsIcon,
    active: ReportsActiveIcon,
    permission: "show_reports",
  },
  {
    title: "Settings",
    path: "/settings",
    isOpened: false,
    icon: SettingsIcon,
    active: SettingsActiveIcon,
    permission: ""
  },
];
