import "../../styles/login.scss";
import styled from "styled-components";
import { loginURL } from "../../library/URLs";
import { useRef, useState } from "react";
import axios from "axios";
import { ErrorNotification } from "../../components/Notifications";
import { Link, useNavigate } from "react-router-dom";
import { generateTransactionID } from "../../library/utilities";
import { SessionManagement } from "../../library/SessionManagement";
import { PasswordBox, TextBox } from "../../components/InputFields";
import { BsChevronRight } from "react-icons/bs";
import ParcelflowLogo from "../../assets/Parcelflow-Logo.svg";

const PageHeader = styled.h4`
  font-family: Prompt,sans-serif;
  font-size: 32px;
  margin-bottom: 8px;
  color: #000;

  @media(max-width:556px) {
    font-size: 2.4rem;
  }
`;

const PageSubheader = styled.h4`
  font-size: 14px;
  margin-bottom: 60px;
  color: #667185;
  font-weight: 400;
`;

const PageFooter = styled.h4`
  font-size: 17px;
  text-align: center;
  margin-bottom: 20px;
`;

const Login = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const form = useRef(null);

  const clearErrorMessage = () => {
    setErrorVisibility(false);
    setErrorMessage("");
  };

  function getDevice() {
    let operatingSystem = 'Not known';
    if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
    if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
    if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
    if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }

    let currentBrowser = 'Not known';
    if (window.navigator.userAgent.indexOf('Chrome') !== -1) { currentBrowser = 'Chrome'; }
    else if (window.navigator.userAgent.indexOf('Firefox') !== -1) { currentBrowser = 'Mozilla Firefox'; }
    else if (window.navigator.userAgent.indexOf('MSIE') !== -1) { currentBrowser = 'Internet Exployer'; }
    else if (window.navigator.userAgent.indexOf('Edge') !== -1) { currentBrowser = 'Edge'; }
    else if (window.navigator.userAgent.indexOf('Safari') !== -1) { currentBrowser = 'Safari'; }
    else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'Opera'; }
    else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'YaBrowser'; }

    return currentBrowser + " on " + operatingSystem;
  }

  const authenticated_routes = [
    {
      privilege: "show_dashboard",
      route: "/"
    },
    {
      privilege: "show_shipments",
      route: "/shipments/all-shipments"
    },
    {
      privilege: "show_customers",
      route: "/customers"
    },
    {
      privilege: "show_transactions",
      route: "/transactions"
    },
    {
      privilege: "show_admins",
      route: "/admins"
    },
    {
      privilege: "show_transporters",
      route: "/transporters"
    },
    {
      privilege: "show_reports",
      route: "/reports"
    }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    clearErrorMessage();

    const data = new FormData(form.current);

    const formValues = {};

    for (let [key, value] of data.entries()) {
      formValues[key] = value;
    }
    formValues["device_id"] = "value";
    formValues["device_name"] = getDevice();
    formValues["token"] = "value";
    formValues["transaction_id"] = generateTransactionID(15);

    setIsSubmitting(true);

    axios
      .post(loginURL, formValues)
      .then(function (response) {
        console.log(response);
        setIsSubmitting(false);

        if (response["data"]["success"] === false) {
          setErrorMessage(response["data"]["message"]);
          setErrorVisibility(true);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        } else if (response["data"]["success"] === true) {
          const name = response["data"]["data"]["name"];
          const email = response["data"]["data"]["email"];
          const phone = response["data"]["data"]["phone"];
          const companyID = response["data"]["data"]["company_id"];
          const companyName = response["data"]["data"]["company_name"];
          const companyLogoURL = response["data"]["data"]["company_logo_url"];
          const companyOperatorID = response["data"]["data"]["operator_id"];
          const profileImageURL = response["data"]["data"]["profile_image_url"];
          const userID = response["data"]["data"]["user_id"];
          const sessionID = response["data"]["data"]["user_ses_id"];
          const employeeType = response["data"]["data"]["employee_type"];
          const environmentalType = response["data"]["data"]["environment_type"];

          const privileges = response["data"]["privileges"];

          SessionManagement.setName(name);
          SessionManagement.setEmail(email);
          SessionManagement.setPhone(phone);
          SessionManagement.setCompanyID(companyID);
          SessionManagement.setCompanyName(companyName);
          SessionManagement.setProfileImageURL(profileImageURL);
          SessionManagement.setUserID(userID);
          SessionManagement.setSessionID(sessionID);
          SessionManagement.setCompanyLogoURL(companyLogoURL);
          SessionManagement.setCompanyOperatorID(companyOperatorID);
          SessionManagement.setAuthenticationStatus("yes");
          SessionManagement.setEmployeeType(employeeType);
          SessionManagement.setEnvironmentalType(environmentalType);

          SessionManagement.setUserPrivileges(privileges);

          let route = "/stations";
          for (const page of authenticated_routes) {
            if (privileges[page.privilege] === 1) {
              route = page.route;
              break;
            }
          };

          navigate(route, {replace: true});
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  return (
    <div >
      <div className="Auth-Wrap" >
        <div className="Auth-Left"></div>
        <div className="Auth-Left-Fixed" >
          <div className="logo-contain" >
            <img src={ParcelflowLogo} alt="logo" />
          </div>
          <div className="logo-contain" >
            <p className="text-redirect" >Are you new here? <Link to="/register" >Create Account</Link></p>
          </div>
        </div>
        <div className="Auth-Right" >
          <div className="Auth-form" >
            {isError === true ? (
              <ErrorNotification message={errorMessage}>
                This is a notification
              </ErrorNotification>
            ) : null}
            <PageHeader className="page-header feature-header">Continue to Parcelflow</PageHeader>
            <PageSubheader>
              Log In with your details to continue
            </PageSubheader>
            <form ref={form} onSubmit={handleSubmit}>
              <section>
                <div className="input-contain mb-4" >
                  <label htmlFor="email">Email</label>
                  <TextBox 
                    placeholder="Your Email"
                    name="email"
                    type="email"
                  />
                </div>

                <div className="input-contain" >
                  <label htmlFor="email">Password</label>
                  <PasswordBox 
                    placeholder="Your Password"
                    className="login-input"
                    name="password"
                    type="password"
                  />
                </div>
              </section>

              <div className="d-flex justify-content-end mt-2" >
                <Link to="/forgot-password" className="password-link" >Forgot Password?</Link>
              </div>

              <div className="d-flex flex-row mt-5 justify-content-end">
                <button type="submit" className="login-button btn-primary rounded">
                  {isSubmitting === false ? (
                      <span className="button-text d-flex gap-2 align-items-center justify-content-center">
                        LOGIN <BsChevronRight />
                      </span>
                  ) : (
                      <span className="spinner-border spinner-grow-sm"/>
                  )}
                </button>
              </div>

              <p className="auth-terms-text" >
                By clicking on “login” you agree to Parcelflow&apos;s Terms of Service and Privacy Policy
              </p>

              {/* <PageSubheader>
                <a href="/forgot-password">Forogtten Password</a>
              </PageSubheader> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
