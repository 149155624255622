import "../styles/home.scss";
import "../styles/customers.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { UserProfileData } from "../library/constants";
import axios from "axios";
import {
  getCustomersURL,
  getShipmentsURL,
  searchForCustomersURL,
} from "../library/URLs";
import styled from "styled-components";
import * as React from "react";
import { BsDot } from "react-icons/bs";
import { map } from "react-bootstrap/ElementChildren";
import SubscriptionInactive from "../components/SubscriptionInactive";
import { SearchBox } from "../components/InputFields";
import Group from "../assets/customers/group.svg";
import Contract from "../assets/customers/workspace_premium.svg";
import Regular from "../assets/customers/military_tech.svg";
import Individual from "../assets/customers/individual.svg";
import Business from "../assets/customers/user-group.svg";
import Logistics from "../assets/customers/truck.svg";
import Pagination from "../components/Pagination";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const Customers = () => {
  const form = useRef(null);
  const navigator = useNavigate();
  const params = useParams();

  const [searchParam] = useSearchParams();

  const page = searchParam.get("page");

  useEffect(() => {
    if(page) {
      setCurrentPage(parseInt(page));
      window.scrollTo(0,0);
    }
  }, [page]);

  const [currentOffset, setCurrentOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [isQuerySearching, setIsQuerySearching] = useState(false);
  const [resultContainerVisibility, setResultContainerVisibility] =
    useState(false);
  const [customerQueryResults, setCustomerQueryResults] = useState([]);

  const getCustomers = async () => {
    const data = {
      limit: 20,
      offset: currentOffset,
      status: "All",
      paginated: "Yes",
      page: page ?? "1",
    };

    const newData = { ...data, ...UserProfileData() };
    setIsLoading(true);

    await axios
    .post(getCustomersURL, newData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {

      setIsLoading(false);

      if (response["data"]["success"] === false) return;

      const newCustomers = response["data"]["data"]["users"];
      const total_pages = response["data"]["data"]["total_pages"];


      setCustomers(newCustomers);
      setTotalPages(parseInt(total_pages));
    });
  };

  useEffect(() => {
    getCustomers();

    // eslint-disable-next-line
  }, [page]);

  const handleClick = (customerID) => {
    navigator("/customer/" + customerID);
  };

  const searchForCustomers = (e) => {
    e.preventDefault();
  };

  const handleQueryChange = () => {
    const formData = new FormData(form.current);
    const query = formData.get("query");
    setQuery(query);

    if (query.toString().trim() === "") {
      setIsQuerySearching(false);
      setResultContainerVisibility(false);

      return null;
    }

    const formValues = {};
    formValues["query"] = query;
    formValues["limit"] = "5";

    const newData = { ...formValues, ...UserProfileData() };

    setIsQuerySearching(true);
    setResultContainerVisibility(true);

    axios.post(searchForCustomersURL, newData).then(function (response) {
      console.log(response);

      if (response["data"]["success"] === false) return;

      const customers = response["data"]["data"];

      setCustomerQueryResults(customers);
    });
  };

  const paginate = (num) => {
    setCurrentPage(num);
    navigator(`/customers?page=${num}`);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
    navigator(`/customers?page=${currentPage + 1}`);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
    navigator(`/customers?page=${currentPage - 1}`);
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />
          <div className="container-fluid shipments-container">
            <div
              className="card border-0 p-4 min-vh-100"
              style={{ backgroundColor: "#FFF" }}
            >
              <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  <img src={Group} alt="group" />
                  <h5 className="mb-2">Customers</h5>
                </div>
                <div className="d-flex flex-column flex-md-row gap-3">
                  <form
                    className="query-result-container "
                    ref={form}
                    onSubmit={searchForCustomers}
                  >
                    <SearchBox
                      onChange={handleQueryChange}
                      name="query"
                      value={query}
                      placeholder="Search using customer's name or number"
                    />
                    <div
                      className={`query-result ${
                        resultContainerVisibility === true
                          ? "result-visible"
                          : null
                      }`}
                    >
                      {setIsQuerySearching === true
                        ? null
                        : customerQueryResults.map((customer) => {
                            return (
                              <div
                                key={customer.id}
                                className="d-flex flex-column"
                                onClick={() => handleClick(customer.id)}
                              >
                                <span style={{ fontSize: 15 }}>
                                  {customer.fullname}
                                </span>
                                <span className="d-flex align-items-center">
                                  <span style={{ fontSize: 13 }}>
                                    {customer.phone}
                                  </span>
                                  <BsDot />
                                  <span style={{ fontSize: 13 }}>
                                    {customer.email}
                                  </span>
                                </span>
                              </div>
                            );
                          })}
                    </div>
                  </form>
                </div>
              </div>
              {/* <div className="d-flex justify-content-end mb-3">
                        
                    </div> */}
              <div className="table-contain w-100">
                <table className="shipments-table w-100">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col">Customer Type</th>
                      <th scope="col">Customer Level</th>
                    </tr>
                  </thead>
                  {isLoading === true ? null : (
                    <tbody>
                      {customers.map((customer) => {
                        return (
                          <tr
                            key={customer.id}
                            onClick={() => handleClick(customer.id)}
                          >
                            <td>{customer.fullname}</td>
                            <td>{customer.email}</td>
                            <td>{customer.phone}</td>
                            <td >
                              <span className="d-flex align-items-center gap-1" >
                                {customer.member_type === "Contract" ? (
                                  <img src={Contract} alt="contract" />
                                ) : (
                                  <img src={Regular} alt="Regular" />
                                )}
                                {customer.member_type}
                              </span>
                            </td>
                            <td>
                            <span style={{whiteSpace:"nowrap"}} className="d-flex align-items-center gap-1" >
                              {customer.account_type === "Individual" ? (
                                <img src={Individual} alt="individual" />
                              ) : customer.account_type === "Business" ? (
                                <img src={Business} alt="business" />
                              ) : (
                                <img src={Logistics} alt="3pl" />
                              )}
                              {customer.account_type}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
              {!isLoading && (
                <Pagination
                  total_pages={totalPages}
                  paginate={paginate}
                  nextPage={nextPage}
                  prevPage={prevPage}
                  current_page={currentPage}
                />
              )}

              {isLoading === false ? null : (
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
