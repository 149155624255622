import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import {
  addAdditionalServiceUrl
} from "../library/URLs";
import { useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import { TextBox, TextFieldBox } from "../components/InputFields";
import { BsSdCard, BsX } from "react-icons/bs";
import { styled as mui_styled } from "@mui/material/styles";
import { Switch } from "@mui/material";

const SubmitButton = styled.button``;

const ParcelflowSwitch = mui_styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 34,
  height: 16,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#15AB68",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 12,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#98A2B3",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const AddServicesDialog = ({ isModalVisible, handleCloseDialog, onSubmit }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [serviceInfo, setServiceInfo] = useState({
    service_name: "",
    description: "",
    fee: "",
    show_interstate: "Yes",
    show_international: "Yes",
  });

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const addService = (e) => {
    e.preventDefault();
    const formValues = {
      ...serviceInfo,
    };
    // formValues['customer_id'] = adminID;

    const newData = { ...formValues, ...UserProfileData() };

    setErrorVisibility(false);
    setIsSubmitting(true);

    axios
      .post(addAdditionalServiceUrl, newData)
      .then(function (response) {
        setIsSubmitting(false);
        if (response["data"]["success"] === true) {
          setSuccessVisibility(true);
          setSuccessMessage("Service added successfully");

          setTimeout(() => {
            setServiceInfo({
              service_name: "",
              fee: "",
              description: "",
              show_international: "Yes",
              show_interstate: "Yes",
            });
            onSubmit();
            handleCloseDialog();
          }, 5000);
        } else {
          setErrorMessage(response["data"]["message"]);
          setErrorVisibility(true);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        setIsSubmitting(false);
        console.log(error);
        return error;
      });
  };

  const handleInput = (e) => {
    const { value, name } = e.target;

    setServiceInfo({
      ...serviceInfo,
      [name]: value,
    });
  };

  const handleToggle = (name, value) => {
    setServiceInfo({
      ...serviceInfo,
      [name]: value,
    });
  };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">ADD NEW SERVICE</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        <p>Fill the details below to add service</p>
        <div className="query-result-container">
          <div>
            <div className="mb-3" >
              <label htmlFor="Description" className="mb-2">
                Service Name
              </label>
              <TextBox
                type="text"
                name="service_name"
                value={serviceInfo.service_name}
                onChange={handleInput}
                placeholder="Input Service Name"
              />
            </div>
          </div>
          <div>
            <div>
              <label htmlFor="Description" className="mb-2">
                Service Fee
              </label>
              <TextBox
                type="text"
                name="fee"
                value={serviceInfo.fee}
                onChange={handleInput}
                placeholder="Input Service Fee"
              />
            </div>
          </div>
          <Row className="gap-3 gap-md-0 my-3">
            <Col className=" col-12 col-md-6 d-flex gap-3 align-items-center">
              <label className="" htmlFor="Description">
                For Interstate
              </label>
              <ParcelflowSwitch
                name="show_interstate"
                onChange={() =>
                  handleToggle(
                    "show_interstate",
                    serviceInfo?.show_interstate === "Yes" ? "No" : "Yes"
                  )
                }
                checked={serviceInfo?.show_interstate === "Yes"}
                className="react-switch"
              />
            </Col>
            <Col className=" col-12 col-md-6 d-flex gap-3 align-items-center">
              <label className="" htmlFor="Description">
                For International
              </label>
              <ParcelflowSwitch
                name="show_international"
                onChange={() =>
                  handleToggle(
                    "show_international",
                    serviceInfo?.show_international === "Yes" ? "No" : "Yes"
                  )
                }
                checked={serviceInfo?.show_international === "Yes"}
                className="react-switch"
              />
            </Col>
          </Row>
          <div>
            <div>
              <label htmlFor="Description" className="mb-2">
                Description
              </label>
              <TextFieldBox
                name="description"
                value={serviceInfo.description}
                onChange={handleInput}
                placeholder="Input Service Description"
              />
            </div>
          </div>
        </div>
        <p className="mt-3">Would you like to continue?</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-secondary mx-3" onClick={handleCloseDialog}>
          <BsX />
          Cancel
        </button>
        <SubmitButton
          onClick={addService}
          type="submit"
          className="btn-primary"
        >
          {isSubmitting === false ? (
            <span className="button-text d-flex align-items-center gap-2">
              <BsSdCard /> Save Service
            </span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AddServicesDialog;
