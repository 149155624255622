import { Modal } from "react-bootstrap";
import { BiX } from "react-icons/bi";
import * as React from "react";
import {
  getNotificationsURL,
  markNotificationsAsReadURL,
} from "../library/URLs";
import { UserProfileData } from "../library/constants";
import axios from "axios";
import styled from "styled-components";
import UserIcon from "../assets/notifications/person_apron.svg";
import ShipmentIcon from "../assets/notifications/deployed_code.svg";
import MoneyIcon from "../assets/notifications/payments.svg";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { BsBellFill, BsX } from "react-icons/bs";

const LoadingSpinner = styled.div`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const NotificationDialog = ({ isModalVisible, handleCloseDialog }) => {
  const [currentOffset, setCurrentOffset] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isEmpty, setIsEmpty] = React.useState(false);
  const [notifications, setNotifications] = React.useState([]);
  const [notificationCount, setNotificationCount] = React.useState(0);
  const navigate = useNavigate();

  const getAllNotifications = async () => {
    const data = {
      limit: 50,
      offset: currentOffset,
    };

    const newData = { ...data, ...UserProfileData() };

    await axios
      .post(getNotificationsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        setIsLoading(false);

        if (response["data"]["success"] === false) return;

        const all_notifications = response["data"]["data"]?.filter(
          (item) => item?.status === "0"
        );

        if (all_notifications.length === 0) setIsEmpty(true);
        setNotificationCount(all_notifications?.length);

        // markNotificationsAsRead();

        setNotifications(all_notifications?.slice(0, 10));
      });
  };

  React.useEffect(() => {
    if(isModalVisible) {
      getAllNotifications();
    }
    // eslint-disable-next-line
  }, [isModalVisible]);
  return (
    <div className="dialog-notification-wrap">
      <div className="d-flex mb-3 justify-content-between align-items-center">
        <p className="d-flex gap-2 align-items-center notification-header m-0">
          <BsBellFill className="notification-close" />
          Notifications
        </p>
        <BsX onClick={handleCloseDialog} className="notification-close" style={{cursor:"pointer"}} />
      </div>
      {isLoading && (
        <div className="d-flex w-100 justify-content-center align-items-center" >
          <LoadingSpinner className="spinner-border spinner-grow-sm" />
        </div>
      )}
      {!isLoading && (
        <div className="notification-dialog">
          {isEmpty ? (
            <p className="notification-dialog-empty">No unread notifications</p>
          ) : (
            <ul
              className="notification-dialog-menu"
              style={{ maxHeight: "60vh", overflow: "auto" }}
            >
              <li className="notification-dialog-count">
                Unread notifications ({notificationCount})
              </li>
              {notifications?.map((notification) => {
                const dateTime = moment(notification.date_time);
                const timestamp = dateTime.format("ddd MMM Do, h:mm A");

                let iconImage;
                if (notification.type === "Shipment") {
                  iconImage = (
                    <div className="notification-dialog-shipment-icon-class">
                      <img src={ShipmentIcon} alt="shipment" />
                    </div>
                  );
                } else if (notification.type === "Account") {
                  iconImage = (
                    <div className="notification-dialog-shipment-icon-class">
                      <img src={MoneyIcon} alt="account" />
                    </div>
                  );
                } else if (notification.type === "User") {
                  iconImage = (
                    <div className="notification-dialog-user-icon-class">
                      <img src={UserIcon} alt="user" />
                    </div>
                  );
                }

                return (
                  <li
                    key={notification.id}
                    className="notification-dialog-list-item mb-2"
                    onClick={()=>navigate("/notifications")}
                    style={{cursor:"pointer"}}
                  >
                    {iconImage}
                    <div className="w-100">
                      <div className="d-flex w-100 align-items-center mb-2 justify-content-between">
                        <p className="notification-dialog-text-small m-0">
                          {notification.type}
                        </p>
                        <p className="notification-dialog-text-small m-0">
                          {timestamp}
                        </p>
                      </div>
                      <p className="notification-dialog-message m-0">
                        {notification.message}{" "}
                      </p>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
          <div>
            <Link to="/notifications" className="notification-dialog-link">
              {isEmpty ? "Open Notifications" : "View all notifications"}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationDialog;
